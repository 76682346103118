import { apiUrl, client } from '../axios';
import { AuthSDK } from '../utils/auth_provider';
import { setError } from '../../components/indicators_and_messaging/toast';
import * as Sentry from '@sentry/react';
import { getUValue } from './u_value';
import _ from 'lodash';
import { ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL } from './room';
import { isFlagSet } from '../helpers';
import { estimateAgeBandToSurveyAgeBand } from './age_bands';
export const MATERIAL_ELEMENT_NAMES = {
    roof: 'Roof',
    'external-wall': 'External Wall',
    'internal-wall': 'Internal Wall',
    'party-wall': 'Party Wall',
    'intermediate-floor-and-ceiling': 'Intermediate Floor/Ceiling',
    'exposed-floor': 'Exposed Floor',
    'ground-floor': 'Ground Floor',
    window: 'Window',
    door: 'Door'
};
export const isMaterialAgeBandIntersectsSurveyAgeBand = (ageBand, materialAgeBand) => {
    const lowerBound = Math.max(ageBand.min_year || 0, materialAgeBand.min_year || 0);
    const upperBound = Math.min(ageBand.max_year || Infinity, materialAgeBand.max_year || Infinity);
    return lowerBound <= upperBound;
};
const findMaterialForConstructionAgeBand = (materials, ageBand) => {
    return materials.find(m => m.age_bands.some(ab => isMaterialAgeBandIntersectsSurveyAgeBand(ageBand, ab)));
};
export const instantiateMaterialSet = (materialsSet, setCustomMaterials) => {
    // check is all the materials are exists, if not, create them
    // iterate each material and create it if it doesn't exist
    Object.keys(materialsSet).forEach(key => {
        const material = materialsSet[key];
        if (!material) {
            return;
        }
        if (!material.uuid || material.uuid.length === 0) {
            setCustomMaterials(prev => [...prev, { ...material, uuid: crypto.randomUUID(), created_at: new Date().getTime(), updated_at: new Date().getTime(), is_modified: true }]);
        }
    });
};
export const getSimpleName = (surface, uValue) => {
    return MATERIAL_ELEMENT_NAMES[surface] + ' - ' + uValue + ' W/m²K';
};
export const getLayersName = (layers) => layers.map(l => l.material_layer.extra_data.material + ' - ' + l.layer_thickness_mm.toFixed(0) + 'mm').join('. ');
export const mapElementByOverrideOrEPC = (overrideUValue, epc, epcAttribute, customMaterials, elementType, companyUUID) => {
    const customUValue = overrideUValue !== null && overrideUValue !== void 0 ? overrideUValue : (epc && getUValue(epc[epcAttribute]));
    if (!customUValue)
        return undefined;
    const customMaterial = customMaterials.find(x => x.applicable_to === elementType && x.extra_data.u_value === customUValue);
    if (customMaterial)
        return customMaterial;
    // Create custom value if it doesn't exist.
    return {
        uuid: undefined,
        name: getSimpleName(elementType, customUValue),
        type: 'custom_simple',
        applicable_to: elementType,
        extra_data: {
            u_value: customUValue
        },
        build_type: undefined,
        age_bands: [],
        layers: [],
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
        server_updated_at: 0,
        deleted_at: undefined,
        company_uuid: companyUUID,
        is_modified: true
    };
};
export const mapEstimateMaterials = (lead, genericMaterials, customMaterials, companyUUID) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    // map only generic materials
    const customSimpleMaterials = customMaterials.filter(x => x.type === 'custom_simple');
    // get some information from the Lead
    const leadLoftInsulation = ((_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.loftInsulation) ? lead.property.houseOverrides.loftInsulation : lead.property.loftInsulation;
    const leadWallGroup = ((_b = lead.property.houseOverrides) === null || _b === void 0 ? void 0 : _b.wallGroup) ? lead.property.houseOverrides.wallGroup : lead.property.wallGroup;
    const leadWallType = ((_c = lead.property.houseOverrides) === null || _c === void 0 ? void 0 : _c.wallType) ? lead.property.houseOverrides.wallType : lead.property.wallType;
    const leadFloorType = ((_d = lead.property.houseOverrides) === null || _d === void 0 ? void 0 : _d.floorType) ? lead.property.houseOverrides.floorType : lead.property.floorType;
    const leadWindowType = ((_e = lead.property.houseOverrides) === null || _e === void 0 ? void 0 : _e.windowType) ? lead.property.houseOverrides.windowType : lead.property.windowType;
    const ageBand = estimateAgeBandToSurveyAgeBand(lead);
    // MAPPING RULES are defined here: https://share.cleanshot.com/MN373bNT
    // and here: https://www.figma.com/file/AonpPIOgtqRuknUdA32vZ0/Architecture?type=whiteboard&node-id=342-2347&t=uyQ6ddIa4u7L0X8i-4
    // map roof
    let roofMapping;
    // try to map by UValue override or an UValue from EPC
    roofMapping = mapElementByOverrideOrEPC((_f = lead.property.houseOverrides) === null || _f === void 0 ? void 0 : _f.roofUValueOverride, lead.epcData, 'roofDescription', customSimpleMaterials, 'roof', companyUUID);
    // if no mapping found, try to map by type
    if (!roofMapping) {
        const roofMaterials = genericMaterials.filter(x => x.applicable_to === 'roof');
        roofMapping = roofMaterials.find(x => x.extra_data.estimate_mapping === leadLoftInsulation);
        // try to map by age band
        if (!roofMapping && ageBand) {
            roofMapping = findMaterialForConstructionAgeBand(roofMaterials, ageBand);
        }
    }
    // map external wall
    let externalWallMapping;
    // try to map by UValue override or an UValue from EPC
    externalWallMapping = mapElementByOverrideOrEPC((_g = lead.property.houseOverrides) === null || _g === void 0 ? void 0 : _g.externalWallUValueOverride, lead.epcData, 'wallsDescription', customSimpleMaterials, 'external-wall', companyUUID);
    // if no mapping found, try to map by type
    if (!externalWallMapping) {
        const externalWallMaterials = genericMaterials.filter(x => x.applicable_to === 'external-wall');
        let wallTypeEvened;
        if (leadWallType === undefined || ['', 'null', 'idk', 'none'].includes(leadWallType.toLowerCase().trim())) {
            wallTypeEvened = 'None';
        }
        else {
            wallTypeEvened = leadWallType;
        }
        // find the material by wall group and wall type
        externalWallMapping = externalWallMaterials.find(x => {
            return x.extra_data.estimate_mapping_wall_group === leadWallGroup && x.extra_data.estimate_mapping_insulation === wallTypeEvened;
        });
        // try to map by age band
        if (!externalWallMapping && ageBand) {
            externalWallMapping = findMaterialForConstructionAgeBand(externalWallMaterials, ageBand);
        }
    }
    // map internal wall
    const internalWallMaterials = genericMaterials.filter(x => x.applicable_to === 'internal-wall');
    let internalWallMapping = internalWallMaterials.find(x => x.extra_data.estimate_mapping === leadWallGroup);
    // try to map by age band
    if (!internalWallMapping && ageBand) {
        internalWallMapping = findMaterialForConstructionAgeBand(internalWallMaterials, ageBand);
    }
    // map party wall
    let partyWallMapping;
    // try to map by UValue override
    partyWallMapping = mapElementByOverrideOrEPC((_h = lead.property.houseOverrides) === null || _h === void 0 ? void 0 : _h.partyWallUValueOverride, lead.epcData, 'wallsDescription', customSimpleMaterials, 'party-wall', companyUUID);
    // if no mapping found, try to map by type
    if (!partyWallMapping) {
        const partyWallMaterials = genericMaterials.filter(x => x.applicable_to === 'party-wall');
        // try to map by age band
        if (ageBand) {
            partyWallMapping = findMaterialForConstructionAgeBand(partyWallMaterials, ageBand);
        }
        // FIXME: this equals to the first and probably only material in the list, which is `MCS default party wall`
        if (!partyWallMapping) {
            partyWallMapping = partyWallMaterials[0];
        }
    }
    // map intermediate floor and ceiling
    const intermediateFloorAndCeilingMaterials = genericMaterials.filter(x => x.applicable_to === 'intermediate-floor-and-ceiling');
    let intermediateFloorAndCeilingMapping = intermediateFloorAndCeilingMaterials.find(x => {
        // map uncertain values to a single one
        let floorTypeEvened;
        if (leadFloorType === undefined || ['', 'none'].includes(leadFloorType.toLowerCase().trim())) {
            floorTypeEvened = 'None';
        }
        else {
            floorTypeEvened = leadFloorType;
        }
        return x.extra_data.estimate_mapping === floorTypeEvened;
    });
    // try to map by age band
    if (!intermediateFloorAndCeilingMapping && ageBand) {
        intermediateFloorAndCeilingMapping = findMaterialForConstructionAgeBand(intermediateFloorAndCeilingMaterials, ageBand);
    }
    // map exposed floor
    const exposedFloorMapping = getExposedFloorMaterial(genericMaterials, ageBand);
    // map ground floor
    let groundFloorMapping;
    // try to map by UValue override
    groundFloorMapping = mapElementByOverrideOrEPC((_j = lead.property.houseOverrides) === null || _j === void 0 ? void 0 : _j.floorUValueOverride, lead.epcData, 'floorDescription', customSimpleMaterials, 'ground-floor', companyUUID);
    if (!groundFloorMapping) {
        const groundFloorMaterials = genericMaterials.filter(x => x.applicable_to === 'ground-floor');
        groundFloorMapping = groundFloorMaterials.find(x => {
            // map uncertain values to a single one
            let floorTypeEvened;
            if (leadFloorType === undefined || ['', 'none'].includes(leadFloorType.toLowerCase().trim())) {
                floorTypeEvened = 'None';
            }
            else {
                floorTypeEvened = leadFloorType;
            }
            return x.extra_data.estimate_mapping === floorTypeEvened;
        });
        // try to map by age band
        if (!groundFloorMapping && ageBand) {
            groundFloorMapping = findMaterialForConstructionAgeBand(groundFloorMaterials, ageBand);
        }
    }
    // map windows
    let windowsMapping;
    // try to map by UValue override or an UValue from EPC
    windowsMapping = mapElementByOverrideOrEPC((_k = lead.property.houseOverrides) === null || _k === void 0 ? void 0 : _k.windowsUValueOverride, lead.epcData, 'windowsDescription', customSimpleMaterials, 'window', companyUUID);
    // if no mapping found, try to map by type
    if (!windowsMapping) {
        const windowsMaterials = genericMaterials.filter(x => x.applicable_to === 'window');
        windowsMapping = windowsMaterials.find(x => {
            // map uncertain values to a single one
            let windowTypeEvened;
            if (leadWindowType === undefined) {
                windowTypeEvened = 'None';
            }
            else if (leadWindowType.toLowerCase().trim() === 'single') {
                windowTypeEvened = 'Single Glazed';
            }
            else if (leadWindowType.toLowerCase().trim() === 'double') {
                windowTypeEvened = 'Double Glazed';
            }
            else if (leadWindowType.toLowerCase().trim() === 'triple') {
                windowTypeEvened = 'Triple Glazed';
            }
            else {
                windowTypeEvened = leadWindowType;
            }
            return x.extra_data.estimate_mapping === windowTypeEvened;
        });
        // try to map by age band
        if (!windowsMapping && ageBand) {
            windowsMapping = findMaterialForConstructionAgeBand(windowsMaterials, ageBand);
        }
    }
    // map doors
    const doorsMaterials = genericMaterials.filter(x => x.applicable_to === 'door');
    let doorsMapping = doorsMaterials.find(x => {
        // map uncertain values to a single one
        let windowTypeEvened;
        if (leadWindowType === undefined) {
            windowTypeEvened = 'None';
        }
        else if (leadWindowType.toLowerCase().trim() === 'single') {
            windowTypeEvened = 'Single';
        }
        else if (leadWindowType.toLowerCase().trim() === 'double') {
            windowTypeEvened = 'Double';
        }
        else if (leadWindowType.toLowerCase().trim() === 'triple') {
            windowTypeEvened = 'Triple';
        }
        else {
            windowTypeEvened = leadWindowType;
        }
        return x.extra_data.estimate_mapping === windowTypeEvened;
    });
    // try to map by age band
    if (!doorsMapping && ageBand) {
        doorsMapping = findMaterialForConstructionAgeBand(doorsMaterials, ageBand);
    }
    // log errors if no mapping found
    if (!roofMapping) {
        console.error(`No mapping for roof u-value: ${leadLoftInsulation}`);
        Sentry.captureException(new Error(`No mapping for roof u-value: ${leadLoftInsulation}`));
    }
    if (!externalWallMapping) {
        console.error(`No mapping for external wall u-value where wall group/wall type is: ${leadWallGroup}/${leadWallType}`);
        Sentry.captureException(new Error(`No mapping for external wall u-value where wall group/wall type is: ${leadWallGroup}/${leadWallType}`));
    }
    if (!internalWallMapping) {
        console.error(`No mapping for internal wall u-value: ${leadWallGroup}`);
        Sentry.captureException(new Error(`No mapping for internal wall u-value: ${leadWallGroup}`));
    }
    if (!partyWallMapping) {
        console.error('No mapping for party wall u-value');
        Sentry.captureException(new Error('No mapping for party wall u-value'));
    }
    if (!intermediateFloorAndCeilingMapping) {
        console.error(`No mapping for intermediate floor and ceiling u-value: ${leadFloorType}`);
        Sentry.captureException(new Error(`No mapping for intermediate floor and ceiling u-value: ${leadFloorType}`));
    }
    if (!groundFloorMapping) {
        console.error('No mapping for ground floor u-value');
        Sentry.captureException(new Error('No mapping for ground floor u-value'));
    }
    if (!windowsMapping) {
        console.error(`No mapping for windows u-value: ${leadWindowType}`);
        Sentry.captureException(new Error(`No mapping for windows u-value: ${leadWindowType}`));
    }
    if (!doorsMapping) {
        console.error(`No mapping for door u-value: ${leadWindowType}`);
        Sentry.captureException(new Error(`No mapping for door u-value: ${leadWindowType}`));
    }
    // return the mapping
    return {
        roof: roofMapping,
        externalWall: externalWallMapping,
        internalWall: internalWallMapping,
        partyWall: partyWallMapping,
        intermediateFloorAndCeiling: intermediateFloorAndCeilingMapping,
        exposedFloor: exposedFloorMapping,
        groundFloor: groundFloorMapping,
        window: windowsMapping,
        door: doorsMapping
    };
};
export const formatMaterialUValue = (uValue) => {
    if (typeof uValue === 'number') {
        return uValue.toFixed(2) + ' W/m²K';
    }
    return uValue;
};
// for some cases (mostly for UI) we need to get the material UValue, but it's available in different type of structures, so we need to get it from the right place
export const getMaterialUValue = (material) => {
    var _a;
    if (material.applicable_to === 'roof') {
        return material.extra_data.u_value;
    }
    else if (material.applicable_to === 'external-wall') {
        return material.extra_data.u_value;
    }
    else if (material.applicable_to === 'internal-wall') {
        return material.extra_data.u_value;
    }
    else if (material.applicable_to === 'party-wall') {
        return material.extra_data.u_value;
    }
    else if (material.applicable_to === 'exposed-floor') {
        return (_a = material.extra_data.u_value) !== null && _a !== void 0 ? _a : material.extra_data.u_value_flow_down; // keep in old version as a fallback
    }
    else if (material.applicable_to === 'intermediate-floor-and-ceiling') {
        const str = [];
        if (material.extra_data.u_value_flow_up) {
            str.push('Up: ' + material.extra_data.u_value_flow_up + ' W/m²K');
        }
        if (material.extra_data.u_value_flow_down) {
            str.push('Down: ' + material.extra_data.u_value_flow_down + ' W/m²K');
        }
        return str.join(', ');
    }
    else if (material.applicable_to === 'ground-floor') {
        // New build and Custom ground floors do have U values
        if (material.build_type === 'new-build' || material.build_type === undefined) {
            return material.extra_data.u_value;
        }
        else {
            // ground floor for retrofit buildings has insulation UValue, which is not what we need to show
            return undefined;
        }
    }
    else if (material.applicable_to === 'window') {
        return material.extra_data.u_value;
    }
    else if (material.applicable_to === 'door') {
        return material.extra_data.u_value;
    }
    else {
        throw new Error('Invalid material type');
    }
};
export const getMaterialByUuid = (uuid, materials) => {
    if (!uuid) {
        return undefined;
    }
    return materials.find(x => x.uuid === uuid);
};
export const getCustomMaterials = async (lead, companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/${lead.uuid}/materials`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data.materials;
    }
    catch (e) {
        setError(e);
    }
};
export const getGenericMaterials = async () => {
    try {
        const result = await client.get(`${apiUrl}materials`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data.materials;
    }
    catch (e) {
        console.error('Error loading Generic Materials', e);
        return undefined;
    }
};
export const getMaterialsGenericLayers = async () => {
    try {
        const result = await client.get(`${apiUrl}materials_layers`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data.layers;
    }
    catch (e) {
        console.error('Error loading Generic Materials Layers', e);
        return undefined;
    }
};
export const updateDefaultMaterial = (survey, setSurvey, material) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    // a copy of materials to compare with
    const materialsBeforeChanges = _.cloneDeep(survey.default_materials);
    // a material set assigned as a default for the survey
    // only one of its attributes will be changed in the switch case
    const materialsSet = _.cloneDeep(survey.default_materials);
    // make a copy of survey
    survey = _.cloneDeep(survey);
    switch (material.applicable_to) {
        case 'external-wall':
            materialsSet.externalWall = material;
            // if the material is changed, update it in the survey
            if (((_a = materialsBeforeChanges.externalWall) === null || _a === void 0 ? void 0 : _a.uuid) !== material.uuid) {
                survey.floors.forEach(floor => {
                    floor.rooms.forEach(room => {
                        room.walls.forEach(wall => {
                            var _a, _b;
                            // update only external walls with default material
                            if (((_a = wall.material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'external-wall' && wall.material.uuid === ((_b = materialsBeforeChanges.externalWall) === null || _b === void 0 ? void 0 : _b.uuid)) {
                                wall.material = material;
                            }
                        });
                    });
                });
            }
            break;
        case 'party-wall':
            materialsSet.partyWall = material;
            // if the material is changed, update it in the survey
            if (((_b = materialsBeforeChanges.partyWall) === null || _b === void 0 ? void 0 : _b.uuid) !== material.uuid) {
                survey.floors.forEach(floor => {
                    floor.rooms.forEach(room => {
                        room.walls.forEach(wall => {
                            var _a, _b;
                            // update only party walls with default material
                            if (((_a = wall.material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'party-wall' && wall.material.uuid === ((_b = materialsBeforeChanges.partyWall) === null || _b === void 0 ? void 0 : _b.uuid)) {
                                wall.material = material;
                            }
                        });
                    });
                });
            }
            break;
        case 'internal-wall':
            materialsSet.internalWall = material;
            // if the material is changed, update it in the survey
            if (((_c = materialsBeforeChanges.internalWall) === null || _c === void 0 ? void 0 : _c.uuid) !== material.uuid) {
                survey.floors.forEach(floor => {
                    floor.rooms.forEach(room => {
                        room.walls.forEach(wall => {
                            var _a, _b;
                            // update only internal walls with default material
                            if (((_a = wall.material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'internal-wall' && wall.material.uuid === ((_b = materialsBeforeChanges.internalWall) === null || _b === void 0 ? void 0 : _b.uuid)) {
                                wall.material = material;
                            }
                        });
                    });
                });
            }
            break;
        case 'ground-floor':
            materialsSet.groundFloor = material;
            // if the material is changed, update it in the survey
            if (((_d = materialsBeforeChanges.groundFloor) === null || _d === void 0 ? void 0 : _d.uuid) !== material.uuid) {
                survey.floors.forEach(floor => {
                    floor.rooms.forEach(room => {
                        var _a;
                        // skip if room's floor was overwritten
                        if (isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL))
                            return;
                        // update only if the floor is ground floor
                        if (((_a = room.floor_material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'ground-floor') {
                            room.floor_material = material;
                        }
                    });
                });
            }
            break;
        case 'intermediate-floor-and-ceiling':
            materialsSet.intermediateFloorAndCeiling = material;
            // if the material is changed, update it in the survey
            if (((_e = materialsBeforeChanges.intermediateFloorAndCeiling) === null || _e === void 0 ? void 0 : _e.uuid) !== material.uuid) {
                survey.floors.forEach(floor => {
                    floor.rooms.forEach(room => {
                        var _a, _b;
                        // if room's floor wasn't overwritten
                        if (!isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_FLOOR_MATERIAL)) {
                            // update only if floor type is intermediate floor
                            if (((_a = room.floor_material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'intermediate-floor-and-ceiling') {
                                room.floor_material = material;
                            }
                        }
                        // if room's ceiling wasn't overwritten
                        if (!isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL)) {
                            if (((_b = room.ceiling_material) === null || _b === void 0 ? void 0 : _b.applicable_to) === 'intermediate-floor-and-ceiling') {
                                room.ceiling_material = material;
                            }
                        }
                    });
                });
            }
            break;
        case 'roof':
            materialsSet.roof = material;
            // if the material is changed, update it in the survey
            if (((_f = materialsBeforeChanges.roof) === null || _f === void 0 ? void 0 : _f.uuid) !== material.uuid) {
                survey.floors.forEach(floor => {
                    floor.rooms.forEach(room => {
                        var _a;
                        // skip if room's ceiling was overwritten
                        if (isFlagSet(room.overwritten_attributes_flags, ROOM_OVERWRITTEN_ATTRIBUTE_CEILING_MATERIAL))
                            return;
                        // update only if ceiling type is intermediate floor
                        if (((_a = room.ceiling_material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'roof') {
                            room.ceiling_material = material;
                        }
                    });
                });
            }
            break;
        case 'door':
            materialsSet.door = material;
            // if the material is changed, update it in the survey
            if (((_g = materialsBeforeChanges.door) === null || _g === void 0 ? void 0 : _g.uuid) !== material.uuid) {
                survey.floors.forEach(floor => {
                    floor.rooms.forEach(room => {
                        room.walls.forEach(wall => {
                            wall.doors.forEach(door => {
                                var _a, _b;
                                // update only doors with default material
                                if (((_a = door.material) === null || _a === void 0 ? void 0 : _a.uuid) === ((_b = materialsBeforeChanges.door) === null || _b === void 0 ? void 0 : _b.uuid)) {
                                    door.material = material;
                                }
                            });
                        });
                    });
                });
            }
            break;
        case 'window':
            materialsSet.window = material;
            // if the material is changed, update it in the survey
            if (((_h = materialsBeforeChanges.window) === null || _h === void 0 ? void 0 : _h.uuid) !== material.uuid) {
                survey.floors.forEach(floor => {
                    floor.rooms.forEach(room => {
                        room.walls.forEach(wall => {
                            wall.windows.forEach(window => {
                                var _a, _b;
                                // update only doors with default material
                                if (((_a = window.material) === null || _a === void 0 ? void 0 : _a.uuid) === ((_b = materialsBeforeChanges.window) === null || _b === void 0 ? void 0 : _b.uuid)) {
                                    window.material = material;
                                }
                            });
                        });
                    });
                });
            }
            break;
    }
    survey.default_materials = materialsSet;
    // overwrite the survey completely
    // TODO: probably, bad practice but there is no way now to update all elements in one go
    setSurvey(survey);
    return materialsSet;
};
export const getExposedFloorMaterial = (genericMaterials, ageBand) => {
    // map exposed floor
    const exposedFloorMaterials = genericMaterials.filter(x => x.applicable_to === 'exposed-floor');
    let exposedFloorMapping;
    // try to map by age band
    if (ageBand) {
        exposedFloorMapping = findMaterialForConstructionAgeBand(exposedFloorMaterials, ageBand);
    }
    if (!exposedFloorMapping) {
        exposedFloorMapping = exposedFloorMaterials[0];
    }
    if (!exposedFloorMapping) {
        console.error('No mapping for exposed floor u-value');
        Sentry.captureException(new Error(`No mapping for exposed floor u-value ${ageBand ? `for age band ${ageBand.min_year}-${ageBand.max_year}` : ''}`));
        //   fallback
        exposedFloorMapping = genericMaterials.filter(x => x.applicable_to === 'roof')[0];
    }
    return exposedFloorMapping;
};
