import { axiosGetV2, axiosPatchV2, axiosPostV2 } from '../axios';
export const HEATLOSS_REPORT_SECTION_SUMMARY = 0x0001;
export const HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY = 0x0002; // UI should enable this if any of the following are enabled
export const HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO = 0x0004;
export const HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM = 0x0008;
export const HEATLOSS_REPORT_SECTION_DESIGN = 0x0010;
export const HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT = 0x0020;
export const HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE = 0x0040;
// all sections
export const HEATLOSS_REPORT_SECTION_ALL = HEATLOSS_REPORT_SECTION_SUMMARY |
    HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY |
    HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO |
    HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM |
    HEATLOSS_REPORT_SECTION_DESIGN |
    HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT |
    HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE;
export const createHLReportAPI = async (report) => await axiosPostV2(`teams/${report.company_uuid}/leads/${report.lead_uuid}/heatloss_reports`, report);
export const sendHLReportAPI = async (report, email_html) => await axiosPostV2(`teams/${report.company_uuid}/leads/${report.lead_uuid}/heatloss_reports/${report.uuid}/send`, { email_html });
export const getHLReportPublicAPI = async (reportUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/heatloss_reports/${reportUUID}`);
export const getHLReportPDFAPI = async (reportUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/heatloss_reports/${reportUUID}/pdf`, { responseType: 'blob' });
export const getHLReportPreviewPDFAPI = async (reportUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/heatloss_reports/${reportUUID}/preview_pdf`, { responseType: 'blob' });
export const setHLOpenedStatusAPI = async (reportUUID, companyUUID) => await axiosPatchV2(`teams/${companyUUID}/heatloss_reports/${reportUUID}/opened_status`, { is_opened: true });
