import React, { useEffect, useState } from 'react';
import { getCompanyDeclinedEmailTemplate } from '../../../../../code/models/company';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName } from '../../../../../code/tiptap_placeholders';
import { Modal } from '../../../../../components/containers/modal';
import { logEvent } from '../../../../../code/log_event';
import { LeadStatusSchema, sendDeclined } from '../../../../../code/models/lead';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { createJobEvent, JobEventType } from '../../../../../code/models/job_event';
export const SendDeclinedModalBlock = ({ isVisible, setIsVisible, adminContext, lead, setLead }) => {
    var _a, _b, _c, _d, _e, _f;
    const [emailTemplateJSON, setEmailTemplateJSON] = useState();
    if (!adminContext.data.company)
        return;
    const companyUUID = adminContext.data.company.public_info.uuid;
    useEffect(() => {
        var _a, _b, _c;
        if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company))
            return;
        // the email template used to send the estimate
        const companyDeclineEmailTemplate = getCompanyDeclinedEmailTemplate(adminContext.data.company.decline_template);
        // render all placeholders EXCEPT the View Estimate button
        const emailJSON = renderJSONReplacingPlaceholders(companyDeclineEmailTemplate, {
            ...defaultPlaceholderMappings(adminContext.data.company.public_info, adminContext.data.user),
            [TTPlaceholderCustomerName.id]: (_c = (_b = lead.customer) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : '',
            [TTPlaceholderCustomerAddress.id]: `${lead.property.address}, ${lead.property.postcode}`
        });
        setEmailTemplateJSON(emailJSON);
    }, [adminContext]);
    return React.createElement(Modal, { title: 'Decline enquiry message', visible: isVisible, setVisible: setIsVisible, onConfirm: async () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            logEvent({ name: 'Decline Enquiry Clicked', properties: {} }, (_d = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.public_info) === null || _c === void 0 ? void 0 : _c.subdomain) !== null && _d !== void 0 ? _d : '');
            // render the email template with the View Estimate button
            const emailHTML = renderHTMLReplacingPlaceholders(emailTemplateJSON);
            await sendDeclined(lead.uuid, (_h = (_g = (_f = (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company) === null || _f === void 0 ? void 0 : _f.public_info) === null || _g === void 0 ? void 0 : _g.uuid) !== null && _h !== void 0 ? _h : '', emailHTML);
            const oldStatus = lead.status;
            setLead({ ...lead, status: LeadStatusSchema.enum.Declined });
            await createJobEvent({
                event_type: JobEventType.enum.EnquiryDeclined,
                company_uuid: companyUUID,
                job_uuid: lead.uuid,
                extra_data: {
                    email_content: emailHTML,
                    recipients: [(_j = lead.customer) === null || _j === void 0 ? void 0 : _j.email]
                }
            });
            if (oldStatus !== LeadStatusSchema.enum.Declined) {
                createJobEvent({
                    event_type: JobEventType.enum.StatusChanged,
                    company_uuid: companyUUID,
                    job_uuid: lead.uuid,
                    extra_data: {
                        old_status: oldStatus,
                        new_status: LeadStatusSchema.enum.Declined
                    }
                });
            }
        }, confirmButtonLabel: "Send" },
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement("div", { className: "flex flex-col gap-4" },
                React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setEmailTemplateJSON(editor.getJSON());
                    }, content: emailTemplateJSON }),
                ((_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.public_info) === null || _c === void 0 ? void 0 : _c.logo) && React.createElement("img", { src: (_f = (_e = (_d = adminContext.data) === null || _d === void 0 ? void 0 : _d.company) === null || _e === void 0 ? void 0 : _e.public_info) === null || _f === void 0 ? void 0 : _f.logo, className: "max-w-40", alt: "Installer logo" }))));
};
