import { getEmitterVelocityMPerS, getEmitterWatts } from './radiator_model';
export const getRadiatorHeightWidthText = (radiatorModel) => `${radiatorModel.height_mm} x ${radiatorModel.width_mm} mm`;
export const getEmitterOutputRow = (radiator, room, survey, design, designTempC, groundTempC) => {
    var _a;
    const radiatorWatts = getEmitterWatts(radiator, room, design, survey, designTempC, groundTempC);
    const velocityMPerS = getEmitterVelocityMPerS(radiator, radiatorWatts, design.delta_t_flow_return_c, design.flow_temp);
    const emitterRow = {
        typeName: (_a = getEmitterTypeName(radiator)) !== null && _a !== void 0 ? _a : '',
        size: getEmitterSizeName(radiator),
        velocityMPerS,
        watts: Math.round(radiatorWatts)
    };
    return emitterRow;
};
export const getEmitterTypeName = (emitter) => {
    var _a;
    if (emitter.emitter_type === 'SECONDARY')
        return emitter.name;
    if (emitter.emitter_type === 'UNDERFLOOR')
        return emitter.floor_construction_type;
    return (_a = emitter.radiator_type) === null || _a === void 0 ? void 0 : _a.type;
};
export const getEmitterSizeName = (radiator) => {
    if (radiator.emitter_type === 'SECONDARY')
        return radiator.room_heating_load_covered_percentage + '%';
    if (radiator.emitter_type === 'UNDERFLOOR')
        return radiator.pipe_centres_uuid + ' centres';
    return getRadiatorHeightWidthText(radiator.radiator_type);
};
export const getFullEmittersListByStatus = (survey, design) => {
    const result = [];
    const designRadiators = design.radiators;
    const rooms = survey.floors.flatMap(x => x.rooms);
    // collect current radiators
    rooms.forEach(room => {
        room.radiators.forEach(radiator => {
            const replacedBy = designRadiators.find(x => x.replaces_uuid === radiator.uuid);
            if (replacedBy) {
                result.push({ radiator, status: 'REPLACED', replacedBy, room });
            }
            else {
                if (design.removed_radiator_uuids.includes(radiator.uuid)) {
                    result.push({ radiator, status: 'REMOVED', replacedBy: undefined, room });
                }
                else {
                    result.push({ radiator, status: 'UNCHANGED', replacedBy: undefined, room });
                }
            }
        });
    });
    // collect designed radiators
    design.radiators.forEach(radiator => {
        const room = rooms.find(x => x.uuid === radiator.room_uuid);
        // if the radiator replaces something, it's already in the list as `replacedBy` in a 'REPLACED' status row
        // or if the original room has been deleted
        if (radiator.replaces_uuid || !room)
            return;
        result.push({ radiator, status: 'ADDED', replacedBy: undefined, room });
    });
    return result;
};
