import { useState, useEffect } from 'react';
const DATABASE_NAME = 'fallback';
export const useIndexedDBFallback = (tableName, key, initialValue, fetcher, onLoadFinished) => {
    const [db, setDB] = useState(null);
    const [value, setValue] = useState(initialValue);
    const [hasFetchingError, setHasFetchingError] = useState(false);
    useEffect(() => {
        let database;
        const request = window.indexedDB.open(DATABASE_NAME);
        request.onsuccess = (event) => {
            database = event.target.result;
            setDB(database);
        };
        request.onerror = (e) => console.log('ERROR: ', e);
        request.onupgradeneeded = (event) => {
            database = event.target.result;
            if (!database.objectStoreNames.contains(tableName)) {
                database.createObjectStore(tableName, { keyPath: 'id' });
            }
            setDB(database);
        };
        return () => {
            if (db) {
                db.close();
                setDB(null);
            }
        };
    }, []);
    useEffect(() => {
        if (!db)
            return;
        getValue();
    }, [db]);
    const getValue = async () => {
        if (!db)
            return;
        // try fetch data from the network
        const data = await fetcher();
        // if data exists and is not an error, return it
        if (data) {
            // save the value to the DB
            setHasFetchingError(false);
            updateValue(data);
            onLoadFinished(data);
            return;
        }
        // if there is no data (usually means a fetching error), set the flag
        setHasFetchingError(true);
        // get the value from the DB
        const transaction = db.transaction(tableName, 'readonly');
        const store = transaction.objectStore(tableName);
        const request = store.get(key);
        request.onsuccess = () => {
            const storedValue = request.result ? request.result.value : null;
            if (storedValue !== null) {
                // if the value is in the DB, return it
                setValue(storedValue);
                onLoadFinished(storedValue);
            }
            else {
                // otherwise use the initial value
                setValue(initialValue);
                onLoadFinished(initialValue);
            }
        };
        request.onerror = (e) => console.log('ERROR: ', e);
    };
    const updateValue = (newValue) => {
        setValue(newValue);
        if (!db)
            return;
        const transaction = db.transaction(tableName, 'readwrite');
        const store = transaction.objectStore(tableName);
        const request = store.put({ id: key, value: newValue });
        request.onerror = (e) => console.log('ERROR: ', e);
    };
    const handleReload = async () => {
        if (!db)
            return;
        await getValue();
    };
    return [value, updateValue, hasFetchingError, handleReload];
};
