export const getFloorAndCeilingDefaultMaterialsForFloor = (floor, survey) => {
    const floorMaterial = getDefaultFloorMaterialForFloor(floor, survey);
    const ceilingMaterial = getDefaultCeilingMaterialForFloor(floor, survey);
    return [floorMaterial, ceilingMaterial];
};
export const getDefaultCeilingMaterialForFloor = (floor, survey) => {
    switch (floor.default_ceiling_material_type) {
        case 'intermediate-floor-and-ceiling':
            return survey.default_materials.intermediateFloorAndCeiling;
        case 'roof':
            return survey.default_materials.roof;
        default:
            throw new Error('Unknown ceiling material type');
    }
};
export const getDefaultFloorMaterialForFloor = (floor, survey) => {
    switch (floor.default_floor_material_type) {
        case 'ground-floor':
            return survey.default_materials.groundFloor;
        case 'intermediate-floor-and-ceiling':
            return survey.default_materials.intermediateFloorAndCeiling;
        case 'exposed-floor':
            return survey.default_materials.exposedFloor;
        default:
            throw new Error('Unknown floor material type');
    }
};
