import React, { useContext, useState } from 'react';
import { Section } from '../components/section';
import { contextSensitiveFormatDate } from '../../../../code/format_date';
import { JobEventType } from '../../../../code/models/job_event';
import { Badge, leadStatusToBadge } from '../../../../components/indicators_and_messaging/badge';
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu';
import { openInNewTab } from '../../../../code/helpers';
import { Select } from '../../../../components/inputs_and_selections/select';
import { AdminContext } from '../../admin_layout';
import { hasHistoryAccess } from '../../../../code/models/user';
import { UnauthorizedPage } from '../../unauthorized_page';
import { Filter } from 'lucide-react';
const HistoryItemDescription = ({ event }) => {
    var _a, _b;
    const renderUserInfo = () => {
        const elements = [];
        if (event.user_name) {
            elements.push(React.createElement("strong", { className: "text-bold font-semibold", key: "user" }, event.user_name), ' ');
        }
        if (event.company_name) {
            elements.push(React.createElement("strong", { className: "text-bold font-semibold", key: "company" },
                "(",
                event.company_name,
                ")"), ' ');
        }
        return elements;
    };
    const renderRecipients = () => {
        var _a;
        if (((_a = event.extra_data) === null || _a === void 0 ? void 0 : _a.recipients) && Array.isArray(event.extra_data.recipients)) {
            return React.createElement("strong", { className: "text-bold font-semibold" },
                " to ",
                event.extra_data.recipients.join(', '));
        }
        return '';
    };
    switch (event.event_type) {
        // Actions without a user
        case JobEventType.enum.JobCreated:
            return React.createElement(React.Fragment, null, "Job created");
        // Actions where the user is anonymous
        case JobEventType.enum.EstimateRejected:
            return React.createElement(React.Fragment, null, "Estimate was rejected by customer");
        case JobEventType.enum.EstimateAccepted:
            return React.createElement(React.Fragment, null, "Estimate was accepted by customer");
        case JobEventType.enum.EstimateCallbackRequested:
            return React.createElement(React.Fragment, null, "A callback was requested by customer");
        case JobEventType.enum.EstimateOpened:
            return React.createElement(React.Fragment, null, "Estimate was opened by customer");
        case JobEventType.enum.ReportOpened:
            return React.createElement(React.Fragment, null, "Report was opened by customer");
        case JobEventType.enum.ProposalOpened:
            return React.createElement(React.Fragment, null, "Proposal was opened by customer");
        case JobEventType.enum.QuoteOpened:
            return React.createElement(React.Fragment, null, "Quote was opened by customer");
        case JobEventType.enum.ProposalAccepted:
            return React.createElement(React.Fragment, null, "Proposal was accepted by customer");
        // Actions which sometimes have a user
        case JobEventType.enum.StatusChanged:
            const newLeadStatusBadge = leadStatusToBadge((_a = event.extra_data) === null || _a === void 0 ? void 0 : _a.new_status);
            const oldLeadStatusBadge = leadStatusToBadge((_b = event.extra_data) === null || _b === void 0 ? void 0 : _b.old_status);
            if (event.user_name) {
                return (React.createElement(React.Fragment, null,
                    renderUserInfo(),
                    "changed the status from",
                    ' ',
                    React.createElement(Badge, { color: oldLeadStatusBadge.color, text: oldLeadStatusBadge.text }),
                    ' ',
                    "to",
                    ' ',
                    React.createElement(Badge, { color: newLeadStatusBadge.color, text: newLeadStatusBadge.text })));
            }
            else {
                return (React.createElement(React.Fragment, null,
                    "Status changed from",
                    ' ',
                    React.createElement(Badge, { color: oldLeadStatusBadge.color, text: oldLeadStatusBadge.text }),
                    ' ',
                    "to",
                    ' ',
                    React.createElement(Badge, { color: newLeadStatusBadge.color, text: newLeadStatusBadge.text })));
            }
        // Actions with a user
        case JobEventType.enum.ReportPDFGenerated:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "downloaded a report PDF"));
        case JobEventType.enum.ProposalPDFGenerated:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "downloaded a proposal PDF"));
        case JobEventType.enum.QuotePDFGenerated:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "downloaded a quote PDF"));
        case JobEventType.enum.EstimateSent:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "sent an estimate",
                renderRecipients()));
        case JobEventType.enum.EstimateReminderSent:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "sent an estimate reminder",
                renderRecipients()));
        case JobEventType.enum.EnquiryDeclined:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "declined the enquiry"));
        case JobEventType.enum.ReportSent:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "sent a report",
                renderRecipients()));
        case JobEventType.enum.ProposalSent:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "sent a proposal",
                renderRecipients()));
        case JobEventType.enum.QuoteSent:
            return (React.createElement(React.Fragment, null,
                renderUserInfo(),
                "sent a quote",
                renderRecipients()));
        default:
            return React.createElement(React.Fragment, null, "Unknown event");
    }
};
const actionsForEvent = (event, subdomain) => {
    switch (event.event_type) {
        case JobEventType.enum.EstimateSent:
        case JobEventType.enum.EstimateReminderSent:
        case JobEventType.enum.EstimateRejected:
        case JobEventType.enum.EstimateAccepted:
        case JobEventType.enum.EstimateCallbackRequested:
            return [
                { label: 'View estimate', onClick: () => { var _a; return openInNewTab(`/${subdomain}/estimate/${(_a = event.extra_data) === null || _a === void 0 ? void 0 : _a.calculated_estimate_uuid}?installer=true`); } }
            ];
        case JobEventType.enum.ReportSent:
        case JobEventType.enum.ReportOpened:
        case JobEventType.enum.ReportPDFGenerated:
            return [
                { label: 'View report', onClick: () => { var _a; return openInNewTab(`/${subdomain}/report/${(_a = event.extra_data) === null || _a === void 0 ? void 0 : _a.report_uuid}?installer=true`); } }
            ];
        case JobEventType.enum.ProposalSent:
        case JobEventType.enum.ProposalOpened:
        case JobEventType.enum.ProposalPDFGenerated:
        case JobEventType.enum.ProposalAccepted:
            return [
                { label: 'View proposal', onClick: () => { var _a; return openInNewTab(`/${subdomain}/proposal/${(_a = event.extra_data) === null || _a === void 0 ? void 0 : _a.proposal_uuid}?installer=true`); } }
            ];
        case JobEventType.enum.QuoteSent:
        case JobEventType.enum.QuoteOpened:
        case JobEventType.enum.QuotePDFGenerated:
            return [
                { label: 'View quote', onClick: () => { var _a; return openInNewTab(`/${subdomain}/quote/${(_a = event.extra_data) === null || _a === void 0 ? void 0 : _a.quote_uuid}?installer=true`); } }
            ];
        default:
            return [];
    }
};
export const JobHistory = ({ lead, companyPublicInfo }) => {
    var _a;
    const adminContext = useContext(AdminContext);
    const filterOptions = [
        { key: 'job-events', value: 'Job events' },
        { key: 'status-updates', value: 'Status updates' }
    ];
    const [activeFilters, setActiveFilters] = useState(filterOptions.map(x => x.key));
    if (!adminContext.isLoading && !hasHistoryAccess(adminContext.data.company, adminContext.data.user)) {
        return React.createElement(UnauthorizedPage, null);
    }
    const eventsWithoutDeprecatedTypes = (_a = lead.history) === null || _a === void 0 ? void 0 : _a.filter(x => x.event_type !== JobEventType.enum.DEPRECATED_ReportGenerated && x.event_type !== JobEventType.enum.DEPRECATED_ProposalGenerated && x.event_type !== JobEventType.enum.DEPRECATED_QuoteGenerated);
    const filteredEvents = eventsWithoutDeprecatedTypes === null || eventsWithoutDeprecatedTypes === void 0 ? void 0 : eventsWithoutDeprecatedTypes.filter(x => {
        if (activeFilters.includes('job-events') && x.event_type !== JobEventType.enum.StatusChanged)
            return true;
        if (activeFilters.includes('status-updates') && x.event_type === JobEventType.enum.StatusChanged)
            return true;
        return false;
    });
    return (React.createElement("div", { className: 'flex flex-col gap-6' },
        React.createElement("div", { className: 'flex flex-col md:flex-row md:justify-between md:items-center gap-2' },
            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Job history"),
            React.createElement("div", { className: 'w-52' },
                React.createElement(Select, { icon: Filter, multiple: true, options: filterOptions, selectedKey: activeFilters, setSelectedKey: setActiveFilters, placeholder: 'Filter events' }))),
        React.createElement(Section, null, (filteredEvents === null || filteredEvents === void 0 ? void 0 : filteredEvents.length) && filteredEvents.length > 0
            ? (React.createElement("ul", null, filteredEvents === null || filteredEvents === void 0 ? void 0 : filteredEvents.sort((a, b) => a.created_at.valueOf() - b.created_at.valueOf()).map(x => React.createElement("li", { key: x.uuid, className: "text-sm text-default flex flex-col md:flex-row md:justify-between min-h-10 px-1 py-2 hover:bg-gray-50 border-b border-gray-200" },
                React.createElement("div", null,
                    React.createElement("span", { className: "text-gray-400" }, contextSensitiveFormatDate(x.created_at)),
                    " ",
                    React.createElement("span", { className: 'hidden md:inline' }, "\u00B7"),
                    " ",
                    React.createElement("span", { className: 'inline-flex flex-wrap gap-1 items-center' },
                        React.createElement(HistoryItemDescription, { event: x }))),
                actionsForEvent(x, companyPublicInfo.subdomain).length > 0 ? React.createElement("div", { className: 'hidden md:block' },
                    React.createElement(DropdownMenu, { items: actionsForEvent(x, companyPublicInfo.subdomain) })) : React.createElement(React.Fragment, null)))))
            : React.createElement("div", { className: "text-gray-400 text-sm text-center" }, "No events to show"))));
};
