import React from 'react';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { ClickableCard } from '../../../components/content_display/card';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
import { sum } from 'lodash';
import { getRoomWatts } from '../../../code/models/heat_loss';
import { HLRFloorplanBlock } from './partials/hlr_floorplan';
export const HLRFloorHeatlossPage = ({ floor }) => {
    const hlrContext = React.useContext(HLRContext);
    const rooms = floor.rooms.flatMap(y => {
        return {
            name: y.name,
            heatLoss: Math.round(getRoomWatts(y, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.survey))
        };
    });
    const totalHeatLossW = sum(rooms.map(x => x.heatLoss));
    return (React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement(HLRSubheaderBlock, { section: "Heat loss by floor", title: floor.name }),
        React.createElement(ClickableCard, { variant: 'GREY' },
            React.createElement("div", { className: "gap-3 flex justify-between" },
                React.createElement("div", { className: "text-gray-900 text-lg font-bold " }, "Heat loss by room"),
                React.createElement("div", { className: "text-lg" },
                    totalHeatLossW,
                    " W")),
            React.createElement(ProgressChart, { items: rooms.map(x => ({
                    name: x.name,
                    value: x.heatLoss
                })), total: totalHeatLossW })),
        React.createElement(HLRFloorplanBlock, { floor: floor }),
        React.createElement("div", { className: "flex-col flex" },
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "Rads: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "number of radiators")),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "UFH: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "underfloor heating in room")),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "\u00B0C: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "room temperature")),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "ACH: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "air changes per hour")),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "W: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "number of windows")),
            React.createElement("div", { className: "" },
                React.createElement("span", { className: "text-gray-900 text-xs tracking-tight font-bold" }, "D: "),
                React.createElement("span", { className: "text-gray-600 text-xs tracking-tight" }, "number of doors")))));
};
