import React, { useState } from 'react';
import { Modal } from '../../components/containers/modal';
import { FormLabel } from '../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group';
import { validateURLAndHasProtocol, validateNotNull } from '../validators';
import { Input } from '../../components/inputs_and_selections/input';
export const AddExternalAttachmentModal = ({ visible, setVisible, onAddAttachment, forceTitle }) => {
    var _a;
    const [fileName, setTitle] = useState(forceTitle !== null && forceTitle !== void 0 ? forceTitle : '');
    const [url, setUrl] = useState('');
    const handleCloseModal = (open) => {
        if (visible) {
            setTitle(forceTitle || '');
            setUrl('');
            setVisible(open);
        }
    };
    const validFields = [validateURLAndHasProtocol(url), validateNotNull(fileName)].every(e => e.value);
    return React.createElement(Modal, { visible: visible, setVisible: () => handleCloseModal(false), title: 'Add external attachment', confirmButtonLabel: 'Save', confirmDisabled: !validFields, onConfirm: () => onAddAttachment(fileName, url) },
        React.createElement("div", { className: 'w-full flex flex-col gap-4' },
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Document title', required: true, complete: fileName !== '' }), input: React.createElement(Input, { placeholder: 'e.g. Case Study', setValue: title => setTitle(title), value: (_a = forceTitle !== null && forceTitle !== void 0 ? forceTitle : fileName) !== null && _a !== void 0 ? _a : '', validator: validateNotNull, disabled: Boolean(forceTitle) }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'File URL', required: true, complete: url !== '' && !!validateURLAndHasProtocol(url).value }), input: React.createElement(Input, { placeholder: 'URL', setValue: url => setUrl(url), value: url !== null && url !== void 0 ? url : '', validator: validateURLAndHasProtocol, doNotValidateWhenEmpty: true }) })));
};
