import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '../../../../components/buttons/button';
import { Section } from '../components/section';
import { HorizontalFormGroup } from '../../../../components/inputs_and_selections/horizontal_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { downloadBlob, isFlagSet } from '../../../../code/helpers';
import { createQuoteSnapshotAPI, getDraftProposalAPI, getProposalPreviewPDFAPI, getQuotePreviewPDFAPI, sendProposalAPI, sendQuoteAPI, updateProposalAPI } from '../../../../code/models/proposal';
import { getCompanyProposalCoverNoteTemplate, getCompanyProposalEmailTemplate, getCompanyQuoteEmailTemplate } from '../../../../code/models/company';
import { LeadStatusSchema } from '../../../../code/models/lead';
import { HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM } from '../../../../code/models/heatloss_report';
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName, TTPlaceholderViewProposal, TTPlaceholderViewQuote } from '../../../../code/tiptap_placeholders';
import { AdminContext } from '../../admin_layout';
import { Modal } from '../../../../components/containers/modal';
import { Input } from '../../../../components/inputs_and_selections/input';
import { validateIsNumber } from '../../../../code/validators';
import { ClickableCard } from '../../../../components/content_display/card';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Text } from '../../../../components/content_display/text';
import { groupBy, sum } from 'lodash';
import { QuoteBuilder } from './quote_builder';
import { CalculatedQuoteDefaultGroups, calculateQuote, QuoteOverrideType } from '../../../../code/calculate_quote';
import { checkIfScottish } from '../../../../code/models/address';
import { useDebounceCallback } from 'usehooks-ts';
import { HeatPump } from '../../../../assets/images/survey_images/survey_images';
import { formatPrice } from '../../../../code/format_price';
import { contextSensitiveFormatDate } from '../../../../code/format_date';
import { FullscreenPreviewModal } from '../../../heat_loss/proposal/partials/fullscreen_preview_modal';
import { SendEmailWithTemplateModal } from '../../../heat_loss/proposal/partials/send_email_with_template_modal';
import { PaymentSchedule } from './payment_schedule';
import { ProposalFiles } from './proposal_files';
import { QuotePublicPageInner } from '../../../quote_public_page';
import { ProposalPublicPageInner } from '../../../proposal_public_page';
import { Loader } from '../../../../components/indicators_and_messaging/loader';
import { createJobEvent, JobEventType } from '../../../../code/models/job_event';
import { Edit, FileText } from 'lucide-react';
export const calculateQuoteSnapshot = (calculatedQuote, proposal) => {
    const customerFacingCalculatedQuote = calculatedQuote.map(x => {
        return {
            uuid: x.uuid,
            name: x.name,
            description: x.description || undefined,
            quantity: x.quantity,
            group_name: x.group_name,
            selected: x.selected || true,
            image_url: x.image_url || undefined,
            subtotal: (proposal === null || proposal === void 0 ? void 0 : proposal.show_cost) ? x.subtotal : undefined
        };
    });
    const calculateVATOnSurvey = (quote) => {
        if (proposal === null || proposal === void 0 ? void 0 : proposal.include_vat_on_survey) {
            return sum(quote.filter(x => x.selected && x.group_name === CalculatedQuoteDefaultGroups.SURVEY).map(x => x.subtotal)) * 0.2;
        }
        return 0;
    };
    const calculateVATOnAll = (quote) => {
        if (proposal === null || proposal === void 0 ? void 0 : proposal.include_vat_on_all) {
            const totalPreDeductions = sum(quote.filter(x => x.selected && x.group_name !== CalculatedQuoteDefaultGroups.GRANTS && x.group_name !== 'Other deductions').map(x => x.subtotal));
            const totalOtherDeductions = sum(quote.filter(x => x.selected && x.group_name === 'Other deductions').map(x => x.subtotal));
            // VAT calculated on the total cost, pre-discount, minus other deductions
            return (totalPreDeductions - totalOtherDeductions) * 0.2;
        }
        return 0;
    };
    const groupedQuote = groupBy(calculatedQuote, 'group_name');
    const totalPreDeductions = sum(calculatedQuote.filter(x => x.selected && x.group_name !== CalculatedQuoteDefaultGroups.GRANTS && x.group_name !== 'Other deductions').map(x => x.subtotal));
    const otherDeductionsTotal = sum(calculatedQuote.filter(x => x.selected && x.group_name === 'Other deductions').map(x => x.selected ? x.subtotal : 0));
    return {
        quote_items: customerFacingCalculatedQuote,
        total_pre_deductions: totalPreDeductions,
        discount_total: otherDeductionsTotal,
        vat_on_all: calculateVATOnAll(calculatedQuote),
        vat_on_survey: calculateVATOnSurvey(calculatedQuote),
        bus_grant: sum(calculatedQuote.filter(x => x.selected && x.group_name === CalculatedQuoteDefaultGroups.GRANTS).map(x => x.subtotal)),
        group_totals: (proposal === null || proposal === void 0 ? void 0 : proposal.show_cost) ? Object.entries(groupedQuote).reduce((acc, [key, value]) => {
            acc[key] = value.reduce((sum, item) => item.selected ? sum + (item.subtotal || 0) : sum, 0);
            return acc;
        }, {}) : {},
        payment_schedule: (proposal === null || proposal === void 0 ? void 0 : proposal.payment_schedule) || undefined,
        additional_notes: (proposal === null || proposal === void 0 ? void 0 : proposal.additional_notes) || undefined
    };
};
export const ProposalConfigurator = ({ currentPathWithoutBase, currentHeatPump, currentHotWaterCylinder, emitters: designedEmitters, lead, survey, files, setLead, companyPublicInfo, navigateTo }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const adminContext = useContext(AdminContext);
    const [proposal, setProposal] = useState();
    const [coverNoteText, setCoverNoteText] = React.useState();
    // settings popup
    const [showSettingsPopup, setShowSettingsPopup] = useState(false);
    const [proposalEditingSettings, setProposalEditingSettings] = useState(undefined);
    // all email content should be rendered and all tiptap has almost the same placeholders
    // worth to define them here once for all editors and renderers
    const tiptapRenderMappings = {
        [TTPlaceholderCustomerName.id]: ((_a = lead.customer) === null || _a === void 0 ? void 0 : _a.name) || '',
        [TTPlaceholderCustomerAddress.id]: lead.property.address + ', ' + lead.property.postcode,
        ...defaultPlaceholderMappings(companyPublicInfo, adminContext.data.user)
    };
    // Proposal modal windows
    const [showProposalPreviewWindow, setShowProposalPreviewWindow] = React.useState(false);
    const [showProposalSendingModal, setShowProposalSendingModal] = React.useState(false);
    const [proposalEmailText, setProposalEmailText] = React.useState(renderJSONReplacingPlaceholders(getCompanyProposalEmailTemplate(adminContext.data.company.proposal_email_template || ''), tiptapRenderMappings));
    // Quote modal windows
    const [showQuotePreviewWindow, setShowQuotePreviewWindow] = React.useState(false);
    const [showQuoteSendingModal, setShowQuoteSendingModal] = React.useState(false);
    const [quoteEmailText, setQuoteEmailText] = React.useState(renderJSONReplacingPlaceholders(getCompanyQuoteEmailTemplate(adminContext.data.company.quote_email_template || ''), tiptapRenderMappings));
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const [calculatedQuote, quoteOverrideType] = calculateQuote({
        company: adminContext.data.company,
        selectedHeatPump: currentHeatPump,
        selectedHotWaterCylinder: currentHotWaterCylinder,
        selectedEmitters: designedEmitters,
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: checkIfScottish(lead.property.postcode),
        override: proposal === null || proposal === void 0 ? void 0 : proposal.quote_items,
        // We can copy across any custom added parts from the estimate
        additionalItemsFromEarlierStages: (_b = lead.estimate_quote_items) === null || _b === void 0 ? void 0 : _b.filter(x => x.selected && x.group_name === CalculatedQuoteDefaultGroups.PARTS)
    });
    const debounceUpdateProposal = useDebounceCallback(async (proposal) => {
        try {
            await updateProposalAPI(proposal, lead.uuid, companyPublicInfo.uuid);
        }
        catch (e) {
            console.error('Error updating proposal', e);
        }
    }, 1000);
    const setAndUpdateProposal = useCallback((proposal) => {
        setProposal(proposal);
        debounceUpdateProposal(proposal);
    }, []);
    useEffect(() => {
        const fetchProposal = async () => {
            const proposal = await getDraftProposalAPI(lead.uuid, companyPublicInfo.uuid);
            if (!proposal || !adminContext)
                return;
            // hydrate proposal
            proposal.cover_note = proposal.cover_note
                ? proposal.cover_note
                : JSON.stringify(getCompanyProposalCoverNoteTemplate(adminContext.data.company.proposal_cover_note_template || ''));
            setCoverNoteText(JSON.parse(proposal.cover_note));
            setAndUpdateProposal(proposal);
        };
        fetchProposal();
    }, [lead.uuid, companyPublicInfo.uuid]);
    const handleProposalSend = async (recipients) => {
        var _a, _b, _c;
        try {
            const proposalSentState = {
                ...proposal,
                // Necessary as the ... is a shallow copy and we need to copy the array
                links: (proposal === null || proposal === void 0 ? void 0 : proposal.links) || [],
                snapshot: {
                    report: calculateReportSnapshot(),
                    quote: calculateQuoteSnapshot(calculatedQuote, proposal)
                },
                recipients,
                email_text: JSON.stringify(proposalEmailText),
                sender_snapshot: {
                    first_name: (_a = adminContext.data.user) === null || _a === void 0 ? void 0 : _a.first_name,
                    last_name: (_b = adminContext.data.user) === null || _b === void 0 ? void 0 : _b.last_name,
                    email: (_c = adminContext.data.user) === null || _c === void 0 ? void 0 : _c.email
                }
            };
            const emailHtmlContent = renderProposalEmailHTML(proposalEmailText, proposalSentState.uuid);
            // save snapshot details and email config datas
            await updateProposalAPI(proposalSentState, lead.uuid, companyPublicInfo.uuid);
            // send proposal. Back-end also will mark it as sent
            await sendProposalAPI(lead.uuid, companyPublicInfo.uuid, proposalSentState.uuid, emailHtmlContent);
            // Update the lead status to 'proposal sent'
            const oldStatus = lead.status;
            setLead({ ...lead, status: LeadStatusSchema.enum.ProposalSent });
            // get a new draft proposal from the back-end (also clones the current proposal's quote items and links)
            const draftProposal = await getDraftProposalAPI(lead.uuid, companyPublicInfo.uuid);
            if (!draftProposal)
                return;
            // copy proposal data over the draft proposal, except the uuid
            const newProposal = { ...draftProposal, ...proposal, uuid: draftProposal.uuid };
            // update the proposal state to new draft proposal
            setAndUpdateProposal(newProposal);
            // We only post the user event if the email was successfully sent, because it's connected to billing
            await createJobEvent({
                event_type: JobEventType.enum.ProposalSent,
                company_uuid: companyPublicInfo.uuid,
                job_uuid: lead.uuid,
                extra_data: {
                    proposal_uuid: proposalSentState.uuid,
                    email_content: emailHtmlContent,
                    recipients
                }
            });
            // Create a second event for the status update
            if (oldStatus !== LeadStatusSchema.enum.ProposalSent) {
                createJobEvent({
                    event_type: JobEventType.enum.StatusChanged,
                    company_uuid: companyPublicInfo.uuid,
                    job_uuid: lead.uuid,
                    extra_data: {
                        old_status: oldStatus,
                        new_status: LeadStatusSchema.enum.ProposalSent
                    }
                });
            }
        }
        catch (e) {
            console.error('Error sending proposal', e);
        }
    };
    const handleDownloadProposalPreviewPDF = async () => {
        try {
            setPdfGenerating(true);
            // save snapshot details — it's required for the PDF
            const proposalSentState = {
                ...proposal,
                snapshot: {
                    report: calculateReportSnapshot(),
                    quote: calculateQuoteSnapshot(calculatedQuote, proposal)
                }
            };
            // save snapshot details — and wait until finished — it's necessary for the PDF generating
            await updateProposalAPI(proposalSentState, lead.uuid, companyPublicInfo.uuid);
            const pdfData = await getProposalPreviewPDFAPI(proposal.uuid, companyPublicInfo.uuid);
            downloadBlob(pdfData, lead.property.address + ' — Full Proposal.pdf');
            // We only post the user event if the PDF was successfully generated, because it's connected to billing
            createJobEvent({
                event_type: JobEventType.enum.ProposalPDFGenerated,
                company_uuid: companyPublicInfo.uuid,
                job_uuid: lead.uuid,
                extra_data: {
                    proposal_uuid: proposalSentState.uuid
                }
            });
        }
        catch (e) {
            console.error('Error generating PDF', e);
        }
        finally {
            setPdfGenerating(false);
        }
    };
    const handleDownloadQuotePreviewPDF = async () => {
        try {
            setPdfGenerating(true);
            const quoteSnapshotResponse = await createQuoteSnapshotAPI(lead.uuid, companyPublicInfo.uuid, {
                snapshot: calculateQuoteSnapshot(calculatedQuote, proposal)
            });
            const pdfData = await getQuotePreviewPDFAPI(quoteSnapshotResponse.uuid, companyPublicInfo.uuid);
            downloadBlob(pdfData, lead.property.address + ' — Quote.pdf');
            // We only post the user event if the PDF was successfully generated, because it's connected to billing
            createJobEvent({
                event_type: JobEventType.enum.QuotePDFGenerated,
                company_uuid: companyPublicInfo.uuid,
                job_uuid: lead.uuid,
                extra_data: {
                    quote_uuid: quoteSnapshotResponse.uuid
                }
            });
        }
        catch (e) {
            console.error('Error generating PDF', e);
        }
        finally {
            setPdfGenerating(false);
        }
    };
    const calculateReportSnapshot = () => {
        return {
            customer: lead.customer,
            property: lead.property,
            survey,
            files,
            currentHeatPump: currentHeatPump,
            currentHotWaterCylinder: currentHotWaterCylinder
        };
    };
    const handleQuoteSend = async (recipients) => {
        try {
            const quoteSnapshotResponse = await createQuoteSnapshotAPI(lead.uuid, companyPublicInfo.uuid, {
                snapshot: calculateQuoteSnapshot(calculatedQuote, proposal),
                recipients,
                email_text: JSON.stringify(quoteEmailText)
            });
            if (!quoteSnapshotResponse) {
                alert('Failed to create report');
            }
            const emailHtmlContent = renderQuoteEmailHTML(quoteEmailText, quoteSnapshotResponse.uuid);
            await sendQuoteAPI(lead.uuid, companyPublicInfo.uuid, quoteSnapshotResponse.uuid, emailHtmlContent);
            // We only post the user event if the email was successfully sent, because it's connected to billing
            createJobEvent({
                event_type: JobEventType.enum.QuoteSent,
                company_uuid: companyPublicInfo.uuid,
                job_uuid: lead.uuid,
                extra_data: {
                    quote_uuid: quoteSnapshotResponse.uuid,
                    email_content: emailHtmlContent,
                    recipients
                }
            });
        }
        catch (e) {
            console.error('Error sending quote', e);
        }
    };
    const renderProposalEmailHTML = (emailTextJSON, proposalUUID) => {
        return renderHTMLReplacingPlaceholders(emailTextJSON, {
            ...tiptapRenderMappings,
            [TTPlaceholderViewProposal.id]: process.env.BASE_URL + adminContext.data.company.public_info.subdomain + '/proposal/' + proposalUUID
        });
    };
    const renderQuoteEmailHTML = (emailTextJSON, quoteUUID) => {
        return renderHTMLReplacingPlaceholders(emailTextJSON, {
            ...tiptapRenderMappings,
            [TTPlaceholderViewQuote.id]: process.env.BASE_URL + adminContext.data.company.public_info.subdomain + '/quote/' + quoteUUID
        });
    };
    if (!proposal)
        return null;
    const quoteHeatPumpName = (_c = calculatedQuote.find(x => x.group_name === CalculatedQuoteDefaultGroups.HEAT_PUMPS)) === null || _c === void 0 ? void 0 : _c.name;
    const quoteImage = (_d = calculatedQuote.find(x => x.group_name === CalculatedQuoteDefaultGroups.HEAT_PUMPS)) === null || _d === void 0 ? void 0 : _d.image_url;
    const quoteTotal = sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0));
    const quoteLastEdited = quoteOverrideType === QuoteOverrideType.FULL ? proposal.quote_items.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())[0].updated_at : undefined;
    const ROUTES = [
        {
            path: /proposal\/quote/,
            component: () => React.createElement(QuoteBuilder, { calculatedQuote: calculatedQuote, quoteOverrideType: quoteOverrideType, currentHeatPump: currentHeatPump, currentHotWaterCylinder: currentHotWaterCylinder, emitters: designedEmitters, navigateTo: navigateTo, setProposal: setProposal, setAndUpdateProposal: setAndUpdateProposal, proposal: proposal, companyPublicInfo: companyPublicInfo })
        }
    ];
    const page = ROUTES.find(x => x.path.test(currentPathWithoutBase));
    if (page)
        return page.component();
    return React.createElement(React.Fragment, null,
        React.createElement(SendEmailWithTemplateModal, { visible: showProposalSendingModal, setVisible: setShowProposalSendingModal, handleSend: handleProposalSend, emailRecipients: !lead.proposal_recipients ? ((_f = (_e = lead.customer) === null || _e === void 0 ? void 0 : _e.email) !== null && _f !== void 0 ? _f : '') : lead.proposal_recipients, setEmailRecipients: (e) => {
                setLead({
                    ...lead,
                    proposal_recipients: e
                });
            }, emailText: proposalEmailText, setEmailText: setProposalEmailText, editorPlaceholders: [
                TTPlaceholderViewProposal
            ] }),
        React.createElement(SendEmailWithTemplateModal, { visible: showQuoteSendingModal, setVisible: setShowQuoteSendingModal, handleSend: handleQuoteSend, emailRecipients: !lead.quote_recipients ? ((_h = (_g = lead.customer) === null || _g === void 0 ? void 0 : _g.email) !== null && _h !== void 0 ? _h : '') : lead.quote_recipients, setEmailRecipients: (e) => {
                setLead({
                    ...lead,
                    quote_recipients: e
                });
            }, emailText: quoteEmailText, setEmailText: setQuoteEmailText, editorPlaceholders: [
                TTPlaceholderViewQuote
            ] }),
        React.createElement(FullscreenPreviewModal, { title: 'Proposal preview', visible: showProposalPreviewWindow, setVisible: setShowProposalPreviewWindow, previewContent: React.createElement(ProposalPublicPageInner, { proposal: proposal, setProposal: setProposal, quoteSnapshot: calculateQuoteSnapshot(calculatedQuote, proposal), reportSnapshot: calculateReportSnapshot(), companyPublicInfo: companyPublicInfo, previewMode: true }), sidebarTitle: 'Send & Save to PDF', sidebarContent: React.createElement("div", { className: 'flex flex-col gap-6 w-full' },
                React.createElement(ProposalSettingsBlock, { proposalEditingSettings: proposal, setProposalEditingSettings: setAndUpdateProposal, calculatedQuote: calculatedQuote }),
                React.createElement("div", { className: 'h-[1px] border-b border-gray-200' }),
                React.createElement(Button, { className: 'w-full', onClick: () => setShowProposalSendingModal(true) }, "Send via Email"),
                React.createElement("div", { className: 'flex flex-row gap-0 items-center w-full justify-between' },
                    React.createElement("div", { className: 'h-[1px] border-b border-dashed border-gray-200 w-2/5' }),
                    React.createElement("div", { className: 'text-center text-gray-500 text-xs w-1/5 ' }, "or"),
                    React.createElement("div", { className: 'h-[1px] border-b border-dashed border-gray-200 w-2/5' })),
                !pdfGenerating && React.createElement(Button, { iconLeft: FileText, onClick: handleDownloadProposalPreviewPDF, colour: 'LIGHT', className: 'w-full', disabled: pdfGenerating }, "Save to PDF"),
                pdfGenerating && React.createElement("div", { className: 'text-center text-gray-500 text-xs flex flex-col gap-2' },
                    React.createElement(Loader, null),
                    React.createElement("span", null, "Generating PDF"))) }),
        React.createElement(FullscreenPreviewModal, { title: 'Quote preview', visible: showQuotePreviewWindow, setVisible: setShowQuotePreviewWindow, previewContent: React.createElement(QuotePublicPageInner, { snapshot: calculateQuoteSnapshot(calculatedQuote, proposal), companyPublicInfo: companyPublicInfo }), sidebarTitle: 'Send & Save to PDF', sidebarContent: React.createElement("div", { className: 'flex flex-col gap-6 w-full' },
                React.createElement(ProposalSettingsBlock, { proposalEditingSettings: proposal, setProposalEditingSettings: setAndUpdateProposal, calculatedQuote: calculatedQuote }),
                React.createElement("div", { className: 'h-[1px] border-b border-gray-200' }),
                React.createElement(Button, { className: 'w-full', onClick: () => setShowQuoteSendingModal(true) }, "Send via Email"),
                React.createElement("div", { className: 'flex flex-row gap-0 items-center w-full justify-between' },
                    React.createElement("div", { className: 'h-[1px] border-b border-dashed border-gray-200 w-2/5' }),
                    React.createElement("div", { className: 'text-center text-gray-500 text-xs w-1/5 ' }, "or"),
                    React.createElement("div", { className: 'h-[1px] border-b border-dashed border-gray-200 w-2/5' })),
                !pdfGenerating && React.createElement(Button, { iconLeft: FileText, onClick: handleDownloadQuotePreviewPDF, colour: 'LIGHT', className: 'w-full', disabled: pdfGenerating }, "Save to PDF"),
                pdfGenerating && React.createElement("div", { className: 'text-center text-gray-500 text-xs flex flex-col gap-2' },
                    React.createElement(Loader, null),
                    React.createElement("span", null, "Generating PDF"))) }),
        showSettingsPopup && React.createElement(Modal, { visible: showSettingsPopup, setVisible: (v) => {
                setShowSettingsPopup(v);
                if (!v)
                    setProposalEditingSettings(undefined);
            }, title: 'Proposal settings', onConfirm: async () => {
                setAndUpdateProposal(proposalEditingSettings);
                setProposalEditingSettings(undefined);
            }, confirmButtonLabel: 'Apply', confirmDisabled: false, hideOnConfirm: true },
            React.createElement(ProposalSettingsBlock, { proposalEditingSettings: proposalEditingSettings, setProposalEditingSettings: setProposalEditingSettings, calculatedQuote: calculatedQuote })),
        React.createElement("div", { className: 'flex flex-col gap-6' },
            React.createElement("div", { className: 'flex flex-row justify-between items-center' },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Configure proposal"),
                React.createElement("div", { className: 'flex flex-row gap-4' },
                    React.createElement(Button, { onClick: () => {
                            setProposalEditingSettings(proposal);
                            setShowSettingsPopup(true);
                        }, colour: 'LIGHT' }, "Settings"),
                    React.createElement(Button, { onClick: () => setShowProposalPreviewWindow(true), colour: 'DARK' }, "Preview and Send"))),
            React.createElement(Section, { title: "Cover note" },
                React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setCoverNoteText(editor.getJSON());
                        setAndUpdateProposal({
                            ...proposal,
                            // the proposal.cover_note is NULL until an installer makes any changes.
                            // So this is the reason we're storing the cover note in a state variable
                            // Once it's saved, we can update the proposal.cover_note.
                            cover_note: JSON.stringify(editor.getJSON())
                        });
                    }, content: renderJSONReplacingPlaceholders(coverNoteText, tiptapRenderMappings) })),
            React.createElement(Section, { title: "Quote", controls: React.createElement(Button, { onClick: () => setShowQuotePreviewWindow(true) }, "Preview and send") },
                React.createElement(ClickableCard, { onClick: () => navigateTo('/proposal/quote'), variant: "LIGHT", border: true },
                    React.createElement("div", { className: 'flex justify-between items-center' },
                        React.createElement("div", { className: "grid grid-cols-[56px_1fr] gap-2" },
                            React.createElement("img", { src: quoteImage || HeatPump, alt: quoteHeatPumpName, className: 'w-12 h-12 object-contain', onError: ({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = HeatPump;
                                } }),
                            React.createElement("div", { className: 'flex flex-col gap-1' },
                                React.createElement(Text, { size: 'SM', bold: true }, quoteHeatPumpName),
                                React.createElement(Text, { size: "SM", className: 'text-gray-500' }, formatPrice(quoteTotal, 0)))),
                        React.createElement(Button, { colour: "TRANSPARENT", iconLeft: Edit }, "Edit"))),
                React.createElement("div", { className: 'flex justify-end' }, (quoteOverrideType === QuoteOverrideType.FULL && quoteLastEdited) ? React.createElement(Badge, { color: 'LIGHT', text: `Last edited ${contextSensitiveFormatDate(quoteLastEdited)}` }) : null)),
            React.createElement(Section, { title: "Contents" },
                React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Heat loss report', helperText: 'Detailed heat loss report with floor-plans and heat loss by element and room.' }), input: React.createElement(React.Fragment, null) }),
                React.createElement(HorizontalFormGroup, { className: 'border-l-gray-300 border-l-4 pl-4', formLabel: React.createElement(FormLabel, { labelText: 'Introduction', helperText: "A description of what heat loss is and why it's important." }), input: React.createElement(Toggle, { value: isFlagSet(proposal === null || proposal === void 0 ? void 0 : proposal.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO), setValue: () => setAndUpdateProposal({
                            ...proposal,
                            contents_bitmask: (proposal === null || proposal === void 0 ? void 0 : proposal.contents_bitmask) ^ HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO
                        }) }) }),
                React.createElement(HorizontalFormGroup, { className: 'border-l-gray-300 border-l-4 pl-4', formLabel: React.createElement(FormLabel, { labelText: 'Detailed page per room', helperText: 'Include a heat loss page for each room showing full details of all the inputs used and the results.' }), input: React.createElement(Toggle, { value: isFlagSet(proposal === null || proposal === void 0 ? void 0 : proposal.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM), setValue: () => setAndUpdateProposal({
                            ...proposal,
                            contents_bitmask: (proposal === null || proposal === void 0 ? void 0 : proposal.contents_bitmask) ^ HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM
                        }) }) }),
                React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'System design', helperText: 'Details of the proposed heat pump, emitter replacement, proposed cylinder and associated hot water calcs.' }), input: React.createElement(React.Fragment, null) }),
                React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Sound assessment', helperText: 'Results of the sound assessment along with the inputs and calculations behind the assessment.' }), input: React.createElement(React.Fragment, null) }),
                React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Performance estimate', helperText: 'An estimate of system performance covering energy, bills and carbon, plus the assumptions behind the numbers.' }), input: React.createElement(React.Fragment, null) })),
            React.createElement(ProposalFiles, { proposal: proposal, setProposal: setProposal, companyPublicInfo: companyPublicInfo })));
};
const ProposalSettingsBlock = ({ proposalEditingSettings, setProposalEditingSettings, calculatedQuote }) => {
    return React.createElement("div", { className: 'w-full flex flex-col gap-6' },
        React.createElement("div", { className: 'flex w-full flex-row justify-between' },
            React.createElement(FormLabel, { labelText: 'Show cost for each line item', helperText: 'Show the cost alongside the line item' }),
            React.createElement(Toggle, { value: proposalEditingSettings.show_cost, setValue: () => setProposalEditingSettings({
                    ...proposalEditingSettings,
                    show_cost: !proposalEditingSettings.show_cost
                }) })),
        React.createElement("div", { className: 'w-full flex flex-row justify-between' },
            React.createElement(FormLabel, { labelText: 'Include VAT on all' }),
            React.createElement(Toggle, { value: proposalEditingSettings.include_vat_on_all, setValue: () => setProposalEditingSettings({
                    ...proposalEditingSettings,
                    include_vat_on_all: !proposalEditingSettings.include_vat_on_all,
                    include_vat_on_survey: !proposalEditingSettings.include_vat_on_all ? false : proposalEditingSettings.include_vat_on_survey
                }) })),
        React.createElement("div", { className: 'w-full flex flex-row justify-between' },
            React.createElement(FormLabel, { labelText: 'Include VAT on survey only' }),
            React.createElement(Toggle, { value: proposalEditingSettings.include_vat_on_survey, setValue: () => setProposalEditingSettings({
                    ...proposalEditingSettings,
                    include_vat_on_survey: !proposalEditingSettings.include_vat_on_survey,
                    include_vat_on_all: !proposalEditingSettings.include_vat_on_survey ? false : proposalEditingSettings.include_vat_on_all
                }) })),
        React.createElement(Input, { className: 'w-full', value: proposalEditingSettings.valid_days || '', setValue: (e) => setProposalEditingSettings({ ...proposalEditingSettings, valid_days: parseInt(e) }), type: 'number', label: 'Valid for', validator: validateIsNumber, postfix: 'days' }),
        React.createElement(PaymentSchedule, { proposal: proposalEditingSettings, updateProposal: setProposalEditingSettings, calculatedQuote: calculatedQuote }));
};
