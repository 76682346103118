import React, { useState } from 'react';
import { Modal } from '../containers/modal';
export const Icon = ({ icon, onClick, confirmTextHeader, confirmTextBody = 'This action cannot be undone.', colour = 'text-default', className, disabled, spin, size }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const onClickToUse = disabled ? undefined : onClick;
    const colourToUse = disabled ? 'text-gray-300' : colour;
    const Icon = icon;
    const sizeToUse = size !== null && size !== void 0 ? size : 'w-5 h-5';
    return React.createElement(React.Fragment, null,
        React.createElement(Icon, { strokeWidth: 1.5, className: `${sizeToUse} ${className} ${colourToUse} ${onClickToUse ? 'cursor-pointer' : ''} ${spin ? 'animate-spin' : ''}`, onClick: confirmTextHeader ? () => setModalOpen(true) : onClickToUse }),
        confirmTextHeader && modalOpen &&
            React.createElement(Modal, { onConfirm: onClickToUse, visible: true, title: confirmTextHeader, setVisible: () => setModalOpen(!modalOpen) }, confirmTextBody));
};
