import React, { Fragment } from 'react';
import { HLRTitlePage } from './hlr_title_page';
import { HLRSummaryPage } from './hlr_summary_page';
import { HLRIntroductionPage } from './hlr_introduction_page';
import { HLRCalculationConditionsPage } from './hlr_calculation_conditions_page';
import { HLRHeatlossByElementPage } from './hlr_heatloss_by_element_page';
import { HLRHeatlossByRoomPage } from './hlr_heatloss_by_room_page';
import { HLRFloorHeatlossPage } from './hlr_floor_heatloss_page';
import { HLRRoomHeatlossPage } from './hlr_room_heatloss_page';
import { getCylinderReheatCalculationRow, getDailyHotWaterVolumeL, getHotWaterCalculationRowLegionella, getHotWaterCalculationRowNormalOperation, getNumberOfOccupants } from '../../../code/models/hot_water_cylinder';
import { eligibleForHeatPumpPlus, getPerformanceEstimateSummary } from '../../../code/models/performance_estimate';
import { HLRHeatPump } from './hlr_heat_pump';
import { HLREmittersIntro } from './hlr_emitters_intro';
import { HLREmittersCurrent } from './hlr_emitters_current';
import { HLREmittersProposed } from './hlr_emitters_proposed';
import { HLRHotWater } from './hlr_hot_water';
import { HLRHotWaterDemand } from './hlr_hot_water_demand';
import { HLRSoundAssessment } from './hlr_sound_assessment';
import { HLRPESummary } from './hlr_pe_summary';
import { HLRPEDetailedResultsIntroduction } from './hlr_pe_detailed_results_intro';
import { HLRPEResultsTable } from './hlr_pe_results_table';
import { HLRPEInputsAndAssumptions } from './hlr_pe_inputs_and_assumptions';
import { HLRPESystemEfficiency1 } from './hlr_pe_system_efficiency_1';
import { HLRPESystemEfficiency2 } from './hlr_pe_system_efficiency_2';
import { HLRPEFlowTempChart } from './hlr_pe_flow_temp_chart';
import { HLRPEMCSKeyFacts } from './hlr_pe_mcs_key_facts';
import { getFullEmittersListByStatus } from '../../../code/models/radiator';
import { getHLReportPDFAPI, HEATLOSS_REPORT_SECTION_DESIGN, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY, HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE, HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT, HEATLOSS_REPORT_SECTION_SUMMARY } from '../../../code/models/heatloss_report';
import { downloadBlob, isFlagSet } from '../../../code/helpers';
import { convertLatLngListToLatLongLiteral } from '../../../code/geocoding';
import { getDesignConditions } from '../../../code/models/design_temp';
import { getHeatTransferCoefficientWattsPerKelvin, getRoomWatts } from '../../../code/models/heat_loss';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from '../../../code/models/range_heat_pump';
import { getSoundAssessmentData } from '../../../code/sound_assessment';
import { getAreaM2 } from '../floor/code/utils';
import { PrintLayout, PrintLayoutPageBreak } from '../../components/print_layout';
import { Button } from '../../../components/buttons/button';
import { FileText } from 'lucide-react';
export const HLRContext = React.createContext(undefined);
export const HeatLossReportPage = (props) => {
    const survey = props.snapshot.survey;
    const calculatedData = hlrMakeCalcsFromSnapshot(props.snapshot);
    // If ONLY sound assessment don't show header and contents
    const emittersDefined = getFullEmittersListByStatus(survey, calculatedData.design).length > 0;
    const logoImageData = props.company_public_info.logo;
    return (React.createElement(HLRContext.Provider, { value: {
            survey,
            property: props.snapshot.property,
            customer: props.snapshot.customer,
            company_public_info: props.company_public_info,
            design: calculatedData.design,
            designTempC: calculatedData.designTempC,
            groundTempC: calculatedData.groundTempC,
            degreeDays: calculatedData.degreeDays,
            currentHeatPump: props.snapshot.currentHeatPump,
            currentHotWaterCylinder: props.snapshot.currentHotWaterCylinder,
            cylinderReheatRow: calculatedData.cylinderReheatRow,
            hotWaterRowNormal: calculatedData.hotWaterRowNormal,
            hotWaterRowLegionella: calculatedData.hotWaterRowLegionella,
            performanceEstimateColumns: calculatedData.performanceEstimateColumns,
            performanceEstimateSummary: calculatedData.performanceEstimateSummary,
            eligibleForHeatPumpPlus: calculatedData.eligibleForHeatPumpPlus,
            soundAssessment: calculatedData.soundAssessment,
            files: props.snapshot.files,
            summaryData: calculatedData.summaryData,
            hpCapacityResult: calculatedData.hpCapacityResult,
            scop: calculatedData.scop
        } },
        React.createElement(PrintLayout
        /*
          Carefully estimate this number based on the header's real height.
          This number is used to offset content below the print-version header.
        */
        , { 
            /*
              Carefully estimate this number based on the header's real height.
              This number is used to offset content below the print-version header.
            */
            headerHeightPx: 120, header: React.createElement("div", { className: [
                    'w-full bg-white border-b border-b-gray-200 justify-between items-center gap-6 flex px-1 py-1',
                    'print:p-6 print:sticky print:top-0 ', // print
                    'sm:px-3 sm:py-3 ', // sm
                    'md:px-6 md:py-6 ', // md
                    'lg:sticky lg:top-0 ' // lg
                ].join(' ') },
                React.createElement("div", { className: 'flex flex-row gap-4' }, logoImageData && React.createElement("img", { src: logoImageData, className: "max-h-10 md:max-h-12", alt: "Installer logo" })),
                React.createElement("div", { className: 'flex flex-row gap-4 print:hidden' }, props.reportUUID && React.createElement(Button, { colour: 'LIGHT', iconLeft: FileText, onClick: async () => {
                        const pdfData = await getHLReportPDFAPI(props.reportUUID, props.company_public_info.uuid);
                        downloadBlob(pdfData, props.snapshot.property.address + ' — Heat Loss Report.pdf');
                    } },
                    React.createElement("span", { className: 'hidden sm:inline' }, "Save to PDF")))), 
            // The gap is used to make space between pages
            content: React.createElement("div", { className: 'print:px-0 px-1 sm:px-3 md:px-6 flex flex-col print:gap-0 gap-8' },
                React.createElement("div", null,
                    React.createElement(PrintLayoutPageBreak, null),
                    React.createElement(HLRTitlePage, null)),
                isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_SUMMARY) && React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRSummaryPage, null))),
                isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY) && React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRIntroductionPage, { hasIntroduction: isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO) })),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRCalculationConditionsPage, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRHeatlossByElementPage, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRHeatlossByRoomPage, null)),
                    survey.floors.map((floor, floorIdx) => {
                        // skip floors without rooms
                        if (floor.rooms.length === 0)
                            return null;
                        return (React.createElement(Fragment, { key: 'floor-' + floorIdx },
                            React.createElement("div", null,
                                React.createElement(PrintLayoutPageBreak, null),
                                React.createElement(HLRFloorHeatlossPage, { floor: floor })),
                            isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM) &&
                                floor.rooms.sort((a, b) => a.name.localeCompare(b.name))
                                    .map((room, roomIdx) => {
                                    return React.createElement(Fragment, { key: 'floor-' + floorIdx + '-room-' + roomIdx },
                                        React.createElement("div", null,
                                            React.createElement(PrintLayoutPageBreak, null),
                                            React.createElement(HLRRoomHeatlossPage, { floor: floor, room: room })));
                                })));
                    })),
                isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_DESIGN) && React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRHeatPump, null)),
                    emittersDefined && React.createElement(React.Fragment, null,
                        React.createElement("div", null,
                            React.createElement(PrintLayoutPageBreak, null),
                            React.createElement(HLREmittersIntro, null)),
                        React.createElement("div", null,
                            React.createElement(PrintLayoutPageBreak, null),
                            React.createElement(HLREmittersCurrent, null)),
                        React.createElement("div", null,
                            React.createElement(PrintLayoutPageBreak, null),
                            React.createElement(HLREmittersProposed, null))),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRHotWater, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRHotWaterDemand, null))),
                isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT) && React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRSoundAssessment, null))),
                isFlagSet(props.sections, HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE) && React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRPESummary, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRPEDetailedResultsIntroduction, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRPEResultsTable, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRPEInputsAndAssumptions, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRPESystemEfficiency1, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRPESystemEfficiency2, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRPEFlowTempChart, null)),
                    React.createElement("div", null,
                        React.createElement(PrintLayoutPageBreak, null),
                        React.createElement(HLRPEMCSKeyFacts, null)))) })));
};
// the main function to calculate the report data from the snapshot
// the function could not be in a .ts file because it uses React FCs to render some views
export const hlrMakeCalcsFromSnapshot = (snapshot) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const design = snapshot.survey.designs[0];
    const latLng = convertLatLngListToLatLongLiteral(snapshot.property.postcodeLocation);
    const altitudetoUseM = (_a = snapshot.survey.altitude_override_m) !== null && _a !== void 0 ? _a : snapshot.property.altitudeM;
    const { designTempDefaultC, degreeDays, groundTempC } = getDesignConditions(latLng, altitudetoUseM, snapshot.survey.exposed_location); // done separately as needed in survey_settings
    const designTempC = (_b = snapshot.survey.design_temp_override_c) !== null && _b !== void 0 ? _b : designTempDefaultC;
    const cylinderReheatRow = getCylinderReheatCalculationRow(snapshot.currentHotWaterCylinder, design.hot_water_storage_temperature_c, (_c = snapshot.currentHeatPump) === null || _c === void 0 ? void 0 : _c.range_heat_pump, designTempC);
    const numberOfOccupants = getNumberOfOccupants(snapshot.survey);
    const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, snapshot.survey.volume_per_person_l);
    const hotWaterRowNormal = getHotWaterCalculationRowNormalOperation((_d = snapshot.currentHeatPump) === null || _d === void 0 ? void 0 : _d.range_heat_pump, dailyHotWaterVolumeL, design.hot_water_storage_temperature_c);
    const hotWaterRowLegionella = getHotWaterCalculationRowLegionella(design.hot_water_storage_temperature_c, (_f = (_e = snapshot.currentHotWaterCylinder) === null || _e === void 0 ? void 0 : _e.litres) !== null && _f !== void 0 ? _f : 0, snapshot.survey.legionnaires_cycle_weeks);
    const heatLossWattsPerKelvin = getHeatTransferCoefficientWattsPerKelvin(snapshot.survey, designTempC, groundTempC);
    const designHotWaterDemandKwh = hotWaterRowNormal.heatEnergyKwhPerCycle * hotWaterRowNormal.cyclesPerYear;
    const scopSpaceHeating = getHeatPumpScopAtFlowTemp((_g = snapshot.currentHeatPump) === null || _g === void 0 ? void 0 : _g.range_heat_pump, design.flow_temp);
    const eligibleForHeatPumpPlusResult = eligibleForHeatPumpPlus(snapshot.currentHeatPump);
    const performanceEstimateSummary = getPerformanceEstimateSummary(snapshot.survey, heatLossWattsPerKelvin, degreeDays, designHotWaterDemandKwh, hotWaterRowNormal.heatingEfficiency, scopSpaceHeating, eligibleForHeatPumpPlusResult);
    const performanceEstimateColumns = [
        {
            key: 'name',
            name: 'Name',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.name)
                    : React.createElement("div", null, row.name))
        },
        {
            key: 'kwh',
            name: 'Energy',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.kwh)
                    : React.createElement("div", null, row.kwh))
        },
        {
            key: 'costUserEnteredTariff', // render with the user entered tariff so they can play. In report this won't be show, the other 3 options will be
            name: 'Bills',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.costUserEnteredTariff)
                    : React.createElement("div", null, row.costUserEnteredTariff))
        },
        {
            key: 'emissionsKG',
            name: 'Emissions',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.emissionsKG)
                    : React.createElement("div", null, row.emissionsKG))
        }
    ];
    const soundAssessmentData = getSoundAssessmentData(snapshot.survey.sound_barrier_uuid, snapshot.survey.reflective_surfaces, snapshot.survey.sound_distance, (_j = (_h = snapshot.currentHeatPump) === null || _h === void 0 ? void 0 : _h.range_heat_pump) === null || _j === void 0 ? void 0 : _j.sound_power_max_dba);
    const soundAssessment = snapshot.currentHeatPump ? (_k = soundAssessmentData === null || soundAssessmentData === void 0 ? void 0 : soundAssessmentData.finalResultDba) !== null && _k !== void 0 ? _k : 0 : 0;
    const summaryData = {
        floorArea: 0,
        heatLoss: 0,
        radiatorsNew: 0,
        radiatorsReplaced: 0,
        ufhNew: 0,
        emittersRemoved: 0,
        emittersUnchanged: 0
    };
    snapshot.survey.floors.forEach(floor => {
        floor.rooms.forEach(room => {
            const roomHeatLoss = getRoomWatts(room, floor.rooms, designTempC, groundTempC, snapshot.survey);
            const roomArea = getAreaM2(room.walls.map(w => ({ x: w.x, y: w.y })));
            summaryData.heatLoss += roomHeatLoss;
            summaryData.floorArea += roomArea;
        });
    });
    const emitters = getFullEmittersListByStatus(snapshot.survey, design);
    emitters.forEach(emitter => {
        if (emitter.status === 'ADDED') {
            if (emitter.radiator.emitter_type === 'UNDERFLOOR') {
                summaryData.ufhNew++;
            }
            else {
                summaryData.radiatorsNew++;
            }
        }
        else if (emitter.status === 'REPLACED') {
            // Currently cannot "Replace" underfloor heating so only need to worry about radiators here
            summaryData.radiatorsReplaced++;
        }
        else if (emitter.status === 'REMOVED') {
            summaryData.emittersRemoved++;
        }
        else if (emitter.status === 'UNCHANGED') {
            summaryData.emittersUnchanged++;
        }
    });
    const hpCapacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(snapshot.currentHeatPump.range_heat_pump, designTempC, design.flow_temp);
    const scop = getHeatPumpScopAtFlowTemp((_l = snapshot.currentHeatPump) === null || _l === void 0 ? void 0 : _l.range_heat_pump, design.flow_temp);
    return {
        design: snapshot.survey.designs[0],
        designTempC,
        groundTempC,
        degreeDays,
        cylinderReheatRow,
        hotWaterRowNormal,
        hotWaterRowLegionella,
        performanceEstimateSummary,
        performanceEstimateColumns,
        eligibleForHeatPumpPlus: eligibleForHeatPumpPlusResult,
        soundAssessment,
        summaryData,
        hpCapacityResult,
        scop
    };
};
