import React, { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from 'lucide-react';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
import { logEvent } from '../../../code/log_event';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
export const FloorAreaStep = ({ companyPublicInfo }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    // save user input in state, because when we do calculations we want to use the original value
    // and not the converted one
    const [userInput, setUserInput] = useState(survey.lead.property.floorArea || 0);
    const [userInputUnit, setUserInputUnit] = useState('sqm');
    const handleValueChange = (value) => {
        const valueAsNumber = parseInt(value);
        setUserInput(valueAsNumber);
    };
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Floor area step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                floorArea: Number((userInputUnit === 'sqm' ? userInput : userInput / 10.764).toFixed(2))
            }
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement(QuestionnaireNavigateBack, null),
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), 
            // show different text depending on whether we have an EPC or not
            headerText: survey.lead.epcData !== undefined
                ? 'Confirm your property’s floor area'
                : 'Your property’s floor area', 
            // show different text depending on whether we have an EPC or not
            captionText: survey.lead.epcData !== undefined
                ? 'We have pre-filled this based on your EPC. Let us know if it has changed or you have a more accurate figure.'
                : 'This is the floor area of all heatable parts of your home' }),
        React.createElement("div", { className: "flex-col gap-3 flex" },
            React.createElement(Input, { size: 'LG', setValue: handleValueChange, type: "number", value: userInput.toString(), placeholder: 'E.g. 169' }),
            React.createElement(SurveyOptionGroup, { noCheckmark: true, inline: true, selectedOptionKey: userInputUnit, options: [
                    { label: 'Square meters', key: 'sqm' },
                    { label: 'Square feet', key: 'sqft' }
                ], onSelect: (value) => {
                    setUserInputUnit(value);
                    setUserInput(value === 'sqm' ? Number((userInput / 10.764).toFixed(2)) : Number((userInput * 10.764).toFixed(2)));
                } })),
        React.createElement(Button, { size: 'LG', disabled: userInput <= 0, onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))));
};
