import React from 'react';
import { Icon } from '../buttons/icon';
import { noop } from 'lodash';
import { ChevronRight } from 'lucide-react';
const colours = {
    DARK: 'bg-gray-100',
    LIGHT: 'border-gray-200',
    YELLOW: 'border-yellow-500',
    RED: 'border-red-500',
    BLUE: 'border-blue-500',
    GREEN: 'border-green-500',
    WHITE: 'bg-white'
};
export const Card = ({ children, className, color }) => React.createElement("div", { className: `border px-4 py-2 rounded-lg ${className} ${colours[color]}` }, children);
const variants = (border) => ({
    LIGHT: `bg-gray-50 ${border ? 'border border-gray-200' : ''}`,
    GREY: `bg-gray-100 ${border ? 'border border-gray-200' : ''}`,
    WHITE: `bg-white ${border ? 'border border-gray-200' : ''}`,
    GREEN: `bg-green-100 ${border ? 'border-green-200' : ''}`,
    RED: `bg-red-100 ${border ? 'border border-red-200' : ''}`,
    YELLOW: `bg-yellow-100 ${border ? 'border border-yellow-500' : ''} text-yellow-500`,
    WARNING: `bg-yellow-200 ${border ? 'border border-yellow-900' : ''} text-yellow-900`,
    PLACEHOLDER: `${border ? 'border border-dashed border-gray-200' : ''} bg-gray-50 text-gray-500`
});
const sizes = {
    SM: 'p-2',
    MD: 'px-4 py-3'
};
export const ClickableCard = ({ title, titleIcon, submitIcon = ChevronRight, showIcon = true, children, variant, className, onClick, size = 'MD', border = true, disabled }) => {
    const variantsMap = variants(border);
    return React.createElement("div", { onClick: !disabled ? onClick : noop, className: `gap-4 rounded-xl flex flex-col ${variantsMap[variant]} ${sizes[size]} ${disabled ? 'opacity-50' : ''} ${onClick && !disabled ? 'cursor-pointer' : ''} ${className}` },
        (titleIcon || title) && React.createElement("div", { className: 'flex gap-2 items-center' },
            React.createElement("div", { className: 'flex gap-2 items-center text-gray-900 font-bold flex-grow' },
                titleIcon && React.createElement(Icon, { icon: titleIcon }),
                title),
            showIcon && onClick && React.createElement(Icon, { icon: submitIcon })),
        children);
};
