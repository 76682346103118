import React, { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Button } from '../../../components/buttons/button';
import { logEvent } from '../../../code/log_event';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { SubHeader } from '../components/sub_header';
import { ChevronRightIcon } from 'lucide-react';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
export const ScottishGrantStep = ({ companyPublicInfo }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const [selectedIsInTouchOption, setSelectedIsInTouchOption] = useState();
    const [selectedReasonOption, setSelectedReasonOption] = useState();
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Home Energy Scotland Step Completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                scottishGrantIsInTouch: selectedIsInTouchOption,
                scottishGrantReason: selectedReasonOption
            }
        });
    };
    const NoKey = 'No';
    const YesKey = 'Yes';
    function buildOption(value) {
        return { key: value, label: value };
    }
    return React.createElement(React.Fragment, null,
        React.createElement(QuestionnaireNavigateBack, null),
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "Have you been in touch with Home Energy Scotland?" }),
        React.createElement(SurveyOptionGroup, { options: [
                { key: NoKey, label: 'No' },
                { key: YesKey, label: 'Yes' }
            ], onSelect: (key) => { setSelectedIsInTouchOption(key); setSelectedReasonOption(undefined); }, selectedOptionKey: selectedIsInTouchOption }),
        selectedIsInTouchOption !== undefined &&
            React.createElement(SubHeader, { content: "More detail ..." }),
        selectedIsInTouchOption === NoKey &&
            React.createElement(SurveyOptionGroup, { options: [
                    buildOption('It\'s on my list to do'),
                    buildOption('I\'m not sure what that means?'),
                    buildOption('I\'m happy to proceed without a grant')
                ], onSelect: (key) => { setSelectedReasonOption(key); }, selectedOptionKey: selectedReasonOption }),
        selectedIsInTouchOption === YesKey &&
            React.createElement(SurveyOptionGroup, { options: [
                    buildOption('I\'m in contact with my local Home Energy Specialist'),
                    buildOption('I\'ve received a Home Energy Improvement Report'),
                    buildOption('I\'ve submitted my application for funding')
                ], onSelect: (key) => { setSelectedReasonOption(key); }, selectedOptionKey: selectedReasonOption }),
        React.createElement(Button, { size: 'LG', disabled: !selectedIsInTouchOption || !selectedReasonOption, onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))));
};
