import React, { useState } from 'react';
import { PageHeader } from '../components/design_page_header';
import { Button } from '../../../../components/buttons/button';
import { Input } from '../../../../components/inputs_and_selections/input';
import { sum } from 'lodash';
import { ChevronLeft, ChevronRight, Trash } from 'lucide-react';
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon';
export const QuoteDesignPage = ({ defaultLineItems, lineItems, setLineItems, isOffline, allSynced }) => {
    const [currentLineItem, setCurrentLineItem] = useState();
    const onDelete = () => {
        setLineItems(lineItems.filter(x => x.uuid !== (currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.uuid)));
        setCurrentLineItem(undefined);
    };
    const onSave = () => {
        setLineItems((currentLineItem === null || currentLineItem === void 0 ? void 0 : currentLineItem.uuid) ? lineItems.map(x => x.uuid === currentLineItem.uuid ? currentLineItem : x) : [...lineItems, { ...currentLineItem, uuid: crypto.randomUUID() }]);
        setCurrentLineItem(undefined);
    };
    const hydratedLineItems = [...defaultLineItems.map(dli => {
            const override = lineItems.find(li => li.type === dli.type);
            return override || dli;
        }), ...lineItems.filter(x => x.type === 'ADDITIONAL')];
    const total = sum(hydratedLineItems.map(x => x.value * x.quantity));
    if (currentLineItem)
        return React.createElement(EditOrAddLineItem, { lineItem: currentLineItem, setLineItem: setCurrentLineItem, onSave: onSave, onDelete: onDelete });
    return React.createElement("div", { className: 'flex flex-col h-full' },
        React.createElement(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Quote', onBack: () => window.history.back() }),
        React.createElement("div", { className: 'flex flex-col p-4 divide divide-y divide-gray-200 overflow-y-auto' },
            hydratedLineItems.map(x => React.createElement("div", { key: x.name, onClick: () => setCurrentLineItem(x), className: 'flex justify-between p-2 items-center cursor-pointer' },
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                    React.createElement("div", { className: 'text-gray-600' },
                        x.quantity,
                        " x \u00A3",
                        x.value)),
                React.createElement("div", { className: 'flex gap-4 items-center' },
                    x.quantity && React.createElement("div", { className: 'text-gray-600' },
                        "\u00A3",
                        x.quantity * x.value),
                    React.createElement(WrappedIcon, { icon: ChevronRight, className: 'text-gray-600 cursor-pointer' })))),
            React.createElement("div", { className: 'p-2 flex justify-between font-bold text-gray-900' },
                React.createElement("div", null, "Total"),
                React.createElement("div", null,
                    "\u00A3",
                    total))),
        React.createElement("div", { className: 'flex p-4' },
            React.createElement(Button, { block: true, onClick: () => setCurrentLineItem({ uuid: undefined, name: '', value: 0, quantity: 0, type: 'ADDITIONAL' }) }, "Add item")));
};
const EditOrAddLineItem = ({ lineItem, setLineItem, onSave, onDelete }) => {
    var _a, _b;
    return React.createElement("div", null,
        React.createElement("div", { className: 'flex justify-between gap-3 px-5 py-4 bg-white border-b border-gray-200 items-center' },
            React.createElement("div", { className: 'flex flex-wrap items-center gap-3 cursor-pointer', onClick: () => setLineItem(undefined) },
                React.createElement(WrappedIcon, { className: 'text-gray-600 cursor-pointer', icon: ChevronLeft }),
                React.createElement("div", { className: 'text-gray-900 text-xl font-bold' }, "Line item")),
            React.createElement("div", { className: 'flex gap-2 items-center' }, lineItem.type === 'ADDITIONAL' && lineItem.uuid && React.createElement(WrappedIcon, { onClick: onDelete, className: 'text-gray-600 cursor-pointer', icon: Trash }))),
        React.createElement("div", { className: 'flex flex-col gap-4 p-4' },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Description"),
                React.createElement(Input, { value: lineItem.name, setValue: (e) => setLineItem(prev => ({ ...prev, name: e })) })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Price (\u00A3)"),
                React.createElement(Input, { type: "number", value: lineItem.value.toString(), setValue: (e) => setLineItem(prev => ({ ...prev, value: Number(e) })) })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Quantity"),
                React.createElement(Input, { type: "number", value: (_b = (_a = lineItem.quantity) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '', setValue: (e) => setLineItem(prev => ({ ...prev, quantity: Number(e) })) })),
            React.createElement("div", { className: 'flex justify-between' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Total"),
                lineItem.quantity && React.createElement("div", { className: 'text-gray-600' },
                    "\u00A3",
                    lineItem.quantity * lineItem.value)),
            React.createElement("div", { className: 'flex gap-2' },
                lineItem.type !== 'ADDITIONAL' && lineItem.uuid && React.createElement(Button, { className: 'flex-1', onClick: onDelete }, "Reset to default"),
                React.createElement(Button, { className: 'flex-1', onClick: onSave }, "Save"))));
};
