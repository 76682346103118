import React from 'react';
import { SurveyElectricsContext } from '../electrics';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const ElectricsIncomingMainAndFuseDetailsBlock = () => {
    const electricsContext = React.useContext(SurveyElectricsContext);
    const { survey, setSurvey } = electricsContext;
    const PHASES = ['Single', 'Three'];
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Incoming main and fuse"),
        React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Phase"),
                React.createElement(RadioGroup, { items: PHASES.map(x => ({
                        name: x,
                        onClick: () => setSurvey({ ...survey, electrics_phase: x }),
                        variant: x === survey.electrics_phase ? 'ACTIVE' : 'DEFAULT'
                    })) })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Main fuse size"),
                React.createElement(Input, { type: 'number', value: survey.electrics_main_fuse_size_amps.toString(), setValue: (e) => setSurvey({ ...survey, electrics_main_fuse_size_amps: Number(e) }), postfix: 'amps' })),
            React.createElement("div", { className: "gap-3 flex justify-between items-center" },
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Looped service"),
                    React.createElement("div", { className: "text-gray-500 text-sm" }, "A looped service is where two properties share a single electricity service cable from the main network. There will be two cables to the cut out.")),
                React.createElement(Toggle, { value: survey.electrics_looped_service, setValue: () => setSurvey({ ...survey, electrics_looped_service: !survey.electrics_looped_service }) }))));
};
