import React, { useState } from 'react';
import { Select } from '../../../components/inputs_and_selections/select';
export const SelectDemo = () => {
    const selectOptions = [
        { key: '1', value: 'Option 1' },
        { key: '2', value: 'Option 2' },
        { key: '3', value: 'An extremely long value which should be truncated when it gets too long for the maximum width of the dropdown' }
    ];
    const [selectedOption, setSelectedOption] = useState('1');
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", null, "Default"),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, size: 'MD', colour: 'DEFAULT', filter: false }),
            React.createElement("div", null, "Searchable"),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, size: 'MD', colour: 'DEFAULT', filter: true }),
            React.createElement("div", null, "With label"),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, size: 'MD', colour: 'DEFAULT', filter: false, label: 'Demo select' }),
            React.createElement("div", null, "Colours"),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, colour: 'DEFAULT' }),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, colour: 'LIGHT' }),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, colour: 'DARK' }),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, colour: 'BLUE' }),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, colour: 'GREEN' }),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, colour: 'RED' }),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, colour: 'YELLOW' }),
            React.createElement("div", null, "Sizes"),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, size: 'SM' }),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, size: 'MD' }),
            React.createElement(Select, { options: selectOptions, selectedKey: selectedOption, setSelectedKey: setSelectedOption, size: 'LG' })));
};
const componentProps = [
    { name: 'options', description: 'Array of options to display in the dropdown in the format { key: string, value: string }' },
    { name: 'selectedKey', description: 'The key of the selected option (shown when the dropdown is closed)' },
    { name: 'setSelectedKey', description: 'Function which receives the key of the clicked option - should update the selectedKey' },
    { name: 'filter', description: 'Whether the dropdown should have a type-to-search filter' },
    { name: 'filterCallback', description: 'Function which receives the search query when the filter is used - should update the options. Optional - if not specified, the options will be filtered by a simple text match.' },
    { name: 'isLoading', description: 'Whether the dropdown is in a loading state - should be set by the filterCallback' },
    { name: 'size', description: 'Size of the dropdown - SM, MD, LG' },
    { name: 'colour', description: 'Colour of the dropdown - DEFAULT, DARK, LIGHT, YELLOW, RED, BLUE, GREEN' },
    { name: 'placeholder', description: 'Placeholder text to show when no option is selected' },
    { name: 'label', description: 'Label to show above the currently selected option. Only shown in non-filtered selects' },
    { name: 'className', description: 'Additional classes to apply to the dropdown' },
    { name: 'dataCy', description: 'data-cy attribute for testing' },
    { name: 'autoFocus', description: 'Whether the dropdown should be focused on render' }
];
const codeBlock = `const [selectedKey, setSelectedKey] = useState<string>('1')

<Select
  options={[
    { key: '1', value: 'One' },
    { key: '2', value: 'Two' },
    { key: '3', value: 'Three' }
  ]}
  selectedKey={selectedKey}
  setSelectedKey={setSelectedKey}
  size='MD'
  label='Demo select'
  colour='DEFAULT'
  filter={false}
/>

`;
