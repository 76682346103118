import React, { useEffect, useRef, useState } from 'react';
import { Section } from '../../../../components/containers/section';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Map, Marker, useAutocomplete } from '@vis.gl/react-google-maps';
import { SettingsLayout } from '../components/settings_layout';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { validateEmail, validatePhone } from '../../../../code/validators';
import { Info } from '../../../../components/buttons/info';
export const CompanyDetails = ({ company, setCompany }) => {
    const [addressLocation, setAddressLocation] = useState();
    const [mapCenter, setMapCenter] = useState();
    const inputRef = useRef(null);
    useEffect(() => {
        var _a, _b, _c, _d;
        setAddressLocation({ lat: (_a = company.lat) !== null && _a !== void 0 ? _a : 0, lng: (_b = company.lng) !== null && _b !== void 0 ? _b : 0 });
        setMapCenter({ lat: (_c = company.lat) !== null && _c !== void 0 ? _c : 0, lng: (_d = company.lng) !== null && _d !== void 0 ? _d : 0 });
    }, []);
    const onPlaceChanged = (place) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        // OnPlaceChanged fires when we click an autocompleted suggestion when typing an address in the Address input.
        // Update the company address, company lat and lng with the new details.
        // And update the map view with the pin on the new location.
        if (place) {
            setCompany({ ...company, public_info: { ...company.public_info, address: (_b = (_a = place.formatted_address) !== null && _a !== void 0 ? _a : place.name) !== null && _b !== void 0 ? _b : '' }, lat: (_e = (_d = (_c = place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lat()) !== null && _e !== void 0 ? _e : 0, lng: (_h = (_g = (_f = place.geometry) === null || _f === void 0 ? void 0 : _f.location) === null || _g === void 0 ? void 0 : _g.lng()) !== null && _h !== void 0 ? _h : 0 });
            setAddressLocation((_k = (_j = place.geometry) === null || _j === void 0 ? void 0 : _j.location) === null || _k === void 0 ? void 0 : _k.toJSON());
            setMapCenter((_m = (_l = place.geometry) === null || _l === void 0 ? void 0 : _l.location) === null || _m === void 0 ? void 0 : _m.toJSON());
        }
    };
    useAutocomplete({
        inputField: inputRef === null || inputRef === void 0 ? void 0 : inputRef.current,
        onPlaceChanged
    });
    return React.createElement(SettingsLayout, { heading: 'Company details', description: 'Manage your company details' },
        React.createElement(Section, { border: true, title: "Company details" },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4' },
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Company name' }), input: React.createElement(Input, { value: company.public_info.name, setValue: (e) => {
                                setCompany({ ...company, public_info: { ...company.public_info, name: e } });
                            } }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Phone number' }), input: React.createElement(Input, { value: company.phone, setValue: (e) => {
                                if (validatePhone(e).value) {
                                    setCompany({ ...company, phone: e });
                                }
                            }, validator: validatePhone, validateImmediately: true }) })),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Notification email', info: React.createElement(Info, { infoModalHeader: 'Notification email', infoModalBody: 'This is the email address where you receive notification emails from Spruce when customers submit an enquiry, book a heat loss survey, or request a callback. It can be different from your login email.' }) }), input: React.createElement(Input, { value: company.notification_email, setValue: (e) => {
                            if (validateEmail(e).value) {
                                setCompany({ ...company, notification_email: e });
                            }
                        }, validator: validateEmail, validateImmediately: true }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Company address' }), input: React.createElement(React.Fragment, null,
                        React.createElement(Input, { ref: inputRef, value: company.public_info.address, setValue: (e) => setCompany({ ...company, public_info: { ...company.public_info, address: e } }) }),
                        ((addressLocation === null || addressLocation === void 0 ? void 0 : addressLocation.lat) !== 0 && (addressLocation === null || addressLocation === void 0 ? void 0 : addressLocation.lng) !== 0) &&
                            React.createElement(Map, { onClick: (e) => {
                                    var _a, _b, _c, _d;
                                    const newLocation = e.detail.latLng;
                                    setAddressLocation(newLocation !== null && newLocation !== void 0 ? newLocation : undefined);
                                    setCompany({
                                        ...company,
                                        lat: (_b = (_a = e.detail.latLng) === null || _a === void 0 ? void 0 : _a.lat) !== null && _b !== void 0 ? _b : undefined,
                                        lng: (_d = (_c = e.detail.latLng) === null || _c === void 0 ? void 0 : _c.lng) !== null && _d !== void 0 ? _d : undefined
                                    });
                                }, streetViewControl: false, zoom: 18, className: "min-h-[200px] w-full h-full rounded overflow-hidden", mapTypeId: "satellite", center: mapCenter },
                                React.createElement(Marker, { position: addressLocation }))) }))));
};
