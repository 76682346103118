import React, { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { getBrandRange, getBrandRangesLite } from '../../../../../code/models/brand_range';
import { chain, orderBy } from 'lodash';
import { Section } from '../../../job_layout/components/section';
import { Button } from '../../../../../components/buttons/button';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { InventoryTable } from '../inventory_table';
import { Modal } from '../../../../../components/containers/modal';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../../../../code/models/range_heat_pump';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { HeatPumpModal } from '../heat_pump_modal';
import { priceCalculations } from '../../../../../code/price_calculations';
import { EmptySection } from '../empty_section';
import { Check, Edit, Plus, Trash } from 'lucide-react';
import { Loader } from '../../../../../components/indicators_and_messaging/loader';
export const HeatPumpsInventory = ({ heatPumps, addHeatPumps, deleteHeatPump, updateHeatPump, company, setCompany, packs }) => {
    const [modalHeatPumpId, setModalHeatPumpId] = useState();
    const [addHeatPumpModalVisible, setAddHeatPumpModalVisible] = useState(false);
    const [addRangeModalVisible, setAddRangeModalVisible] = useState(false);
    const [selectedRangeId, setSelectedRangeId] = useState('');
    const [brandRangesLite, setBrandRangesLite] = useState([]);
    const [brandRanges, setBrandRanges] = useState([]);
    const [selectedHeatPumpIds, setSelectedHeatPumpIds] = useState([]);
    const rangeHeatPumps = brandRanges.flatMap(x => x.range_heat_pumps).filter(x => x.deleted_at === null);
    const hydratedHeatPumps = chain(orderBy(heatPumps, x => { var _a; return (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.name; }))
        .groupBy(x => { var _a, _b, _c; return (_c = (_b = (_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ''; })
        .map((values, key) => ({ key, values }))
        .value();
    const modalHeatPump = heatPumps.find(x => x.uuid === modalHeatPumpId);
    const heatPumpsColumns = [
        { key: 'name', name: 'Name' },
        { key: 'costPrice', name: 'Cost price' },
        { key: 'markup', name: 'Markup' },
        { key: 'customerPrice', name: 'Customer price' },
        { key: 'menu', name: '', align: 'right' }
    ];
    const rangeHeatPumpColumns = [
        { key: 'name', name: 'Name' },
        { name: 'Heat Capacity @ 45°C (kW)', render: (row) => React.createElement("div", null, getHeatPumpCapacityAtOutsideTempAndFlowTemp(row, -3, 45).capacityKw) }
    ];
    const hpRows = orderBy(rangeHeatPumps.filter(x => x.brand_range_uuid === selectedRangeId && !heatPumps.map(x => x.range_heat_pump_uuid).includes(x.uuid)), x => getHeatPumpCapacityAtOutsideTempAndFlowTemp(x, -3, 45).capacityKw);
    const selectedRange = brandRanges.find(x => x.uuid === selectedRangeId);
    const setDefaultRange = async (uuid) => {
        if (!uuid)
            return;
        const updatedCompany = { ...company, default_brand_range_uuid: uuid };
        setCompany({ company: updatedCompany, debounce: false });
    };
    const handleLoadBrandRangesLite = async () => {
        // do not load if already loaded
        if (brandRanges.length > 0)
            return;
        const response = await getBrandRangesLite(company.public_info.uuid);
        if (response)
            setBrandRangesLite(response);
    };
    const handleLoadBrandRanges = async () => {
        if (!selectedRangeId)
            return;
        const response = await getBrandRange(company.public_info.uuid, selectedRangeId);
        if (response)
            setBrandRanges(response);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "space-y-6" },
            React.createElement("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full" },
                React.createElement(Heading, { size: "2xl" }, "Heat pumps"),
                React.createElement(Button, { colour: 'DARK', onClick: async () => {
                        setAddRangeModalVisible(true);
                        await handleLoadBrandRangesLite();
                    }, iconLeft: Plus }, "Add range")),
            !hydratedHeatPumps.length
                ? React.createElement(EmptySection, { title: 'No heat pumps added', description: 'Add heat pumps to your inventory to create estimates and quotes for your customers.', button: React.createElement(Button, { onClick: () => setAddRangeModalVisible(true), iconLeft: Plus }, "Add range") })
                : hydratedHeatPumps.map((brandRange, index) => {
                    var _a;
                    return (React.createElement(Section, { title: React.createElement("div", { className: 'flex items-center justify-between gap-3 w-full' },
                            React.createElement("div", { className: 'flex items-center gap-2' },
                                React.createElement(Heading, { size: "xl" }, brandRange.key),
                                ((_a = brandRange.values[0].range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid) === company.default_brand_range_uuid && React.createElement(Badge, { color: "LIGHT", text: "Default range" })),
                            React.createElement(DropdownMenu, { items: [
                                    { label: 'Make default range', onClick: () => { var _a; return setDefaultRange((_a = brandRange.values[0].range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid); }, icon: Check }
                                    // { label: 'Delete range', onClick: noop, icon: Trash } // Not currently implemented
                                ] })), key: index },
                        React.createElement(InventoryTable, { rows: brandRange.values
                                .sort((a, b) => getHeatPumpCapacityAtOutsideTempAndFlowTemp(a.range_heat_pump, -3, 45).capacityKw - getHeatPumpCapacityAtOutsideTempAndFlowTemp(b.range_heat_pump, -3, 45).capacityKw)
                                .map(x => {
                                const packForHeatPump = packs.find(pack => pack.uuid === x.default_pack_uuid);
                                return {
                                    name: React.createElement("div", { className: "flex flex-col gap-2 items-start" },
                                        React.createElement(Text, { size: "SM" }, x.name),
                                        packForHeatPump && React.createElement("div", { className: 'flex gap-1 items-center' },
                                            React.createElement(Badge, { color: "LIGHT", text: packForHeatPump.name }))),
                                    costPrice: React.createElement(Input, { type: 'number', value: x.cost_price, prefix: "\u00A3", setValue: (value) => updateHeatPump({ heatPump: priceCalculations.updateCostPrice(x, parseFloat(value)), debounce: true }) }),
                                    markup: React.createElement(Input, { type: 'number', step: 1, value: Math.round(x.markup), setValue: (value) => updateHeatPump({ heatPump: priceCalculations.updateMarkup(x, parseFloat(value)), debounce: true }), postfix: '%' }),
                                    customerPrice: React.createElement(Input, { type: 'number', value: priceCalculations.calculateCustomerPrice(x.cost_price, x.markup), setValue: (value) => updateHeatPump({ heatPump: priceCalculations.updateCostPriceAndMarkupToMatchCustomerPrice(x, parseFloat(value)), debounce: true }), prefix: '\u00A3' }),
                                    menu: React.createElement(DropdownMenu, { items: [
                                            { label: 'Edit', onClick: () => setModalHeatPumpId(x.uuid), icon: Edit },
                                            { label: 'Delete', onClick: () => deleteHeatPump(x.uuid), icon: Trash, confirmText: 'Are you sure you want to delete this heat pump?' }
                                        ] })
                                };
                            }), columns: heatPumpsColumns })));
                })),
        addRangeModalVisible &&
            React.createElement(Modal, { allowOverflow: true, confirmButtonLabel: 'Continue', visible: true, setVisible: () => setAddRangeModalVisible(false), title: 'Add a heat pump range', onConfirm: async () => {
                    await handleLoadBrandRanges();
                    setAddRangeModalVisible(false);
                    setAddHeatPumpModalVisible(true);
                } },
                React.createElement("div", { className: 'flex flex-col gap-2 w-full' },
                    brandRangesLite.length === 0 &&
                        React.createElement("div", { className: 'flex justify-center items-center w-full h-32' },
                            React.createElement(Loader, null)),
                    brandRangesLite.length !== 0 && React.createElement(React.Fragment, null,
                        React.createElement(Select, { dataCy: "add_dropdown", filter: true, selectedKey: selectedRangeId, options: orderBy(brandRangesLite.map(x => ({ key: x.uuid, value: x.name })), x => x.value), setSelectedKey: setSelectedRangeId }),
                        React.createElement("div", { className: "text-gray-600 text-xs" },
                            "Can't find the range you are looking for?",
                            React.createElement("a", { target: "_blank", href: "mailto:support@spruce.eco", className: "underline", rel: "noreferrer" }, "Let us know"),
                            "and we'll add it for you.")))),
        addHeatPumpModalVisible &&
            React.createElement(Modal, { onConfirm: () => {
                    const heatPumpsToAdd = rangeHeatPumps.filter(x => selectedHeatPumpIds.includes(x.uuid)).map(x => ({
                        uuid: crypto.randomUUID(),
                        name: x.name,
                        cost_price: 0,
                        markup: 0,
                        range_heat_pump: { ...x, brand_range: selectedRange },
                        range_heat_pump_uuid: x.uuid
                    }));
                    addHeatPumps(heatPumpsToAdd);
                    setSelectedHeatPumpIds([]);
                }, confirmButtonLabel: 'Add', confirmDisabled: selectedHeatPumpIds.length === 0, visible: true, setVisible: () => setAddHeatPumpModalVisible(false), title: 'Add a heat pump' },
                hpRows.length === 0 &&
                    React.createElement("div", { className: 'w-full' },
                        "No available ",
                        React.createElement("span", { className: 'font-bold' }, selectedRange.name),
                        " heat pumps to add.",
                        React.createElement("br", null),
                        "Perhaps, you have already added all the heat pumps from this range."),
                hpRows.length !== 0 &&
                    React.createElement(TableLite, { rows: hpRows, rowIdName: 'uuid', columns: rangeHeatPumpColumns, selectedIds: selectedHeatPumpIds, setSelectedIds: setSelectedHeatPumpIds })),
        modalHeatPump && React.createElement(HeatPumpModal, { heatPump: modalHeatPump, setVisible: () => setModalHeatPumpId(undefined), updateHeatPump: updateHeatPump, packs: packs })));
};
