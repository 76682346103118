import { setError } from '../../components/indicators_and_messaging/toast';
import { apiUrl, client } from '../axios';
import { z } from 'zod';
export const addressSchema = z.object({
    postcode: z.string(),
    postcode_inward: z.string(),
    postcode_outward: z.string(),
    post_town: z.string(),
    dependant_locality: z.string(),
    double_dependant_locality: z.string(),
    thoroughfare: z.string(),
    dependant_thoroughfare: z.string(),
    building_number: z.string(),
    building_name: z.string(),
    sub_building_name: z.string(),
    po_box: z.string(),
    department_name: z.string(),
    organisation_name: z.string(),
    udprn: z.string(),
    postcode_type: z.string(),
    su_organisation_indicator: z.string(),
    delivery_point_suffix: z.string(),
    line_1: z.string(),
    line_2: z.string(),
    line_3: z.string(),
    premise: z.string(),
    longitude: z.number(),
    latitude: z.number(),
    eastings: z.number().int(),
    northings: z.number().int(),
    country: z.string(),
    traditional_county: z.string(),
    administrative_county: z.string(),
    postal_county: z.string(),
    county: z.string(),
    district: z.string(),
    ward: z.string(),
    uprn: z.string(),
    id: z.string(),
    country_iso: z.string(),
    country_iso_2: z.string(),
    county_code: z.string(),
    language: z.string(),
    umprn: z.string(),
    dataset: z.string()
});
export const addressLookupSchema = z.object({
    id: z.string(),
    suggestion: z.string(),
    udprn: z.string(),
    urls: z.object({
        udprn: z.string()
    })
});
export const checkIfScottish = (postcode) => {
    if (!postcode || postcode.length === 0)
        return false;
    return /^(AB|DD|DG|EH|FK|G|HS|IV|KA|KW|KY|ML|PA|PH|TD|ZE)[0-9]+/i.test(postcode);
};
export const checkIfWelsh = (postcode) => {
    if (!postcode || postcode.length === 0)
        return false;
    return /^(CF|LD|LL|NP|SA|SY)[0-9]+/i.test(postcode);
};
export const buildSingleLineAddressFromLines = (address) => {
    return [address.line_1, address.line_2, address.line_3, address.post_town].filter(x => !!x).join(', ');
};
export const findAddresses = async (query) => {
    const result = await client.get(`${apiUrl}addresses?query=${query.slice(0, 150)}`);
    return result.data;
};
export const getAddressForUdprn = async (udprn) => {
    try {
        const result = await client.get(`${apiUrl}addresses/${udprn}`);
        return result.data;
    }
    catch (e) {
        setError(e);
    }
};
// Parses the address data from the address lookup into the lead
export const parseAddressData = (address, lead) => {
    return {
        ...lead,
        property: {
            ...lead.property,
            address: buildSingleLineAddressFromLines(address),
            postcode: address.postcode,
            // Lat/long
            postcodeLocation: [address.latitude, address.longitude],
            uprn: address.uprn,
            udprn: address.udprn.toString()
        }
    };
};
// Parses the address data from the address lookup into an object ready for the manual address form
export const parseAddressDataForManualEntry = (address) => {
    return {
        line_1: address.line_1,
        line_2: address.line_2,
        line_3: address.line_3,
        town: address.post_town,
        postcode: address.postcode
    };
};
