import React from 'react';
export const SubHeader = ({ content, className = '', size = 'lg' }) => {
    let sigingClasses = '';
    switch (size) {
        case 'sm':
            sigingClasses = 'text-sm ';
            break;
        case 'md':
            sigingClasses = ' ';
            break;
        case 'lg':
            sigingClasses = 'text-xl ';
            break;
    }
    return React.createElement("div", { className: `${className} ${sigingClasses} text-gray-900 font-bold ` }, content);
};
