import React, { useState } from 'react';
import { Input } from '../../components/inputs_and_selections/input';
import { Button } from '../../components/buttons/button';
import { remindPassword } from '../../code/models/auth';
import { SpruceLogoBlack } from '../../assets/images/spruce_logo/spruce_logo';
import { Link } from '../../components/buttons/link';
export const RemindPasswordPage = ({ navigateTo }) => {
    // common admin/ preloaded data
    const [email, setEmail] = useState('');
    // component specific
    const [showSent, setShowSent] = useState(false);
    const handleSubmit = async () => {
        await remindPassword(email);
        setShowSent(true);
    };
    return React.createElement("div", { className: "flex flex-col items-center justify-center gap-16 w-2/3 max-w-80 mx-auto" },
        React.createElement("img", { alt: "logo", className: "mt-20 max-h-28 max-w-2/3", src: SpruceLogoBlack }),
        React.createElement("div", { className: "w-full" },
            React.createElement("div", { className: "flex flex-col gap-4" },
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "text-slate-600 text-lg font-bold" }, "Email"),
                    React.createElement(Input, { onEnter: async () => { await handleSubmit(); }, setValue: setEmail, type: "text", value: email, placeholder: 'Email' })),
                React.createElement(Link, { text: 'Back to Login page', onClick: () => navigateTo('/login') }),
                React.createElement(Button, { onClick: handleSubmit }, "Send link"),
                showSent && React.createElement("div", { className: "text-sm text-green-600" }, "If your email address is registered on Spruce, we will send you an email with a link to reset your password."))));
};
