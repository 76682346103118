import { getCompanyEstimateEmailTemplate } from '../../../../../code/models/company';
import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { defaultPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName, TTPlaceholderHeatPumpModel, TTPlaceholderViewEstimate } from '../../../../../code/tiptap_placeholders';
export const SendEstimateEmailTemplateBlock = ({ company, setCompany }) => {
    return React.createElement(Section, { border: true, title: "Send estimate email template" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'space-y-2' },
                React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                        setCompany({
                            ...company,
                            estimate_template: JSON.stringify(editor.getJSON())
                        });
                    }, placeholders: [
                        TTPlaceholderViewEstimate,
                        TTPlaceholderCustomerName,
                        TTPlaceholderCustomerAddress,
                        TTPlaceholderHeatPumpModel,
                        TTPlaceholderCarbonSaving,
                        ...defaultPlaceholders
                    ], content: getCompanyEstimateEmailTemplate(company.estimate_template || '') }))));
};
