import React from 'react';
import { Info } from '../../../components/buttons/info';
export const InfoDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", { className: 'flex justify-start items-end gap-4' },
                React.createElement(Info, { infoModalHeader: 'What is this?', infoModalBody: 'Lots of nice info about heat pumps' }))));
};
const componentProps = [
    { name: 'infoModalHeader', description: 'What text should be in the header of the modal that opens on click' },
    { name: 'infoModalBody', description: 'What text should be in the body of the modal that opens on click [optional]' },
    { name: 'colour', description: 'What colour should the icon be [optional - defaults to text-gray-400]' },
    { name: 'className', description: 'Any additional classes you want to add to the component [optional]' }
];
const codeBlock = `<Info
    infoModalHeader={"What is this?"}
    infoModalBody={undefined}
    colour={"text-red-800"}  // default is text-gray-400
></Info>`;
