import React, { useEffect, useState } from 'react';
import { Button } from '../buttons/button';
import { Input } from '../inputs_and_selections/input';
import { WrappedIcon } from '../buttons/wrapped_icon';
import { Check, Edit, Trash, X } from 'lucide-react';
export const Table = ({ columns, rows, editable, addNew, onDeleteRow, onSaveRow, editableColumnWidth }) => {
    const newRowEmpty = Object.fromEntries(columns.map(x => ([x.key, ''])));
    const [editingRow, setEditingRow] = useState();
    const [newRow, setNewRow] = useState(newRowEmpty);
    const [addNewDisabled, setAddNewDisabled] = useState(true);
    const isRowValid = (row) => columns.every(c => !c.validator || c.validator(row));
    useEffect(() => {
        // check each column has a value
        const hasValue = columns.every(x => newRow[x.key] !== '');
        setAddNewDisabled(!hasValue);
    }, [newRow]);
    return React.createElement("div", { className: "rounded overflow-auto" },
        React.createElement("table", { className: "text-sm text-left text-gray-500 w-full shadow table-auto" },
            React.createElement("thead", { className: "text-gray-700 bg-gray-50" },
                React.createElement("tr", null,
                    columns.map((x, i) => React.createElement("th", { className: 'px-2 py-4', key: i }, x.name)),
                    editable && React.createElement("th", { style: { width: editableColumnWidth } }))),
            React.createElement("tbody", null,
                rows.map((x, xi) => {
                    const isEditing = (editingRow === null || editingRow === void 0 ? void 0 : editingRow.uuid) === x.uuid;
                    return React.createElement("tr", { className: "bg-white border-b hover:bg-gray-50", key: xi },
                        columns.map((y, yi) => React.createElement("td", { key: yi, className: "p-2 h-full" }, cell(y, isEditing ? editingRow : x, (e) => { setEditingRow((prev) => ({ ...prev, [y.key]: e })); }, isEditing))),
                        editable && React.createElement("td", null,
                            React.createElement("div", { className: "flex items-center space-x-2 justify-end p-2 h-full" },
                                !isEditing && React.createElement(Button, { onClick: () => { setEditingRow({ ...x }); } },
                                    React.createElement(WrappedIcon, { "data-cy": 'table_edit_button', className: "w-3 h-3", icon: Edit })),
                                !isEditing && onDeleteRow && React.createElement(Button, { onClick: () => { onDeleteRow(x.uuid); } },
                                    React.createElement(WrappedIcon, { "data-cy": 'table_delete_button', className: "w-3 h-3", icon: Trash })),
                                isEditing && React.createElement(Button, { disabled: !isRowValid(editingRow), onClick: () => { onSaveRow === null || onSaveRow === void 0 ? void 0 : onSaveRow(editingRow); setEditingRow(undefined); } },
                                    React.createElement(WrappedIcon, { "data-cy": 'table_save_button', className: "w-3 h-3", icon: Check })),
                                isEditing && React.createElement(Button, { onClick: () => { setEditingRow(undefined); } },
                                    React.createElement(WrappedIcon, { className: "w-3 h-3", icon: X })))));
                }),
                addNew && newRow && React.createElement("tr", null,
                    columns.map((x, i) => React.createElement("td", { key: i, className: "p-2" }, cell(x, newRow, (e) => { setNewRow((prev) => ({ ...prev, [x.key]: e })); }, true))),
                    React.createElement("td", null,
                        React.createElement("div", { className: "h-full flex items-center justify-end p-2" },
                            React.createElement(Button, { disabled: addNewDisabled, onClick: () => { onSaveRow === null || onSaveRow === void 0 ? void 0 : onSaveRow(newRow); setNewRow(newRowEmpty); } }, "Add New")))))));
};
const cell = (column, row, onChange, isEditing) => {
    var _a, _b, _c;
    if (isEditing) {
        if (column.editRender)
            return column.editRender(row, onChange);
        if (column.key)
            return React.createElement(Input, { setValue: (e) => { onChange(e); }, value: (_b = (_a = row[column.key]) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '' });
        return;
    }
    return column.render ? column.render(row) : (_c = row[column.key]) === null || _c === void 0 ? void 0 : _c.toString();
};
