import React from 'react';
import { Button } from '../../components/buttons/button';
import { Input } from '../../components/inputs_and_selections/input';
import { MaterialInputField } from './materials/material_input_field';
import { validateInMm } from '../../code/validators';
export const RooflightPage = ({ rooflight, setRooflight, onSave, materials, survey, setMsProps, setPage }) => {
    const isValidForm = [validateInMm(rooflight.width_mm), validateInMm(rooflight.height_mm)].every(x => x.value);
    return React.createElement("div", { className: 'flex flex-col' },
        React.createElement("div", { className: "p-5 bg-white flex-col gap-5 flex" },
            React.createElement("div", { className: 'text-gray-900 font-bold text-sm' }, "Rooflight material"),
            React.createElement(MaterialInputField, { selectorProps: {
                    materials,
                    title: 'Rooflight material',
                    surfaceType: 'window',
                    selectedMaterial: rooflight.material,
                    ageBand: survey.age_band,
                    onSelectCallback: (material) => {
                        setRooflight(prev => ({ ...prev, material }));
                    }
                }, setMsProps: setMsProps, setPage: setPage }),
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'flex gap-2 text-sm text-gray-600' },
                    React.createElement("div", { className: 'flex-1 flex flex-col gap-1' },
                        React.createElement("div", null, "Width"),
                        React.createElement(Input, { type: 'number', step: 1, value: rooflight.width_mm.toString(), setValue: (e) => setRooflight(prev => ({ ...prev, width_mm: parseFloat(e) })), validator: validateInMm, postfix: 'mm' })),
                    React.createElement("div", { className: 'flex-1 flex flex-col gap-1' },
                        React.createElement("div", null, "Height"),
                        React.createElement(Input, { type: 'number', step: 1, value: rooflight.height_mm.toString(), setValue: (e) => setRooflight(prev => ({ ...prev, height_mm: parseFloat(e) })), validator: validateInMm, postfix: 'mm' })))),
            React.createElement(Button, { disabled: !isValidForm, onClick: () => {
                    onSave();
                    setRooflight(undefined);
                } }, "Apply")));
};
