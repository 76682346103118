import React from 'react';
export const ColoredBlockWrapper = ({ children, color }) => {
    const colors = {
        gray: 'bg-gray-100',
        green: 'bg-green-50',
        blue: 'bg-slate-100',
        yellow: 'bg-yellow-50'
    };
    return React.createElement("div", { className: `${colors[color]} w-full p-5 rounded-lg flex-col flex` }, children);
};
