import React, { useState } from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { Button } from '../../../../../components/buttons/button';
import { InventoryTable } from '../inventory_table';
import { Section } from '../../../job_layout/components/section';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { DropdownMenu } from '../../../../../components/buttons/dropdown_menu';
import { Modal } from '../../../../../components/containers/modal';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { Edit, Plus, Trash } from 'lucide-react';
const ModalInputs = ({ labour, updateLabour }) => {
    return (React.createElement("div", { className: "space-y-4 w-full" },
        React.createElement(Input, { label: 'Name', placeholder: 'Enter labour name', value: labour.name, setValue: (value) => updateLabour('name', value) }),
        React.createElement(Input, { label: 'Price/day (ex. VAT)', type: "number", value: labour.cost_price, setValue: (value) => updateLabour('cost_price', parseFloat(value)), prefix: "\u00A3", postfix: "/day" }),
        React.createElement(Input, { label: 'Default number of days', type: "number", value: labour.days, setValue: (value) => updateLabour('days', parseFloat(value)) })));
};
const AddLabourModal = ({ addLabour, visible, setVisible }) => {
    const [labour, setLabour] = useState({
        name: '',
        cost_price: 0,
        days: 0
    });
    const updateLabour = (field, value) => {
        setLabour(prev => ({ ...prev, [field]: value }));
    };
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: 'Add labour', onConfirm: () => {
            addLabour({
                ...labour,
                uuid: crypto.randomUUID(),
                default_include: false
            });
            setLabour({ name: '', cost_price: 0, days: 0 });
        }, confirmButtonLabel: 'Add' },
        React.createElement(ModalInputs, { labour: labour, updateLabour: updateLabour })));
};
const EditLabourModal = ({ labour, updateLabour, visible, setVisible }) => {
    const [localLabour, setLocalLabour] = useState(labour);
    const updateLocalLabour = (field, value) => {
        setLocalLabour(prev => ({ ...prev, [field]: value }));
    };
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: 'Edit labour', onConfirm: () => {
            updateLabour(localLabour);
        }, confirmButtonLabel: 'Save' },
        React.createElement(ModalInputs, { labour: labour, updateLabour: updateLocalLabour })));
};
export const LabourInventory = ({ labour, addOrUpdateLabour, deleteLabour, company, setCompany }) => {
    const [addLabourModalVisible, setAddLabourModalVisible] = useState(false);
    const [editLabourModalLabour, setEditLabourModalLabour] = useState(undefined);
    const labourColumns = [
        { key: 'name', name: 'Name' },
        { key: 'alwaysInclude', name: 'Always include', infoText: 'If toggled, this labour item will always be added to new estimates and quotes.' },
        { key: 'costPrice', name: 'Price/day (ex. VAT)' },
        { key: 'days', name: 'Default number of days' },
        { key: 'menu', name: '', align: 'right' }
    ];
    const labourRows = [
        {
            name: 'Survey',
            alwaysInclude: React.createElement(Toggle, { value: company.default_include_survey || false, setValue: async () => {
                    setCompany({ company: { ...company, default_include_survey: !company.default_include_survey }, debounce: false });
                } }),
            costPrice: React.createElement(Input, { type: 'number', value: company.survey_cost || 0, prefix: "\u00A3", setValue: async (value) => {
                    setCompany({ company: { ...company, survey_cost: parseFloat(value) }, debounce: true });
                } }),
            days: '',
            menu: ''
        },
        ...labour
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(labour => ({
            name: labour.name,
            alwaysInclude: React.createElement(Toggle, { value: labour.default_include, setValue: () => addOrUpdateLabour({ ...labour, default_include: !labour.default_include }) }),
            costPrice: React.createElement(Input, { type: 'number', value: labour.cost_price, prefix: "\u00A3", postfix: "/day", setValue: (value) => {
                    addOrUpdateLabour({ ...labour, cost_price: parseFloat(value) });
                } }),
            days: React.createElement(Input, { type: 'number', value: labour.days, setValue: (value) => {
                    addOrUpdateLabour({ ...labour, days: parseFloat(value) });
                } }),
            menu: React.createElement(DropdownMenu, { items: [
                    { label: 'Edit', onClick: () => setEditLabourModalLabour(labour), icon: Edit },
                    { label: 'Delete', onClick: () => deleteLabour(labour), icon: Trash, confirmText: 'Are you sure you want to delete this labour item?' }
                ] })
        }))
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "space-y-6" },
            React.createElement("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full" },
                React.createElement(Heading, { size: "2xl" }, "Labour"),
                React.createElement(Button, { colour: 'DARK', onClick: () => setAddLabourModalVisible(true), iconLeft: Plus }, "Add labour")),
            React.createElement(Section, null,
                React.createElement(InventoryTable, { rows: labourRows, columns: labourColumns }))),
        React.createElement(AddLabourModal, { visible: addLabourModalVisible, setVisible: setAddLabourModalVisible, addLabour: addOrUpdateLabour }),
        editLabourModalLabour && React.createElement(EditLabourModal, { labour: editLabourModalLabour, updateLabour: addOrUpdateLabour, visible: !!editLabourModalLabour, setVisible: (visible) => {
                if (!visible) {
                    setEditLabourModalLabour(undefined);
                }
            } })));
};
