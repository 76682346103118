import React from 'react';
import { updateCompanySettings } from '../../../../../code/models/company';
import { Info } from '../../../../../components/buttons/info';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { Section } from '../../../../../components/containers/section';
import { ListItem } from '../../../../../components/content_display/list_item';
export const EstimateConfigurationBlock = ({ company, setCompany }) => {
    const handleResponsesToggle = async (toggleName) => {
        const otherToggleName = toggleName === 'no_request_callback' ? 'no_survey_booking' : 'no_request_callback';
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                // Flip the current toggle value
                [toggleName]: !company.public_info[toggleName],
                /*
                  Ensure 'no_request_callback' and 'no_survey_booking' can't be true at the same time:
                  - If toggleName is being set to true (currently false), and the otherToggleName is already true,
                  we flip otherToggleName to be false.
                  - Otherwise, leave it as is
                */
                [otherToggleName]: !company.public_info[toggleName] && company.public_info[otherToggleName]
                    ? false : company.public_info[otherToggleName]
            }
        };
        setCompany(newCompany);
        await updateCompanySettings(newCompany);
    };
    return (React.createElement(Section, { border: true, title: 'Configuration' },
        React.createElement("div", { className: 'flex flex-col gap-6' },
            React.createElement("p", null, "Choose which sections you would like to appear in the homeowner-facing estimate."),
            React.createElement("div", { className: 'flex flex-col divide-y divide-gray-300' },
                React.createElement(ListItem, { primaryText: 'Line-by-line cost breakdown', secondaryText: 'Show the cost of each line item on the estimate', actionButton: React.createElement(Toggle, { value: company.public_info.show_line_item_costs_on_estimate, setValue: async () => {
                            const newCompany = {
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    show_line_item_costs_on_estimate: !company.public_info.show_line_item_costs_on_estimate
                                }
                            };
                            setCompany(newCompany);
                            await updateCompanySettings(newCompany);
                        } }) }),
                React.createElement(ListItem, { primaryText: 'Estimated bill savings', secondaryText: 'Include estimated bill savings at the head of the page and within each cost estimate.', actionButton: React.createElement(Toggle, { value: company.public_info.show_bill_savings_on_estimate, setValue: async () => {
                            const newCompany = {
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    show_bill_savings_on_estimate: !company.public_info.show_bill_savings_on_estimate
                                }
                            };
                            setCompany(newCompany);
                            await updateCompanySettings(newCompany);
                        } }) }),
                React.createElement(ListItem, { primaryText: '\'About us\' section', secondaryText: 'Display the \'About us\' section on the estimate', actionButton: React.createElement(Toggle, { value: company.public_info.show_about_us_on_estimate, setValue: async () => {
                            const newCompany = {
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    show_about_us_on_estimate: !company.public_info.show_about_us_on_estimate
                                }
                            };
                            setCompany(newCompany);
                            await updateCompanySettings(newCompany);
                        } }) }),
                React.createElement(ListItem, { primaryText: 'Customer review section', secondaryText: 'Display the customer review section on the estimate', actionButton: React.createElement(Toggle, { value: company.public_info.show_customer_review_on_estimate, setValue: async () => {
                            const newCompany = {
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    show_customer_review_on_estimate: !company.public_info.show_customer_review_on_estimate
                                }
                            };
                            setCompany(newCompany);
                            await updateCompanySettings(newCompany);
                        } }) }),
                React.createElement("div", { className: "border-t border-gray-300" })),
            React.createElement("div", { className: 'flex flex-col gap-3' },
                React.createElement(FormLabel, { labelText: 'Call to action options', size: 'LG', info: React.createElement(Info, { infoModalHeader: 'Call to Action Options', infoModalBody: `Give customers the option to book a survey, request a callback or both. You must select at least one option. 
                  For Scottish properties, these options will have no effect and requesting a callback will be enabled by default.` }) }),
                React.createElement("div", { className: 'flex flex-col divide-y divide-gray-300' },
                    React.createElement(ListItem, { primaryText: '\'Book a survey\' button', secondaryText: 'Display the \'Book a survey\' button on the estimate', actionButton: React.createElement(Toggle, { value: !company.public_info.no_survey_booking, setValue: () => handleResponsesToggle('no_survey_booking') }) }),
                    React.createElement(ListItem, { primaryText: '\'Request a callback\' button', secondaryText: 'Display the \'Request a callback\' button on the estimate', actionButton: React.createElement(Toggle, { value: !company.public_info.no_request_callback, setValue: () => handleResponsesToggle('no_request_callback') }) }))))));
};
