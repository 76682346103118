import React, { useContext, useEffect, useState } from 'react';
import { Caption } from '../components/caption_1';
import { SurveyActionType } from '../../../code/survey/survey';
import { Input } from '../../../components/inputs_and_selections/input';
import { Accordion } from '../../../components/content_display/accordion';
import { Button } from '../../../components/buttons/button';
import { logEvent } from '../../../code/log_event';
import { getAddressIncludingPostcode, postLead } from '../../../code/models/lead';
import { calculateEstimate } from '../../../code/calculate_estimate';
import { ColoredBlockWrapper } from '../components/colored_block_wrapper';
import { StepHeader } from '../components/steps_header';
import { SubHeader } from '../components/sub_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { validateEmail, validateNotNull } from '../../../code/validators';
import { numberFormat } from '../../../code/number_format';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { geocodeAddress } from '../../../code/geocoding';
import { Select } from '../../../components/inputs_and_selections/select';
import { TextArea } from '../../../components/inputs_and_selections/text_area';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
import { createJobEvent, JobEventType } from '../../../code/models/job_event';
export const useGeocodeAddress = (postcode, address) => {
    const [loadingLocation, setLoadingLocation] = useState(true);
    const [location3D, setLocation3D] = useState();
    // Needed to allow for async loading of the location
    useEffect(() => {
        // Ensure we don't try to set state on an unmounted component (chatgpt suggested this, happy to loose if not needed)
        let isMounted = true;
        setLoadingLocation(true);
        const loadAdressLocation = async () => {
            try {
                const location = await geocodeAddress(address, postcode);
                if (isMounted) {
                    setLocation3D(location);
                    setLoadingLocation(false);
                }
            }
            catch (error) {
                if (isMounted) {
                    setLoadingLocation(false);
                }
            }
        };
        loadAdressLocation();
        return () => { isMounted = false; };
    }, [address, postcode]);
    return { location3D, loadingLocation };
};
export const GetContactsStep = ({ adminEstimate, companyPublicInfo, navigateTo }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const [loading, setLoading] = useState(false);
    // Add location to the lead now as needed to get the heat loss calc right and then also it's stored for later
    const { location3D, loadingLocation } = useGeocodeAddress(getAddressIncludingPostcode(survey.lead), survey.lead.property.postcode);
    const lead = { ...survey.lead, property: { ...survey.lead.property, latLng: location3D ? [location3D.lat, location3D.lng] : undefined, altitudeM: location3D === null || location3D === void 0 ? void 0 : location3D.altitudeM } };
    const calculatedHeatLoss = calculateEstimate(lead, [], [], undefined);
    const [fieldsData, setFieldsData] = useState({
        name: '',
        email: '',
        phone: '',
        // This is a legacy workaround for a specific customer - their company name gets put in the source field
        sourceCompany: survey.lead.customer.source,
        source: ''
    });
    // const [isAgreementAccepted, setIsAgreementAccepted] = useState<boolean>(true) // Set to false when we re-enable component.
    const [comment, setComment] = useState('');
    const [customSource, setCustomSource] = useState('');
    const isValidForm = [validateEmail(fieldsData.email), validateNotNull(fieldsData.name), validateNotNull(fieldsData.phone)].every(x => x.value);
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Contact details step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                lead: { ...lead, customer: { ...fieldsData, source: getSource(fieldsData, customSource) }, notes: comment }
            }
        });
    };
    const getSource = (fieldsData, customSource) => {
        // If we've got the sourceCompany filled in from the workaround in the lead,
        // we concatenate it with the source
        const baseSource = fieldsData.source === 'Other' ? customSource : fieldsData.source;
        return fieldsData.sourceCompany
            ? `${fieldsData.sourceCompany} - ${baseSource || 'Not provided'}`
            : baseSource || 'Not provided';
    };
    if (loading)
        return React.createElement(Loader, null);
    const handleSubmit = async () => {
        survey.lead.customer = { ...fieldsData, source: getSource(fieldsData, customSource) };
        survey.lead.notes = comment;
        setLoading(true);
        const leadUUID = await postLead(survey.lead, companyPublicInfo.uuid, adminEstimate);
        createJobEvent({
            event_type: JobEventType.enum.JobCreated,
            company_uuid: companyPublicInfo.uuid,
            job_uuid: leadUUID
        });
        if (adminEstimate) {
            // If we're an installer, take us straight to the estimate
            navigateTo(`/${companyPublicInfo.subdomain}/admin/quotes/${leadUUID}/dashboard`);
        }
        else {
            // Otherwise, take us to the Thank You page
            handleNavigateNextPage();
            setLoading(false);
            window.parent.postMessage('spruce_customer_requested_estimate', '*');
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement(QuestionnaireNavigateBack, null),
        !loadingLocation &&
            React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: `We estimate your property has ${(calculatedHeatLoss.totalWatts / 1000).toFixed(1)}kW of heat loss`, captionText: `You could save ${numberFormat(0).format(calculatedHeatLoss.CO2SavedKg)}kg of CO2 every year by switching to a heat pump.` }),
        loadingLocation &&
            React.createElement(Loader, null),
        React.createElement(ColoredBlockWrapper, { color: "blue" },
            React.createElement("div", { className: "flex flex-col  gap-6" },
                React.createElement("div", { className: "flex flex-col gap-2" },
                    React.createElement(SubHeader, { content: "Please enter your details if you'd like us to send a (no-obligation) cost estimate" })),
                React.createElement("div", { className: "w-full flex flex-col gap-3" },
                    React.createElement("div", { className: "space-y-2" },
                        React.createElement("div", { className: "text-gray-600 text-lg" }, "Name"),
                        React.createElement(Input, { size: 'LG', setValue: (value) => { setFieldsData(prev => ({ ...prev, name: value })); }, type: 'text', value: fieldsData.name, validator: validateNotNull, placeholder: 'Name' })),
                    React.createElement("div", { className: "space-y-2" },
                        React.createElement("div", { className: "text-gray-600 text-lg" }, "Email"),
                        React.createElement(Input, { size: 'LG', setValue: (value) => { setFieldsData(prev => ({ ...prev, email: value })); }, type: 'text', value: fieldsData.email, validator: validateEmail, placeholder: 'Email' })),
                    React.createElement("div", { className: "space-y-2" },
                        React.createElement("div", { className: "text-gray-600 text-lg" }, "Phone"),
                        React.createElement(Input, { size: 'LG', setValue: (value) => { setFieldsData(prev => ({ ...prev, phone: value })); }, type: 'text', validator: validateNotNull, value: fieldsData.phone, placeholder: 'Phone' }))),
                companyPublicInfo.sources.length &&
                    React.createElement("div", { className: "flex flex-col gap-4" },
                        React.createElement("div", { className: "text-gray-600 text-lg" }, "Where did you hear about us? (optional)"),
                        React.createElement(Select, { size: 'LG', options: [
                                ...companyPublicInfo.sources.map(source => ({ key: source, value: source })),
                                { key: 'Other', value: 'Other' }
                            ], setSelectedKey: (key) => {
                                setFieldsData(prev => ({ ...prev, source: key }));
                            }, selectedKey: fieldsData.source }),
                        fieldsData.source === 'Other' &&
                            React.createElement(Input, { size: 'LG', setValue: setCustomSource, type: "text", value: customSource, placeholder: 'Please specify' })),
                React.createElement("div", { className: "flex flex-col gap-4" },
                    React.createElement("div", { className: "text-gray-600 text-lg" }, "Any final information we should know? (optional)"),
                    React.createElement(TextArea, { size: 'LG', setValue: setComment, value: comment, placeholder: 'E.g. I have a mix of double and single glazing' })),
                React.createElement(Caption, { content: "We won't share your details with anyone else." }),
                React.createElement(Button, { size: 'LG', block: true, disabled: !isValidForm, onClick: handleSubmit }, adminEstimate ? 'Generate estimate' : 'Send me an estimate'))),
        React.createElement("div", { className: "flex flex-col gap-1" },
            React.createElement(SubHeader, { content: "Common questions" }),
            React.createElement(Accordion, { rows: faqRows() })));
};
export const faqRows = () => {
    return [
        {
            title: 'What is a heat loss survey and why is it important?',
            body: React.createElement("div", { className: "space-y-4" },
                React.createElement("p", null, "This is a room-by-room survey of your property to work out how much heat each room loses - and so how much energy is required to keep it warm on the coldest days."),
                React.createElement("p", null, "Because heat pumps run at a lower temperature than a gas boiler (part of the reason they're more efficient) it's also important to create a detailed model of the whole current heating system so that you can rest assured that it will function effectively with a heat pump."),
                React.createElement("p", null, "Heat loss reports are mandated by MCS and so it is absolutely necessary if you want to be eligible for the government grant."))
        },
        {
            title: 'What are the benefits of getting a heat pump?',
            body: React.createElement("div", { className: "space-y-4" },
                React.createElement("p", null, "We're probably a bit biased but there are lots!"),
                React.createElement("p", null,
                    "They're ",
                    React.createElement("span", { className: "font-semibold" }, "green"),
                    " - heat pumps only use electricity and produce no other emissions. On average you can expect to reduce your carbon emissions by 75% compared to an equivalent gas boiler."),
                React.createElement("p", null,
                    "They're ",
                    React.createElement("span", { className: "font-semibold" }, "efficient"),
                    " - heat pumps have the almost magical ability to turn electricity into heat with a 300-500% efficiency rate, meaning 3-5x the amount of energy that goes in as electricity comes out as heat! This also means that they're generally cheaper to run than a gas boiler, saving you money every year."),
                React.createElement("p", null,
                    "They last a ",
                    React.createElement("span", { className: "font-semibold" }, "long time"),
                    " - a heat pump should last 20-25 years, compared to an average UK gas boiler lifespan of only 10-12 years."))
        },
        {
            title: 'What guarantees or insurance do I get with the heat pump?',
            body: React.createElement("div", { className: "space-y-4" },
                React.createElement("p", null, "You can rest assured that if there are any issues they'll be promptly dealt with without any additional cost: every installation done by an MCS-accredited installer comes with a minimum 2 year workmanship guarantee as well as manufacturer's warranty on the heat pump itself which is normally 5-7 years, depending on the heat pump."))
        },
        {
            title: 'Will a heat pump keep me warm in winter?',
            body: React.createElement("div", { className: "space-y-4" },
                React.createElement("p", null, "In short, yes! Presuming that the system has been designed and installed well."),
                React.createElement("p", null, "Heat pumps have been very popular for years in Sweden, Norway, and Finland - and their winters get a lot colder than the UK!"))
        },
        {
            title: 'Will I need to replace my radiators if I have a heat pump?',
            body: React.createElement("div", { className: "space-y-4" },
                React.createElement("p", null, "It's possible that you may need to upgrade your radiators when switching to a heat pump - this is because heat pumps operate at lower temperatures compared to traditional boilers, which is part of what makes them so efficient."),
                React.createElement("p", null, "Whether you will need to replace radiators depends on a number of factors, including how big your current radiators are, how many you have in each room, and how old they are."),
                React.createElement("p", null, "The type of heat pump you go for might also have an impact - for example, high-temperature heat pumps normally don't need as many radiators to be changed - though the trade-off is often that the ongoing running costs tend to be a bit higher."),
                React.createElement("p", null, "After the heat loss survey, your installer will advise you on whether you'll need to make changes and what your options are."))
        },
        {
            title: 'What grants are available and how do I get them?',
            body: React.createElement("div", { className: "space-y-4" },
                React.createElement("p", null, "If you\u2019re in England\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC65\uDB40\uDC6E\uDB40\uDC67\uDB40\uDC7F or Wales\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC77\uDB40\uDC6C\uDB40\uDC73\uDB40\uDC7F\uDB40\uDC67\uDB40\uDC62\uDB40\uDC77\uDB40\uDC6C\uDB40\uDC73\uDB40\uDC7F, you may be eligible for the \u00A37.5k Boiler Upgrade Scheme (BUS) grant."),
                React.createElement("p", null, "Your installer will apply for the grant for you - you don't need to do anything yourself on this front."),
                React.createElement("p", null, "To be eligible, your property needs to have an up-to-date EPC and to not already have a heat pump."),
                React.createElement("p", null, "If you're in Scotland\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC73\uDB40\uDC63\uDB40\uDC74\uDB40\uDC7F, things are a little different."),
                React.createElement("p", null, "You can access up to \u00A315,000 to install a heat pump (up to \u00A37,500 as a grant plus up to \u00A37,500 as an optional loan, or \u00A39,000 grant plus \u00A37,500 optional loan if the household qualifies for the rural uplift)."),
                React.createElement("p", null,
                    "You\u2019ll need to speak to Home Energy Scotland, who will help you through the process. More details are available ",
                    React.createElement("a", { target: "_blank", href: "https://www.homeenergyscotland.org/funding/grants-loans/", className: "underline", rel: "noreferrer" }, "here"),
                    "."))
        }
    ];
};
