import { bilinearInterpolate } from './range_heat_pump';
import { getManifoldMeanWaterTempC } from './manifold';
import { orderBy } from 'lodash';
export const getUnderfloorHeatingOutput = (ufh, manifolds, roomTempC, floorAreaM2, designFlowTempC, designDeltaT) => {
    const manifold = manifolds.find(m => m.uuid === ufh.manifold_uuid);
    if (!manifold) {
        return {
            meanWaterTempC: 0,
            surfaceTempC: 0,
            surfaceTempExceedsMax: false,
            wattsPerM2ActiveFloor: 0,
            watts: 0,
            wattsPerM2Overall: 0,
            pipeLengthM: 0,
            activeAreaM2: 0,
            warning: 'Please select a manifold for this underfloor heating system.'
        };
    }
    const meanWaterTempC = getManifoldMeanWaterTempC(manifold, designFlowTempC, designDeltaT);
    return getUnderfloorHeatingOutputAtRoomAndFlowTemp(ufh, roomTempC, floorAreaM2, meanWaterTempC);
};
export const getUnderfloorHeatingOutputAtRoomAndFlowTemp = (ufh, roomTempC, floorAreaM2, meanWaterTempC) => {
    const data = getSurfaceTempDataForUfh(ufh);
    if (data.length === 0) {
        return {
            meanWaterTempC,
            surfaceTempC: 0,
            surfaceTempExceedsMax: false,
            wattsPerM2ActiveFloor: 0,
            watts: 0,
            wattsPerM2Overall: 0,
            pipeLengthM: 0,
            activeAreaM2: 0,
            warning: 'We do not have data for this combination of pipe model, pipe spacing,  floor construction and floor covering. Please get get in touch if you think we should have this data!'
        };
    }
    const surfaceTempCIncludingExtrapolated = bilinearInterpolate(data.map(d => ({ x: d.mean_water_temp_C, y: d.room_temp_C, output: d.surface_temp_C })), meanWaterTempC, roomTempC);
    // Use value as long as water temp is either correct or above the input value (in which case we are being conservative)
    const warning = surfaceTempCIncludingExtrapolated.accuracy === 'CorrectXY'
        ? undefined
        : `We do not have output data for a mean water temp of ${meanWaterTempC}°C and room temp of ${roomTempC}°C so have used the data for a mean water temp of ${surfaceTempCIncludingExtrapolated.dataPoint.x}°C and a room temp of ${surfaceTempCIncludingExtrapolated.dataPoint.y}°C`;
    const surfaceTempC = surfaceTempCIncludingExtrapolated.dataPoint.output; // We are not stopping them from using an overestimate value here
    const wattsPerM2ActiveFloor = 8.92 * (surfaceTempC - roomTempC) ** 1.1;
    const activeAreaM2 = (ufh.pct_of_floor_area_heated / 100) * floorAreaM2;
    const watts = wattsPerM2ActiveFloor * activeAreaM2;
    const wattsPerM2Overall = watts / floorAreaM2;
    return {
        meanWaterTempC,
        surfaceTempC,
        surfaceTempExceedsMax: surfaceTempC > (roomTempC + 9),
        wattsPerM2ActiveFloor,
        watts,
        wattsPerM2Overall,
        pipeLengthM: getUFHPipeLength(ufh, floorAreaM2),
        activeAreaM2,
        warning
    };
};
export const getUFHPipeLength = (ufh, floorAreaM2) => {
    return (ufh.pct_of_floor_area_heated / 100) * floorAreaM2 * 1000 / UFH_PIPE_CENTRES.find(x => x.uuid === ufh.pipe_centres_uuid).spacingMm;
};
// Data inputs and helper methods
// Map UFH floor construction from floor material to give user initial value
export const getDefaultUfhFloorConstructionFromFloorMaterial = (floorMaterial) => {
    if (!floorMaterial) {
        return 'Suspended';
    }
    if (floorMaterial.applicable_to === 'ground-floor') {
        const extraData = floorMaterial.extra_data;
        if (extraData.construction === 'Solid') {
            return 'Solid (Screed)';
        }
        return 'Suspended';
    }
    return 'Suspended';
};
// Floor covering
export const getFloorCovering = (uuid) => {
    const floorCovering = FLOOR_COVERINGS.find(fc => fc.uuid === uuid);
    if (!floorCovering) {
        throw new Error(`Floor covering with uuid ${uuid} not found`);
    }
    return floorCovering;
};
// NB: If you add a floor covering here that has a resistance that isn't 0, 0.05, 0.1, 0.15, or 0.2 you need to
// Add the equivalent data to the lookup in the UFH surface temp table. Use the Emitter and pipework data spreadsheet
// To generate this data using the polynomial fits
export const FLOOR_COVERINGS = [
    { uuid: 'stone_tiles', name: 'Stone Tiles', resistanceM2KPerW: 0 },
    { uuid: 'vinyl_tiles', name: 'Vinyl tiles, 2mm', resistanceM2KPerW: 0 },
    { uuid: 'ceramic_tiles', name: 'Ceramic tiles, 5mm', resistanceM2KPerW: 0 },
    { uuid: 'epoxy_coating', name: 'Epoxy coating, 3mm', resistanceM2KPerW: 0 },
    { uuid: 'parquet', name: 'Parquet', resistanceM2KPerW: 0.05 },
    { uuid: 'stone', name: 'Stone, 75mm', resistanceM2KPerW: 0.05 },
    { uuid: 'marble', name: 'Marble, 25mm', resistanceM2KPerW: 0.05 },
    { uuid: 'cushioned_linoleum', name: 'Cushioned linoleum', resistanceM2KPerW: 0.05 },
    { uuid: 'carpet_tiles', name: 'Carpet Tiles, 9mm', resistanceM2KPerW: 0.1 },
    { uuid: 'hardwood', name: 'Hardwood, 13mm', resistanceM2KPerW: 0.1 },
    { uuid: 'medium_pile_carpet', name: 'Medium pile carpet', resistanceM2KPerW: 0.15 },
    { uuid: 'wood_block', name: 'Wood block', resistanceM2KPerW: 0.15 },
    { uuid: 'timber_laminate', name: 'Timber laminate, 22mm', resistanceM2KPerW: 0.15 },
    { uuid: 'deep_pile_carpet', name: 'Deep pile carpet with 5mm underlay', resistanceM2KPerW: 0.2 }
];
export const UFH_PIPE_CENTRES = [
    { uuid: '100mm', spacingMm: 100 },
    { uuid: '150mm', spacingMm: 150 },
    { uuid: '200mm', spacingMm: 200 },
    { uuid: '250mm', spacingMm: 250 },
    { uuid: '300mm', spacingMm: 300 }
];
// Surface temperature data
const getSurfaceTempDataForUfh = (ufh) => {
    var _a;
    const filteredData = SURFACE_TEMPERATURE_DATA.filter(std => {
        return std.floor_construction === ufh.floor_construction_type &&
            std.pipe_model_uuid === ufh.pipe_model_uuid &&
            std.pipe_spacing_mm === ufh.pipe_centres_uuid;
    });
    const floorCoveringResistanceM2KPerW = (_a = FLOOR_COVERINGS.find(x => x.uuid === ufh.floor_covering_uuid)) === null || _a === void 0 ? void 0 : _a.resistanceM2KPerW;
    // If we don't have a matching floor covering resistance for this set up, take the closest one. We should try and have output data for all floor covering options for all other combinations, but this is a backup
    // Not sure if we can tell whether this will choose the higher or the lower resistances values when the taret value is in the middle (e.g. we want 0.05 and we have 0 and 0.1)
    const closestResistance = orderBy(filteredData, x => Math.abs(x.floor_covering_resistance_m2K_per_W - (floorCoveringResistanceM2KPerW !== null && floorCoveringResistanceM2KPerW !== void 0 ? floorCoveringResistanceM2KPerW : 0)));
    if (closestResistance.length === 0)
        return [];
    const result = filteredData.filter(x => x.floor_covering_resistance_m2K_per_W === closestResistance[0].floor_covering_resistance_m2K_per_W);
    return result;
};
// Solid (Screed) - Type A data
// Suspended - Type B or Type B1 data
export const SURFACE_TEMPERATURE_DATA = [
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 24 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 25.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 26.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 27.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 27.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 22.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 24.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 25.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 26.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 27.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 21.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 23.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 24.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 25.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 27 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 21.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 23 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 25.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 20.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 22.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 25 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 26.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 28.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 29 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 29.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 30.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 25.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 26.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 27.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 28.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 29.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 24 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 25.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 26.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 28.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 29.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 22.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 24.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 26.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 27.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 28.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 21.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 24 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 25.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 26.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 28 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 29.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 30.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 31.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 32.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 33.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 27.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 29.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 30.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 31.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 32.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 26 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 27.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 29 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 30.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 31.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 24.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 26.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 27.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 29.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 30.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 23.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 25.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 26.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 28.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 29.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 31.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 33.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 34.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 35.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 36.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 29.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 31.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 32.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 33.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 34.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 28 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 29.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 31 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 32.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 33.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 26.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 28.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 29.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 31 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 32.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 25 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 27.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 28.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 29.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 31.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 34.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 36 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 36.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 37.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 38.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 32 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 33.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 34.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 35.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 37 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 29.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 31.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 33 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 34.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 35.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 28.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 30.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 31.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 32.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 34 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 26.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 28.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 30 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 31.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 32.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 21.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 23.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 24.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 27 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 21.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 23 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 25.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 20.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 22.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 25.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 19.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 22 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 19.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 23.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 25.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 26.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 28 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 29.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 22.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 24.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 26.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 27.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 28.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 22.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 24.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 25.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 26.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 28.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 21.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 23.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 26.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 27.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 28.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 30 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 31.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 24.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 26.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 27.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 29.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 30.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 23.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 25.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 27.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 28.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 29.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 22.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 26.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 29.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 22 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 27.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 29.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 30.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 32 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 33.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 26.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 28.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 29.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 31 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 32.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 25.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 27.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 28.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 30 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 31.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 26.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 27.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 29.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 30.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 23.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 27 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 30 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 29.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 31.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 32.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 34 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 35.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 28.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 30.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 31.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 32.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 34 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 26.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 28.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 30.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 31.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 33 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 25.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 27.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 29.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 30.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 32.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 24.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 26.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 28.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 29.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 31.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 20.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 22.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 25.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 20 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 22.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 19.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 19.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 22.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 18.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 22.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 22.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 25.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 26.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 28.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 21.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 23.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 25 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 26.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 23.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 26 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 23.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 27.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 28.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 29.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 23 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 25.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 26.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 29.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 22.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 21.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 23.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 21 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 23.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 25.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 27.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 28.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 30.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 31.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 24.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 26.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 28 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 29.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 30.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 23.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 27.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 30.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 22.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 26.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 29.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 22.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 24.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 26 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 29.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 26.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 29 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 30.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 31.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 33.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 28 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 29.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 30.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 32.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 24.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 27.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 28.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 30.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 31.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 24 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 26.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 27.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 29.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 30.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 23.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 25.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 27.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 28.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 30.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 19.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 26 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 19.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 18.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 22.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 18.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 22.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 18.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 20.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 22.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 23.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 21 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 23.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 26.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 22.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 26 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 21.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 21.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 23.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 20.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 23.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 20.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 22.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 23.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 27.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 30.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 23 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 26.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 29.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 22.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 24.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 26.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 29.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 21.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 28.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 21.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 23.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 25.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 28.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 25 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 27.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 28.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 30.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 31.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 24.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 26.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 28 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 29.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 31.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 23.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 25.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 27.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 29 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 30.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 22.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 25.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 26.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 28.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 30 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 22.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 24.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 26.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 27.9 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 29.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 19.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.725 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23.125 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.875 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 19.225 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21.35 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 23 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.425 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.925 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 18.625 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 21 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 22.525 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.05 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.575 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 18.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 20.95 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 22.35 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 24.05 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.575 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 15, surface_temp_C: 18.275 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 18, surface_temp_C: 20.55 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 20, surface_temp_C: 22.175 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 22, surface_temp_C: 23.825 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 30, room_temp_C: 24, surface_temp_C: 25.45 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.875 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 23.125 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 26.05 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.45 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.225 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.025 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.925 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.225 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.625 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.45 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.975 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.575 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.925 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.025 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.575 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.275 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.275 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 24.925 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.325 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 22.35 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.475 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.875 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.45 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.875 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 21.55 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.05 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.025 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.45 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 21.075 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 23.45 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.925 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.675 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.075 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 20.625 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 23.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.575 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.15 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27.775 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 20.275 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 22.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.275 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 25.85 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 23.525 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.775 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 27.35 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.925 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 30.275 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 22.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.025 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 26.55 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.075 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 29.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 22.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 24.45 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 26.075 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.675 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 29.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 21.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 24.05 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 25.625 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.325 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 28.65 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 21.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 23.4 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 25.275 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 26.85 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 28.425 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 24.825 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 27.175 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 28.525 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 30.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '100mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 31.675 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 24.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 26.275 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 27.8 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 29.55 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '150mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 31 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 23.125 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 25.675 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 27.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 28.825 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 30.3 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 22.6 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 25.15 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 26.5 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 28.2 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '250mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 29.7 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 22.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 24.525 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 26.1 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 27.675 },
    { floor_construction: 'Solid (Screed)', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '300mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 29.25 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 22 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 23.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 27 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 30 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 24.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 26.8 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 28.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 29.8 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 31.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 25.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 28 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 29.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 31 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 32.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.05 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.3625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.9125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.9125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 21.1875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 23.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.05 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.6 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.1625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 22.3125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 24.625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 26.1875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.7375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 29.2875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 23.4125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 25.7625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 27.3125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 28.875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 30.3875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 24.525 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 26.9375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 28.4875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 30.0375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 31.5875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.6 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 20.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 22.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.1 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 21.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 23.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 25.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.1 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 28.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 22.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 24.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 26.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 28.1 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 29.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 23.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 25.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 27.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 29.1 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 30.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.05 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.5125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.1625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 24.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.3625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 19.9375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 22.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.05 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 25.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27.3125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 20.8625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 23.325 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 24.9375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 26.5875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 28.2375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 21.7625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 24.2125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 25.8625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 27.475 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 29.1375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 22.325 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 24.8875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 26.5375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 28.1875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 29.8375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 18.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 22.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 24.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 19.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 22 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 23.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 25.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 20.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 22.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 24.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 26.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 27.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 21.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 23.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 25.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 27 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 28.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 21.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 23.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 25.6 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 27.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_16', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 29 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 24.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 27.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 22 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 24.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 27.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 23.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 25.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 27 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 28.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 30 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 24.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 26.8 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 28.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 29.8 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 31.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 25.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 28 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 29.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 31 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 32.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 20.05 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 22.3625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.9125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.9125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 21.1875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 23.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 25.05 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.6 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 28.1625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 22.3125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 24.625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 26.1875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.7375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 29.2875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 23.4125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 25.7625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 27.3125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 28.875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 30.3875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 24.525 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 26.9375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 28.4875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 30.0375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.05, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 31.5875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 25 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.6 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 20.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 22.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 26.1 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 21.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 23.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 25.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 27.1 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 28.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 22.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 24.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 26.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 28.1 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 29.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 23.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 25.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 27.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 29.1 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.1, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 30.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 19.05 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.5125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 23.1625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 24.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.3625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 19.9375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 22.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 24.05 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 25.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27.3125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 20.8625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 23.325 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 24.9375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 26.5875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 28.2375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 21.7625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 24.2125 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 25.8625 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 27.475 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 29.1375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 22.325 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 24.8875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 26.5375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 28.1875 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.15, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 29.8375 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 15, surface_temp_C: 18.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 18, surface_temp_C: 21.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 20, surface_temp_C: 22.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 22, surface_temp_C: 24.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 35, room_temp_C: 24, surface_temp_C: 26.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 15, surface_temp_C: 19.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 18, surface_temp_C: 22 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 20, surface_temp_C: 23.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 22, surface_temp_C: 25.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 40, room_temp_C: 24, surface_temp_C: 27 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 15, surface_temp_C: 20.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 18, surface_temp_C: 22.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 20, surface_temp_C: 24.5 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 22, surface_temp_C: 26.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 45, room_temp_C: 24, surface_temp_C: 27.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 15, surface_temp_C: 21.2 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 18, surface_temp_C: 23.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 20, surface_temp_C: 25.4 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 22, surface_temp_C: 27 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 50, room_temp_C: 24, surface_temp_C: 28.7 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 15, surface_temp_C: 21.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 18, surface_temp_C: 23.9 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 20, surface_temp_C: 25.6 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 22, surface_temp_C: 27.3 },
    { floor_construction: 'Suspended', floor_covering_resistance_m2K_per_W: 0.2, pipe_model_uuid: 'pex_20', pipe_spacing_mm: '200mm', mean_water_temp_C: 55, room_temp_C: 24, surface_temp_C: 29 }
];
