import mixpanel from 'mixpanel-browser';
export const logEvent = (event, companySubdomain) => {
    // allow Sign Up event without a company subdomain
    if (!companySubdomain && event.name !== 'Sign Up Posted')
        return;
    // Only log events in production and staging
    if (!['production', 'staging'].includes(process.env.APP_ENV))
        return;
    // Do not rename companyId key name here! It's used by MixPanel
    mixpanel.track(event.name, { ...event.properties, companyId: companySubdomain });
};
