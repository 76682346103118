import React, { useContext } from 'react';
import { AppContext } from '../../app_context';
import { WrappedIcon } from '../buttons/wrapped_icon';
import { X } from 'lucide-react';
export const toastError = (message) => ({ colour: 'bg-red-500', message });
export const toastSuccess = (message) => ({ colour: 'bg-green-500', message });
export const setError = (e) => {
    var _a;
    const axiosError = e;
    // if (axiosError.response?.status === 401) setIsLoggedIn("", setState);
    // let errorMessage = 'We had an issue fulfilling that request, please contact joseph@spruce.eco.'
    // if (axiosError.response?.data) {
    //   errorMessage = axiosError.response.data.detail?.length > 0
    //     ? axiosError.response.data.detail
    //     : <ul>{(axiosError.response.data.errors?.map(x => x.detail) as string[]).map(x => <li>{x}</li>)}</ul>
    // }
    // setState((prev) => ({ ...prev, toastState: toastError(errorMessage) }));
    if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) !== 401)
        throw e;
};
export const ToastPage = () => {
    const { globalState, setGlobalState } = useContext(AppContext);
    return (React.createElement("div", { style: {
            paddingTop: 'env(safe-area-inset-top)',
            paddingRight: 'env(safe-area-inset-right)',
            paddingBottom: 'env(safe-area-inset-bottom)',
            paddingLeft: 'env(safe-area-inset-left)'
        }, className: "absolute top-0 right-0 flex" }, globalState.toastState && React.createElement(ToastInner, { toast: globalState.toastState, setGlobalState: setGlobalState })));
};
export const ToastInner = ({ toast, setGlobalState }) => React.createElement("div", { className: `${toast.colour} z-20 rounded m-2 px-4 py-2 break-all text-slate-50 flex justify-between space-x-4 items-center` },
    React.createElement("div", null, toast.message),
    React.createElement(WrappedIcon, { onClick: () => { setGlobalState((prev) => ({ ...prev, toastState: null })); }, className: "text-xl cursor-pointer", icon: X }));
