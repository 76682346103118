import React, { useContext } from 'react';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { AdminContext } from '../../../admin/admin_layout';
import { CheckCircle, ChevronLeft, Menu, RefreshCw, Wifi } from 'lucide-react';
import { Select } from '../../../../components/inputs_and_selections/select';
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon';
import { createJobEvent, JobEventType } from '../../../../code/models/job_event';
export const STATUS_ITEMS = [
    { key: 'New', value: 'Estimate Required', colour: 'YELLOW' },
    { key: 'Quoted', value: 'Estimate Sent', colour: 'BLUE' },
    { key: 'Converted', value: 'Estimate Accepted', colour: 'GREEN' },
    { key: 'Rejected', value: 'Estimate Rejected', colour: 'RED' },
    { key: 'Declined', value: 'Enquiry Declined', colour: 'RED' },
    { key: 'SurveyBooked', value: 'Survey Booked', colour: 'BLUE' },
    { key: 'SurveyInProgress', value: 'Survey In Progress', colour: 'YELLOW' },
    { key: 'SurveyCompleted', value: 'Survey Completed', colour: 'GREEN' },
    { key: 'ProposalSent', value: 'Proposal Sent', colour: 'BLUE' },
    { key: 'ProposalAccepted', value: 'Proposal Accepted', colour: 'GREEN' },
    { key: 'Archived', value: 'Archived', colour: 'LIGHT' }
];
export const PageHeader = (props) => {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white" },
            React.createElement(PageHeaderNoPadding, { ...props })));
};
export const PageHeaderNoPadding = ({ title, onBack, children, rightHandChildren, completed, isOffline, allSynced, lead, setLead }) => {
    var _a;
    const adminContext = useContext(AdminContext);
    const toggleSidebar = () => adminContext.isSidebarOpen
        ? adminContext.hideSidebar()
        : adminContext.showSidebar();
    const statusColour = lead && ((_a = STATUS_ITEMS.find(x => x.key === lead.status)) === null || _a === void 0 ? void 0 : _a.colour);
    const leadSelect = lead && setLead && React.createElement("div", { className: 'flex justify-end' },
        React.createElement("div", null,
            React.createElement(Select, { colour: statusColour, options: STATUS_ITEMS, selectedKey: lead.status, setSelectedKey: async (newStatus) => {
                    var _a, _b;
                    const oldStatus = lead.status;
                    setLead({ ...lead, status: newStatus });
                    if (oldStatus !== newStatus) {
                        createJobEvent({
                            event_type: JobEventType.enum.StatusChanged,
                            company_uuid: (_b = (_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.public_info.uuid) !== null && _b !== void 0 ? _b : '',
                            job_uuid: lead.uuid,
                            extra_data: { old_status: oldStatus, new_status: newStatus }
                        });
                    }
                } })));
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex justify-between gap-4 items-center' },
            React.createElement("div", { className: 'flex gap-4 items-center cursor-pointer', onClick: onBack || toggleSidebar },
                onBack && React.createElement(WrappedIcon, { icon: ChevronLeft, className: 'flex-shrink-0' }),
                !onBack && React.createElement(WrappedIcon, { icon: Menu, "data-cy": 'nav_open', className: 'md:hidden flex-shrink-0' }),
                React.createElement("div", { className: 'text-gray-900 text-xl font-bold' }, title),
                isOffline
                    ? React.createElement(Badge, { color: 'RED', text: 'Offline', icon: Wifi })
                    : allSynced
                        ? React.createElement(Badge, { color: 'GREEN', text: 'Synced', icon: CheckCircle })
                        : allSynced === undefined ? '' : React.createElement(Badge, { color: "YELLOW", spin: true, icon: RefreshCw, text: "Sync pending..." })),
            React.createElement("div", { className: 'flex gap-2' },
                React.createElement("div", { className: 'flex gap-2 justify-end items-center' },
                    rightHandChildren,
                    completed !== undefined && React.createElement(Badge, { color: completed ? 'GREEN' : 'LIGHT', text: completed ? 'Complete' : 'Incomplete' })),
                React.createElement("div", { className: 'hidden md:flex' }, leadSelect))),
        leadSelect && React.createElement("div", { className: 'md:hidden' }, leadSelect),
        children);
};
