import { useState } from 'react';
import { isFlagSet } from './helpers';
// calculate flow state ...
export const usePagesFlow = (props) => {
    if (props.pages.length === 0) {
        throw new Error('No pages provided');
    }
    // modes for the next page
    const NEXT_PAGE_MODE_UNVISITED = 0x1; // find the first unvisited page (bit == 0)
    const NEXT_PAGE_MODE_THROUGH = 0x2; // go through the pages one by one
    const [mode] = useState(NEXT_PAGE_MODE_THROUGH);
    const findInitialPage = () => {
        var _a, _b;
        // filter out pages that should be hidden or skipped
        const availablePages = props.pages.filter(p => { var _a; return !((_a = p.hideOrSkipPage) === null || _a === void 0 ? void 0 : _a.call(p)); });
        let initialPage = availablePages[0];
        // if the startPage is provided, use it
        if (props.startPage) {
            initialPage = (_a = availablePages.find(p => p.flagBit === props.startPage)) !== null && _a !== void 0 ? _a : initialPage;
        }
        else {
            // if the startPage is not provided AND if the mode is NEXT_PAGE_MODE_UNVISITED
            if (mode === NEXT_PAGE_MODE_UNVISITED) {
                // find the first page that is not visited
                initialPage = (_b = availablePages.find(p => {
                    var _a;
                    // return the first page that is not visited
                    // and not hidden or skipped
                    // and not the current page
                    return !isFlagSet(props.flagsCompletedPages, p.flagBit) && !((_a = p.hideOrSkipPage) === null || _a === void 0 ? void 0 : _a.call(p));
                })) !== null && _b !== void 0 ? _b : initialPage;
            }
        }
        return initialPage;
    };
    const [currentPageBitFlag, setCurrentPageBitFlag] = useState(findInitialPage().flagBit);
    const next = () => {
        let next_page;
        // filter out pages that should be hidden or skipped
        const availablePages = props.pages.filter(p => { var _a; return !((_a = p.hideOrSkipPage) === null || _a === void 0 ? void 0 : _a.call(p)); });
        // find the next page based on the mode
        switch (mode) {
            case NEXT_PAGE_MODE_UNVISITED:
                // find first unset bit
                next_page = availablePages.find(p => {
                    var _a;
                    // return the first page that is not visited
                    // and not hidden or skipped
                    return p.flagBit !== currentPageBitFlag && !isFlagSet(props.flagsCompletedPages, p.flagBit) && !((_a = p.hideOrSkipPage) === null || _a === void 0 ? void 0 : _a.call(p));
                });
                break;
            case NEXT_PAGE_MODE_THROUGH:
                // find next page to the one with the flagBit
                const index = availablePages.findIndex(p => {
                    return p.flagBit === currentPageBitFlag;
                });
                // is this the last page?
                if (index === -1 || index === availablePages.length - 1) {
                    next_page = undefined;
                }
                else {
                    next_page = availablePages[index + 1];
                }
                break;
        }
        // if there is a next page
        if (next_page) {
            // set the page
            props.onPageCompleted(currentPageBitFlag);
            setCurrentPageBitFlag(next_page.flagBit);
        }
        else {
            // notify the parent component that there are no more pages
            props.onEndOfFlow();
        }
    };
    const prev = () => {
        // filter out pages that should be hidden or skipped
        const availablePages = props.pages.filter(p => { var _a; return !((_a = p.hideOrSkipPage) === null || _a === void 0 ? void 0 : _a.call(p)); });
        // find previous page to the one with the flagBit
        const index = availablePages.findIndex(p => {
            var _a;
            return p.flagBit === currentPageBitFlag && !((_a = p.hideOrSkipPage) === null || _a === void 0 ? void 0 : _a.call(p));
        });
        setCurrentPageBitFlag(availablePages[index - 1].flagBit);
    };
    return [currentPageBitFlag, next, prev];
};
