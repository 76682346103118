import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { updateCompanySettings } from '../../../../../code/models/company';
import { SettingsImageInput } from '../../components/settings_image_input';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Text } from '../../../../../components/content_display/text';
import { Card } from '../../../../../components/content_display/card';
export const BrandSignatureBlock = ({ company, setCompany }) => {
    var _a, _b, _c, _d;
    return React.createElement(Section, { border: true, title: 'Company signature' },
        React.createElement("div", { className: 'flex flex-col gap-4  ' },
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4' },
                React.createElement("div", { className: 'grid grid-cols-1 gap-4' },
                    React.createElement(Text, { size: "SM", className: 'text-gray-500' }, "This signature will appear on estimates and proposals."),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Name' }), input: React.createElement(Input, { value: (_a = company.public_info.estimate_contact_name) !== null && _a !== void 0 ? _a : '', setValue: nameInput => setCompany({
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    estimate_contact_name: nameInput
                                }
                            }), placeholder: 'Signature name' }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Job title' }), input: React.createElement(Input, { value: (_b = company.public_info.estimate_contact_role) !== null && _b !== void 0 ? _b : '', setValue: roleInput => setCompany({
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    estimate_contact_role: roleInput
                                }
                            }), placeholder: 'Signature job title' }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Phone' }), input: React.createElement(Input, { value: (_c = company.public_info.estimate_contact_phone) !== null && _c !== void 0 ? _c : '', setValue: senderInput => setCompany({
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    estimate_contact_phone: senderInput
                                }
                            }), placeholder: 'Signature phone number' }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Image', helperText: 'For best results, image should be square' }), input: React.createElement(SettingsImageInput, { imgClassName: 'rounded-full aspect-square object-cover w-20 h-20', buttonText: 'Upload image', placeholderText: 'No image', helperText: 'Maximum file size 5MB', b64Image: (_d = company.public_info.estimate_contact_portrait) !== null && _d !== void 0 ? _d : '', altText: 'Company signature image', handleUpdateImage: async (imageSrc) => {
                                const newCompany = {
                                    ...company,
                                    public_info: {
                                        ...company.public_info,
                                        estimate_contact_portrait: imageSrc
                                    }
                                };
                                await updateCompanySettings(newCompany);
                                setCompany(newCompany);
                            }, handleRemoveImage: async () => {
                                const newCompany = {
                                    ...company,
                                    public_info: {
                                        ...company.public_info,
                                        estimate_contact_portrait: ''
                                    }
                                };
                                await updateCompanySettings(newCompany);
                                setCompany(newCompany);
                            } }) })),
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement(FormLabel, { labelText: 'Preview' }),
                    company.public_info.estimate_contact_name || company.public_info.estimate_contact_role || company.public_info.estimate_contact_phone || company.public_info.estimate_contact_portrait ? null : React.createElement(Text, { size: 'SM', className: 'text-gray-500' }, "Fill in the fields to see a preview."),
                    company.public_info.estimate_contact_name || company.public_info.estimate_contact_role || company.public_info.estimate_contact_phone || company.public_info.estimate_contact_portrait ? React.createElement(Card, { color: 'LIGHT', className: 'bg-slate-50 flex flex-col gap-4' },
                        React.createElement("div", { className: 'flex justify-start items-center pt-1 gap-4' },
                            company.public_info.estimate_contact_portrait && React.createElement("img", { src: company.public_info.estimate_contact_portrait, alt: 'Estimate contact headshot', className: 'rounded-full w-20 h-20 object-cover' }),
                            React.createElement("div", { className: 'flex flex-col gap-1' },
                                React.createElement("div", null,
                                    React.createElement(Text, { bold: true, size: 'MD' }, company.public_info.estimate_contact_name),
                                    React.createElement(Text, { size: 'SM' }, company.public_info.estimate_contact_role)),
                                React.createElement("a", { className: 'text-base text-sky-800 font-bold underline', href: `tel:${company.public_info.estimate_contact_phone}` }, company.public_info.estimate_contact_phone))))
                        : null))));
};
