import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Loader } from '../components/indicators_and_messaging/loader';
import { getProposalPDFAPI, getProposalPublicAPI, setProposalOpenedAPI, signProposalAPI } from '../code/models/proposal';
import { Button } from '../components/buttons/button';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName } from '../code/tiptap_placeholders';
import { CylinderIcon2, HeatPump, RadiatorIcon } from '../assets/images/survey_images/survey_images';
import { HLRContext, hlrMakeCalcsFromSnapshot } from './heat_loss/proposal/heatloss_report';
import { HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM } from '../code/models/heatloss_report';
import { HLRSoundAssessment } from './heat_loss/proposal/hlr_sound_assessment';
import { HLRPESummary } from './heat_loss/proposal/hlr_pe_summary';
import { HLRPEDetailedResultsIntroduction } from './heat_loss/proposal/hlr_pe_detailed_results_intro';
import { HLRPEResultsTable } from './heat_loss/proposal/hlr_pe_results_table';
import { HLRPEInputsAndAssumptions } from './heat_loss/proposal/hlr_pe_inputs_and_assumptions';
import { HLRPESystemEfficiency1 } from './heat_loss/proposal/hlr_pe_system_efficiency_1';
import { HLRPESystemEfficiency2 } from './heat_loss/proposal/hlr_pe_system_efficiency_2';
import { HLRPEFlowTempChart } from './heat_loss/proposal/hlr_pe_flow_temp_chart';
import { HLRPEMCSKeyFacts } from './heat_loss/proposal/hlr_pe_mcs_key_facts';
import { getFullEmittersListByStatus } from '../code/models/radiator';
import { HLRHeatPump } from './heat_loss/proposal/hlr_heat_pump';
import { HLREmittersIntro } from './heat_loss/proposal/hlr_emitters_intro';
import { HLREmittersCurrent } from './heat_loss/proposal/hlr_emitters_current';
import { HLREmittersProposed } from './heat_loss/proposal/hlr_emitters_proposed';
import { HLRHotWater } from './heat_loss/proposal/hlr_hot_water';
import { HLRHotWaterDemand } from './heat_loss/proposal/hlr_hot_water_demand';
import { downloadBlob, isFlagSet } from '../code/helpers';
import { HLRIntroductionPage } from './heat_loss/proposal/hlr_introduction_page';
import { HLRCalculationConditionsPage } from './heat_loss/proposal/hlr_calculation_conditions_page';
import { HLRHeatlossByElementPage } from './heat_loss/proposal/hlr_heatloss_by_element_page';
import { HLRHeatlossByRoomPage } from './heat_loss/proposal/hlr_heatloss_by_room_page';
import { HLRFloorHeatlossPage } from './heat_loss/proposal/hlr_floor_heatloss_page';
import { HLRRoomHeatlossPage } from './heat_loss/proposal/hlr_room_heatloss_page';
import { Icon } from '../components/buttons/icon';
import _ from 'lodash';
import { formatPrice } from '../code/format_price';
import { numberFormat } from '../code/number_format';
import { PublicQuoteBlock, PublicQuotePaymentScheduleBlock } from './quote_public_page';
import { Modal } from '../components/containers/modal';
import { Text } from '../components/content_display/text';
import { Input } from '../components/inputs_and_selections/input';
import { validateLooksLikeDate, validateNotNull } from '../code/validators';
import { yellowEnvelope } from '../assets/images/images';
import { PrintLayoutPageBreak } from './components/print_layout';
import { Heading } from '../components/content_display/heading';
import confetti from 'canvas-confetti';
import { DEFAULT_BRAND_COLOUR_PRIMARY, determineBestTextShade } from '../code/utils/colour_utils';
import { Check, Download, ExternalLink, FileText, Signature } from 'lucide-react';
import { createJobEvent, JobEventType } from '../code/models/job_event';
import { LeadStatusSchema } from '../code/models/lead';
// const HLRContext = React.createContext<HeatLossReportContextProps | undefined>(undefined)
const ProposalLinkAnchor = ({ index, link }) => {
    return React.createElement("a", { className: 'bg-gray-50 rounded text-gray-600 text-sm font-bold w-full underline p-2 flex items-center justify-between gap-2', key: index, rel: 'noreferrer', target: '_blank' // Open all links in new tab.
        , href: `${link.url}${link.internal ? `?name_override=${encodeURIComponent(link.title)}.${link.url.split('.')[1]}` : ''}` },
        React.createElement("div", null, link.title),
        React.createElement(Icon, { icon: link.internal ? Download : ExternalLink }));
};
export const ProposalPublicPage = (props) => {
    const [proposal, setProposal] = useState();
    const isInstallerClick = new URLSearchParams(window.location.search).get('installer');
    useEffect(() => {
        const asyncCall = async () => {
            const result = await getProposalPublicAPI(props.proposalUUID, props.companyPublicInfo.uuid);
            if (result) {
                setProposal(result);
            }
            if (!isInstallerClick && (result === null || result === void 0 ? void 0 : result.is_opened) === false) {
                await setProposalOpenedAPI(props.proposalUUID, props.companyPublicInfo.uuid);
                createJobEvent({
                    event_type: JobEventType.enum.ProposalOpened,
                    company_uuid: props.companyPublicInfo.uuid,
                    job_uuid: result.lead_uuid,
                    extra_data: {
                        proposal_uuid: props.proposalUUID
                    }
                });
            }
        };
        asyncCall();
    }, []);
    if (!proposal) {
        return (React.createElement("div", { className: "flex flex-col items-center justify-center mt-10 mx-auto" },
            React.createElement(Loader, null)));
    }
    const quoteSnapshot = proposal.snapshot.quote;
    const reportSnapshot = proposal.snapshot.report;
    return React.createElement(React.Fragment, null,
        React.createElement(ProposalPublicPageInner, { proposal: proposal, setProposal: setProposal, quoteSnapshot: quoteSnapshot, reportSnapshot: reportSnapshot, companyPublicInfo: props.companyPublicInfo }),
        React.createElement("div", { id: 'pdf-ready' }));
};
export const ProposalPublicPageInner = ({ proposal, setProposal, quoteSnapshot, reportSnapshot, companyPublicInfo, previewMode }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const reportCalculatedData = hlrMakeCalcsFromSnapshot(reportSnapshot);
    const emittersDefined = getFullEmittersListByStatus(reportSnapshot.survey, reportCalculatedData.design).length > 0;
    const hasPaymentSchedule = proposal.payment_schedule && proposal.payment_schedule.length > 0;
    const [acceptProposalModalVisible, setAcceptProposalModalVisible] = useState(false);
    const coverNoteHTML = (proposal === null || proposal === void 0 ? void 0 : proposal.cover_note)
        ? renderHTMLReplacingPlaceholders(JSON.parse(proposal.cover_note), {
            ...defaultPlaceholderMappings(companyPublicInfo, proposal.sender_snapshot),
            [TTPlaceholderCustomerName.id]: reportSnapshot.customer.name,
            [TTPlaceholderCustomerAddress.id]: reportSnapshot.property.address + ', ' + reportSnapshot.property.postcode
        })
        : '';
    const hpImage = process.env.S3_BUCKET_URL + '/hp-images/' + ((_b = (_a = reportSnapshot.currentHeatPump) === null || _a === void 0 ? void 0 : _a.range_heat_pump) === null || _b === void 0 ? void 0 : _b.uuid) + '.png';
    const logoImageData = companyPublicInfo.logo;
    const sectionRefs = useRef({
        summary: null,
        quote: null,
        paymentSchedule: null,
        hlr: null,
        systemDesign: null,
        soundAssessment: null,
        performanceEstimate: null,
        documents: null
    });
    const [activeSection, setActiveSection] = useState('summary');
    const sections = [
        { id: 'summary', title: 'Summary', threshold: 0 },
        { id: 'quote', title: 'Quote', threshold: 0 },
        { id: 'paymentSchedule', conditions: () => (!!hasPaymentSchedule), title: 'Payment schedule', threshold: 0 },
        { id: 'hlr', title: 'Heat loss report', threshold: 0 },
        { id: 'systemDesign', title: 'System design', threshold: 0 },
        { id: 'soundAssessment', title: 'Sound assessment', threshold: 0 },
        { id: 'performanceEstimate', title: 'Performance estimate', threshold: 0 },
        {
            id: 'documents',
            conditions: () => {
                return !!((proposal === null || proposal === void 0 ? void 0 : proposal.links) && proposal.links.length > 0);
            },
            title: 'Documents',
            threshold: 0
        }
    ];
    useEffect(() => {
        const observers = [];
        sections.forEach((section) => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(section.id);
                    }
                });
            }, {
                threshold: section.threshold,
                rootMargin: '100px 0px -50% 0px'
            });
            if (sectionRefs.current[section.id]) {
                observer.observe(sectionRefs.current[section.id]);
            }
            observers.push(observer);
        });
        return () => {
            observers.forEach((observer) => observer.disconnect());
        };
    }, []);
    const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total + quoteSnapshot.bus_grant;
    const gapBetweenPages = 'gap-6 sm:gap-6 md:gap-8 lg:gap-10';
    return React.createElement(React.Fragment, null,
        React.createElement(HLRContext.Provider, { value: {
                survey: reportSnapshot.survey,
                property: reportSnapshot.property,
                customer: reportSnapshot.customer,
                company_public_info: companyPublicInfo,
                design: reportCalculatedData.design,
                designTempC: reportCalculatedData.designTempC,
                groundTempC: reportCalculatedData.groundTempC,
                degreeDays: reportCalculatedData.degreeDays,
                currentHeatPump: reportSnapshot.currentHeatPump,
                currentHotWaterCylinder: reportSnapshot.currentHotWaterCylinder,
                cylinderReheatRow: reportCalculatedData.cylinderReheatRow,
                hotWaterRowNormal: reportCalculatedData.hotWaterRowNormal,
                hotWaterRowLegionella: reportCalculatedData.hotWaterRowLegionella,
                performanceEstimateColumns: reportCalculatedData.performanceEstimateColumns,
                performanceEstimateSummary: reportCalculatedData.performanceEstimateSummary,
                eligibleForHeatPumpPlus: reportCalculatedData.eligibleForHeatPumpPlus,
                soundAssessment: reportCalculatedData.soundAssessment,
                files: reportSnapshot.files,
                summaryData: reportCalculatedData.summaryData,
                hpCapacityResult: reportCalculatedData.hpCapacityResult,
                scop: reportCalculatedData.scop
            } },
            React.createElement("div", { className: `${previewMode ? 'h-[calc(100vh-150px)]' : 'print:h-full lg:h-screen'} w-full bg-white flex flex-col items-center` },
                React.createElement("div", { className: 'w-full border-b border-b-gray-200 flex flex-col items-center' },
                    React.createElement("div", { className: 'w-full max-w-[1104px] flex flex-row justify-between gap-2 items-center p-3 ' +
                            'print:p-6 print:sticky print:top-0 ' + // print
                            'sm:px-3 sm:pt-3 ' + // sm
                            'md:px-6 md:pt-6 ' + // md
                            'lg:sticky lg:top-0 ' // lg
                     },
                        React.createElement("div", { className: 'flex flex-row gap-4' }, logoImageData && React.createElement("img", { src: logoImageData, className: "max-h-10 md:max-h-12", alt: "Installer logo" })),
                        !previewMode && React.createElement("div", { className: 'print:hidden flex flex-row gap-4' },
                            React.createElement(Button, { colour: 'LIGHT', iconLeft: FileText, onClick: async () => {
                                    const pdfData = await getProposalPDFAPI(proposal.uuid, companyPublicInfo.uuid);
                                    downloadBlob(pdfData, reportSnapshot.property.address + ' — Full Proposal.pdf');
                                } },
                                React.createElement("span", { className: 'hidden sm:inline' }, "Save to PDF")),
                            !proposal.acceptance_status &&
                                React.createElement(Button, { style: {
                                        backgroundColor: companyPublicInfo.colour_primary,
                                        borderColor: companyPublicInfo.colour_primary,
                                        color: determineBestTextShade((_c = companyPublicInfo.colour_primary) !== null && _c !== void 0 ? _c : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                                    }, iconLeft: Signature, onClick: () => setAcceptProposalModalVisible(true) },
                                    React.createElement("span", { className: 'hidden sm:inline' }, "Review & accept quote")),
                            proposal.acceptance_status &&
                                React.createElement("div", { className: "p-2.5 bg-green-100 rounded-md justify-center items-center flex gap-1 text-green-800 " },
                                    React.createElement(Icon, { icon: Check, className: 'text-green-800' }),
                                    React.createElement("div", { className: "text-sm font-bold" }, "Accepted"))))),
                React.createElement("div", { className: 'lg:hidden print:hidden w-full overflow-scroll no-scrollbar sticky top-0 bg-white z-20 py-2 px-1 print:px-0 sm:px-3 md:px-6 ' },
                    React.createElement("div", { className: 'flex flex-row gap-2' }, _.compact(sections.map((section) => {
                        if (section.conditions && !section.conditions())
                            return null;
                        return (React.createElement("div", { key: section.id, className: (activeSection === section.id ? 'bg-gray-100 font-semibold' : '') + 'select-none cursor-pointer px-3 py-2 rounded justify-start items-start text-sm text-nowrap', onClick: () => sectionRefs.current[section.id].scrollIntoView({ behavior: 'smooth' }) }, section.title));
                    })))),
                React.createElement("div", { className: 'w-full max-w-[1104px] print:px-0 px-1 sm:px-3 md:px-6 ' },
                    React.createElement("div", { className: 'flex flex-row gap-8 print:gap-0' },
                        React.createElement("div", { className: 'hidden min-w-48 flex-col gap-5 sticky top-0 pr-2 ' +
                                'print:hidden ' + // print
                                'lg:flex lg:pt-12 ' + // lg
                                'overflow-y-scroll ' + // Scroll on overflow
                                (previewMode ? 'h-[calc(100vh-250px)] ' : 'lg:h-[calc(100vh-100px)] ') },
                            React.createElement("div", { className: "px-3 text-gray-500 text-xs font-semibold uppercase" }, "Contents"),
                            React.createElement("div", { className: 'flex flex-col gap-0' }, _.compact(sections.map((section) => {
                                if (section.conditions && !section.conditions())
                                    return null;
                                return React.createElement("div", { key: section.id, className: (activeSection === section.id ? 'bg-gray-100 font-semibold' : '') + ' cursor-pointer px-3 py-2 rounded justify-start items-start text-sm', onClick: () => sectionRefs.current[section.id].scrollIntoView({ behavior: 'smooth' }) }, section.title);
                            }))),
                            (proposal.links && proposal.links.length > 0) && (React.createElement("div", { className: 'flex flex-col gap-4' }, (_d = proposal.links) === null || _d === void 0 ? void 0 : _d.map((link, index) => (React.createElement(ProposalLinkAnchor, { key: index, link: link })))))),
                        React.createElement("div", { className: [
                                (previewMode ? 'h-[calc(100vh-250px)] ' : 'lg:h-[calc(100vh-100px)] ') + 'overflow-scroll grow shrink flex flex-col pt-6 pb-6 px-3 ',
                                (previewMode ? '' : 'print:h-full ') + 'print:pt-0 ', // print
                                'sm:pt-6 ', // sm
                                'md:pt-8 ', // md
                                'lg:pt-12',
                                gapBetweenPages // lg: pt- should match with left menu's pt- value
                            ].join(' ') },
                            React.createElement("div", { ref: (el) => (sectionRefs.current.summary = el), className: `lg:scroll-mt-4 scroll-mt-14 flex flex-col ${gapBetweenPages}` },
                                React.createElement("div", null,
                                    React.createElement(Heading, { size: '3xl' }, "Your heat pump proposal"),
                                    React.createElement("div", { className: 'flex flex-col md:flex-row gap-8 mt-8' },
                                        React.createElement("div", { className: 'flex flex-col gap-8' },
                                            React.createElement("div", { className: 'flex flex-col gap-4' },
                                                React.createElement("div", { className: "text-gray-900 text-sm font-semibold uppercase" }, "Prepared for"),
                                                React.createElement("div", { className: 'flex flex-col gap-1' },
                                                    React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, (_e = reportSnapshot.customer.name) !== null && _e !== void 0 ? _e : ''),
                                                    React.createElement("div", { className: "text-sm flex-col flex gap-0" },
                                                        React.createElement("span", null, reportSnapshot.property.address),
                                                        React.createElement("span", null, reportSnapshot.property.postcode),
                                                        React.createElement("span", null, reportSnapshot.customer.email),
                                                        React.createElement("span", null, reportSnapshot.customer.phone)))),
                                            companyPublicInfo.estimate_contact_name && (React.createElement("div", { className: 'flex flex-col gap-4' },
                                                React.createElement("div", { className: "text-gray-900 text-sm font-semibold uppercase" }, "Prepared by"),
                                                React.createElement("div", { className: 'flex flex-row gap-4' },
                                                    companyPublicInfo.estimate_contact_portrait && (React.createElement("img", { src: companyPublicInfo.estimate_contact_portrait, className: "rounded-full aspect-square object-cover w-16 h-16", alt: companyPublicInfo.estimate_contact_name })),
                                                    React.createElement("div", { className: "text-sm flex-col flex gap-1" },
                                                        React.createElement("div", { className: 'text-gray-900 font-bold' }, companyPublicInfo.estimate_contact_name),
                                                        React.createElement("span", { className: '' }, companyPublicInfo.estimate_contact_role),
                                                        React.createElement("a", { href: 'tel:' + companyPublicInfo.estimate_contact_phone, className: 'font-bold text-sky-700 underline' }, companyPublicInfo.estimate_contact_phone))))),
                                            React.createElement("div", { className: 'flex flex-col gap-3', dangerouslySetInnerHTML: { __html: coverNoteHTML } })),
                                        React.createElement("div", { className: 'md:min-w-60' },
                                            React.createElement("div", { className: 'p-4 bg-gray-50 rounded-md border border-gray-200 flex-col flex gap-4' },
                                                React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Your quote"),
                                                React.createElement("div", { className: 'flex flex-col gap-2' },
                                                    React.createElement("div", { className: 'flex flex-row justify-between items-center text-xs' },
                                                        React.createElement("div", { className: '' }, "Total before grant (excl.\u00A0VAT)"),
                                                        React.createElement("div", { className: 'whitespace-nowrap text-nowrap' }, formatPrice(quoteSnapshot.total_pre_deductions, 2))),
                                                    quoteSnapshot.bus_grant < 0 &&
                                                        React.createElement("div", { className: 'flex flex-row justify-between items-start text-xs' },
                                                            React.createElement("div", { className: '' }, "Boiler Upgrade Scheme (BUS) Grant"),
                                                            React.createElement("div", { className: 'whitespace-nowrap text-nowrap' }, formatPrice(quoteSnapshot.bus_grant, 2)))),
                                                React.createElement("div", { className: 'flex flex-row justify-between items-start text-neutral-950 font-bold' },
                                                    React.createElement("div", { className: '' }, "Total"),
                                                    React.createElement("div", { className: 'whitespace-nowrap text-nowrap' }, formatPrice(total, 2))),
                                                !previewMode && React.createElement("div", { className: 'print:hidden flex flex-col gap-3' },
                                                    !proposal.acceptance_status &&
                                                        React.createElement(Button, { style: {
                                                                backgroundColor: companyPublicInfo.colour_primary,
                                                                borderColor: companyPublicInfo.colour_primary,
                                                                color: determineBestTextShade((_f = companyPublicInfo.colour_primary) !== null && _f !== void 0 ? _f : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                                                            }, onClick: () => setAcceptProposalModalVisible(true) }, "Review & accept"),
                                                    React.createElement(Button, { colour: 'LIGHT', onClick: () => {
                                                            sectionRefs.current.quote.scrollIntoView({ behavior: 'smooth' });
                                                        } }, "View full quote")),
                                                proposal.acceptance_status && React.createElement("div", { className: "p-2.5 bg-green-100 rounded-md justify-center items-center flex gap-1 text-green-800 " },
                                                    React.createElement(Icon, { icon: Check, className: 'text-green-800' }),
                                                    React.createElement("div", { className: "text-sm font-bold" }, "Accepted")),
                                                React.createElement("div", { className: 'flex flex-col gap-2' },
                                                    React.createElement("div", { className: 'flex flex-row justify-between items-center text-xs' },
                                                        React.createElement("div", { className: '' }, "Issue date"),
                                                        proposal.sent_at && React.createElement("div", null, new Date(proposal.sent_at).toLocaleDateString()),
                                                        !proposal.sent_at && React.createElement("div", null, "(not sent yet)")),
                                                    React.createElement("div", { className: 'flex flex-row justify-between items-center text-xs' },
                                                        React.createElement("div", null, "Valid until"),
                                                        proposal.sent_at &&
                                                            React.createElement("div", null, new Date(new Date(proposal.sent_at).getTime() + (proposal.valid_days * 24 * 60 * 60 * 1000)).toLocaleDateString()),
                                                        !proposal.sent_at && React.createElement("div", null, "(not sent yet)")),
                                                    proposal.acceptance_customer_name && React.createElement("div", { className: 'flex flex-row justify-between items-center text-xs' },
                                                        React.createElement("div", { className: '' }, "Signed by"),
                                                        React.createElement("div", null, proposal.acceptance_customer_name)),
                                                    proposal.acceptance_customer_date && React.createElement("div", { className: 'flex flex-row justify-between items-center text-xs' },
                                                        React.createElement("div", null, "Signed on"),
                                                        React.createElement("div", null, proposal.acceptance_customer_date))),
                                                ((_g = proposal.links) === null || _g === void 0 ? void 0 : _g.find(link => link.category === 'proposal_terms_and_conditions')) && (() => {
                                                    const terms_and_conditions_link = proposal.links.find(link => link.category === 'proposal_terms_and_conditions');
                                                    return terms_and_conditions_link && (React.createElement("a", { rel: 'noreferrer', target: '_blank', href: `${terms_and_conditions_link.url}${terms_and_conditions_link.internal ? `?name_override=${encodeURIComponent(terms_and_conditions_link.title)}.${terms_and_conditions_link.url.split('.')[1]}` : ''}`, className: 'text-xs bold flex justify-between items-center underline' },
                                                        React.createElement("div", null, terms_and_conditions_link.title),
                                                        React.createElement(Icon, { icon: terms_and_conditions_link.internal ? Download : ExternalLink, className: 'size-3' })));
                                                })())))),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement("div", { className: 'flex flex-col gap-4' },
                                        React.createElement("div", { className: "flex-col flex gap-4" },
                                            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Your home\u2019s heat loss"),
                                            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 gap-3' },
                                                React.createElement("div", { className: 'p-5 gap-4 flex flex-col justify-between bg-gray-100 rounded-md' },
                                                    React.createElement("div", { className: "" }, "Floor area"),
                                                    React.createElement("div", { className: 'flex gap-1 items-end' },
                                                        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, numberFormat(0).format(reportCalculatedData.summaryData.floorArea)),
                                                        React.createElement("div", { className: " text-lg" }, "m\u00B2"))),
                                                React.createElement("div", { className: 'p-5 gap-4 flex flex-col justify-between bg-gray-100 rounded-md' },
                                                    React.createElement("div", { className: "" }, "Total heat loss"),
                                                    React.createElement("div", { className: 'flex gap-1 items-end' },
                                                        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, numberFormat(2).format(reportCalculatedData.summaryData.heatLoss / 1000)),
                                                        React.createElement("div", { className: " text-lg" }, "kW"))),
                                                React.createElement("div", { className: 'p-5 gap-4 flex flex-col justify-between bg-gray-100 rounded-md' },
                                                    React.createElement("div", { className: "" }, "Average heat load"),
                                                    React.createElement("div", { className: 'flex gap-1 items-end' },
                                                        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, numberFormat(0).format(reportCalculatedData.summaryData.heatLoss / reportCalculatedData.summaryData.floorArea)),
                                                        React.createElement("div", { className: " text-lg" }, "W/m\u00B2"))))),
                                        React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Your proposed system"),
                                        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4' },
                                            React.createElement("div", { className: 'p-5 bg-slate-100 rounded-md flex flex-row gap-8' },
                                                React.createElement("img", { alt: '', className: "w-20 max-h-20 object-contain", src: hpImage, onError: (e) => {
                                                        e.currentTarget.onerror = null;
                                                        e.currentTarget.src = HeatPump;
                                                    } }),
                                                React.createElement("div", { className: "flex-col gap-1 flex" },
                                                    React.createElement("div", { className: "text-xs font-semibold uppercase" }, "Heat pump"),
                                                    React.createElement("div", { className: "flex-col justify-start items-start gap-3 flex" },
                                                        React.createElement("div", { className: "text-gray-900 font-semibold" }, reportSnapshot.currentHeatPump.name),
                                                        React.createElement("div", { className: "flex-col justify-start items-start gap-1 flex" },
                                                            React.createElement("div", { className: "self-stretch flex justify-between gap-3" },
                                                                React.createElement("div", { className: "text-xs font-semibold" },
                                                                    "Capacity at ",
                                                                    reportCalculatedData.hpCapacityResult.flowTempC,
                                                                    "\u00A0\u00B0C flow temp and ",
                                                                    reportCalculatedData.hpCapacityResult.outsideTempC,
                                                                    "\u00A0\u00B0C outdoor air temperature"),
                                                                React.createElement("div", { className: "text-right text-xs font-semibold whitespace-nowrap" },
                                                                    reportCalculatedData.hpCapacityResult.capacityKw,
                                                                    " kW")),
                                                            React.createElement("div", { className: "self-stretch flex justify-between gap-3" },
                                                                React.createElement("div", { className: "text-xs font-semibold" },
                                                                    "SCOP at ",
                                                                    reportCalculatedData.design.flow_temp,
                                                                    " \u00B0C"),
                                                                React.createElement("div", { className: "text-right text-xs font-semibold whitespace-nowrap" }, reportCalculatedData.scop)))))),
                                            React.createElement("div", { className: 'p-5 bg-slate-100 rounded-md flex flex-row gap-8' },
                                                React.createElement("img", { alt: '', className: "w-20 max-h-20 object-contain", src: CylinderIcon2 }),
                                                React.createElement("div", { className: "flex-col gap-1 flex" },
                                                    React.createElement("div", { className: "text-xs font-semibold uppercase" }, "Cylinder"),
                                                    React.createElement("div", { className: "flex-col justify-start items-start gap-3 flex" },
                                                        React.createElement("div", { className: "text-gray-900 font-semibold" }, ((_h = reportSnapshot.currentHotWaterCylinder) === null || _h === void 0 ? void 0 : _h.name) || '?'),
                                                        React.createElement("div", { className: "self-stretch flex justify-between gap-3" },
                                                            React.createElement("div", { className: "text-xs font-semibold" }, "Capacity"),
                                                            React.createElement("div", { className: "text-right text-xs font-semibold whitespace-nowrap" },
                                                                Math.round(reportSnapshot.currentHotWaterCylinder.litres),
                                                                " litres"))))),
                                            React.createElement("div", { className: 'sm:col-span-2 p-5 bg-slate-100 rounded-md flex flex-row gap-8' },
                                                React.createElement("img", { alt: '', src: RadiatorIcon, className: "w-12 h-12" }),
                                                React.createElement("div", { className: "flex-col gap-1 flex" },
                                                    React.createElement("div", { className: "text-xs font-semibold uppercase" }, "Emitters"),
                                                    React.createElement("div", { className: "flex-col gap-2 flex" },
                                                        React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                                            React.createElement("div", { className: "text-gray-900 font-semibold" }, reportCalculatedData.summaryData.radiatorsNew + reportCalculatedData.summaryData.radiatorsReplaced),
                                                            React.createElement("div", { className: "" }, "new radiators")),
                                                        reportCalculatedData.summaryData.ufhNew > 0 &&
                                                            React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                                                React.createElement("div", { className: "text-gray-900 font-semibold" }, reportCalculatedData.summaryData.ufhNew),
                                                                React.createElement("div", { className: "" }, "new ufh zones")),
                                                        React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                                            React.createElement("div", { className: "text-gray-900 font-semibold" }, reportCalculatedData.summaryData.emittersUnchanged),
                                                            React.createElement("div", { className: "" }, "retained")),
                                                        React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                                            React.createElement("div", { className: "text-gray-900 font-semibold" }, reportCalculatedData.summaryData.emittersRemoved + reportCalculatedData.summaryData.radiatorsReplaced),
                                                            React.createElement("div", { className: "" }, "removed")))))),
                                        proposal.links && React.createElement("div", { className: 'grid grid-cols-2 gap-4 w-full mb-12' }, (_j = proposal.links) === null || _j === void 0 ? void 0 : _j.filter(link => ['proposal_heat_pump_spec', 'proposal_hot_water_cylinder_spec'].includes(link.category)).map((link, index) => React.createElement(ProposalLinkAnchor, { key: index, link: link })))),
                                    React.createElement("div", { className: 'flex flex-col gap-4' },
                                        React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Performance"),
                                        React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4' },
                                            React.createElement("div", { className: 'p-5 bg-stone-100 rounded-md' },
                                                React.createElement("div", { className: 'flex flex-col gap-2' },
                                                    React.createElement("div", { className: "text-xs font-semibold uppercase" }, "Annual CO\u2082 savings"),
                                                    React.createElement("div", { className: 'flex flex-row gap-2 items-end' },
                                                        React.createElement("div", { className: "text-gray-900 text-xl font-semibold" },
                                                            numberFormat(0).format(reportCalculatedData.performanceEstimateSummary.minEmissionsTonnesSavings),
                                                            "\u2014",
                                                            numberFormat(0).format(reportCalculatedData.performanceEstimateSummary.maxEmissionsTonnesSavings)),
                                                        React.createElement("div", { className: "text-sm" }, "tonnes")))),
                                            React.createElement("div", { className: 'p-5 bg-stone-100 rounded-md' },
                                                React.createElement("div", { className: 'flex flex-col gap-2' },
                                                    React.createElement("div", { className: "text-xs font-semibold uppercase" }, "Annual running cost savings"),
                                                    React.createElement("div", { className: 'flex flex-row gap-2 items-end' },
                                                        React.createElement("div", { className: "text-gray-900 text-xl font-semibold" },
                                                            formatPrice(reportCalculatedData.performanceEstimateSummary.minBillSavings, 0),
                                                            "\u00A0to\u00A0",
                                                            formatPrice(reportCalculatedData.performanceEstimateSummary.maxBillSavings, 0)),
                                                        React.createElement("div", { className: "text-sm" }, "per year")))))))),
                            React.createElement("div", { ref: (el) => (sectionRefs.current.quote = el), className: 'lg:scroll-mt-4 scroll-mt-14' },
                                React.createElement(PrintLayoutPageBreak, null),
                                React.createElement("div", { className: "text-black text-3xl font-bold pb-8" }, "Quote"),
                                React.createElement(PublicQuoteBlock, { quoteSnapshot: quoteSnapshot })),
                            (quoteSnapshot.payment_schedule && quoteSnapshot.payment_schedule.length > 0) &&
                                React.createElement("div", { ref: (el) => (sectionRefs.current.paymentSchedule = el), className: 'lg:scroll-mt-4 scroll-mt-14' },
                                    React.createElement(PublicQuotePaymentScheduleBlock, { quoteSnapshot: quoteSnapshot })),
                            React.createElement("div", { ref: (el) => (sectionRefs.current.hlr = el), className: `lg:scroll-mt-4 scroll-mt-14 flex flex-col ${gapBetweenPages}` },
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRIntroductionPage, { hasIntroduction: isFlagSet(proposal.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO) })),
                                React.createElement("div", null,
                                    isFlagSet(proposal.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO) &&
                                        React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRCalculationConditionsPage, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRHeatlossByElementPage, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRHeatlossByRoomPage, null)),
                                reportSnapshot.survey.floors.map((floor, floorIdx) => {
                                    // skip floors without rooms
                                    if (floor.rooms.length === 0)
                                        return null;
                                    return (React.createElement(Fragment, { key: 'floor-' + floorIdx },
                                        React.createElement("div", null,
                                            React.createElement(PrintLayoutPageBreak, null),
                                            React.createElement(HLRFloorHeatlossPage, { floor: floor })),
                                        isFlagSet(proposal.contents_bitmask, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM) &&
                                            floor.rooms.sort((a, b) => a.name.localeCompare(b.name))
                                                .map((room, roomIdx) => {
                                                return React.createElement(Fragment, { key: 'floor-' + floorIdx + '-room-' + roomIdx },
                                                    React.createElement("div", null,
                                                        React.createElement(PrintLayoutPageBreak, null),
                                                        React.createElement(HLRRoomHeatlossPage, { floor: floor, room: room })));
                                            })));
                                })),
                            React.createElement("div", { ref: (el) => (sectionRefs.current.systemDesign = el), className: `lg:scroll-mt-4 scroll-mt-14 flex flex-col  ${gapBetweenPages}` },
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRHeatPump, null)),
                                emittersDefined && React.createElement(React.Fragment, null,
                                    React.createElement("div", null,
                                        React.createElement(PrintLayoutPageBreak, null),
                                        React.createElement(HLREmittersIntro, null)),
                                    React.createElement("div", null,
                                        React.createElement(PrintLayoutPageBreak, null),
                                        React.createElement(HLREmittersCurrent, null)),
                                    React.createElement("div", null,
                                        React.createElement(PrintLayoutPageBreak, null),
                                        React.createElement(HLREmittersProposed, null))),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRHotWater, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRHotWaterDemand, null))),
                            React.createElement("div", { ref: (el) => (sectionRefs.current.soundAssessment = el), className: 'lg:scroll-mt-4 scroll-mt-14' },
                                React.createElement(PrintLayoutPageBreak, null),
                                React.createElement(HLRSoundAssessment, null)),
                            React.createElement("div", { ref: (el) => (sectionRefs.current.performanceEstimate = el), className: `lg:scroll-mt-4 scroll-mt-14 flex flex-col ${gapBetweenPages}` },
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRPESummary, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRPEDetailedResultsIntroduction, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRPEResultsTable, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRPEInputsAndAssumptions, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRPESystemEfficiency1, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRPESystemEfficiency2, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRPEFlowTempChart, null)),
                                React.createElement("div", null,
                                    React.createElement(PrintLayoutPageBreak, null),
                                    React.createElement(HLRPEMCSKeyFacts, null))),
                            (proposal.links && proposal.links.length > 0) && (React.createElement("div", { ref: (el) => (sectionRefs.current.documents = el), className: 'lg:scroll-mt-4 scroll-mt-14 flex flex-col gap-6' },
                                React.createElement(PrintLayoutPageBreak, null),
                                React.createElement("div", { className: "text-black text-3xl font-bold" }, "Documents"),
                                React.createElement("div", { className: 'flex flex-col gap-4' }, (_k = proposal.links) === null || _k === void 0 ? void 0 : _k.map((link, index) => React.createElement(ProposalLinkAnchor, { key: index, link: link }))))))))),
            React.createElement(AcceptProposalModal, { companyPublicInfo: companyPublicInfo, proposal: proposal, setProposal: setProposal, quoteSnapshot: quoteSnapshot, visible: acceptProposalModalVisible, setVisible: setAcceptProposalModalVisible, termsAndConditionsLink: (_l = proposal.links) === null || _l === void 0 ? void 0 : _l.find(link => link.category === 'proposal_terms_and_conditions') })));
};
const AcceptProposalModal = ({ companyPublicInfo, proposal, setProposal, quoteSnapshot, visible, setVisible, termsAndConditionsLink }) => {
    const [accepted, setAccepted] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [status, setStatus] = useState('NOT_ACCEPTED');
    const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total + quoteSnapshot.bus_grant;
    const termsAndConditionsLinkUrl = termsAndConditionsLink
        ? (termsAndConditionsLink === null || termsAndConditionsLink === void 0 ? void 0 : termsAndConditionsLink.url) + ((termsAndConditionsLink === null || termsAndConditionsLink === void 0 ? void 0 : termsAndConditionsLink.internal) ? `?name_override=${encodeURIComponent(termsAndConditionsLink.title)}.${termsAndConditionsLink.url.split('.')[1]}` : '')
        : undefined;
    const handleOnConfirm = async () => {
        if (status === 'ACCEPTED') {
            setVisible(false);
            return;
        }
        try {
            await signProposalAPI({ acceptance_customer_name: name, acceptance_customer_date: date }, proposal.lead_uuid, companyPublicInfo.uuid, proposal.uuid);
            setStatus('ACCEPTED');
            setProposal({
                ...proposal,
                acceptance_status: 1,
                acceptance_customer_name: name,
                acceptance_customer_date: date
            });
            confetti();
            await createJobEvent({
                event_type: JobEventType.enum.ProposalAccepted,
                company_uuid: companyPublicInfo.uuid,
                job_uuid: proposal.lead_uuid,
                extra_data: {
                    proposal_uuid: proposal.uuid
                }
            });
            if (proposal.lead_status !== LeadStatusSchema.enum.ProposalAccepted) {
                createJobEvent({
                    event_type: JobEventType.enum.StatusChanged,
                    company_uuid: companyPublicInfo.uuid,
                    job_uuid: proposal.lead_uuid,
                    extra_data: {
                        old_status: proposal.lead_status,
                        new_status: LeadStatusSchema.enum.ProposalAccepted
                    }
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    const allValid = (validateNotNull(name).value && validateLooksLikeDate(date).value && accepted);
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: 'Accept proposal', confirmButtonLabel: status === 'ACCEPTED' ? 'Done' : 'Sign', onConfirm: handleOnConfirm, hideOnConfirm: false, confirmDisabled: !allValid, cancelDisabled: status === 'ACCEPTED' },
        status === 'ACCEPTED' && React.createElement("div", { className: 'px-5 py-8 bg-green-50 rounded-lg flex-col justify-center items-center gap-2 flex w-full' },
            React.createElement("img", { alt: 'envelope', src: yellowEnvelope, className: 'w-16 h-16' }),
            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Success"),
            React.createElement("div", { className: "text-center flex flex-col gap-1" },
                React.createElement("span", null, "Your proposal was successfully signed."))),
        status === 'NOT_ACCEPTED' && React.createElement("div", { className: 'flex flex-col gap-5 w-full' },
            React.createElement("div", { className: 'flex flex-col gap-2 w-full border-b border-gray-300 pb-5' },
                React.createElement("div", { className: 'flex flex-row justify-between items-center text-xs' },
                    React.createElement(Text, { size: "XS", className: "text-gray-600" }, "Total before grant"),
                    React.createElement(Text, { size: "XS", className: "text-gray-600" }, formatPrice(quoteSnapshot.total_pre_deductions, 2))),
                quoteSnapshot.bus_grant < 0 &&
                    React.createElement("div", { className: 'flex flex-row justify-between items-start' },
                        React.createElement(Text, { size: "XS", className: "text-gray-600" }, "Boiler Upgrade Scheme (BUS) Grant"),
                        React.createElement(Text, { size: "XS", className: "text-gray-600" }, formatPrice(quoteSnapshot.bus_grant, 2))),
                React.createElement("div", { className: 'flex flex-row justify-between items-center' },
                    React.createElement(Text, { bold: true }, "Total"),
                    React.createElement(Text, { bold: true }, formatPrice(total, 2)))),
            React.createElement("label", { className: "flex items-center gap-2" },
                React.createElement("input", { type: "checkbox", className: "h-4 w-4", checked: accepted, onChange: () => setAccepted(!accepted) }),
                React.createElement("div", { className: "text-gray-600 text-sm" },
                    "I accept the proposal",
                    termsAndConditionsLinkUrl ? React.createElement(React.Fragment, null,
                        "and agree to be bound by the ",
                        React.createElement("a", { href: termsAndConditionsLinkUrl, target: "_blank", rel: "noreferrer", className: "underline" }, "terms and conditions"),
                        ".") : React.createElement(React.Fragment, null, "."))),
            React.createElement(Input, { label: 'Your name', placeholder: 'Full name', value: name, setValue: setName, validator: validateNotNull }),
            React.createElement(Input, { label: "Today's date", placeholder: 'dd/mm/yyyy', value: date, setValue: setDate, validator: validateLooksLikeDate }))));
};
