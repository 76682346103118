import React from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
export const HLRHotWater = () => {
    const hlrContext = React.useContext(HLRContext);
    const locationPhotos = hlrContext.files.filter(x => hlrContext.survey.cylinder_photos.some(y => y.image_uuid === x.uuid));
    return React.createElement("div", { className: 'flex-col gap-6 flex' },
        React.createElement(HLRSubheaderBlock, { section: "System design", title: "Hot water" }),
        React.createElement("div", null, "Your heat pump will provide 100% of your hot water requirement. Heat pumps cannot create heat as quickly as combi boilers, so they can\u2019t heat up hot water instantaneously when you need it. Instead we install a cylinder which stores hot water so it's always ready when you need it."),
        React.createElement("div", { className: 'text-gray-900 font-bold' }, "Proposed cylinder model"),
        React.createElement("div", null, "We've selected the following hot water cylinder based on the number of bedrooms in your home."),
        hlrContext.currentHotWaterCylinder
            ? React.createElement(ClickableCard, { variant: 'WHITE' },
                React.createElement("div", { className: "flex-col gap-3 flex" },
                    React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, hlrContext.currentHotWaterCylinder.name),
                    React.createElement("div", { className: "gap-2 flex justify-between" },
                        React.createElement("div", { className: "text-xs font-semibold" }, "Capacity"),
                        React.createElement("div", { className: "text-xs font-semibold" },
                            Math.round(hlrContext.currentHotWaterCylinder.litres),
                            " litres"))))
            : React.createElement(ClickableCard, { variant: 'PLACEHOLDER' }, "No cylinder found"),
        (hlrContext.survey.cylinder_location_description || locationPhotos.length > 0) && React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'text-gray-900 font-bold' }, "Cylinder location"),
            hlrContext.survey.cylinder_location_description &&
                React.createElement("div", null, hlrContext.survey.cylinder_location_description),
            locationPhotos.length > 0 && React.createElement("div", { className: 'flex flex-wrap gap-6' }, locationPhotos.map(x => React.createElement("img", { key: x.uuid, className: 'max-h-64 rounded-md', src: x.file_base64 || `${process.env.S3_BUCKET_URL}/${x.file_url}` })))));
};
