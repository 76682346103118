import React, { useContext, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Button } from '../../../components/buttons/button';
import { logEvent } from '../../../code/log_event';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { WALL_GROUPS } from '../../../code/models/u_value';
import { ChevronRightIcon } from 'lucide-react';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
export const WallTypeStep = ({ companyPublicInfo }) => {
    var _a, _b;
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    const [hasSelectionChanged, setHasSelectionChanged] = useState(false);
    const [selectedOption, setSelectedOption] = useState(survey.lead.property.wallGroup ? survey.lead.property.wallGroup : (_b = (_a = survey.lead.property.construction_age_band) === null || _a === void 0 ? void 0 : _a.wallGroup) !== null && _b !== void 0 ? _b : '');
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Wall type step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                wallGroup: selectedOption,
                // If we know it's cavity wall but they select solid brick, we need to remove e.g. Insulated wallType from EPC so our defaults can take over.
                wallType: hasSelectionChanged ? undefined : survey.lead.property.wallType
            }
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement(QuestionnaireNavigateBack, null),
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "What are most of your external walls made of?" }),
        React.createElement(SurveyOptionGroup, { options: WALL_GROUPS.map(x => ({ icon: x.icon, key: x.name, label: x.name })), onSelect: (key) => {
                setSelectedOption(key);
                setHasSelectionChanged(true);
            }, selectedOptionKey: selectedOption }),
        React.createElement(Button, { size: 'LG', disabled: !selectedOption, onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))));
};
