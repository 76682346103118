import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { ClickableCard } from '../../../../components/content_display/card';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const CylinderLocationHasExistingHWCBlock = () => {
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const { survey, setSurvey } = cylinderLocationContext;
    return React.createElement(React.Fragment, null,
        React.createElement(ClickableCard, { variant: 'GREY' },
            React.createElement("div", { className: "items-center gap-3 flex justify-between" },
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Existing cylinder"),
                    React.createElement("div", { className: "text-gray-500 text-sm" }, "Property has an existing hot water cylinder")),
                React.createElement(Toggle, { value: survey.has_existing_cylinder, setValue: () => setSurvey({ ...survey, has_existing_cylinder: !survey.has_existing_cylinder }) }))));
};
