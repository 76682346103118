import React, { useContext } from 'react';
import { EstimatePageInner } from '../../../../estimate_page';
import { estimateAndLeadToCalculatedEstimate } from '../../../../../code/models/calculated_estimate';
import { Section } from '../../components/section';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { AdminContext } from '../../../admin_layout';
export const EstimatePreview = ({ estimate, lead, companyPublicInfo }) => {
    var _a, _b, _c;
    const adminContext = useContext(AdminContext);
    const calculatedEstimate = estimateAndLeadToCalculatedEstimate(estimate, lead, adminContext.data);
    const notGrantEligible = (!lead.epc_scotland && (!((_a = lead.epcData) === null || _a === void 0 ? void 0 : _a.lmkKey) || estimate.busGrantEligibleReasons.some(x => !x.hasPassed)));
    return (React.createElement(Section, { title: "Estimate preview" },
        React.createElement(Alert, { type: "INFO" }, "This is a preview of the estimate that the customer will receive by email."),
        React.createElement("div", { className: "border border-light rounded overflow-hidden" },
            React.createElement(EstimatePageInner, { estimate: calculatedEstimate, companyPublicInfo: (_c = (_b = adminContext.data.company) === null || _b === void 0 ? void 0 : _b.public_info) !== null && _c !== void 0 ? _c : companyPublicInfo, isScottish: estimate.isScottish, grantEligible: !notGrantEligible, isPreview: true }))));
};
