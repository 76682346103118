import React from 'react';
import { FloorButtons } from './floor_buttons';
import { RoomButtons } from './room_buttons';
import { WallButtons } from './wall_buttons';
import { FloorPlanButtons } from './floor_plan_buttons';
import { RoomDrawingButtons } from '../room_drawing_buttons';
export const EntityToolbar = ({ setPage, currentWall, currentRoom, currentFloor, setTempImageAndScale, setStageStep, showFloorPlanButtons, setShowFloorPlanButtons, setFloor, drawingRooms, setDrawingRooms, cancelDrawingRooms, scalingPoints, setScalingPoints, intersectingShapes, planOpacity, setPlanOpacity }) => {
    if (drawingRooms)
        return React.createElement(RoomDrawingButtons, { cancelDrawingRooms: cancelDrawingRooms, scalingPoints: scalingPoints, setScalingPoints: setScalingPoints });
    if (showFloorPlanButtons)
        return React.createElement(FloorPlanButtons, { floor: currentFloor, setFloor: setFloor, setShowFloorPlanButtons: setShowFloorPlanButtons, setTempImageAndScale: setTempImageAndScale, setStageStep: setStageStep, planOpacity: planOpacity, setPlanOpacity: setPlanOpacity });
    if (currentWall)
        return React.createElement(WallButtons, { setPage: setPage, currentWall: currentWall });
    if (currentRoom)
        return React.createElement(RoomButtons, { setPage: setPage, currentRoom: currentRoom });
    return React.createElement(FloorButtons, { setShowFloorPlanButtons: setShowFloorPlanButtons, setStageStep: setStageStep, setPage: setPage, currentFloor: currentFloor, setTempImageAndScale: setTempImageAndScale, setDrawingRooms: setDrawingRooms, intersectingShapes: intersectingShapes });
};
