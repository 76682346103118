// Figma link: https://www.figma.com/design/8qckV47cTJMgQVf2N5m8Vf/Design-System---Spruce?node-id=5397-8709&m=dev
import React from 'react';
export const colours = {
    DARK: 'text-gray-900',
    LIGHT: 'text-gray-300'
};
export const ListItem = ({ onClick, topBadge, leftIcon, primaryText, primaryTextColour, secondaryText, rightClickableIcon, rightBadge, actionButton, figure }) => {
    const allClickable = !actionButton && !rightClickableIcon && onClick; // if no clickable content on right - make all clickable
    const onlyMiddleClickable = (actionButton || rightClickableIcon) && onClick; // if no clickable content at all - make only middle clickable
    return React.createElement("div", { className: `p-4 justify-between items-center gap-4 flex first:border-none border-t border-gray-200 ${allClickable ? 'cursor-pointer' : ''}`, onClick: allClickable ? onClick : undefined },
        leftIcon && React.createElement("div", { className: "p-2 bg-gray-100 rounded-xl gap-2.5 flex items-center justify-center w-10 h-10" }, leftIcon),
        React.createElement("div", { className: `flex flex-col flex-grow ${onlyMiddleClickable ? 'cursor-pointer' : ''}`, onClick: !allClickable ? onClick : undefined },
            topBadge && React.createElement("div", null, topBadge),
            React.createElement("div", { className: `${primaryTextColour ? colours[primaryTextColour] : colours.DARK} text-base font-bold` }, primaryText),
            secondaryText && React.createElement("div", { className: " text-gray-500 text-xs tracking-tight" }, secondaryText)),
        React.createElement("div", { className: 'flex gap-4 items-center' },
            rightBadge && React.createElement("div", { className: onlyMiddleClickable ? 'cursor-pointer' : '', onClick: onlyMiddleClickable ? onClick : undefined }, rightBadge),
            actionButton && React.createElement("div", null, actionButton),
            figure && React.createElement("div", null, figure),
            rightClickableIcon && React.createElement("div", null, rightClickableIcon)));
};
