import React, { useEffect, useState } from 'react';
import { SettingsLayout } from '../components/settings_layout';
import { Section } from '../../../../components/containers/section';
import { AttachmentCard } from '../../../../code/proposal_attachments/attachment_card';
import { deleteDefaultLink, getDefaultLinks, insertDefaultLink } from '../../../../code/models/default_links';
import { proposalCategoryTitles } from '../../job_layout/proposal_configurator/proposal_files';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Button } from '../../../../components/buttons/button';
import { Plus } from 'lucide-react';
export const ProposalAttachments = ({ company }) => {
    const [requiredAttachments, setRequiredAttachments] = useState(Object.keys(proposalCategoryTitles).map(key => ({ key, attachment: undefined })));
    const [customAttachments, setCustomAttachments] = useState([]);
    const [error, setError] = useState(undefined);
    useEffect(() => {
        const fetchDefaultAttachments = async () => {
            const companyDefaultAttachments = await getDefaultLinks(company.public_info.uuid);
            if (companyDefaultAttachments.error) {
                setError(companyDefaultAttachments.error);
            }
            else {
                setRequiredAttachments(Object.keys(proposalCategoryTitles).map(key => {
                    var _a;
                    return ({
                        key,
                        attachment: (_a = companyDefaultAttachments.links) === null || _a === void 0 ? void 0 : _a.find(link => link.category === key)
                    });
                }));
                setCustomAttachments(companyDefaultAttachments.links
                    ? companyDefaultAttachments.links.filter(link => link.category === 'proposal_custom')
                    : []);
            }
        };
        fetchDefaultAttachments();
    }, []);
    const handleRequiredAttachmentChange = async (key, title, url, internal, isAdding) => {
        var _a, _b;
        const initialRequiredAttachments = requiredAttachments;
        // Common uuid for both state and DB updates
        const uuid = crypto.randomUUID();
        // Optimistically update requiredAttachments
        setRequiredAttachments(requiredAttachments.map(e => e.key === key ? { key: e.key, attachment: isAdding ? { uuid, title, url, internal, category: key } : undefined } : e));
        setError(undefined);
        try {
            if (isAdding) {
                await insertDefaultLink({ uuid, title, url, internal, category: key }, company.public_info.uuid);
            }
            else {
                const uuidToDelete = (_b = (_a = requiredAttachments.find(e => e.key === key)) === null || _a === void 0 ? void 0 : _a.attachment) === null || _b === void 0 ? void 0 : _b.uuid;
                if (uuidToDelete) {
                    await deleteDefaultLink(uuidToDelete, company.public_info.uuid);
                }
            }
        }
        catch (e) {
            setError(e.message);
            setRequiredAttachments(initialRequiredAttachments);
        }
    };
    const handleCustomAttachmentChange = async (index, title, url, internal, isAdding) => {
        var _a;
        const initialAttachments = customAttachments;
        const uuid = crypto.randomUUID();
        const updatedAttachments = customAttachments.map((attachment, i) => i === index ? (isAdding ? { uuid, title, url, internal, category: 'proposal_custom' } : undefined) : attachment).filter(attachment => attachment !== undefined);
        setCustomAttachments(updatedAttachments);
        try {
            if (isAdding) {
                await insertDefaultLink({ uuid, title, url, internal, category: 'proposal_custom' }, company.public_info.uuid);
            }
            else {
                const uuidToDelete = (_a = customAttachments[index]) === null || _a === void 0 ? void 0 : _a.uuid;
                if (uuidToDelete) {
                    await deleteDefaultLink(uuidToDelete, company.public_info.uuid);
                }
            }
            setError(undefined);
        }
        catch (e) {
            setError(e.message);
            setCustomAttachments(initialAttachments);
        }
    };
    return React.createElement(SettingsLayout, { heading: 'Proposal attachments', description: 'Configure the default attachments added to all new proposals. You can override these on a per-proposal basis.' },
        React.createElement("div", { className: 'flex flex-col gap-8' },
            React.createElement(Section, null,
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement(FormLabel, { size: 'LG', labelText: 'MCS Recommended Documents' }),
                    error && React.createElement(Alert, { type: 'DANGER' }, error),
                    requiredAttachments
                        .filter(requiredAttachment => requiredAttachment.key === 'proposal_terms_and_conditions')
                        .map(requiredAttachment => {
                        return React.createElement(AttachmentCard, { key: requiredAttachment.key, forceTitle: proposalCategoryTitles[requiredAttachment.key], attachment: requiredAttachment.attachment, onAddAttachment: (title, url, internal) => handleRequiredAttachmentChange(requiredAttachment.key, proposalCategoryTitles[requiredAttachment.key], url, internal, true), onRemoveAttachment: () => handleRequiredAttachmentChange(requiredAttachment.key, '', '', false, false) });
                    }),
                    React.createElement(FormLabel, { size: 'LG', labelText: 'Extra documents' }),
                    customAttachments.map((link, index) => (React.createElement(AttachmentCard, { key: index, attachment: link, onAddAttachment: (title, url, internal) => handleCustomAttachmentChange(index, title, url, internal, true), onRemoveAttachment: () => handleCustomAttachmentChange(index, '', '', false, false) }))),
                    React.createElement(Button, { iconLeft: Plus, colour: 'LIGHT', className: 'w-fit', disabled: customAttachments.length > 0 && customAttachments.some(attachment => attachment === undefined), onClick: () => setCustomAttachments(prev => [...prev, undefined]) }, "Add document")))));
};
