import React from 'react';
import { ListItem } from '../../../components/content_display/list_item';
import { noop } from 'lodash';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { Icon } from '../../../components/buttons/icon';
import { Button } from '../../../components/buttons/button';
import { Link } from '../../../components/buttons/link';
import { Square } from 'lucide-react';
export const ListItemDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(ListItem, { onClick: noop, topBadge: React.createElement(Badge, { text: 'Badge', color: 'BLUE' }), leftIcon: React.createElement(Icon, { icon: Square }), primaryText: 'Hello world', primaryTextColour: 'DARK', secondaryText: 'My secondary text', rightClickableIcon: React.createElement(Icon, { icon: Square }), rightBadge: React.createElement(Badge, { text: 'Badge', color: 'GREEN' }), actionButton: React.createElement(Button, { colour: 'DARK' }, "Button"), figure: React.createElement(Link, { text: 'Figure', onClick: noop }) })));
};
const componentProps = [
    { name: 'onClick', description: 'Action that should happen when clicking' },
    { name: 'topBadge', description: 'If present show badge above primary text' },
    { name: 'leftIcon', description: 'If present show icon in gray background left of all content' },
    { name: 'primaryText', description: 'Primary bold text of list item' },
    { name: 'primaryTextColour', description: 'DARK | LIGHT' },
    { name: 'secondaryText', description: 'Secondary optional text of list item' },
    { name: 'rightClickableIcon', description: 'Right most icon, usually a chevron to indicate clickable list item' },
    { name: 'rightBadge', description: 'If populated show a badge on the right hand side' },
    { name: 'actionButton', description: 'If populated show a button on the right hand side' },
    { name: 'figure', description: 'If populated show a figure on the right hand side' }
];
const codeBlock = `<ListItem
  onClick={noop}
  topBadge={<Badge text='Badge' color='BLUE' />}
  leftIcon={<Icon icon={Square} />}
  primaryText='Hello world'
  primaryTextColour='DARK'
  secondaryText='My secondary text'
  rightClickableIcon={<Icon icon={Square} />}
  rightBadge={<Badge text='Badge' color='GREEN' />}
  actionButton={<Button colour='DARK'>Button</Button>}
  figure={<Link onClick={noop}>Figure</Link>}
/>`;
