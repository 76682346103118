import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
export const CylinderLocationPipeRunsBlock = () => {
    var _a;
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const { survey, setSurvey } = cylinderLocationContext;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Pipe runs"),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Distance"),
                React.createElement("div", { className: "text-gray-500 text-sm" }, "Rough distance from the cylinder location to the heat pump location.")),
            React.createElement(Input, { type: 'number', value: (_a = survey.pipe_run_distance_metre) === null || _a === void 0 ? void 0 : _a.toString(), postfix: 'm', setValue: (e) => setSurvey({ ...survey, pipe_run_distance_metre: Number(e) }) })),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Pipe run description"),
                React.createElement("div", { className: "text-gray-500 text-sm" }, "Where the pipe runs should go from the cylinder location to the heat pump location.")),
            React.createElement(TextArea, { value: survey.pipe_run_description, setValue: (e) => setSurvey({ ...survey, pipe_run_description: e }) })));
};
