import { orderBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Stage, Layer, Circle, Line, Image, Text } from 'react-konva';
import useImage from 'use-image';
import { RoomDrawing } from './room_drawing';
import { gray200, indigo600, red500, white } from './code/constants';
import { removeUndefinedFromList } from '../../../code/helpers';
import { Grid } from './grid';
import { SNAPPING_TOLERANCE_PIXELS } from '../constants';
import { getTextDimensions } from './line_with_text';
export const FloorStage = ({ onClick, onTouchEnd, onTouchMove, onWheelProp, onDragMove, stageScale, stagePosition, stageSize, roomsWithMetaData, currentRoomId, wallsWithoutDuplicates, survey, floor, dragStopped, currentWall, setStageScale, setCurrentWallId, setPage, setWalls, currentRoom, setCurrentRoomId, defaultMaterials, isStatic = false, setStagePosition, addEvent, scalingPoints, mousePosition, tempImageAndScale, stageRef, setStageSize, stageStep, onMouseMove, isDrawing, intersectingShapes, scalingWindow, scalingDoor, guidelines, setGuidelines, showFloorPlan, planOpacity }) => {
    var _a;
    const [image, status] = useImage((_a = (floor.floor_plan_image || `${process.env.S3_BUCKET_URL}/${floor.floor_plan_url}`)) !== null && _a !== void 0 ? _a : '', 'anonymous');
    const [tempImage] = useImage(tempImageAndScale.image);
    const [touches, setTouches] = useState(0);
    useEffect(() => {
        var _a, _b, _c, _d;
        const currentWidth = (_b = (_a = stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0;
        const currentHeight = (_d = (_c = stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) === null || _c === void 0 ? void 0 : _c.clientHeight) !== null && _d !== void 0 ? _d : 0;
        setStageSize({
            width: currentWidth,
            height: currentHeight
        });
        setStagePosition({
            x: currentWidth ? currentWidth / 2 : 0,
            y: currentHeight ? currentHeight / 2 : 0
        });
        const handleResize = () => {
            var _a, _b, _c, _d;
            setStageSize({
                width: (_b = (_a = stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) !== null && _b !== void 0 ? _b : 0,
                height: (_d = (_c = stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) === null || _c === void 0 ? void 0 : _c.clientHeight) !== null && _d !== void 0 ? _d : 0
            });
        };
        const resizeObserver = new ResizeObserver(handleResize);
        if (stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) {
            resizeObserver.observe(stageRef === null || stageRef === void 0 ? void 0 : stageRef.current);
        }
        handleResize();
        return () => {
            if (stageRef === null || stageRef === void 0 ? void 0 : stageRef.current) {
                resizeObserver.unobserve(stageRef === null || stageRef === void 0 ? void 0 : stageRef.current);
            }
        };
    }, []);
    const isCreatingPoints = stageStep === 1 || stageStep === 2;
    const cursorType = isCreatingPoints || isDrawing
        ? 'cursor-pointer'
        : 'cursor-move';
    // If we are scaling an image, once we have 2 points we don't want to show the onHover point or line anymore.
    const trueScalingPoints = [...scalingPoints.map(sp => [sp.x * stageScale, sp.y * stageScale]).flat()];
    const trueMousePosition = mousePosition ? [mousePosition.x * stageScale, mousePosition.y * stageScale] : [];
    const scalingLinesWithMouse = !stageStep || stageStep === 1 || stageStep === 2
        ? [...trueScalingPoints, ...trueMousePosition]
        : trueScalingPoints;
    const scalingPointsWithMouse = !stageStep || stageStep === 1 || stageStep === 2
        ? [mousePosition ? { x: mousePosition.x * stageScale, y: mousePosition.y * stageScale } : undefined, ...scalingPoints.map(sp => ({ x: sp.x * stageScale, y: sp.y * stageScale }))]
        : scalingPoints.map(sp => ({ x: sp.x * stageScale, y: sp.y * stageScale }));
    const historicalOffset = survey.old_unit_vector
        ? (((475) * 20) * stageScale) + (500 * stageScale)
        : 0;
    const imageSyncingText = 'Image syncing, please wait...';
    const imageSyncingTextDimensions = getTextDimensions(imageSyncingText, false, 50);
    // onTap fires on mobile, when clicking shape the onTap also calls onTap event on stage below shape.
    // onClick fires on mobile, but only the first event target e.g. clicking a shape will not call the onClick stage below.
    // We cannot call onClick and onTap as it would double up points when drawing rooms.
    // We must set listening=false on all shapes when drawing so the onClick can successfully fire on the stage.
    return React.createElement(Stage, { hitOnDragEnabled: true, className: `bg-gray-100 ${isStatic ? '' : 'fixed'} ${cursorType} flex`, onClick: onClick, onTouchEnd: () => {
            onTouchEnd();
            setTouches(0);
        }, onTouchMove: onTouchMove, onMouseMove: onMouseMove, onTouchStart: (e) => { var _a; return setTouches((_a = e.evt.touches) === null || _a === void 0 ? void 0 : _a.length); }, x: stagePosition.x, y: stagePosition.y, draggable: !isStatic, width: stageSize.width, height: stageSize.height, onWheel: onWheelProp, onDragMove: onDragMove },
        React.createElement(Layer, null,
            React.createElement(Grid, { size: 'MM', scale: stageScale, position: stagePosition, stageHeight: stageSize.height, stageWidth: stageSize.width }),
            React.createElement(Grid, { size: 'CM', scale: stageScale, position: stagePosition, stageHeight: stageSize.height, stageWidth: stageSize.width }),
            React.createElement(Grid, { size: 'M', scale: stageScale, position: stagePosition, stageHeight: stageSize.height, stageWidth: stageSize.width })),
        React.createElement(Layer, null,
            tempImage && React.createElement(Image, { x: -((tempImage.width * stageScale * tempImageAndScale.scale) / 2), y: -((tempImage.height * stageScale * tempImageAndScale.scale) / 2), opacity: 0.8, image: tempImage, width: tempImage.width * stageScale, height: tempImage.height * stageScale, scale: { x: tempImageAndScale.scale, y: tempImageAndScale.scale } }),
            status === 'loading' && React.createElement(Text, { text: imageSyncingText, fontSize: 50, fill: gray200, offsetX: imageSyncingTextDimensions.width / 2, offsetY: -50 }),
            showFloorPlan && image && !tempImage && floor.floor_plan_is_showing && React.createElement(Image, { listening: false, x: historicalOffset - ((image.width * stageScale * floor.floor_plan_scale) / 2), y: historicalOffset - ((image.height * stageScale * floor.floor_plan_scale) / 2), opacity: planOpacity, image: image, width: image.width * stageScale, height: image.height * stageScale, scale: { x: floor.floor_plan_scale, y: floor.floor_plan_scale } }),
            !stageStep && !scalingWindow && !scalingDoor && orderBy(roomsWithMetaData, x => x.uuid === currentRoomId ? 1 : 0).map(x => {
                return React.createElement(RoomDrawing, { touches: touches, wallsWithoutDupes: wallsWithoutDuplicates, key: x.uuid, room: x, floor: floor, dragStopped: dragStopped, currentWall: currentWall, stageScale: stageScale, setStageScale: setStageScale, setCurrentWallId: setCurrentWallId, setPage: setPage, setWalls: setWalls, currentRoom: currentRoom, currentRoomId: currentRoomId, setCurrentRoomId: setCurrentRoomId, defaultMaterials: defaultMaterials, setStagePosition: setStagePosition, addEvent: addEvent, isDrawing: isDrawing, setGuidelines: setGuidelines, snappingTolerancePixels: SNAPPING_TOLERANCE_PIXELS });
            }),
            scalingPoints.length > 0 && React.createElement(Line, { points: removeUndefinedFromList(scalingLinesWithMouse), strokeWidth: 2, stroke: indigo600, listening: false }),
            removeUndefinedFromList(scalingPointsWithMouse).map((sp, i) => React.createElement(Circle, { key: i, x: sp.x, y: sp.y, radius: 5, stroke: indigo600, fill: i === scalingPoints.length ? indigo600 : white, listening: false })),
            intersectingShapes.map((x, i) => React.createElement(Line, { key: i, points: x.flat().map(x => x * stageScale), opacity: 0.2, fill: red500, listening: false, closed: true })),
            guidelines.map((x, i) => React.createElement(Line, { listening: false, key: i, points: [x.p1.x, x.p1.y, x.p2.x, x.p2.y], strokeWidth: 1, stroke: indigo600, dash: [10, 10] }))));
};
