import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { Input } from '../../../../components/inputs_and_selections/input';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
export const CylinderLocationExistingDetailsBlock = () => {
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const { survey, setSurvey } = cylinderLocationContext;
    const cylinderTypes = ['Vented', 'Unvented'];
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Existing cylinder"),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "text-gray-900 font-bold " }, "Cylinder volume"),
            React.createElement(Input, { type: 'number', value: survey.existing_cylinder_volume_l.toString(), setValue: (e) => setSurvey({ ...survey, existing_cylinder_volume_l: Number(e) }), postfix: React.createElement("span", { className: 'text-gray-500' }, "litres") })),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Type"),
            React.createElement(RadioGroup, { items: cylinderTypes.map(x => ({
                    name: x,
                    onClick: () => setSurvey({ ...survey, existing_cylinder_type: x }),
                    variant: survey.existing_cylinder_type === x ? 'ACTIVE' : 'DEFAULT'
                })) })),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "text-gray-900 font-bold " }, "Coil surface area"),
            React.createElement(Input, { type: 'number', value: survey.existing_cylinder_coil_size.toString(), setValue: (e) => setSurvey({ ...survey, existing_cylinder_coil_size: Number(e) }), postfix: 'm\u00B2' })),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "text-gray-900 font-bold " }, "Brand and model"),
            React.createElement(Input, { value: survey.existing_cylinder_brand_and_model, setValue: (e) => setSurvey({ ...survey, existing_cylinder_brand_and_model: e }) })),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "text-gray-900 font-bold" }, "Location description"),
            React.createElement(TextArea, { value: survey.existing_cylinder_location_description, setValue: (e) => setSurvey({ ...survey, existing_cylinder_location_description: e }), placeholder: 'Physical location, where is it in relation to existing boiler' })));
};
