import React from 'react';
import { BrandLogoBlock } from './blocks/brand_logo_block';
import { SettingsLayout } from '../components/settings_layout';
import { BrandColoursBlock } from './blocks/brand_colours_block';
import { BrandSignatureBlock } from './blocks/brand_signature_block';
export const AccountBranding = ({ company, setCompany }) => {
    return React.createElement(SettingsLayout, { heading: 'Branding', description: 'Manage your company\'s branding on estimates, proposals, quotes, and reports' },
        React.createElement(BrandLogoBlock, { company: company, setCompany: setCompany }),
        React.createElement(BrandSignatureBlock, { company: company, setCompany: setCompany }),
        React.createElement(BrandColoursBlock, { company: company, setCompany: setCompany }));
};
