import React from 'react';
export const PrintLayoutContext = React.createContext({ headerHeightPx: 0 });
export const PrintLayout = (props) => {
    // It's very tricky to print header on each page. There is a solution reused:
    // https://medium.com/@Idan_Co/the-ultimate-print-html-template-with-header-footer-568f415f6d2a
    return React.createElement(React.Fragment, null,
        React.createElement("div", { 
            // using style because arbitrary class names are not allowed in print media
            style: { height: `${props.headerHeightPx}px` }, className: 'w-full print:top-0 print:fixed print:w-[210mm] print:max-w-[210mm]' }, props.header),
        React.createElement("table", { className: 'w-full' },
            React.createElement("thead", { className: 'print:table-header-group hidden' },
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement("div", { 
                            // using style because arbitrary class names are not allowed in print media
                            style: { height: `${props.headerHeightPx}px` } }, "\u00A0")))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement("div", { className: 'print:w-[210mm] print:max-w-[210mm]' },
                            React.createElement(PrintLayoutContext.Provider, { value: { headerHeightPx: props.headerHeightPx } }, props.content)))))));
};
export const PrintLayoutPageBreak = (props) => {
    var _a;
    return React.createElement("div", { className: `${(_a = props.className) !== null && _a !== void 0 ? _a : ''} print:break-before-page` });
};
// TODO: switch proposal and report rendering to this component
export const PrintLayoutPaginateBlocks = (props) => {
    return React.createElement(React.Fragment, null, props.blocks.map((block, index) => (React.createElement("div", { key: index },
        React.createElement(PrintLayoutPageBreak, null),
        block))));
};
