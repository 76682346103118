import React, { useState } from 'react';
import { PageHeader } from '../components/design_page_header';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TableLite } from '../../../../components/content_display/table_lite';
import { Button } from '../../../../components/buttons/button';
import { openInNewTab } from '../../../../code/helpers';
import { ClickableCard } from '../../../../components/content_display/card';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { numberFormat } from '../../../../code/number_format';
import { Icon } from '../../../../components/buttons/icon';
import { getElectricityPencePerKwh, getHeatingFuel, getHeatingPencePerUnit, HEATING_FUELS } from '../../../../code/models/heating_fuel';
import { getExistingSystemEfficiencyFromSurvey } from '../../../../code/models/heating_system';
import { defaultTariffIsOverridden } from '../../survey/existing_heating_system/ehs_energy_use_block';
import { ExternalLink, XCircle } from 'lucide-react';
export const PerformanceDesignPage = ({ performanceEstimateColumns, performanceEstimateSummary, survey, setSurvey, postcode, degreeDays, heatLossWattsPerKelvin, scopSpaceHeating, scopHotWater, isOffline, allSynced }) => {
    var _a, _b;
    const [page, setPage] = useState('');
    const existingFuel = getHeatingFuel(survey.existing_system_fuel_uuid);
    const existingSystemEfficiency = getExistingSystemEfficiencyFromSurvey(survey);
    const existingSystemPencePerUnit = getHeatingPencePerUnit(existingFuel, survey.existing_system_p_per_unit_override);
    const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity');
    if (page === 'EPC')
        return React.createElement(EpcPage, { isOffline: isOffline, allSynced: allSynced, postcode: postcode, survey: survey, setSurvey: setSurvey, setPage: setPage });
    if (page === 'DEGREE_DAYS') {
        return React.createElement(HeatLossDegreeDaysPage, { isOffline: isOffline, allSynced: allSynced, heatLossWattsPerKelvin: heatLossWattsPerKelvin, degreeDays: degreeDays, spaceHeatingDemandKwh: performanceEstimateSummary.hddEstimate.proposed.demandSpaceHeatingKwh, hotWaterDemandKwh: performanceEstimateSummary.hddEstimate.proposed.demandHotWaterKwh, setPage: setPage });
    }
    if (page === 'CURRENT_CONSUMPTION') {
        return React.createElement(CurrentConsumptionPage, { isOffline: isOffline, allSynced: allSynced, existingSystemEfficiency: existingSystemEfficiency, spaceHeatingDemandKwh: performanceEstimateSummary.currentConsumptionEstimate.proposed.demandSpaceHeatingKwh, hotWaterDemandKwh: performanceEstimateSummary.currentConsumptionEstimate.proposed.demandHotWaterKwh, survey: survey, setSurvey: setSurvey, setPage: setPage });
    }
    if (page === 'FUEL_INPUTS') {
        return React.createElement(FuelInputsPage, { isOffline: isOffline, allSynced: allSynced, survey: survey, setSurvey: setSurvey, setPage: setPage, scopSpaceHeating: scopSpaceHeating, scopHotWater: scopHotWater, heatingFuel: existingFuel, heatingSystemEfficiency: existingSystemEfficiency });
    }
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Performance estimate', onBack: () => window.history.back() }),
        React.createElement("div", { className: 'p-5 flex flex-col gap-6 overflow-y-auto' },
            survey.use_epc_performance
                ? ((_a = survey.epc_heating_kwh) !== null && _a !== void 0 ? _a : 0) > 0 && ((_b = survey.epc_hot_water_kwh) !== null && _b !== void 0 ? _b : 0) > 0
                    ? React.createElement(ClickableCard, { variant: 'WHITE', title: 'EPC', onClick: () => setPage('EPC') },
                        React.createElement("div", { className: 'overflow-x-auto p-2' },
                            React.createElement(TableLite, { columns: performanceEstimateColumns, rows: [performanceEstimateSummary.epcEstimate.existing, performanceEstimateSummary.epcEstimate.proposed, performanceEstimateSummary.epcEstimate.savings] })))
                    : React.createElement(ClickableCard, { title: '\u26A0 Please enter EPC annual demand data', onClick: () => setPage('EPC'), variant: 'YELLOW', border: true })
                : React.createElement(ClickableCard, { title: 'EPC not possible to obtain', onClick: () => setPage('EPC'), variant: 'PLACEHOLDER' }),
            React.createElement(ClickableCard, { variant: 'WHITE', title: 'Heat Loss & Degree Days', onClick: () => setPage('DEGREE_DAYS') },
                React.createElement("div", { className: 'overflow-x-auto p-2' },
                    React.createElement(TableLite, { columns: performanceEstimateColumns, rows: [performanceEstimateSummary.hddEstimate.existing, performanceEstimateSummary.hddEstimate.proposed, performanceEstimateSummary.hddEstimate.savings] }))),
            React.createElement(ClickableCard, { variant: 'WHITE', title: 'Based on their existing consumption', onClick: () => setPage('CURRENT_CONSUMPTION') },
                React.createElement("div", { className: 'overflow-x-auto p-2' },
                    React.createElement(TableLite, { columns: performanceEstimateColumns, rows: [performanceEstimateSummary.currentConsumptionEstimate.existing, performanceEstimateSummary.currentConsumptionEstimate.proposed, performanceEstimateSummary.currentConsumptionEstimate.savings] }))),
            React.createElement(ClickableCard, { variant: 'WHITE', title: 'Fuel inputs', onClick: () => setPage('FUEL_INPUTS') },
                React.createElement("div", { className: 'flex flex-col gap-4 text-sm' },
                    React.createElement("div", { className: 'flex flex-col gap-1' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Existing system"),
                        React.createElement("div", { className: 'text-gray-500' },
                            existingSystemEfficiency * 100,
                            "% Efficiency \u00B7 ",
                            existingSystemPencePerUnit,
                            " ",
                            existingFuel.penceUnit,
                            " \u00B7 ",
                            existingFuel.emissions_g_per_kwh,
                            " g/kWh")),
                    React.createElement("div", { className: 'flex flex-col gap-1' },
                        React.createElement("div", { className: 'font-bold text-gray-900' }, "Heat pump"),
                        React.createElement("div", { className: 'text-gray-500' },
                            (scopSpaceHeating * 100).toFixed(),
                            "% Efficiency \u00B7 ",
                            getElectricityPencePerKwh(survey.electricity_p_per_kwh_override),
                            " ",
                            electricity.penceUnit,
                            " \u00B7 ",
                            electricity.emissions_g_per_kwh,
                            " g/kWh"))))));
};
const EpcPage = ({ setPage, survey, setSurvey, postcode, isOffline, allSynced }) => {
    var _a, _b, _c, _d;
    const epcUrl = survey.epc_certificate_number ? `https://find-energy-certificate.service.gov.uk/energy-certificate/${survey.epc_certificate_number}` : `https://find-energy-certificate.service.gov.uk/find-a-certificate/search-by-postcode?postcode=${postcode}`;
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'EPC energy requirements', onBack: () => setPage('') }),
        React.createElement("div", { className: 'flex flex-col gap-6 p-5 overflow-y-auto' },
            React.createElement("div", { className: 'flex justify-between items-center gap-2' },
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, "Possible to obtain a valid EPC"),
                    React.createElement("div", { className: 'text-gray-500 text-sm' }, "Cases where this will not be possible include refurbishment & extension projects")),
                React.createElement(Toggle, { value: survey.use_epc_performance, setValue: () => setSurvey({ ...survey, use_epc_performance: !survey.use_epc_performance }) })),
            survey.use_epc_performance && React.createElement(React.Fragment, null,
                React.createElement(Button, { colour: 'LIGHT', onClick: () => openInNewTab(epcUrl), iconLeft: ExternalLink }, "Find energy performance certificate"),
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "EPC certificate number"),
                    React.createElement(Input, { value: survey.epc_certificate_number, setValue: (e) => setSurvey({ ...survey, epc_certificate_number: e }) })),
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Heating energy required"),
                    React.createElement(Input, { postfix: 'kWh', type: 'number', value: (_b = (_a = survey.epc_heating_kwh) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '', setValue: (e) => setSurvey({ ...survey, epc_heating_kwh: parseFloat(e) }) })),
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Hot water energy required"),
                    React.createElement(Input, { postfix: 'kWh', type: 'number', value: (_d = (_c = survey.epc_hot_water_kwh) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '', setValue: (e) => setSurvey({ ...survey, epc_hot_water_kwh: parseFloat(e) }) })))));
};
const HeatLossDegreeDaysPage = ({ setPage, degreeDays, spaceHeatingDemandKwh, hotWaterDemandKwh, heatLossWattsPerKelvin, isOffline, allSynced }) => {
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Heat loss & degree days', onBack: () => setPage('') }),
        React.createElement("div", { className: 'flex flex-col gap-6 p-5 overflow-y-auto' },
            React.createElement("div", { className: 'text-gray-500' }, "Heating degree day data is based on the property's postcode."),
            React.createElement("div", null,
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heat loss per \u00B0C"),
                React.createElement("div", null,
                    heatLossWattsPerKelvin.toFixed(0),
                    " W/\u00B0C")),
            React.createElement("div", null,
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heating degree days"),
                React.createElement("div", null,
                    numberFormat(0).format(degreeDays),
                    " \u00B0C days")),
            React.createElement("div", null,
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heating energy required"),
                React.createElement("div", null, spaceHeatingDemandKwh)),
            React.createElement("div", null,
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Hot water energy required"),
                React.createElement("div", null, hotWaterDemandKwh))));
};
const CurrentConsumptionPage = ({ spaceHeatingDemandKwh, hotWaterDemandKwh, existingSystemEfficiency, survey, setSurvey, setPage, isOffline, allSynced }) => {
    const heatingFuel = getHeatingFuel(survey.existing_system_fuel_uuid);
    const consumptionFuelUnits = survey.existing_system_annual_consumption_kWh > 0 ? Math.round(survey.existing_system_annual_consumption_kWh / heatingFuel.kWh_per_unit) : '';
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Based on your existing consumption', onBack: () => setPage('') }),
        React.createElement("div", { className: 'flex flex-col gap-6 p-5 overflow-y-auto' },
            React.createElement("div", { className: 'text-gray-500' }, "The property's existing consumption and heating system efficiency gives another way to estimate how much heat the property actually uses."),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" },
                    heatingFuel.name,
                    " annual consumption"),
                React.createElement("div", { className: "text-gray-500 text-sm" }, "E.g. from a recent bill or from the smart meter in-home display"),
                React.createElement(Input, { step: 0.01, type: 'number', value: consumptionFuelUnits.toString(), setValue: (e) => setSurvey({
                        ...survey,
                        existing_system_annual_consumption_kWh: Number(e) * heatingFuel.kWh_per_unit
                    }), postfix: heatingFuel.unit })),
            React.createElement("div", null,
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Existing system efficiency"),
                React.createElement("div", null,
                    existingSystemEfficiency * 100,
                    " %")),
            React.createElement("div", null,
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Heating energy required"),
                React.createElement("div", null, spaceHeatingDemandKwh)),
            React.createElement("div", null,
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Hot water energy required"),
                React.createElement("div", null, hotWaterDemandKwh))));
};
export const FuelInputsPage = ({ survey, setSurvey, setPage, scopSpaceHeating, scopHotWater, heatingFuel, heatingSystemEfficiency, isOffline, allSynced }) => {
    const electricity = HEATING_FUELS.find(x => x.uuid === 'electricity');
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(PageHeader, { isOffline: isOffline, allSynced: allSynced, title: 'Fuel inputs', onBack: () => setPage('') }),
        React.createElement("div", { className: 'flex flex-col gap-6 p-5 overflow-y-auto' },
            React.createElement("div", { className: 'font-bold text-gray-900 text-xl' }, "Existing system"),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Efficiency")),
                React.createElement("div", { className: "text-gray-600" },
                    heatingSystemEfficiency * 100,
                    "%")),
            React.createElement("div", { className: 'flex flex-col gap-2' }, heatingFuel.uuid !== 'electricity' ? React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'font-bold text-gray-900' },
                    heatingFuel.name,
                    " tariff"),
                React.createElement(Input, { step: 0.01, type: 'number', value: getHeatingPencePerUnit(heatingFuel, survey.existing_system_p_per_unit_override).toString(), setValue: (e) => setSurvey({ ...survey, existing_system_p_per_unit_override: parseFloat(e) }), postfix: React.createElement("div", { className: 'flex items-center gap-2.5' },
                        React.createElement("div", null, heatingFuel.penceUnit),
                        React.createElement("div", null, defaultTariffIsOverridden(heatingFuel, survey.existing_system_p_per_unit_override) &&
                            React.createElement(Icon, { icon: XCircle, onClick: () => setSurvey(({ ...survey, existing_system_p_per_unit_override: undefined })), colour: 'text-gray-400' }))) })) : React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: "text-gray-900 font-bold" },
                        heatingFuel.name,
                        " tariff")),
                React.createElement("div", { className: "text-gray-600" },
                    getElectricityPencePerKwh(survey.electricity_p_per_kwh_override),
                    " ",
                    electricity.penceUnit))),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Emissions"),
                React.createElement("div", null,
                    heatingFuel.emissions_g_per_kwh,
                    " g/kWh")),
            React.createElement("div", { className: 'border border-gray-200' }),
            React.createElement("div", { className: 'font-bold text-gray-900 text-xl' }, "Heat pump"),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Efficiency"),
                React.createElement("div", null,
                    "Heating ",
                    (scopSpaceHeating * 100).toFixed(),
                    " %"),
                React.createElement("div", null,
                    "Hot water ",
                    scopHotWater * 100,
                    " %")),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Electricity tariff"),
                React.createElement(Input, { step: 0.01, type: 'number', value: getElectricityPencePerKwh(survey.electricity_p_per_kwh_override).toString(), setValue: (e) => setSurvey({ ...survey, electricity_p_per_kwh_override: parseFloat(e) }), postfix: React.createElement("div", { className: 'flex items-center gap-2.5' },
                        React.createElement("div", null, electricity === null || electricity === void 0 ? void 0 : electricity.penceUnit),
                        React.createElement("div", null, defaultTariffIsOverridden(electricity, survey.electricity_p_per_kwh_override) &&
                            React.createElement(Icon, { icon: XCircle, onClick: () => setSurvey(({ ...survey, electricity_p_per_kwh_override: undefined })), colour: 'text-gray-400' }))) })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Emissions"),
                React.createElement("div", null, electricity === null || electricity === void 0 ? void 0 :
                    electricity.emissions_g_per_kwh,
                    " g/kWh"))));
};
