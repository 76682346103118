import React, { useContext } from 'react';
import { Section } from '../../components/section';
import { Text } from '../../../../../components/content_display/text';
import { formatPrice } from '../../../../../code/format_price';
import { SummaryTable } from '../../../../../components/content_display/summary_table';
import { Button } from '../../../../../components/buttons/button';
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../../../../../code/calculate_estimate';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { CalculatedQuoteDefaultGroups, calculateQuote, quoteItemNameWithQuantity } from '../../../../../code/calculate_quote';
import { AdminContext } from '../../../admin_layout';
import { groupBy, sum } from 'lodash';
import { getApproxNumberOfRadiatorChanges } from '../../../../../code/models/estimated_rooms_and_radiators';
import { AlertTriangle, Edit } from 'lucide-react';
export const CostEstimate = ({ lead, estimate, navigateTo, companyPublicInfo }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const adminContext = useContext(AdminContext);
    const [calculatedQuote] = calculateQuote({
        company: adminContext.data.company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, (_b = (_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.estimate_default_radiator_change_percentage) !== null && _b !== void 0 ? _b : DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    const groupedCalculatedQuote = groupBy(calculatedQuote, 'group_name');
    const quoteHeatPump = (_c = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HEAT_PUMPS]) === null || _c === void 0 ? void 0 : _c[0];
    const quoteHotWaterCylinder = (_d = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]) === null || _d === void 0 ? void 0 : _d[0];
    const systemDesignRows = [
        { key: 'Heat pump', value: ((_e = estimate.heatPump) === null || _e === void 0 ? void 0 : _e.name) || React.createElement(Badge, { color: "RED", text: "No heat pump selected", icon: AlertTriangle }) },
        { key: 'Cylinder', value: ((_f = estimate.hotWaterCylinder) === null || _f === void 0 ? void 0 : _f.name) || React.createElement(Badge, { color: "RED", text: "No hot water cylinder selected", icon: AlertTriangle }) },
        { key: 'Flow temperature (°C)', value: estimate.flowTempC },
        // { key: 'Radiator changes', value: estimate.radiator_changes },
        { key: `SCOP at ${estimate.flowTempC}°C`, value: estimate.sCOP }
    ];
    const costBreakdownRows = [
        ...(quoteHeatPump ? [{ key: quoteItemNameWithQuantity(quoteHeatPump), value: formatPrice(quoteHeatPump.subtotal) }] : []),
        ...(quoteHotWaterCylinder ? [{ key: quoteItemNameWithQuantity(quoteHotWaterCylinder), value: formatPrice(quoteHotWaterCylinder.subtotal) }] : []),
        ...((_g = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.PARTS]) === null || _g === void 0 ? void 0 : _g.map(part => ({ key: quoteItemNameWithQuantity(part), value: formatPrice(part.subtotal) }))) || [],
        ...((_h = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.RADIATORS]) === null || _h === void 0 ? void 0 : _h.map(radiator => ({ key: quoteItemNameWithQuantity(radiator), value: formatPrice(radiator.subtotal) }))) || [],
        ...((_j = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR]) === null || _j === void 0 ? void 0 : _j.map(ufh => ({ key: quoteItemNameWithQuantity(ufh, 'm²'), value: formatPrice(ufh.subtotal) }))) || [],
        ...((_k = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.LABOUR]) === null || _k === void 0 ? void 0 : _k.map(labour => ({ key: quoteItemNameWithQuantity(labour, 'days'), value: formatPrice(labour.subtotal) }))) || [],
        ...((_l = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.SURVEY]) === null || _l === void 0 ? void 0 : _l.map(survey => { var _a; return ({ key: quoteItemNameWithQuantity(survey), value: formatPrice(((_a = survey.subtotal) !== null && _a !== void 0 ? _a : 0) * 1.2) }); })) || [],
        ...(((_m = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]) === null || _m === void 0 ? void 0 : _m.some(grant => grant.selected)) ? ((_o = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]) === null || _o === void 0 ? void 0 : _o.map(grant => ({ key: quoteItemNameWithQuantity(grant), value: formatPrice(grant.subtotal) }))) || [] : [])
    ];
    const handleEditCostEstimate = () => {
        const url = `/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}/estimate/cost-estimate`;
        navigateTo(url);
    };
    return (React.createElement(Section, { title: "Cost estimate", controls: React.createElement(Button, { size: "SM", iconLeft: Edit, colour: 'LIGHT', onClick: handleEditCostEstimate }, "Edit cost estimate") },
        React.createElement("div", { className: 'flex flex-col gap-5' },
            React.createElement("div", { className: 'flex items-center justify-between' },
                React.createElement(Text, { bold: true }, "Estimate total"),
                React.createElement(Text, { bold: true }, formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0))))),
            React.createElement("div", null,
                React.createElement(Text, { bold: true, className: 'mb-1' }, "System design"),
                React.createElement(SummaryTable, { rows: systemDesignRows })),
            React.createElement("div", null,
                React.createElement(Text, { bold: true, className: 'mb-1' }, "Cost breakdown"),
                React.createElement(SummaryTable, { rows: costBreakdownRows }),
                (estimate.isScottish && lead.scottish_rural) && React.createElement(Alert, { className: "mt-3", type: 'SUCCESS' }, "This property is likely to be eligible for the HES rural uplift.")))));
};
