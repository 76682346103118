import React, { useContext } from 'react';
import { ArrowLeftIcon } from 'lucide-react';
import { SurveyActionType } from '../../../code/survey/survey';
import { SurveyDispatchContext } from '../survey_page';
import { Button } from '../../../components/buttons/button';
export const QuestionnaireNavigateBack = () => {
    const dispatch = useContext(SurveyDispatchContext);
    const handleNavigatePreviousPage = () => {
        dispatch({
            type: SurveyActionType.NavigatePreviousPage
        });
    };
    return React.createElement(Button, { colour: 'LIGHT', onClick: handleNavigatePreviousPage, block: false, className: "self-start" },
        React.createElement("div", { className: "flex items-center gap-x-2" },
            React.createElement(ArrowLeftIcon, { className: "h-4 w-4" }),
            React.createElement("div", null, "Back")));
};
