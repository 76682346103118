import React, { useEffect, useRef, useState } from 'react';
import { Accordion } from '../components/content_display/accordion';
import { Button } from '../components/buttons/button';
import { getCompanyBookingConfirmationActionEmailTemplate, getCompanyBookingConfirmationNoActionEmailTemplate, getCompanyEstimateCoverNote } from '../code/models/company';
import { getCalculatedEstimate, sendCallback, sendRejected, sendSurvey, updateCalculatedEstimate } from '../code/models/calculated_estimate';
import { checkIfEligibleForBusGrant, getCommutesSaved, getFlightsSaved } from '../code/calculate_estimate';
import { numberFormat } from '../code/number_format';
import { LogoHeader } from './survey/components/logo_header';
import { faqRows } from './survey/steps/get_contacts_step';
import { checkIfScottish } from '../code/models/address';
import { Link } from '../components/buttons/link';
import { logEvent } from '../code/log_event';
import { formatPrice } from '../code/format_price';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, TTPlaceholderCustomerName } from '../code/tiptap_placeholders';
import { openInNewTab } from '../code/helpers';
import { Text } from '../components/content_display/text';
import { Heading } from '../components/content_display/heading';
import { Icon } from '../components/buttons/icon';
import { Modal } from '../components/containers/modal';
import { RadioGroup } from '../components/inputs_and_selections/radio';
import { Info } from '../components/buttons/info';
import { Emoji } from '../components/content_display/emoji';
import { TextArea } from '../components/inputs_and_selections/text_area';
import { SummaryTable } from '../components/content_display/summary_table';
import { Alert } from '../components/indicators_and_messaging/alert';
import { Card } from '../components/content_display/card';
import { NEXT_STEPS_ENGLAND_WALES, NEXT_STEPS_SCOTLAND } from './admin/settings_page/estimate_settings/blocks/estimate_next_steps_block';
import { DEFAULT_BRAND_COLOUR_PRIMARY, DEFAULT_BRAND_COLOUR_SECONDARY, determineBestTextShade } from '../code/utils/colour_utils';
import { CalculatedQuoteDefaultGroups, quoteItemNameWithQuantity } from '../code/calculate_quote';
import { groupBy, sum, lowerCase } from 'lodash';
import { ArrowDown, CheckCircle } from 'lucide-react';
import { LeadStatusSchema } from '../code/models/lead';
import { createJobEvent, JobEventType } from '../code/models/job_event';
export const EstimatePage = ({ estimateId, companyPublicInfo }) => {
    const isInstallerClick = new URLSearchParams(window.location.search).get('installer');
    const [estimate, setEstimate] = useState();
    const [isScottish, setIsScottish] = useState(false);
    const [grantEligible, setGrantEligible] = useState(false);
    useEffect(() => {
        const asyncCall = async () => {
            var _a, _b;
            const estimate = await getCalculatedEstimate(estimateId, companyPublicInfo.uuid);
            if (estimate && ((estimate === null || estimate === void 0 ? void 0 : estimate.status) === 'Sent' || !(estimate === null || estimate === void 0 ? void 0 : estimate.status)) && !isInstallerClick) {
                await updateCalculatedEstimate({ ...estimate, status: 'Opened' }, companyPublicInfo.subdomain);
                createJobEvent({
                    event_type: JobEventType.enum.EstimateOpened,
                    job_uuid: estimate.lead_uuid,
                    company_uuid: companyPublicInfo.uuid,
                    extra_data: {
                        calculated_estimate_uuid: estimate.uuid
                    }
                });
            }
            setEstimate(estimate);
            setIsScottish(checkIfScottish(estimate.postcode));
            if (isScottish || !estimate.lmk_key)
                return;
            const epc = estimate === null || estimate === void 0 ? void 0 : estimate.epc;
            const inspectionDate = (epc === null || epc === void 0 ? void 0 : epc.inspectionDate) ? new Date(epc === null || epc === void 0 ? void 0 : epc.inspectionDate) : undefined;
            // the epc_recommendations might be not stored in the Lead
            // if installer have sent the estimate before our release to store the epc_recommendations in the Lead
            // in that case we just don't show the green box on the page
            if ((_a = estimate.lead) === null || _a === void 0 ? void 0 : _a.epc_recommendations) {
                const grantEligibleReasons = checkIfEligibleForBusGrant(inspectionDate, (_b = estimate.lead) === null || _b === void 0 ? void 0 : _b.epc_recommendations);
                setGrantEligible(isScottish || grantEligibleReasons.every(x => x.hasPassed));
            }
        };
        if (estimateId)
            asyncCall();
    }, [estimateId]);
    if (!estimate)
        return;
    return React.createElement(EstimatePageInner, { estimate: estimate, isScottish: isScottish, grantEligible: grantEligible, companyPublicInfo: companyPublicInfo });
};
const ESTIMATE_CARD_COLORS = {
    WHITE: 'bg-white',
    GREY: 'bg-slate-50',
    YELLOW: 'bg-yellow-100',
    GREEN: 'bg-green-100',
    BRIGHT_GREEN: 'bg-green-600'
};
const ESTIMATE_CARD_PADDINGS = {
    XS: 'p-2',
    SM: 'p-3',
    MD: 'p-4',
    LG: 'p-6'
};
// Component used for the cards on this page
export const EstimateCard = ({ color, size = 'MD', className, children }) => {
    return React.createElement("div", { className: `border border-gray-200 rounded-lg flex flex-col ${ESTIMATE_CARD_COLORS[color]} ${ESTIMATE_CARD_PADDINGS[size]} ${className}` }, children);
};
// A version of the estimate card that is styled to look more like a tag
const TagCard = ({ color, children, className }) => {
    return React.createElement("div", { className: `rounded flex justify-between ${ESTIMATE_CARD_COLORS[color]} p-2 ${className}` }, children);
};
// Shared between the modals on this page
const ModalConfirmationMessage = ({ heading, text }) => {
    return (React.createElement("div", { className: "bg-green-50 rounded-lg flex-col justify-center items-center text-center gap-2 flex px-6 py-20 w-full" },
        React.createElement(Icon, { icon: CheckCircle, colour: "text-green-600 text-3xl" }),
        React.createElement(Heading, { size: "lg" }, heading),
        React.createElement(Text, null, text)));
};
const NotInterestedModal = ({ visible, setVisible, onConfirm }) => {
    const [reason, setReason] = useState();
    const [otherReason, setOtherReason] = useState();
    const [otherReasonFieldVisible, setOtherReasonFieldVisible] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const variant = (name) => reason === name ? 'ACTIVE' : 'DEFAULT';
    const reasons = [
        'Estimates are too high',
        'Estimated savings not enough',
        'Already gone with another installer',
        'Boiler fixed/replaced',
        'Don’t know enough to take the next step'
    ];
    const handleConfirm = () => {
        var _a;
        if (!reason && !otherReason)
            return;
        onConfirm((_a = otherReason !== null && otherReason !== void 0 ? otherReason : reason) !== null && _a !== void 0 ? _a : '');
        setShowConfirmation(true);
    };
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: "I\u2019m not interested right now", onConfirm: showConfirmation ? undefined : handleConfirm, hideOnConfirm: false, confirmDisabled: !reason && !otherReason }, showConfirmation ? React.createElement(ModalConfirmationMessage, { heading: "Response received", text: "Thanks! We appreciate your feedback." })
        : (React.createElement("div", { className: "flex flex-col gap-5 w-full" },
            React.createElement(Text, null, "If you\u2019re now not interested then we would love to know the reason why."),
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement(Heading, { size: "lg" }, "Select your reason"),
                React.createElement(RadioGroup, { items: [
                        ...reasons.map((name) => ({ name, onClick: () => { setReason(name); setOtherReasonFieldVisible(false); }, variant: variant(name) })),
                        { name: 'Other', onClick: () => { setOtherReasonFieldVisible(true); setReason('Other'); }, variant: variant('Other') }
                    ], isVertical: true })),
            otherReasonFieldVisible && React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement(Heading, { size: "lg" }, "Tell us why"),
                React.createElement(Text, null, "Briefly explain why you are no longer interested."),
                React.createElement(TextArea, { value: otherReason, setValue: setOtherReason }))))));
};
const RequestCallbackModal = ({ visible, setVisible, onConfirm }) => {
    const [discussionContent, setDiscussionContent] = useState();
    const [callTime, setCallTime] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleConfirm = () => {
        if (!discussionContent && !callTime)
            return;
        onConfirm(discussionContent, callTime);
        setShowConfirmation(true);
    };
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: "Request a callback", onConfirm: showConfirmation ? undefined : handleConfirm, hideOnConfirm: false, confirmDisabled: !discussionContent && !callTime }, showConfirmation ? React.createElement(ModalConfirmationMessage, { heading: "Callback request sent", text: "Thanks! We will review your request and be in touch via email to schedule a call to discuss your query in more detail." })
        : (React.createElement("div", { className: "flex flex-col gap-5" },
            React.createElement("div", { className: "flex flex-col gap-1" },
                React.createElement(Text, null, "We\u2019d love to walk you through our recommendations in more details and respond to any questions or concerns you may have."),
                React.createElement(Text, null, "Please tell us what you\u2019d like to discuss and when would be a good time to call below.")),
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement(Heading, { size: "lg" }, "What would you like to discuss?"),
                React.createElement(Text, null, "Briefly summarise the questions you have, or the topics you\u2019d like to cover."),
                React.createElement("textarea", { className: 'border border-medium p-3 rounded-lg resize-none focus:outline-2 focus:outline-accent', value: discussionContent, onChange: (e) => setDiscussionContent(e.target.value) })),
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement(Heading, { size: "lg" }, "When would be a good time to call?"),
                React.createElement(Text, null, "Tell us when you will be available."),
                React.createElement("textarea", { className: 'border border-medium p-3 rounded-lg resize-none focus:outline-2 focus:outline-accent', value: callTime, onChange: (e) => setCallTime(e.target.value) }))))));
};
// Request a survey modal is currently just the confirmation, no functionality
const RequestSurveyModal = ({ visible, setVisible }) => {
    return (React.createElement(Modal, { visible: visible, setVisible: setVisible, title: "Book your survey", confirmButtonLabel: "Request survey" },
        React.createElement(ModalConfirmationMessage, { heading: "Survey request sent", text: "Thanks! We will review your request and be in touch via email to schedule your survey." })));
};
const NextSteps = ({ isScottish, companyPublicInfo }) => {
    let steps;
    if (isScottish) {
        steps = companyPublicInfo.next_steps_scottish ? JSON.parse(companyPublicInfo.next_steps_scottish) : NEXT_STEPS_SCOTLAND;
    }
    else {
        steps = companyPublicInfo.next_steps ? JSON.parse(companyPublicInfo.next_steps) : NEXT_STEPS_ENGLAND_WALES;
    }
    return (React.createElement("div", { className: 'grid lg:grid-cols-3 gap-5 w-full max-w-screen-2xl' }, steps.map((step, index) => (React.createElement(EstimateCard, { key: index, color: "WHITE", className: "gap-4 shadow" },
        React.createElement("div", { className: "grid grid-cols-[32px_1fr] gap-2" },
            React.createElement("div", null,
                React.createElement(Heading, { size: "3xl" }, `${index + 1}.`)),
            React.createElement("div", null,
                React.createElement(Text, { bold: true }, step.title),
                React.createElement(Text, { size: "SM" }, step.description))))))));
};
export const ContactCardWrapper = ({ hasContent, children }) => {
    if (hasContent) {
        return React.createElement(Card, { color: 'LIGHT', className: 'bg-slate-50 flex flex-col gap-4' }, children);
    }
    else {
        return React.createElement("div", null, children);
    }
};
const BILL_SAVINGS_EMOJI = React.createElement(Emoji, { emoji: '\uD83D\uDCB0' });
const CARBON_SAVINGS_EMOJI = React.createElement(Emoji, { emoji: '\u2708\uFE0F' });
const INFO_POPUPS = {
    heatLoss: {
        title: 'Heat loss explained',
        content: 'This shows how fast your home loses heat in cold weather. Your heating system needs to keep up with this loss to keep your home warm and comfortable.'
    },
    carbonSavings: (estimate) => ({
        title: 'Estimated carbon savings',
        content: `With the projected decarbonization of the grid, you could lower your carbon footprint by up to ${numberFormat(0).format(estimate.co2_saved_kg)}kg of CO2 each year. That’s like avoiding ${getFlightsSaved(estimate.co2_saved_kg)} flights to Spain or ${getCommutesSaved(estimate.co2_saved_kg)} daily commutes to work.`
    }),
    billSavings: (billSavingsInfoContent, homeownerFacing = true) => {
        var _a, _b, _c;
        return ({
            title: 'Potential bill savings',
            content: React.createElement("div", { className: 'space-y-3' },
                homeownerFacing && React.createElement("div", null, "Estimating your running costs with a heat pump can be tricky since it depends on many factors, some beyond our control."),
                React.createElement("div", null,
                    "This estimate is based on typical usage and weather patterns, and assumes you'll switch to a specialised heat pump tariff, which offers lower electricity rates. Providers like OVO, EDF, and Octopus offer heat pump tariffs\u2014check ",
                    React.createElement(Link, { onClick: () => openInNewTab('https://spruce-energy.notion.site/Heat-pump-tariffs-569d003c41014e8b9e519b1e11ce7b66'), className: 'inline', text: 'this page' }),
                    " for the latest options. "),
                billSavingsInfoContent.annual_bill_hp_tariff_gbp && billSavingsInfoContent.annual_bill_baseline_gbp && billSavingsInfoContent.annual_kwh_heat_required && billSavingsInfoContent.average_efficiency_baseline && billSavingsInfoContent.average_efficiency_hp && React.createElement("div", null,
                    "The calculations behind this estimate are as follows:",
                    React.createElement("ul", { className: 'pl-5 mt-3 space-y-3' },
                        React.createElement("li", null,
                            React.createElement("span", { className: 'font-semibold' }, "Existing system"),
                            React.createElement("ul", { className: 'list-disc list-inside pl-5' },
                                React.createElement("li", null,
                                    numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required),
                                    " kWh of heat needed for heating and hot water"),
                                React.createElement("li", null,
                                    billSavingsInfoContent.average_efficiency_baseline ? (billSavingsInfoContent.average_efficiency_baseline * 100).toFixed(0) : null,
                                    "% assumed efficiency"),
                                React.createElement("li", null,
                                    " \u2192 ",
                                    numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required / billSavingsInfoContent.average_efficiency_baseline),
                                    " kWh of ",
                                    lowerCase(billSavingsInfoContent.fuel_name_baseline)),
                                React.createElement("li", null, (_a = billSavingsInfoContent.tariff_p_per_kwh_baseline) === null || _a === void 0 ? void 0 :
                                    _a.toFixed(2),
                                    " p/kWh for ",
                                    lowerCase(billSavingsInfoContent.fuel_name_baseline)),
                                React.createElement("li", { className: 'font-semibold' },
                                    " \u2192 \u00A3",
                                    numberFormat(0).format(billSavingsInfoContent.annual_bill_baseline_gbp),
                                    " for heating and hot water (",
                                    billSavingsInfoContent.tariff_p_per_kwh_baseline && billSavingsInfoContent.average_efficiency_baseline ? numberFormat(2).format(billSavingsInfoContent.tariff_p_per_kwh_baseline / billSavingsInfoContent.average_efficiency_baseline) : null,
                                    " p/kWh of heat)"))),
                        React.createElement("li", null,
                            React.createElement("span", { className: 'font-semibold' }, "Heat pump - with a heat pump tariff"),
                            React.createElement("ul", { className: 'list-disc list-inside pl-5' },
                                React.createElement("li", null,
                                    numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required),
                                    " kWh of heat needed for heating and hot water"),
                                React.createElement("li", null,
                                    (billSavingsInfoContent.average_efficiency_hp * 100).toFixed(0),
                                    "% predicted average efficiency"),
                                React.createElement("li", null,
                                    " \u2192 ",
                                    numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required / billSavingsInfoContent.average_efficiency_hp),
                                    " kWh of electricity"),
                                React.createElement("li", null, (_b = billSavingsInfoContent.tariff_p_per_kwh_electricity_hp_tariff) === null || _b === void 0 ? void 0 :
                                    _b.toFixed(2),
                                    " p/kWh for electricity (",
                                    billSavingsInfoContent.electric_hp_tariff_based_on,
                                    ")"),
                                React.createElement("li", { className: 'font-semibold' },
                                    " \u2192 \u00A3",
                                    numberFormat(0).format(billSavingsInfoContent.annual_bill_hp_tariff_gbp),
                                    " for heating and hot water (",
                                    billSavingsInfoContent.tariff_p_per_kwh_electricity_hp_tariff ? numberFormat(2).format(billSavingsInfoContent.tariff_p_per_kwh_electricity_hp_tariff / billSavingsInfoContent.average_efficiency_hp) : null,
                                    " p/kWh of heat)"))),
                        billSavingsInfoContent.annual_bill_hp_price_cap_gbp && billSavingsInfoContent.tariff_p_per_kwh_electricity_price_cap && React.createElement("li", null,
                            React.createElement("span", { className: 'font-semibold' }, "Heat pump - with the price cap"),
                            React.createElement("ul", { className: 'list-disc list-inside pl-5' },
                                React.createElement("li", null,
                                    numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required),
                                    " kWh of heat needed for heating and hot water"),
                                React.createElement("li", null,
                                    (billSavingsInfoContent.average_efficiency_hp * 100).toFixed(0),
                                    "% predicted average efficiency"),
                                React.createElement("li", null,
                                    " \u2192 ",
                                    numberFormat(0).format(billSavingsInfoContent.annual_kwh_heat_required / billSavingsInfoContent.average_efficiency_hp),
                                    " kWh of electricity"),
                                React.createElement("li", null, (_c = billSavingsInfoContent.tariff_p_per_kwh_electricity_price_cap) === null || _c === void 0 ? void 0 :
                                    _c.toFixed(2),
                                    " p/kWh for electricity (",
                                    billSavingsInfoContent.price_cap_description,
                                    ")"),
                                React.createElement("li", { className: 'font-semibold' },
                                    " \u2192 \u00A3",
                                    numberFormat(0).format(billSavingsInfoContent.annual_bill_hp_price_cap_gbp),
                                    " for heating and hot water (",
                                    billSavingsInfoContent.tariff_p_per_kwh_electricity_hp_tariff ? numberFormat(2).format(billSavingsInfoContent.tariff_p_per_kwh_electricity_price_cap / billSavingsInfoContent.average_efficiency_hp) : null,
                                    " p/kWh of heat)"))))),
                React.createElement("div", null,
                    " The heat pump efficiency values used are based on sCOP data for the proposed heat pump (",
                    billSavingsInfoContent.heat_pump_name,
                    ") at the proposed flow temperature (",
                    billSavingsInfoContent.flow_temperature_c,
                    "\u00B0C). They account for a lower heat pump efficiency when making hot water due to the higher flow temperatures involved."),
                React.createElement(Alert, { type: "WARNING" }, "Note that these potential bill savings are not guaranteed \u2013 they're estimated based upon what we know about your property at this stage, with broad-brush assumptions about how you heat your home."))
        });
    },
    flowTemperature: {
        title: 'Flow temperature explained',
        content: React.createElement("div", { className: 'space-y-2' },
            React.createElement("div", null, "Flow temperature is the temperature of the water coming out of your heat pump and into your radiators."),
            React.createElement("div", null, "While heat pumps can produce very hot water, they operate most efficiently at lower temperatures, typically between 35-50\u00B0C. In contrast, gas boilers have traditionally been set to produce water at higher temperatures, around 70-80\u00B0C, although they also work more efficiently at lower temperatures."),
            React.createElement("div", null, "This is why it\u2019s best to run a heat pump system consistently at a lower flow temperature (\u2018low and slow\u2019) rather than in short, powerful bursts."))
    },
    busGrant: {
        title: 'BUS grant eligibility',
        content: React.createElement("div", { className: 'space-y-2' },
            React.createElement("div", null,
                "Based on the information you\u2019ve provided we believe you will be eligible for the ",
                React.createElement("a", { href: "https://www.gov.uk/apply-boiler-upgrade-scheme/check-if-youre-eligible", target: "_blank", className: "text-sky-800 font-bold underline", rel: "noreferrer" }, "Boiler Upgrade Scheme (BUS)"),
                "."),
            React.createElement("div", null, "You and your property must meet the following criteria:"),
            React.createElement("div", { className: 'text-accent font-bold' }, "You must:"),
            React.createElement("div", { className: 'space-y-2 text-gray-600' },
                React.createElement("div", { className: 'flex gap-2 items-top' },
                    React.createElement(Icon, { icon: CheckCircle, colour: 'text-green-600', className: 'h-5' }),
                    React.createElement("div", null, "Own the property you are applying for.")),
                React.createElement("div", { className: 'flex gap-2 items-top' },
                    React.createElement(Icon, { icon: CheckCircle, colour: 'text-green-600', className: 'h-5' }),
                    React.createElement("div", null, "Be replacing fossil fuel heating systems."))),
            React.createElement("div", { className: 'text-accent font-bold' }, "Your property must:"),
            React.createElement("div", { className: 'space-y-2 text-gray-600' },
                React.createElement("div", { className: 'flex gap-2 items-top' },
                    React.createElement(Icon, { icon: CheckCircle, colour: 'text-green-600', className: 'h-5' }),
                    React.createElement("div", null, "Have an Energy Performance Certificate (EPC) that is under 10 years old."))))
    },
    hesGrant: {
        title: 'HES grant eligibility',
        content: React.createElement("div", { className: 'space-y-2' },
            React.createElement("div", null,
                "You may be eligible for a grant of up to \u00A37,500-\u00A39,000 from ",
                React.createElement("a", { href: "https://www.homeenergyscotland.org/home-energy-scotland-grant-loan", target: "_blank", className: "text-sky-800 font-bold underline", rel: "noreferrer" }, "Home Energy Scotland (HES)"),
                ", plus an interest free loan of up to \u00A37,500."),
            React.createElement("div", null, "You must follow the HES process to secure the funding, and meet the following criteria:"),
            React.createElement("div", { className: 'text-accent font-bold' }, "You must:"),
            React.createElement("div", { className: 'space-y-2 text-gray-600' },
                React.createElement("div", { className: 'flex gap-2 items-top' },
                    React.createElement(Icon, { icon: CheckCircle, colour: 'text-green-600', className: 'h-5' }),
                    React.createElement("div", null, "Own and live in the property you are applying for, and it must be your primary residence.")),
                React.createElement("div", { className: 'flex gap-2 items-top' },
                    React.createElement(Icon, { icon: CheckCircle, colour: 'text-green-600', className: 'h-5' }),
                    React.createElement("div", null, "Not be replacing an existing heat pump."))))
    },
    estimatedCost: {
        title: 'Estimated cost',
        content: "This is an initial estimate based on the information we have so far. We'll be able to provide a final quote after completing a room-by-room heat loss survey of your property."
    },
    systemDesign: {
        title: 'System design explained',
        content: React.createElement("div", { className: 'space-y-2' },
            React.createElement("div", null, "One of the most important factors in a successful heat pump installation is the design of the overall system."),
            React.createElement("div", { className: 'text-accent font-bold' }, "Flow temperature"),
            React.createElement("div", null, "This is the temperature of the water running through your radiators.  A lower flow temperature makes the heat pump run more efficiently, reducing your energy bills."),
            React.createElement("div", { className: 'text-accent font-bold' }, "Radiators"),
            React.createElement("div", null, "Lower flow temperatures increase efficiency but reduce the heat output from each radiator. To maintain comfort on cold days, some radiators may need to be upgraded to work effectively with lower flow temperatures."),
            React.createElement("div", { className: 'text-accent font-bold' }, "Cylinder"),
            React.createElement("div", null, "Also known as hot water tanks, cylinders are  required in heat pump systems to ensure continuous supply of hot water, similar to with traditional gas boilers."))
    },
    soundPower: {
        title: 'Sound power level explained',
        content: React.createElement("div", { className: 'space-y-2' },
            React.createElement("div", null, "Sound power level measures the total acoustic energy emitted by a noise source. The number shown here represents the maximum sound level the heat pump can produce. In normal operation, it will typically be quieter."),
            React.createElement("div", null, "Sound pressure level is what you actually hear and varies based on the location of the noise source and your distance from it. During the heat loss survey, we'll conduct a sound assessment to ensure the sound pressure level meets planning requirements."),
            React.createElement("div", { className: 'text-accent font-bold' }, "How do these values compare to everyday sounds?"),
            React.createElement("ul", { className: 'list-disc list-inside' },
                React.createElement("li", null, "0 dB: Threshold of hearing"),
                React.createElement("li", null, "40 dB: Very soft - Refrigerator humming"),
                React.createElement("li", null, "60 dB: Moderately loud - Normal conversation, restaurant"),
                React.createElement("li", null, "80 dB: Very loud - City traffic, lorry")))
    },
    efficiency: (estimate) => ({
        title: 'Efficiency explained',
        content: React.createElement("div", { className: 'space-y-2' },
            React.createElement("div", null, "This figure shows how much heat the heat pump generates compared to the electricity it uses in space heating mode."),
            React.createElement("div", { className: 'text-accent font-bold' }, "How can it be over 100% efficient?"),
            React.createElement("div", null, "Heat pumps work by capturing heat from the outdoor air and using a small amount of electricity to increase that heat's temperature. Essentially, they\u2019re harnessing free solar energy stored in the air!"),
            React.createElement("div", null,
                "An efficiency of ",
                Math.floor(estimate.scop * 100),
                "% means your heat pump delivers ",
                estimate.scop,
                " units of heat for every 1 unit of electricity, because it captures ",
                estimate.scop - 1,
                " units of heat from the air."),
            React.createElement("div", null, "For comparison, even the most modern gas boilers are around 90% efficient and can never reach the same efficiency levels as a heat pump."))
    }),
    radiatorChanges: {
        title: 'Estimated radiator changes explained',
        content: React.createElement("div", { className: 'space-y-2' },
            React.createElement("div", null, "Heat pumps operate at a lower flow temperature than boilers, so they need a larger radiator surface area to effectively heat your home. This means some radiators in your property may need to be upgraded to ensure the system runs efficiently."),
            React.createElement("div", { className: 'text-accent font-bold' }, "How is this estimated?"),
            React.createElement("div", null, "We\u2019ve made a rough estimate based on your property size to give you an idea of potential costs. However, we\u2019ll need to complete a full survey to determine the exact requirements."))
    }
};
export const BillSavingsInfo = ({ billSavingsInfoContent, homeownerFacing = true }) => {
    return React.createElement(Info, { infoModalHeader: INFO_POPUPS.billSavings(billSavingsInfoContent).title, infoModalBody: INFO_POPUPS.billSavings(billSavingsInfoContent, homeownerFacing).content });
};
export const EstimatePageInner = ({ estimate, isScottish, grantEligible, companyPublicInfo, isPreview }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12;
    const costEstimateHeadingRef = useRef(null);
    const [showNotInterestedModal, setShowNotInterestedModal] = useState(false);
    const [showRequestCallbackModal, setShowRequestCallbackModal] = useState(false);
    const [showRequestSurveyModal, setShowRequestSurveyModal] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(true);
    if (!estimate)
        return;
    // Only show heat loss button and text if english property and the installer allows survey booking
    const showRequestSurveyButton = !companyPublicInfo.no_survey_booking && !isScottish;
    // Currently, do not show bill savings
    const billSavings = (estimate.annual_bill_baseline_gbp && estimate.annual_bill_hp_tariff_gbp) ? estimate.annual_bill_baseline_gbp - estimate.annual_bill_hp_tariff_gbp : undefined;
    const showBillSavings = companyPublicInfo.show_bill_savings_on_estimate && billSavings && billSavings > 0 && estimate.range_heat_pump;
    // on Request Callback button click
    const handleRequestCallback = async (discussionContent, callTime) => {
        logEvent({ name: 'Request a callback clicked', properties: { discussionContent, callTime } }, companyPublicInfo.subdomain);
        await sendCallback(estimate.uuid, companyPublicInfo.uuid, discussionContent, callTime);
        createJobEvent({
            event_type: JobEventType.enum.EstimateCallbackRequested,
            job_uuid: estimate.lead_uuid,
            company_uuid: companyPublicInfo.uuid,
            extra_data: {
                calculated_estimate_uuid: estimate.uuid
            }
        });
    };
    // on Not Interested button click
    const handleNotInterested = async (reason) => {
        var _a, _b;
        logEvent({ name: 'Not interested clicked', properties: { reason } }, companyPublicInfo.subdomain);
        await sendRejected(estimate, companyPublicInfo.uuid, reason);
        await createJobEvent({
            event_type: JobEventType.enum.EstimateRejected,
            job_uuid: estimate.lead_uuid,
            company_uuid: companyPublicInfo.uuid,
            extra_data: {
                calculated_estimate_uuid: estimate.uuid
            }
        });
        if (((_a = estimate.lead) === null || _a === void 0 ? void 0 : _a.status) !== LeadStatusSchema.enum.Converted) {
            createJobEvent({
                event_type: JobEventType.enum.StatusChanged,
                job_uuid: estimate.lead_uuid,
                company_uuid: companyPublicInfo.uuid,
                extra_data: {
                    old_status: (_b = estimate.lead) === null || _b === void 0 ? void 0 : _b.status,
                    new_status: LeadStatusSchema.enum.Rejected
                }
            });
        }
    };
    // on Request Survey button click
    const handleRequestSurvey = async () => {
        var _a, _b, _c, _d;
        logEvent({ name: 'Request heat loss survey clicked', properties: {} }, companyPublicInfo.subdomain);
        let actionTemplateRendered;
        if (companyPublicInfo.booking_redirect_enabled) {
            actionTemplateRendered = renderHTMLReplacingPlaceholders(getCompanyBookingConfirmationActionEmailTemplate(companyPublicInfo.booking_confirmation_action_template), {
                ...defaultPlaceholderMappings(companyPublicInfo, estimate.sender_snapshot),
                [TTPlaceholderCustomerName.id]: (_a = estimate.name) !== null && _a !== void 0 ? _a : ''
            });
            openInNewTab(companyPublicInfo.booking_redirect_url);
        }
        else {
            actionTemplateRendered = renderHTMLReplacingPlaceholders(getCompanyBookingConfirmationNoActionEmailTemplate(companyPublicInfo.booking_confirmation_noaction_template), {
                ...defaultPlaceholderMappings(companyPublicInfo, estimate.sender_snapshot),
                [TTPlaceholderCustomerName.id]: (_b = estimate.name) !== null && _b !== void 0 ? _b : ''
            });
        }
        await sendSurvey(estimate, companyPublicInfo.uuid, actionTemplateRendered);
        if (!companyPublicInfo.booking_redirect_enabled) {
            setShowRequestSurveyModal(true);
        }
        await createJobEvent({
            event_type: JobEventType.enum.EstimateAccepted,
            job_uuid: estimate.lead_uuid,
            company_uuid: companyPublicInfo.uuid,
            extra_data: {
                calculated_estimate_uuid: estimate.uuid
            }
        });
        if (((_c = estimate.lead) === null || _c === void 0 ? void 0 : _c.status) !== LeadStatusSchema.enum.Converted) {
            createJobEvent({
                event_type: JobEventType.enum.StatusChanged,
                job_uuid: estimate.lead_uuid,
                company_uuid: companyPublicInfo.uuid,
                extra_data: {
                    old_status: (_d = estimate.lead) === null || _d === void 0 ? void 0 : _d.status,
                    new_status: LeadStatusSchema.enum.Converted
                }
            });
        }
    };
    const heatPumpHasDimensions = ((_a = estimate.range_heat_pump) === null || _a === void 0 ? void 0 : _a.width_mm) && ((_b = estimate.range_heat_pump) === null || _b === void 0 ? void 0 : _b.height_mm) && ((_c = estimate.range_heat_pump) === null || _c === void 0 ? void 0 : _c.depth_mm);
    const quoteItemsWithSubtotals = (_d = estimate.calculated_estimate_quote_items) === null || _d === void 0 ? void 0 : _d.map(item => ({
        ...item,
        subtotal: item.selected ? item.price * item.quantity : 0
    }));
    const groupedQuoteItems = groupBy(quoteItemsWithSubtotals, 'group_name');
    const quoteGrantEnabled = (_e = groupedQuoteItems[CalculatedQuoteDefaultGroups.GRANTS]) === null || _e === void 0 ? void 0 : _e.some(grant => grant.selected);
    const quoteGrant = sum((_f = groupedQuoteItems[CalculatedQuoteDefaultGroups.GRANTS]) === null || _f === void 0 ? void 0 : _f.map(x => x.selected ? x.price : 0));
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col" },
            React.createElement("div", { className: 'bg-white border-solid border-b border-gray-300 px-6 py-3 justify-between items-center flex flex-col sm:flex-row gap-2 md:sticky md:top-0 z-10' },
                React.createElement(LogoHeader, { companyPublicInfo: companyPublicInfo }),
                React.createElement("div", { className: 'hidden sm:flex gap-3' },
                    React.createElement(Button, { style: {
                            backgroundColor: companyPublicInfo.colour_primary,
                            borderColor: companyPublicInfo.colour_primary,
                            color: determineBestTextShade((_g = companyPublicInfo.colour_primary) !== null && _g !== void 0 ? _g : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                        }, onClick: () => { var _a; return (_a = costEstimateHeadingRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' }); } }, "View cost estimate"),
                    (!companyPublicInfo.no_request_callback || isScottish) && React.createElement(Button, { colour: 'LIGHT', onClick: () => setShowRequestCallbackModal(true), disabled: isPreview }, "Request a callback"))),
            React.createElement("div", { style: { backgroundColor: (_h = companyPublicInfo.colour_secondary) !== null && _h !== void 0 ? _h : DEFAULT_BRAND_COLOUR_SECONDARY }, className: "px-4 md:px-6 py-8 flex flex-col items-center gap-8 w-full" },
                React.createElement("div", { className: 'flex flex-col items-center gap-1 text-center max-w-screen-2xl' },
                    React.createElement(Text, { className: determineBestTextShade((_j = companyPublicInfo.colour_secondary) !== null && _j !== void 0 ? _j : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' },
                        estimate.address,
                        ", ",
                        estimate.postcode),
                    React.createElement(Heading, { className: determineBestTextShade((_k = companyPublicInfo.colour_secondary) !== null && _k !== void 0 ? _k : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white', size: "3xl" }, "Great news! Your property is suitable for a heat pump.")),
                React.createElement("div", { className: 'grid lg:grid-cols-2 gap-5 w-full max-w-screen-2xl' },
                    React.createElement("div", { className: 'flex flex-col gap-5' },
                        React.createElement(EstimateCard, { color: "WHITE", className: 'gap-5 shadow flex-1' },
                            React.createElement(Heading, { size: "lg" },
                                "Hi ",
                                estimate.name,
                                "!"),
                            React.createElement("div", { className: 'flex flex-col gap-2 text-sm', dangerouslySetInnerHTML: {
                                    __html: renderHTMLReplacingPlaceholders(getCompanyEstimateCoverNote((_m = (_l = estimate.cover_note) !== null && _l !== void 0 ? _l : companyPublicInfo.calculated_estimate_cover_note) !== null && _m !== void 0 ? _m : ''), {
                                        ...defaultPlaceholderMappings(companyPublicInfo, estimate.sender_snapshot)
                                    }, undefined, 'text-base text-bold font-bold')
                                } }),
                            React.createElement(ContactCardWrapper, { hasContent: Boolean(companyPublicInfo.estimate_contact_name || companyPublicInfo.estimate_contact_phone || companyPublicInfo.estimate_contact_role || companyPublicInfo.estimate_contact_portrait) },
                                React.createElement("div", { className: 'flex justify-start items-center pt-1 gap-4' },
                                    companyPublicInfo.estimate_contact_portrait && React.createElement("img", { src: companyPublicInfo.estimate_contact_portrait, alt: 'Estimate contact headshot', className: 'rounded-full w-20 h-20 object-cover' }),
                                    React.createElement("div", { className: 'flex flex-col gap-1' },
                                        React.createElement("div", null,
                                            React.createElement(Text, { bold: true, size: 'MD' }, companyPublicInfo.estimate_contact_name),
                                            React.createElement(Text, { size: 'SM' }, companyPublicInfo.estimate_contact_role)),
                                        React.createElement("a", { className: 'text-base text-sky-800 font-bold underline', href: `tel:${companyPublicInfo.estimate_contact_phone}` }, companyPublicInfo.estimate_contact_phone))),
                                React.createElement("div", { className: 'grid sm:grid-cols-2 gap-4 pb-1' },
                                    React.createElement(Button, { style: {
                                            backgroundColor: companyPublicInfo.colour_primary,
                                            borderColor: companyPublicInfo.colour_primary,
                                            color: determineBestTextShade((_o = companyPublicInfo.colour_primary) !== null && _o !== void 0 ? _o : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                                        }, className: (!companyPublicInfo.no_request_callback || isScottish) ? 'col-span-1' : 'col-span-full', onClick: () => { var _a; return (_a = costEstimateHeadingRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' }); } }, "View cost estimate"),
                                    (!companyPublicInfo.no_request_callback || isScottish) && React.createElement(Button, { colour: 'LIGHT', onClick: () => setShowRequestCallbackModal(true), disabled: isPreview }, "Request a callback")))),
                        React.createElement(EstimateCard, { color: "WHITE", className: 'gap-1 shadow flex-1' },
                            React.createElement(Heading, { size: "lg" }, "Did you know?"),
                            React.createElement(Text, { size: "SM" }, "Heat pumps are more than 100% efficient! That's possible because they use a small amount of electricity to capture a larger amount of free heat from outdoor air, and do this happily in temperatures well below 0\u00B0C."))),
                    React.createElement("div", { className: 'flex flex-col gap-5' },
                        React.createElement(EstimateCard, { color: "WHITE", className: 'gap-4 shadow flex-1' },
                            React.createElement(Heading, { size: "lg" }, "Estimate overview"),
                            React.createElement(EstimateCard, { size: "SM", color: "GREY", className: 'gap-1' },
                                React.createElement("div", { className: 'flex gap-1 items-center' },
                                    React.createElement(Text, { size: "SM" }, "Estimated cost of installation"),
                                    React.createElement(Info, { infoModalHeader: INFO_POPUPS.estimatedCost.title, infoModalBody: INFO_POPUPS.estimatedCost.content })),
                                React.createElement(Heading, { size: "xl" }, formatPrice(sum(quoteItemsWithSubtotals === null || quoteItemsWithSubtotals === void 0 ? void 0 : quoteItemsWithSubtotals.map(i => i.selected ? i.subtotal : 0))))),
                            React.createElement(EstimateCard, { size: "SM", color: "GREY", className: 'gap-1' },
                                React.createElement("div", { className: 'flex gap-1 items-center' },
                                    React.createElement(Text, { size: "SM" }, "Estimated total heat loss"),
                                    React.createElement(Info, { infoModalHeader: INFO_POPUPS.heatLoss.title, infoModalBody: INFO_POPUPS.heatLoss.content })),
                                React.createElement(Heading, { size: "xl" }, (estimate.total_watts / 1000).toFixed(1) + ' kW')),
                            ((isScottish || grantEligible) && quoteGrantEnabled) && React.createElement(EstimateCard, { size: 'SM', color: "GREY", className: 'gap-1' },
                                React.createElement("div", { className: 'flex gap-2 items-center' },
                                    React.createElement(Text, { size: "SM" }, "Predicted grant eligibility"),
                                    React.createElement(Info, { infoModalHeader: isScottish ? INFO_POPUPS.hesGrant.title : INFO_POPUPS.busGrant.title, infoModalBody: isScottish ? INFO_POPUPS.hesGrant.content : INFO_POPUPS.busGrant.content })),
                                React.createElement(Heading, { size: "xl" },
                                    React.createElement("div", { className: 'flex gap-1 items-center' },
                                        React.createElement(Icon, { icon: CheckCircle, colour: 'text-green-600' }),
                                        React.createElement("div", null, "Eligible"))))),
                        React.createElement(EstimateCard, { color: "WHITE", className: 'gap-4 shadow flex-1' },
                            React.createElement(Heading, { size: "lg" }, "What impact would it have?"),
                            showBillSavings && React.createElement(EstimateCard, { size: 'SM', color: "YELLOW", className: 'gap-1' },
                                React.createElement("div", { className: 'flex gap-1 items-center' },
                                    React.createElement(Text, { size: "SM" }, "Potential bill savings"),
                                    React.createElement(BillSavingsInfo, { billSavingsInfoContent: estimate })),
                                React.createElement(Heading, { size: "xl" }, React.createElement(React.Fragment, null,
                                    React.createElement("span", null,
                                        BILL_SAVINGS_EMOJI,
                                        " \u00A3",
                                        numberFormat(0).format(billSavings)),
                                    React.createElement("span", { className: 'text-base font-medium text-default' }, "/year")))),
                            React.createElement(EstimateCard, { size: 'SM', color: "GREEN", className: 'gap-1' },
                                React.createElement("div", { className: 'flex gap-1 items-center' },
                                    React.createElement(Text, { size: "SM" }, "Estimated carbon savings"),
                                    React.createElement(Info, { infoModalHeader: INFO_POPUPS.carbonSavings(estimate).title, infoModalBody: INFO_POPUPS.carbonSavings(estimate).content })),
                                React.createElement(Heading, { size: "xl" }, React.createElement("div", { className: 'flex justify-between' },
                                    React.createElement("div", { className: 'flex gap-2 items-center' },
                                        CARBON_SAVINGS_EMOJI,
                                        React.createElement("div", null,
                                            getFlightsSaved(estimate.co2_saved_kg),
                                            " flights to Spain ",
                                            React.createElement("span", { className: 'text-base font-medium text-default' }, "/year"))))))))),
                " ",
                React.createElement("div", { className: 'flex flex-col items-center gap-2 text-center max-w-screen-2xl' },
                    React.createElement(Text, { className: determineBestTextShade((_p = companyPublicInfo.colour_secondary) !== null && _p !== void 0 ? _p : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Scroll to view the personalised cost estimates we have prepared for you"),
                    React.createElement(Icon, { className: determineBestTextShade((_q = companyPublicInfo.colour_secondary) !== null && _q !== void 0 ? _q : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white', icon: ArrowDown }))),
            React.createElement("div", { className: 'flex flex-col items-center w-full gap-10 bg-gray-50' },
                React.createElement("div", { className: "px-4 pt-10 md:px-6 flex flex-col items-center w-full" },
                    React.createElement("div", { className: 'text-center pb-6' },
                        React.createElement(Heading, { size: "2xl" }, "So, what happens next?")),
                    React.createElement(NextSteps, { isScottish: isScottish, companyPublicInfo: companyPublicInfo })),
                React.createElement("div", { className: "px-4 md:px-6" },
                    React.createElement("div", { className: 'pb-6 text-center', ref: costEstimateHeadingRef },
                        React.createElement(Heading, { size: "2xl" }, "Your personalised cost estimate")),
                    React.createElement(EstimateCard, { color: "WHITE", size: "LG", className: 'gap-4 shadow w-full max-w-screen-lg' },
                        React.createElement(Heading, { size: "2xl" }, estimate.heat_pump_name),
                        React.createElement("div", { className: `grid ${imageLoaded ? 'sm:grid-cols-[2fr_1fr]' : ''} gap-5` },
                            React.createElement("div", { className: 'flex flex-col gap-4' },
                                React.createElement("div", { className: 'flex flex-col gap-2' },
                                    showBillSavings && React.createElement(TagCard, { color: "YELLOW" },
                                        React.createElement(Text, { size: "XS", bold: true },
                                            BILL_SAVINGS_EMOJI,
                                            " Potential bill savings"),
                                        React.createElement("div", { className: 'flex gap-1 items-center' },
                                            React.createElement(Text, { size: "XS", bold: true, className: 'text-default' },
                                                "\u00A3",
                                                numberFormat(0).format(billSavings),
                                                "/year"),
                                            React.createElement(BillSavingsInfo, { billSavingsInfoContent: estimate }))),
                                    React.createElement(TagCard, { color: "GREEN" },
                                        React.createElement(Text, { size: "XS", bold: true },
                                            React.createElement("div", { className: 'flex gap-2 items-center' },
                                                CARBON_SAVINGS_EMOJI,
                                                React.createElement("div", null, "Estimated carbon savings"))),
                                        React.createElement("div", { className: 'flex gap-1 items-center' },
                                            React.createElement(Text, { size: "XS", bold: true, className: 'text-default' },
                                                getFlightsSaved(estimate.co2_saved_kg),
                                                " flights to Spain/year"),
                                            React.createElement(Info, { infoModalHeader: INFO_POPUPS.carbonSavings(estimate).title, infoModalBody: INFO_POPUPS.carbonSavings(estimate).content })))),
                                React.createElement(EstimateCard, { color: "WHITE", size: "MD", className: 'gap-4' },
                                    (estimate.range_heat_pump || heatPumpHasDimensions || estimate.scop) && React.createElement("div", null,
                                        React.createElement(Text, { size: "MD", bold: true }, "Key specifications"),
                                        React.createElement(SummaryTable, { rows: [
                                                // { key: 'Warranty', value: '7 years' },
                                                ((_r = estimate.range_heat_pump) === null || _r === void 0 ? void 0 : _r.sound_power_max_dba) ? { key: 'Sound power level', value: `${(_s = estimate.range_heat_pump) === null || _s === void 0 ? void 0 : _s.sound_power_max_dba} dB(A)`, infoButton: React.createElement(Info, { infoModalHeader: INFO_POPUPS.soundPower.title, infoModalBody: INFO_POPUPS.soundPower.content }), size: 'MD' } : undefined,
                                                heatPumpHasDimensions ? { key: 'Dimensions (WxHxD)', value: `${(_t = estimate.range_heat_pump) === null || _t === void 0 ? void 0 : _t.width_mm}mm x ${(_u = estimate.range_heat_pump) === null || _u === void 0 ? void 0 : _u.height_mm}mm x ${(_v = estimate.range_heat_pump) === null || _v === void 0 ? void 0 : _v.depth_mm}mm`, size: 'MD' } : undefined,
                                                estimate.scop ? { key: 'Efficiency', value: `${Math.floor(estimate.scop * 100)}%`, infoButton: React.createElement(Info, { infoModalHeader: INFO_POPUPS.efficiency(estimate).title, infoModalBody: INFO_POPUPS.efficiency(estimate).content }), size: 'MD' } : undefined
                                            ].filter((row) => row !== undefined) })),
                                    React.createElement("div", null,
                                        React.createElement("div", { className: 'flex gap-1 items-center' },
                                            React.createElement(Text, { size: "MD", bold: true }, "System design"),
                                            React.createElement(Info, { infoModalHeader: INFO_POPUPS.systemDesign.title, infoModalBody: INFO_POPUPS.systemDesign.content })),
                                        React.createElement(SummaryTable, { rows: [
                                                estimate.flow_temperature_c ? { key: 'Flow temperature (°C)', value: `${estimate.flow_temperature_c}°C`, infoButton: React.createElement(Info, { infoModalHeader: INFO_POPUPS.flowTemperature.title, infoModalBody: INFO_POPUPS.flowTemperature.content }), size: 'MD' } : undefined,
                                                // { key: 'Estimated # radiator changes', value: '0' },
                                                { key: 'Cylinder', value: estimate.hot_water_cylinder_name, size: 'MD' }
                                            ].filter((row) => row !== undefined) })))),
                            React.createElement("div", { className: "flex sm:justify-end sm:items-end items-center justify-center" },
                                React.createElement("img", { src: process.env.S3_BUCKET_URL + '/hp-images/' + estimate.range_heat_pump_uuid + '.png', alt: estimate.heat_pump_name, className: 'max-h-80 object-contain', onError: (e) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.remove();
                                        setImageLoaded(false);
                                    } }))),
                        React.createElement(EstimateCard, { color: "GREY", size: "MD", className: 'gap-4' },
                            React.createElement("div", null,
                                React.createElement("div", { className: 'flex flex-row justify-between items-center' },
                                    React.createElement(Text, { size: "MD", bold: true }, "Estimate cost"),
                                    React.createElement(Text, { size: "SM", bold: true }, formatPrice(sum(quoteItemsWithSubtotals === null || quoteItemsWithSubtotals === void 0 ? void 0 : quoteItemsWithSubtotals.map(i => i.selected ? i.subtotal : 0)) - quoteGrant))),
                                React.createElement("div", { className: "ml-5" },
                                    React.createElement(SummaryTable, { rows: [
                                            ...(((_w = groupedQuoteItems[CalculatedQuoteDefaultGroups.HEAT_PUMPS]) === null || _w === void 0 ? void 0 : _w.map((x) => ({ key: quoteItemNameWithQuantity(x), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' }))) || []),
                                            ...(((_x = groupedQuoteItems[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]) === null || _x === void 0 ? void 0 : _x.map((x) => ({ key: quoteItemNameWithQuantity(x), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' }))) || []),
                                            ...(((_y = groupedQuoteItems[CalculatedQuoteDefaultGroups.PARTS]) === null || _y === void 0 ? void 0 : _y.map((x) => ({ key: quoteItemNameWithQuantity(x), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' }))) || []),
                                            ...(((_z = groupedQuoteItems[CalculatedQuoteDefaultGroups.UNDERFLOOR]) === null || _z === void 0 ? void 0 : _z.map((x) => ({ key: quoteItemNameWithQuantity(x, 'm²'), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' }))) || []),
                                            ...(((_0 = groupedQuoteItems[CalculatedQuoteDefaultGroups.LABOUR]) === null || _0 === void 0 ? void 0 : _0.map((x) => ({ key: quoteItemNameWithQuantity(x, 'days'), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal) : '', size: 'MD' }))) || []),
                                            ...(((_1 = groupedQuoteItems[CalculatedQuoteDefaultGroups.SURVEY]) === null || _1 === void 0 ? void 0 : _1.map((x) => ({ key: quoteItemNameWithQuantity(x), value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(x.subtotal * 1.2) : '', size: 'MD' }))) || [])
                                        ].filter((row) => row !== undefined) }))),
                            ((_2 = groupedQuoteItems[CalculatedQuoteDefaultGroups.RADIATORS]) === null || _2 === void 0 ? void 0 : _2.length) > 0 && React.createElement(Card, { color: 'WHITE' },
                                React.createElement("div", { className: 'flex gap-1 items-center' },
                                    React.createElement(Text, { size: "MD", bold: true }, "Estimated additional cost of radiator changes"),
                                    React.createElement(Info, { infoModalHeader: 'Estimated radiator changes explained', infoModalBody: React.createElement("div", { className: 'flex flex-col gap-3' },
                                            React.createElement(Text, null, "Heat pumps operate at a lower flow temperature than boilers, so they need a larger radiator surface area to effectively heat your home. This means some radiators in your property may need to be upgraded to ensure the system runs efficiently."),
                                            React.createElement("div", { className: 'flex flex-col gap-1' },
                                                React.createElement(Heading, { size: 'md' }, "How is this estimated?"),
                                                React.createElement(Text, null, "We\u2019ve made a rough estimate based on your property size to give you an idea of potential costs. However, we\u2019ll need to complete a full survey to determine the exact requirements."))) })),
                                React.createElement("div", { className: 'py-2 px-6' },
                                    React.createElement(SummaryTable, { rows: [
                                            { key: 'Number of changes', value: sum((_3 = groupedQuoteItems[CalculatedQuoteDefaultGroups.RADIATORS]) === null || _3 === void 0 ? void 0 : _3.map(x => x.quantity)), size: 'MD' },
                                            { key: 'Estimated cost', value: companyPublicInfo.show_line_item_costs_on_estimate ? formatPrice(sum((_4 = groupedQuoteItems[CalculatedQuoteDefaultGroups.RADIATORS]) === null || _4 === void 0 ? void 0 : _4.map(x => x.subtotal))) : undefined, size: 'MD' }
                                        ].filter(row => row.value !== undefined) }))),
                            (!isScottish && quoteGrantEnabled) && React.createElement(TagCard, { color: "BRIGHT_GREEN" },
                                React.createElement("div", { className: 'flex gap-1 items-center' },
                                    React.createElement(Icon, { icon: CheckCircle, colour: 'text-white', className: 'w-6' }),
                                    React.createElement(Text, { size: "XS", className: 'text-white' }, "Boiler Upgrade Scheme (BUS) Grant")),
                                React.createElement(Text, { size: "XS", className: 'text-white' }, formatPrice(quoteGrant))),
                            React.createElement("div", { className: "flex items-center justify-between gap-4" },
                                React.createElement(Text, { bold: true }, "Estimated total"),
                                React.createElement(Text, { bold: true }, formatPrice(sum(quoteItemsWithSubtotals === null || quoteItemsWithSubtotals === void 0 ? void 0 : quoteItemsWithSubtotals.map(i => i.selected ? i.subtotal : 0)))))),
                        React.createElement(Alert, { type: "WARNING" },
                            React.createElement("div", { className: 'flex flex-col space-y-2' },
                                React.createElement("p", null, "Please note this is an initial estimate based on the information we have so far. We'll be able to provide a final quote once we've done a room-by-room heat loss survey of your property."),
                                showBillSavings && React.createElement("p", null, "Note also that these potential bill savings are not guaranteed \u2013 they're estimated based upon what we know about your property, with broad-brush assumptions about how you heat your home."))))),
                React.createElement("div", { className: "w-full max-w-screen-2xl mx-auto px-4 md:px-6" },
                    React.createElement("div", { style: { backgroundColor: (_5 = companyPublicInfo.colour_secondary) !== null && _5 !== void 0 ? _5 : DEFAULT_BRAND_COLOUR_SECONDARY }, className: "px-6 py-10 flex flex-col items-center w-full gap-5 rounded-lg" },
                        showRequestSurveyButton
                            ? React.createElement("div", { className: "flex flex-col gap-3 text-center" },
                                React.createElement(Heading, { size: "2xl", className: determineBestTextShade((_6 = companyPublicInfo.colour_secondary) !== null && _6 !== void 0 ? _6 : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Book a full heat loss survey today"),
                                (!companyPublicInfo.no_request_callback || isScottish) && React.createElement(Text, { className: determineBestTextShade((_7 = companyPublicInfo.colour_secondary) !== null && _7 !== void 0 ? _7 : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Or request a callback to discuss your needs in more detail."))
                            : React.createElement("div", { className: "flex flex-col gap-3 text-center" },
                                React.createElement(Heading, { size: "2xl", className: determineBestTextShade((_8 = companyPublicInfo.colour_secondary) !== null && _8 !== void 0 ? _8 : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Let us know if you'd like to proceed"),
                                React.createElement(Text, { className: determineBestTextShade((_9 = companyPublicInfo.colour_secondary) !== null && _9 !== void 0 ? _9 : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Please request a callback so we can answer any outstanding questions and book in the next steps. And if you're not interested in progressing right now, please just let us know below.")),
                        React.createElement("div", { className: "w-full max-w-80 flex flex-col gap-3 items-center" },
                            showRequestSurveyButton &&
                                React.createElement(Button, { style: {
                                        backgroundColor: companyPublicInfo.colour_primary,
                                        borderColor: companyPublicInfo.colour_primary,
                                        color: determineBestTextShade((_10 = companyPublicInfo.colour_primary) !== null && _10 !== void 0 ? _10 : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                                    }, className: 'w-full', onClick: handleRequestSurvey, disabled: isPreview }, "Book a full heat loss survey"),
                            (!companyPublicInfo.no_request_callback || isScottish) && React.createElement(Button, { style: isScottish ? {
                                    backgroundColor: companyPublicInfo.colour_primary,
                                    borderColor: companyPublicInfo.colour_primary,
                                    color: determineBestTextShade((_11 = companyPublicInfo.colour_primary) !== null && _11 !== void 0 ? _11 : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                                } : {}, className: 'w-full', colour: showRequestSurveyButton ? 'LIGHT' : 'DARK', onClick: () => setShowRequestCallbackModal(true), disabled: isPreview }, "Request a callback"),
                            isScottish && React.createElement(React.Fragment, null,
                                React.createElement(Button, { colour: "LIGHT", className: 'w-full', disabled: isPreview },
                                    React.createElement("a", { target: "_blank", href: "https://www.homeenergyscotland.org/funding/grants-loans/", rel: "noreferrer" }, "Visit Home Energy Scotland"))),
                            React.createElement(Link, { className: determineBestTextShade((_12 = companyPublicInfo.colour_secondary) !== null && _12 !== void 0 ? _12 : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? '' : 'text-white', text: 'I\u2019m not interested right now', onClick: () => setShowNotInterestedModal(true), disabled: isPreview })))),
                (companyPublicInfo.show_about_us_on_estimate || companyPublicInfo.show_customer_review_on_estimate) && React.createElement("div", { className: 'w-full max-w-screen-2xl mx-auto px-4 md:px-6 flex flex-col items-center gap-6' },
                    React.createElement(Heading, { size: "2xl", className: 'text-center' },
                        "Why choose ",
                        companyPublicInfo.name,
                        "?"),
                    React.createElement("div", { className: `grid grid-cols-1 ${(companyPublicInfo.show_about_us_on_estimate && companyPublicInfo.show_customer_review_on_estimate) ? 'sm:grid-cols-2' : ''} gap-5` },
                        companyPublicInfo.show_about_us_on_estimate && React.createElement(EstimateCard, { color: 'WHITE', className: 'p-4 gap-4' },
                            React.createElement(Heading, { size: "lg" }, "About us"),
                            React.createElement("div", { className: 'grid lg:grid-cols-2 gap-4' },
                                React.createElement(Text, { size: 'SM', className: `w-full whitespace-pre-line ${!companyPublicInfo.about_us_image && 'col-span-full'}` }, companyPublicInfo.about_us),
                                companyPublicInfo.about_us_image && React.createElement("img", { src: companyPublicInfo.about_us_image, alt: 'About us', className: 'object-contain justify-self-center' }))),
                        companyPublicInfo.show_customer_review_on_estimate && React.createElement(EstimateCard, { color: 'WHITE', className: 'p-4 gap-4' },
                            React.createElement(Heading, { size: "lg" }, "See what customers are saying"),
                            React.createElement(EstimateCard, { color: 'GREY', className: 'flex-col gap-2 grow' },
                                React.createElement(Text, { className: 'whitespace-pre-line' }, companyPublicInfo.customer_review),
                                companyPublicInfo.customer_review_name && React.createElement(Text, { size: 'SM', bold: true, className: 'pl-1' }, ` - ${companyPublicInfo.customer_review_name}${companyPublicInfo.customer_review_location ? `, ${companyPublicInfo.customer_review_location}` : ''}`)),
                            companyPublicInfo.customer_review_link_text && React.createElement(Button, { colour: 'LIGHT', onClick: () => companyPublicInfo.customer_review_link && openInNewTab(companyPublicInfo.customer_review_link) }, companyPublicInfo.customer_review_link_text)))),
                React.createElement("div", { className: 'w-full bg-white' },
                    React.createElement("div", { className: 'w-full max-w-screen-2xl px-4 md:px-6 py-10 flex flex-col gap-6 mx-auto' },
                        React.createElement(Heading, { size: "2xl" }, "Common questions"),
                        React.createElement(Accordion, { rows: faqRows() }))))),
        React.createElement(NotInterestedModal, { visible: showNotInterestedModal, setVisible: setShowNotInterestedModal, onConfirm: handleNotInterested }),
        React.createElement(RequestCallbackModal, { visible: showRequestCallbackModal, setVisible: setShowRequestCallbackModal, onConfirm: handleRequestCallback }),
        React.createElement(RequestSurveyModal, { visible: showRequestSurveyModal, setVisible: setShowRequestSurveyModal }));
};
