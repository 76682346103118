import React, { useContext, useState } from 'react';
import { Section } from '../../components/section';
import { Text } from '../../../../../components/content_display/text';
import { formatPrice } from '../../../../../code/format_price';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { Button } from '../../../../../components/buttons/button';
import { Icon } from '../../../../../components/buttons/icon';
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../../../../../code/calculate_estimate';
import { Alert } from '../../../../../components/indicators_and_messaging/alert';
import { Badge } from '../../../../../components/indicators_and_messaging/badge';
import { InventoryTable } from '../../../costs_and_inventory/components/inventory_table';
import { groupBy, sum } from 'lodash';
import { CalculatedQuoteDefaultGroups, calculateQuote, QuoteOverrideType } from '../../../../../code/calculate_quote';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { bulkDeleteEstimateQuoteItems, bulkInsertEstimateQuoteItems, deleteEstimateQuoteItem, insertEstimateQuoteItem, updateEstimateQuoteItem } from '../../../../../code/models/estimate_quote_item';
import { priceCalculations } from '../../../../../code/price_calculations';
import { AdminContext } from '../../../admin_layout';
import { getApproxNumberOfRadiatorChanges } from '../../../../../code/models/estimated_rooms_and_radiators';
import { AlertTriangle, RotateCw, XCircle } from 'lucide-react';
export const CostBreakdown = ({ lead, setLead, estimate, companyPublicInfo }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const [selectedLineItemUUID, setSelectedLineItemUUID] = useState(undefined);
    const adminContext = useContext(AdminContext);
    const [calculatedQuote, quoteOverrideType] = calculateQuote({
        company: adminContext.data.company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, (_b = (_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.estimate_default_radiator_change_percentage) !== null && _b !== void 0 ? _b : DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    const groupedCalculatedQuote = groupBy(calculatedQuote, 'group_name');
    const quoteOverridden = quoteOverrideType === QuoteOverrideType.FULL || quoteOverrideType === QuoteOverrideType.PARTIAL;
    const createQuoteOverrideWithUpdatedItem = async (item) => {
        const updatedPayload = calculatedQuote.map(x => x.uuid === item.uuid ? item : x).map(x => ({ ...x, uuid: crypto.randomUUID() }));
        setLead({ ...lead, estimate_quote_items: updatedPayload });
        await bulkInsertEstimateQuoteItems(updatedPayload, lead.uuid, companyPublicInfo.uuid);
    };
    const createQuoteOverrideWithNewItem = async (item) => {
        const updatedPayload = [...calculatedQuote, item].map(x => ({ ...x, uuid: crypto.randomUUID() }));
        setLead({ ...lead, estimate_quote_items: updatedPayload });
        await bulkInsertEstimateQuoteItems(updatedPayload, lead.uuid, companyPublicInfo.uuid);
    };
    const createQuoteOverrideWithoutItem = async (item) => {
        const updatedPayload = calculatedQuote.filter(x => x.uuid !== item.uuid).map(x => ({ ...x, uuid: crypto.randomUUID() }));
        setLead({ ...lead, estimate_quote_items: updatedPayload });
        await bulkInsertEstimateQuoteItems(updatedPayload, lead.uuid, companyPublicInfo.uuid);
    };
    const handleUpdateItem = async (item) => {
        // Is the quote overridden?
        if (quoteOverridden) {
            // Just update this specific item
            await updateEstimateQuoteItem(item, lead.uuid, companyPublicInfo.uuid);
            return setLead({ ...lead, estimate_quote_items: lead.estimate_quote_items.map(x => x.uuid === item.uuid ? item : x) });
        }
        // Otherwise, create a new quote override, which also updates the state
        await createQuoteOverrideWithUpdatedItem(item);
    };
    const handleAddItem = async (item) => {
        // Is the quote overridden?
        if (quoteOverridden) {
            // Create a new item
            await insertEstimateQuoteItem(item, lead.uuid, companyPublicInfo.uuid);
            return setLead({ ...lead, estimate_quote_items: [...lead.estimate_quote_items, item] });
        }
        // Otherwise, create a new quote override, which also updates the state
        await createQuoteOverrideWithNewItem(item);
    };
    const handleDeleteItem = async (item) => {
        // Is the quote overridden?
        if (quoteOverridden) {
            // Just delete this specific item
            await deleteEstimateQuoteItem(item.uuid, lead.uuid, companyPublicInfo.uuid);
            return setLead({ ...lead, estimate_quote_items: lead.estimate_quote_items.filter(x => x.uuid !== item.uuid) });
        }
        // Otherwise, create a new quote override, which also updates the state
        await createQuoteOverrideWithoutItem(item);
    };
    const handleResetToDefault = async () => {
        setLead({ ...lead, estimate_quote_items: [] });
        await bulkDeleteEstimateQuoteItems(lead.uuid, companyPublicInfo.uuid);
    };
    const addableItems = [
        { uuid: 'radiator', name: 'Radiator', cost_price: adminContext.data.company.default_radiator_cost || 0, deleted_at: null },
        { uuid: 'underfloor', name: 'Underfloor heating', cost_price: adminContext.data.company.default_underfloor_cost || 0, deleted_at: null },
        ...adminContext.data.parts,
        ...adminContext.data.labour,
        { uuid: 'survey', name: 'Survey', cost_price: Math.round((_c = adminContext.data.company.survey_cost) !== null && _c !== void 0 ? _c : 0), deleted_at: null }
    ]
        .map(x => !x.deleted_at ? { key: x.uuid, value: `${x.name} - £${x.cost_price}` } : undefined)
        .filter(Boolean);
    const handleAddAdditionalItem = async (key) => {
        var _a, _b, _c, _d, _e;
        if (!key)
            return;
        if (key === 'radiator' || key === 'underfloor') {
            const item = {
                uuid: crypto.randomUUID(),
                lead_uuid: lead.uuid,
                group_name: key === 'radiator' ? CalculatedQuoteDefaultGroups.RADIATORS : CalculatedQuoteDefaultGroups.UNDERFLOOR,
                name: key === 'radiator' ? 'Radiator' : 'Underfloor heating',
                price: key === 'radiator' ? adminContext.data.company.default_radiator_cost || 0 : adminContext.data.company.default_underfloor_cost || 0,
                quantity: 1,
                include_vat: false,
                selected: true
            };
            if ((_a = lead.estimate_quote_items) === null || _a === void 0 ? void 0 : _a.find(x => x.name === item.name)) {
                const existingItem = lead.estimate_quote_items.find(x => x.name === item.name);
                return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 });
            }
            await handleAddItem(item);
        }
        else if (key === 'survey') {
            const survey = {
                group_name: CalculatedQuoteDefaultGroups.SURVEY,
                uuid: crypto.randomUUID(),
                lead_uuid: lead.uuid,
                name: 'Survey',
                price: Math.round((_b = adminContext.data.company.survey_cost) !== null && _b !== void 0 ? _b : 0),
                quantity: 1,
                include_vat: true,
                selected: true
            };
            if ((_c = lead.estimate_quote_items) === null || _c === void 0 ? void 0 : _c.find(x => x.name === survey.name)) {
                const existingItem = lead.estimate_quote_items.find(x => x.name === survey.name);
                return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 });
            }
            await handleAddItem(survey);
        }
        else if (adminContext.data.parts.find(x => x.uuid === key)) {
            const item = adminContext.data.parts.find(x => x.uuid === key);
            if (!item)
                return;
            // Does this part already exist in the quote?
            if ((_d = lead.estimate_quote_items) === null || _d === void 0 ? void 0 : _d.find(x => x.name === item.name)) {
                // If so, just increment the quantity
                const existingItem = lead.estimate_quote_items.find(x => x.name === item.name);
                return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 });
            }
            // Otherwise, add a new item with a quantity of 1
            await handleAddItem({
                uuid: crypto.randomUUID(),
                lead_uuid: lead.uuid,
                group_name: CalculatedQuoteDefaultGroups.PARTS,
                name: item.name,
                description: item.description || '',
                price: priceCalculations.calculateCustomerPrice(item.cost_price, item.markup || 0),
                quantity: 1,
                include_vat: false,
                selected: true
            });
        }
        else {
            const item = adminContext.data.labour.find(x => x.uuid === key);
            if (!item)
                return;
            // Does this labour item already exist in the quote?
            if ((_e = lead.estimate_quote_items) === null || _e === void 0 ? void 0 : _e.find(x => x.name === item.name)) {
                // If so, just increment the quantity
                const existingItem = lead.estimate_quote_items.find(x => x.name === item.name);
                return await handleUpdateItem({ ...existingItem, quantity: existingItem.quantity + 1 });
            }
            // Otherwise, add a new item with a quantity of 1
            await handleAddItem({
                uuid: crypto.randomUUID(),
                lead_uuid: lead.uuid,
                group_name: CalculatedQuoteDefaultGroups.LABOUR,
                name: item.name,
                description: item.description || '',
                price: item.cost_price,
                quantity: item.days,
                include_vat: false,
                selected: true
            });
        }
        setSelectedLineItemUUID(undefined);
    };
    const costBreakdownColumns = [
        { key: 'item', name: 'Item' },
        { key: 'quantity', name: 'Quantity' },
        { key: 'price', name: 'Price' },
        { key: 'total', name: 'Total', align: 'right' },
        { key: 'menu', name: '', align: 'right' }
    ];
    const quoteHeatPump = (_d = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HEAT_PUMPS]) === null || _d === void 0 ? void 0 : _d[0];
    const quoteHotWaterCylinder = (_e = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]) === null || _e === void 0 ? void 0 : _e[0];
    const costBreakdownRows = [
        ...(quoteHeatPump ? [{
                item: React.createElement(React.Fragment, null,
                    React.createElement(Text, { bold: true }, quoteHeatPump.name || React.createElement(Badge, { color: "RED", text: "No heat pump selected", icon: AlertTriangle })),
                    quoteHeatPump.description && React.createElement(Text, { size: "SM" }, quoteHeatPump.description)),
                quantity: React.createElement(Input, { type: "number", value: quoteHeatPump.quantity, setValue: (value) => handleUpdateItem({ ...quoteHeatPump, quantity: parseFloat(value) }) }),
                price: React.createElement(Input, { type: "number", value: quoteHeatPump.price, setValue: (value) => handleUpdateItem({ ...quoteHeatPump, price: parseFloat(value) }), prefix: "\u00A3" }),
                total: formatPrice(quoteHeatPump.subtotal),
                menu: null
            }] : []),
        ...(quoteHotWaterCylinder ? [{
                item: React.createElement(React.Fragment, null,
                    React.createElement(Text, { bold: true }, quoteHotWaterCylinder.name || React.createElement(Badge, { color: "RED", text: "No hot water cylinder selected", icon: AlertTriangle })),
                    quoteHotWaterCylinder.description && React.createElement(Text, { size: "SM" }, quoteHotWaterCylinder.description)),
                quantity: React.createElement(Input, { type: "number", value: quoteHotWaterCylinder.quantity, setValue: (value) => handleUpdateItem({ ...quoteHotWaterCylinder, quantity: parseFloat(value) }) }),
                price: React.createElement(Input, { type: "number", value: quoteHotWaterCylinder.price, setValue: (value) => handleUpdateItem({ ...quoteHotWaterCylinder, price: parseFloat(value) }), prefix: "\u00A3" }),
                total: formatPrice(quoteHotWaterCylinder.subtotal),
                menu: React.createElement(Icon, { icon: XCircle, onClick: () => handleDeleteItem(quoteHotWaterCylinder), confirmTextHeader: 'Delete quote item', confirmTextBody: 'Are you sure you want to remove this item from the quote?' })
            }] : []),
        ...(_g = (_f = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.PARTS]) === null || _f === void 0 ? void 0 : _f.map(part => ({
            item: React.createElement(React.Fragment, null,
                React.createElement(Text, { bold: true }, part.name),
                part.description && React.createElement(Text, { size: "SM" }, part.description)),
            quantity: React.createElement(Input, { type: "number", value: part.quantity, setValue: (value) => handleUpdateItem({ ...part, quantity: parseFloat(value) }) }),
            price: React.createElement(Input, { type: "number", value: part.price, setValue: (value) => handleUpdateItem({ ...part, price: parseFloat(value) }), prefix: "\u00A3" }),
            total: formatPrice(part.subtotal),
            menu: React.createElement(Icon, { icon: XCircle, onClick: () => handleDeleteItem(part), confirmTextHeader: 'Delete quote item', confirmTextBody: 'Are you sure you want to remove this item from the quote?' })
        }))) !== null && _g !== void 0 ? _g : [],
        ...(_j = (_h = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.RADIATORS]) === null || _h === void 0 ? void 0 : _h.map(radiator => ({
            item: React.createElement(Text, { bold: true }, radiator.name),
            quantity: React.createElement(Input, { type: "number", value: radiator.quantity, setValue: (value) => handleUpdateItem({ ...radiator, quantity: parseFloat(value) }) }),
            price: React.createElement(Input, { type: "number", value: radiator.price, setValue: (value) => handleUpdateItem({ ...radiator, price: parseFloat(value) }), prefix: "\u00A3" }),
            total: formatPrice(radiator.subtotal),
            menu: React.createElement(Icon, { icon: XCircle, onClick: () => handleDeleteItem(radiator), confirmTextHeader: 'Delete quote item', confirmTextBody: 'Are you sure you want to remove this item from the quote?' })
        }))) !== null && _j !== void 0 ? _j : [],
        ...(_l = (_k = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR]) === null || _k === void 0 ? void 0 : _k.map(underfloor => ({
            item: React.createElement(Text, { bold: true }, underfloor.name),
            quantity: React.createElement(Input, { type: "number", value: underfloor.quantity, setValue: (value) => handleUpdateItem({ ...underfloor, quantity: parseFloat(value) }), postfix: 'm²' }),
            price: React.createElement(Input, { type: "number", value: underfloor.price, setValue: (value) => handleUpdateItem({ ...underfloor, price: parseFloat(value) }), prefix: "\u00A3" }),
            total: formatPrice(underfloor.subtotal),
            menu: React.createElement(Icon, { icon: XCircle, onClick: () => handleDeleteItem(underfloor), confirmTextHeader: 'Delete quote item', confirmTextBody: 'Are you sure you want to remove this item from the quote?' })
        }))) !== null && _l !== void 0 ? _l : [],
        ...(_o = (_m = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.LABOUR]) === null || _m === void 0 ? void 0 : _m.map(labour => ({
            item: React.createElement(React.Fragment, null,
                React.createElement(Text, { bold: true }, labour.name),
                labour.description && React.createElement(Text, { size: "SM" }, labour.description)),
            quantity: React.createElement(Input, { type: "number", value: labour.quantity, setValue: (value) => handleUpdateItem({ ...labour, quantity: parseFloat(value) }), postfix: 'days' }),
            price: React.createElement(Input, { type: "number", value: labour.price, setValue: (value) => handleUpdateItem({ ...labour, price: parseFloat(value) }), prefix: "\u00A3" }),
            total: formatPrice(labour.subtotal),
            menu: React.createElement(Icon, { icon: XCircle, onClick: () => handleDeleteItem(labour), confirmTextHeader: 'Delete quote item', confirmTextBody: 'Are you sure you want to remove this item from the quote?' })
        }))) !== null && _o !== void 0 ? _o : [],
        ...(_q = (_p = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.SURVEY]) === null || _p === void 0 ? void 0 : _p.map(survey => {
            var _a;
            return ({
                item: React.createElement(Text, { bold: true }, "Survey"),
                quantity: '',
                price: React.createElement(Input, { type: "number", value: survey.price, setValue: (value) => handleUpdateItem({ ...survey, price: parseFloat(value) }), prefix: "\u00A3" }),
                total: formatPrice(((_a = survey.subtotal) !== null && _a !== void 0 ? _a : 0) * 1.2),
                menu: React.createElement(Icon, { icon: XCircle, onClick: () => handleDeleteItem(survey), confirmTextHeader: 'Delete quote item', confirmTextBody: 'Are you sure you want to remove this item from the quote?' })
            });
        })) !== null && _q !== void 0 ? _q : []
    ];
    const grantsBreakdownColumns = [
        { key: 'description', name: 'Description' },
        { key: 'apply', name: 'Apply to estimate' },
        { key: 'price', name: 'Price' }
    ];
    const grantsBreakdownRows = [
        ...((_s = (_r = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]) === null || _r === void 0 ? void 0 : _r.map(grant => ({
            description: React.createElement(React.Fragment, null,
                React.createElement(Text, { bold: true }, grant.name),
                grant.description && React.createElement(Text, { size: "SM" }, grant.description)),
            apply: React.createElement(Toggle, { value: !!grant.selected, setValue: () => handleUpdateItem({ ...grant, selected: !grant.selected }) }),
            price: formatPrice(grant.price)
        }))) !== null && _s !== void 0 ? _s : [])
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Section, { title: 'Cost breakdown', controls: React.createElement(Button, { size: "SM", iconLeft: RotateCw, colour: 'LIGHT', disabled: !quoteOverridden, onClick: handleResetToDefault, confirmText: "This will clear any changes you've made and revert back to defaults." }, "Reset to defaults") },
            quoteOverridden && React.createElement(Alert, { type: "INFO" }, "This cost estimate has been manually overridden. It will not update automatically based on changes to the selected heat pump and hot water cylinder, or your costs and inventory. Reset to default to revert to automatic calculation."),
            React.createElement(InventoryTable, { columns: costBreakdownColumns, rows: costBreakdownRows }),
            React.createElement("div", { className: "flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4" },
                React.createElement(Select, { placeholder: 'Add item', dataCy: "additional_items_dropdown", setSelectedKey: handleAddAdditionalItem, selectedKey: selectedLineItemUUID, options: addableItems, filter: true }),
                React.createElement("div", { className: "flex items-center flex-1" },
                    React.createElement(Text, { bold: true, className: "whitespace-nowrap" }, "Total before deductions:"),
                    React.createElement(Text, { bold: true, className: "ml-2" }, formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)) - sum((_t = groupedCalculatedQuote[CalculatedQuoteDefaultGroups.GRANTS]) === null || _t === void 0 ? void 0 : _t.map(x => x.selected ? x.price : 0))))))),
        grantsBreakdownRows.length > 0 &&
            React.createElement(Section, { title: 'Grants' },
                React.createElement(InventoryTable, { columns: grantsBreakdownColumns, rows: grantsBreakdownRows }),
                React.createElement("div", { className: "flex items-center justify-end gap-4" },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement(Text, { bold: true }, "Total after deductions:"),
                        React.createElement(Text, { bold: true, className: "ml-2" }, formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)))))))));
};
