import { apiUrl, client } from '../axios';
import { AuthSDK } from '../utils/auth_provider';
import { parseWithZod } from '../zod';
import { InventoryPartSchema } from './inventory';
export const getParts = async (companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/parts`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        const parsedResult = result.data.map(x => parseWithZod(InventoryPartSchema, x));
        return parsedResult;
    }
    catch (e) {
        console.error('Error getting parts', e);
        return undefined;
    }
};
export const insertPart = async (part, companyUUID) => {
    const response = await client.post(`${apiUrl}teams/${companyUUID}/parts`, part, { headers: { 'x-auth-token': AuthSDK.getToken() } });
    return response.data;
};
export const updatePart = async (part, companyUUID) => {
    await client.patch(`${apiUrl}teams/${companyUUID}/parts/${part.uuid}`, part, { headers: { 'x-auth-token': AuthSDK.getToken() } });
};
export const deletePart = async (partUUID, companyUUID) => {
    await client.delete(`${apiUrl}teams/${companyUUID}/parts/${partUUID}`, { headers: { 'x-auth-token': AuthSDK.getToken() } });
};
