import React, { useState } from 'react';
import { ClickableCard } from '../../components/content_display/card';
import { AddExternalAttachmentModal } from './add_external_attachment_modal';
import { AddInternalAttachmentModal } from './add_internal_attachment_modal';
import { Text } from '../../components/content_display/text';
import { Button } from '../../components/buttons/button';
export const ProposalAttachmentPlaceholder = ({ addAttachment, forceTitle }) => {
    const [modal, setModal] = useState(undefined);
    const handleCloseModal = (open) => {
        if (!open) {
            setModal(undefined);
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement(ClickableCard, { variant: 'PLACEHOLDER', className: 'flex items-center justify-center' },
            React.createElement("div", { className: 'flex flex-wrap gap-3 items-center justify-center p-0 sm:py-4' },
                React.createElement(Text, null, "No file provided"),
                React.createElement(Text, null, "-"),
                React.createElement("div", { className: 'flex gap-3 items-center' },
                    React.createElement(Button, { colour: 'LIGHT', onClick: () => setModal('internal') }, "Choose file"),
                    React.createElement(Text, null, "or"),
                    React.createElement(Button, { colour: 'LIGHT', onClick: () => setModal('external') }, "Add link")))),
        React.createElement(AddInternalAttachmentModal, { forceTitle: forceTitle, visible: modal === 'internal', onAddAttachment: (title, url) => addAttachment(title, url, true), setVisible: handleCloseModal }),
        React.createElement(AddExternalAttachmentModal, { forceTitle: forceTitle, visible: modal === 'external', onAddAttachment: (title, url) => addAttachment(title, url, false), setVisible: handleCloseModal }));
};
