import React from 'react';
import { SurveySiteDetailsContext } from '../site_details';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const SiteDetailsMainsWaterDetailsBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, setSurvey } = prContext;
    const isFullScreen = (flowContext === null || flowContext === void 0 ? void 0 : flowContext.viewMode) === 'FLOW';
    return React.createElement(React.Fragment, null,
        isFullScreen && React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Mains water"),
        React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement("div", { className: "items-center gap-3 flex justify-between" },
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Stopcock works")),
                React.createElement(Toggle, { size: 'LG', value: survey.stopcock_works, setValue: () => setSurvey({ ...survey, stopcock_works: !survey.stopcock_works }) })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Stopcock location"),
                React.createElement(TextArea, { value: survey.stopcock_location, setValue: (e) => setSurvey({ ...survey, stopcock_location: e }) })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Size of water main"),
                React.createElement(Input, { type: 'number', value: survey.size_of_water_main_mm.toString(), setValue: (e) => setSurvey({ ...survey, size_of_water_main_mm: Number(e) }), postfix: React.createElement("span", { className: 'text-gray-500' }, "mm") })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Mains flow rate"),
                React.createElement(Input, { type: 'number', value: survey.mains_flow_rate_l_per_min.toString(), setValue: (e) => setSurvey({ ...survey, mains_flow_rate_l_per_min: Number(e) }), postfix: React.createElement("span", { className: 'text-gray-500' }, "L/min") })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Mains pressure"),
                React.createElement(Input, { type: 'number', value: survey.mains_pressure_bar.toString(), setValue: (e) => setSurvey({ ...survey, mains_pressure_bar: Number(e) }), postfix: React.createElement("span", { className: 'text-gray-500' }, "bar") }))));
};
