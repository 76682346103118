import React from 'react';
import { SummaryTable } from '../../../components/content_display/summary_table';
export const SummaryTableDemo = () => {
    const rows = [
        { key: 'Key 1', value: 'Value 1', emoji: '1️⃣' },
        { key: 'Key 2', value: 'Value 2', emoji: '2️⃣' },
        { key: 'Key 3', value: 'Value 3', emoji: '3️⃣' },
        { key: 'Key 4', value: 'Value 4', emoji: '4️⃣' },
        { key: 'Total', value: 'Total Value', size: 'LG', emoji: '🔢' }
    ];
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(SummaryTable, { rows: rows })));
};
const componentProps = [
    { name: 'rows', description: 'Array of table rows' }
];
const codeBlock = `
const rows: SummaryTableRow[] = [
  { key: 'Key 1', value: 'Value 1', emoji: '1️⃣' },
  { key: 'Key 2', value: 'Value 2', emoji: '2️⃣' },
  { key: 'Key 3', value: 'Value 3', emoji: '3️⃣' },
  { key: 'Key 4', value: 'Value 4', emoji: '4️⃣' },
  { key: 'Total', value: 'Total Value', size: 'LG', emoji: '🔢' }
]

<SummaryTable
  rows={rows}
/>
`;
