import React from 'react';
export const ProgressBar = ({ progress }) => {
    if (progress < 0 || progress > 100) {
        throw new Error(`Progress bar progress must be between 0 and 100, but got ${progress}`);
    }
    // progressbar steps number
    const blocksNumber = 20;
    // use only 20 blocks to represent 100% progress
    const progressBlocksNumber = Math.floor(progress / 5);
    const restBlocksNumber = blocksNumber - progressBlocksNumber;
    const blocks = [];
    // FIXME: w-1/12 works but it's not 1/blocksNumber. Confusing.
    const commonClasses = 'h-1 w-1/12';
    for (let i = 0; i < progressBlocksNumber; i++) {
        blocks.push(React.createElement("div", { key: 'progress-' + i, className: `${commonClasses} bg-gray-500` }));
    }
    for (let i = 0; i < restBlocksNumber; i++) {
        blocks.push(React.createElement("div", { key: 'rest-' + i, className: `${commonClasses} bg-gray-100` }));
    }
    return React.createElement("div", { className: "h-1 flex flex-row w-full" }, blocks);
};
