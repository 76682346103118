import React from 'react';
import { Card } from '../../../components/content_display/card';
export const CardDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(Card, { className: '', color: 'BLUE' }, "Hello world")));
};
const componentProps = [
    { name: 'className', description: 'An array of items that should appear in the accordion' },
    { name: 'color', description: 'DARK | LIGHT | YELLOW | RED | BLUE | GREEN | WHITE' }
];
const codeBlock = `<Card
  className=''
  color='BLUE'
>Hello world</Card>`;
