import React from 'react';
import { SurveyHeatPumpLocationContext } from '../heat_pump_location';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Input } from '../../../../components/inputs_and_selections/input';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Check } from 'lucide-react';
export const HPLInstallationDetailsBlock = () => {
    const hplContext = React.useContext(SurveyHeatPumpLocationContext);
    const { survey, setSurvey } = hplContext;
    const mountingLocations = ['Ground', 'Wall', 'Flat roof'];
    const baseBuiltBy = ['Installer', 'Builder'];
    const condensateDrain = ['Nearby drain or downpipe', 'Soak away to be dug'];
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Installation"),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: 'flex justify-between' },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Mounting location"),
                React.createElement(Badge, { color: survey.mounting_location ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.mounting_location ? Check : undefined })),
            React.createElement(RadioGroup, { items: mountingLocations.map(x => ({
                    name: x,
                    onClick: () => setSurvey({ ...survey, mounting_location: x }),
                    variant: survey.mounting_location === x ? 'ACTIVE' : 'DEFAULT'
                })) })),
        survey.mounting_location === 'Ground' && React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: 'flex justify-between' },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Base to be built by"),
                React.createElement(Badge, { color: survey.base_to_be_build_by ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.base_to_be_build_by ? Check : undefined })),
            React.createElement(RadioGroup, { items: baseBuiltBy.map(x => ({
                    name: x,
                    onClick: () => setSurvey({ ...survey, base_to_be_build_by: x }),
                    variant: survey.base_to_be_build_by === x ? 'ACTIVE' : 'DEFAULT'
                })) })),
        survey.mounting_location === 'Flat roof' && React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Roof height"),
                React.createElement(Input, { type: 'number', value: survey.roof_height_m.toString(), setValue: (e) => setSurvey({ ...survey, roof_height_m: Number(e) }), postfix: 'm' })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Access notes"),
                    React.createElement("div", { className: "text-gray-500 text-sm" }, "How will you access the roof?")),
                React.createElement(TextArea, { value: survey.access_notes, setValue: (e) => setSurvey({ ...survey, access_notes: e }) }))),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: 'flex justify-between' },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Condensate drain"),
                React.createElement(Badge, { color: survey.condensate_drain ? 'GREEN' : 'YELLOW', text: 'Required', icon: survey.condensate_drain ? Check : undefined })),
            React.createElement(RadioGroup, { items: condensateDrain.map(x => ({
                    name: x,
                    onClick: () => setSurvey({ ...survey, condensate_drain: x }),
                    variant: survey.condensate_drain === x ? 'ACTIVE' : 'DEFAULT'
                })) })));
};
