import React from 'react';
import { Heading } from '../../../../../components/content_display/heading';
import { InventoryTable } from '../inventory_table';
import { Section } from '../../../job_layout/components/section';
import { Input } from '../../../../../components/inputs_and_selections/input';
export const EmittersInventory = ({ company, setCompany }) => {
    const emitterColumns = [
        { key: 'name', name: 'Emitter type' },
        { key: 'price', name: 'Default price' }
    ];
    const emitterRows = [
        {
            name: 'Radiator',
            price: React.createElement(Input, { type: 'number', value: company.default_radiator_cost || 0, prefix: "\u00A3", postfix: "/radiator", setValue: async (value) => {
                    const updatedCompany = { ...company, default_radiator_cost: parseFloat(value) };
                    setCompany({ company: updatedCompany, debounce: true });
                } })
        },
        {
            name: 'Underfloor Heating',
            price: React.createElement(Input, { type: 'number', value: company.default_underfloor_cost || 0, prefix: "\u00A3", postfix: "/m\u00B2", setValue: async (value) => {
                    const updatedCompany = { ...company, default_underfloor_cost: parseFloat(value) };
                    setCompany({ company: updatedCompany, debounce: true });
                } })
        }
    ];
    return (React.createElement("div", { className: "space-y-6" },
        React.createElement("div", { className: "flex flex-col gap-3 justify-start items-start sm:flex-row sm:justify-between sm:items-center w-full" },
            React.createElement(Heading, { size: "2xl" }, "Emitters")),
        React.createElement(Section, null,
            React.createElement(InventoryTable, { rows: emitterRows, columns: emitterColumns }))));
};
