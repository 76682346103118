import React from 'react';
import { SettingsLayout } from '../components/settings_layout';
import { Section } from '../../../../components/containers/section';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../components/inputs_and_selections/input';
import { FlowTempSlider } from '../../../heat_loss/design/pages/emitter_design_page';
import { DEFAULT_AIR_CHANGES, DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE, DEFAULT_FLOW_TEMP_C, DEFAULT_INTERNAL_TEMP_C } from '../../../../code/calculate_estimate';
import { validateIntegerIsInRange, validateIsPositiveNumberOrZero } from '../../../../code/validators';
import { Info } from '../../../../components/buttons/info';
export const EstimatePreferences = ({ company, setCompany }) => {
    var _a, _b, _c, _d;
    return React.createElement(SettingsLayout, { heading: 'Estimate preferences', description: 'Configure how your estimates are generated' },
        React.createElement(Section, { border: true, title: 'Heat loss estimate defaults' },
            React.createElement("div", { className: 'flex flex-col gap-8' },
                React.createElement(Alert, { type: 'INFO' }, "Changing these settings will not impact estimates that have already been sent to customers."),
                React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 grid-flow-row gap-4' },
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Internal temp. (\u00B0C)' }), input: React.createElement(Input, { type: 'number', value: (_a = company.estimate_default_internal_temp_c) !== null && _a !== void 0 ? _a : DEFAULT_INTERNAL_TEMP_C, setValue: temp => {
                                if (validateIsPositiveNumberOrZero(temp)) {
                                    setCompany({ ...company, estimate_default_internal_temp_c: parseInt(temp) });
                                }
                            }, validator: validateIsPositiveNumberOrZero, validateImmediately: true, postfix: React.createElement(React.Fragment, null, "\u00B0C") }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'ACH' }), input: React.createElement(Input, { type: 'number', step: 0.1, value: (_b = company.estimate_default_ach) !== null && _b !== void 0 ? _b : DEFAULT_AIR_CHANGES, validator: validateIsPositiveNumberOrZero, validateImmediately: true, setValue: ach => {
                                if (validateIsPositiveNumberOrZero(ach)) {
                                    setCompany({ ...company, estimate_default_ach: parseFloat(ach) });
                                }
                            } }) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Rad changes (%)', info: React.createElement(Info, { infoModalHeader: 'Radiator changes', infoModalBody: 'Approximate percentage of the radiators you would typically replace on a job. We estimate the number of radiators in the home based on the enquiry data, then apply this percentage to approximate the number of rad changes. These are included on the estimates by default. If you don\'t want to show any radiator changes by default, set this to 0%.' }) }), input: React.createElement(Input, { type: 'number', step: 1, value: (_c = company.estimate_default_radiator_change_percentage) !== null && _c !== void 0 ? _c : DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE, validator: e => validateIntegerIsInRange(e, 0, 100), validateImmediately: true, setValue: percentage => {
                                if (validateIntegerIsInRange(percentage, 0, 100)) {
                                    setCompany({ ...company, estimate_default_radiator_change_percentage: parseInt(percentage) });
                                }
                            }, postfix: React.createElement(React.Fragment, null, "%") }) })),
                React.createElement(FlowTempSlider, { minFlowTemp: 35, maxFlowTemp: 55, flowTemp: (_d = company.default_flow_temp_c) !== null && _d !== void 0 ? _d : DEFAULT_FLOW_TEMP_C, setFlowTemp: flowTemp => setCompany({ ...company, default_flow_temp_c: flowTemp }) }))));
};
