import React, { useState } from 'react';
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper';
import { FloorplanHeatLossSettingsBlock } from './floorplan/floorplan_heatloss_settings_block';
import { updateDefaultMaterial } from '../../../code/models/material';
import _ from 'lodash';
import { MaterialInputField } from '../materials/material_input_field';
import { MaterialsSelector } from '../materials/materials_selector';
import { PageHeader } from '../design/components/design_page_header';
import { CustomMaterialPopup } from '../materials/custom_material_popup';
export const FloorplanFlow = (props) => {
    const [selectorProps, setSelectorProps] = useState();
    const [tempCustomMaterial, setTempCustomMaterial] = useState();
    const [customMaterialHeader, setCustomMaterialHeader] = useState();
    const selectedMaterialsSet = props.survey.default_materials;
    const [innerPages, setInnerPages] = useState(['FLOW']);
    const innerPage = innerPages[innerPages.length - 1];
    const setInnerPage = (page) => setInnerPages(prev => [...prev, page]);
    const handleMaterialUpdate = (material) => {
        updateDefaultMaterial(props.survey, props.setSurvey, material);
    };
    // WARNING! Never change flagBits for the pages because they are stored in the DB.
    // instead, add new pages or replace existing with new flags
    const pages = [
        {
            flagBit: 0x001,
            pbValue: 25,
            page: React.createElement(FloorplanHeatLossSettingsBlock, { survey: props.survey, setSurvey: props.setSurvey, designTempDefault: props.designTempDefault, altitudeDefaultM: props.altitudeDefaultM })
        },
        {
            flagBit: 0x002,
            pbValue: 50,
            page: React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Walls"),
                React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                    React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "External wall"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'External wall',
                            surfaceType: 'external-wall',
                            materials: props.materials,
                            ageBand: props.survey.age_band,
                            selectedMaterial: selectedMaterialsSet === null || selectedMaterialsSet === void 0 ? void 0 : selectedMaterialsSet.externalWall,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })),
                React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                    React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "Party wall"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Party wall',
                            surfaceType: 'party-wall',
                            materials: props.materials,
                            ageBand: props.survey.age_band,
                            selectedMaterial: selectedMaterialsSet === null || selectedMaterialsSet === void 0 ? void 0 : selectedMaterialsSet.partyWall,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })),
                React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                    React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "Internal wall"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Internal wall',
                            surfaceType: 'internal-wall',
                            materials: props.materials,
                            ageBand: props.survey.age_band,
                            selectedMaterial: selectedMaterialsSet === null || selectedMaterialsSet === void 0 ? void 0 : selectedMaterialsSet.internalWall,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })))
        },
        {
            flagBit: 0x004,
            pbValue: 75,
            page: React.createElement(React.Fragment, null,
                React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                    React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Floors"),
                    React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                        React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "Ground floor"),
                        React.createElement(MaterialInputField, { selectorProps: {
                                title: 'Ground floor',
                                surfaceType: 'ground-floor',
                                materials: props.materials,
                                ageBand: props.survey.age_band,
                                selectedMaterial: selectedMaterialsSet === null || selectedMaterialsSet === void 0 ? void 0 : selectedMaterialsSet.groundFloor,
                                onSelectCallback: handleMaterialUpdate
                            }, setMsProps: setSelectorProps, setPage: setInnerPage })),
                    React.createElement("div", { className: "w-full flex-col justify-start items-start gap-2 flex" },
                        React.createElement("div", { className: "self-stretch font-bold text-gray-900" }, "Intermediate floors/ceilings"),
                        React.createElement(MaterialInputField, { selectorProps: {
                                title: 'Intermediate floors/ceilings',
                                surfaceType: 'intermediate-floor-and-ceiling',
                                materials: props.materials,
                                ageBand: props.survey.age_band,
                                selectedMaterial: selectedMaterialsSet === null || selectedMaterialsSet === void 0 ? void 0 : selectedMaterialsSet.intermediateFloorAndCeiling,
                                onSelectCallback: handleMaterialUpdate
                            }, setMsProps: setSelectorProps, setPage: setInnerPage }))),
                React.createElement("div", { className: "self-stretch h-px border border-gray-200" }),
                React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                    React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Roof"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Roof',
                            surfaceType: 'roof',
                            materials: props.materials,
                            ageBand: props.survey.age_band,
                            selectedMaterial: selectedMaterialsSet === null || selectedMaterialsSet === void 0 ? void 0 : selectedMaterialsSet.roof,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })))
        },
        {
            flagBit: 0x008,
            pbValue: 100,
            page: React.createElement(React.Fragment, null,
                React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                    React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Doors"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Door',
                            surfaceType: 'door',
                            materials: props.materials,
                            ageBand: props.survey.age_band,
                            selectedMaterial: selectedMaterialsSet === null || selectedMaterialsSet === void 0 ? void 0 : selectedMaterialsSet.door,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })),
                React.createElement("div", { className: "self-stretch h-px border border-gray-200" }),
                React.createElement("div", { className: "self-stretch flex-col justify-start items-start gap-5 flex" },
                    React.createElement("div", { className: "self-stretch text-gray-900 text-xl font-bold" }, "Windows"),
                    React.createElement(MaterialInputField, { selectorProps: {
                            title: 'Window',
                            surfaceType: 'window',
                            materials: props.materials,
                            ageBand: props.survey.age_band,
                            selectedMaterial: selectedMaterialsSet === null || selectedMaterialsSet === void 0 ? void 0 : selectedMaterialsSet.window,
                            onSelectCallback: handleMaterialUpdate
                        }, setMsProps: setSelectorProps, setPage: setInnerPage })))
        }
    ];
    const [currentPage, setCurrentPage] = useState(0x001);
    if (innerPage === 'MATERIALS') {
        return React.createElement("div", { className: 'flex flex-col h-full' },
            React.createElement(PageHeader, { isOffline: props.isOffline, allSynced: props.allSynced, title: 'Choose a material', onBack: () => {
                    setInnerPages(prev => prev.slice(0, -1));
                } }),
            React.createElement("div", { className: 'overflow-y-auto' },
                React.createElement(MaterialsSelector, { groupedProps: selectorProps, setGroupedProps: setSelectorProps, setMaterialsCallback: props.setMaterials, setPage: setInnerPage, setTempCustomMaterial: setTempCustomMaterial, onBack: () => setInnerPages(prev => prev.slice(0, -1)), companyUUID: props.companyUUID })));
    }
    if (innerPage === 'CUSTOM_MATERIAL') {
        return React.createElement("div", { className: 'flex flex-col h-full' },
            React.createElement("div", { className: 'px-5 pt-4 pb-3 gap-2 flex flex-col border-b border-gray-300 bg-white' }, customMaterialHeader),
            React.createElement("div", { className: 'overflow-y-auto' },
                React.createElement(CustomMaterialPopup, { material: tempCustomMaterial, materialsLayers: props.materialsLayers, setMaterial: setTempCustomMaterial, onSave: (newMaterial) => {
                        // NB! do not override materials with the `materials` variable!
                        // because it contains filtered values for the current surface and will override the global materials list
                        props.setMaterials(prev => ([...prev, newMaterial]));
                        setSelectorProps(prev => ({
                            ...prev,
                            materials: [...prev.materials, newMaterial],
                            selectedMaterial: newMaterial
                        }));
                        if (selectorProps.onSelectCallback) {
                            // trigger global callback
                            selectorProps.onSelectCallback(newMaterial);
                        }
                    }, setVisible: () => setInnerPages(prev => prev.slice(0, -1)), onBack: () => setInnerPages(prev => prev.slice(0, -1)), setHeader: setCustomMaterialHeader })));
    }
    return React.createElement(SurveySectionFlowWrapper, { completeSectionID: 'FLOOR', hideCompleteButton: true, completionChecks: [], isOffline: props.isOffline, allSynced: props.allSynced, sectionUIName: 'Start floor plan', pages: pages, flags_attribute_name: 'flags_floorplan_pages_completed', startPage: currentPage, onPageShow: (flagBit) => {
            // save visited page as a page to continue flow from
            // it's required when we back from the materials selector sub-flow
            // without this approach, we will always start from the first page
            setCurrentPage(flagBit);
        }, onPageComplete: async (flagBit) => {
            // mark some sections as completed if user has seen them
            switch (flagBit) {
                case 0x001:
                    // user sees materials: mark SETTINGS section as completed
                    await props.setSurvey(prev => ({ ...prev, completed_sections: _.uniq([...prev.completed_sections, 'SETTINGS']) }));
                    break;
                case 0x008:
                    // user sees floorplan: mark MATERIALS section as completed
                    await props.setSurvey(prev => ({ ...prev, completed_sections: _.uniq([...prev.completed_sections, 'MATERIALS']) }));
                    break;
            }
            // mark page as visited
            await props.setSurvey(prev => ({
                ...prev,
                flags_floorplan_pages_completed: props.survey.flags_floorplan_pages_completed | flagBit
            }));
        }, onFormComplete: () => props.navigateTo('/survey/new_floor'), onClose: () => window.history.back(), survey: props.survey, setSurvey: props.setSurvey });
};
