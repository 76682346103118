import React from 'react';
import { Checkbox } from '../inputs_and_selections/checkbox';
const sizes = {
    SM: 'text-xs',
    MD: 'text-sm'
};
export const TableLite = ({ selectedIds, setSelectedIds, columns, rows, rowIdName, size = 'MD', showCheckboxes = true, alignRight = true }) => {
    const toggleSelected = setSelectedIds && ((id) => setSelectedIds(prev => prev.includes(id) ? prev.filter(x => x !== id) : [...prev, id]));
    const allChecked = selectedIds && rowIdName && rows.every(x => selectedIds.includes(x[rowIdName]));
    return React.createElement("table", { className: `${sizes[size]} text-gray-600 w-full sm:divide-y border-light` },
        React.createElement("thead", { className: 'uppercase tracking-wide text-xs text-gray-500 font-semibold hidden sm:table-header-group' },
            React.createElement("tr", null,
                showCheckboxes && setSelectedIds && rowIdName && React.createElement("th", { className: 'py-2 text-start' },
                    React.createElement(Checkbox, { checked: !!allChecked, setChecked: () => allChecked ? setSelectedIds([]) : setSelectedIds(rows.map(x => x[rowIdName])) })),
                columns.map((x, i) => React.createElement("th", { key: i, className: `p-2 first:px-0 last:px-0 ${alignRight ? i === 0 ? 'text-start' : 'sm:text-end' : 'text-start'}` }, x.name)))),
        React.createElement("tbody", { className: 'sm:divide-y sm:border-light' }, rows.map((x, xi) => {
            return React.createElement("tr", { key: xi, className: 'flex flex-col sm:table-row border border-light rounded sm:border-0 mb-2 p-2 sm:mb-0 sm:p-0' },
                showCheckboxes && selectedIds && toggleSelected && rowIdName && React.createElement("td", { className: 'p-2' },
                    React.createElement(Checkbox, { checked: selectedIds.includes(x[rowIdName]), setChecked: () => toggleSelected(x[rowIdName]) })),
                columns.map((y, yi) => React.createElement("td", { key: yi, className: `grid grid-cols-[1fr_2fr] sm:table-cell border-b last:border-0 border-gray-100 sm:border-0 ${alignRight ? yi === 0 ? 'text-start' : 'sm:text-end' : 'text-start'} py-2 sm:p-2 sm:first:ps-0 sm:last:pe-0` },
                    (x[y.key] !== undefined) && React.createElement("span", { className: 'sm:hidden uppercase tracking-wide text-xs text-gray-500 font-semibold' }, columns[yi].name),
                    y.render ? y.render(x) : React.createElement("div", null, x[y.key]))));
        })));
};
