import { sum } from 'lodash';
import { getRoomTemp, getRoomACH, getRoomWatts } from '../../../../code/models/heat_loss';
import { getEmitterWatts } from '../../../../code/models/radiator_model';
import { getAreaM2, getEmitterOutputVsDemandText } from './utils';
export const getRoomsWithMetaData = (survey, floor, designTempC, groundTempC, design, stageScale) => {
    const roomsWithMetaData = floor.rooms.map(x => {
        const text = x.name;
        const areaCalculated = `${getAreaM2(x.walls.map(w => ({ x: w.x * stageScale, y: w.y * stageScale })))} m²`;
        const roomTempC = getRoomTemp(x, survey);
        const ACH = getRoomACH(x, survey);
        const radiatorText = x.radiators.length > 0
            // if it's UFH show "UFH" rather than "1 Rads". If they also have radiators, just still only show UFH as it's rare that you would have UFH and radiators
            ? x.radiators.find(r => r.emitter_type === 'UNDERFLOOR') ? 'UFH' : `${x.radiators.length} Rads`
            : '';
        const rooflightText = x.rooflights.length > 0 ? `${x.rooflights.length} RL` : '';
        const roomText = [rooflightText, radiatorText, `${roomTempC}°C`, `${ACH} ACH`].filter(x => !!x).join(' • ');
        const wattsLost = Math.round(getRoomWatts(x, floor.rooms, designTempC, groundTempC, survey));
        const wattsEmitted = Math.round(sum(x.radiators.map(y => getEmitterWatts(y, x, design, survey, designTempC, groundTempC))));
        const emitterOutputVsRoomDemandText = getEmitterOutputVsDemandText(wattsEmitted, wattsLost);
        return {
            ...x,
            text,
            areaCalculated,
            emitterOutputVsRoomDemandText,
            wattsLost,
            roomText
        };
    });
    return roomsWithMetaData;
};
