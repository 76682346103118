import React from 'react';
import { ACH_AGES, THERMAL_BRIDGING_ADDITIONS } from '../../constants';
import { Select } from '../../../../components/inputs_and_selections/select';
import { Icon } from '../../../../components/buttons/icon';
import { ClickableCard } from '../../../../components/content_display/card';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../components/inputs_and_selections/input';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { HorizontalFormGroup } from '../../../../components/inputs_and_selections/horizontal_form_group';
import { Info } from '../../../../components/buttons/info';
import { Link } from '../../../../components/buttons/link';
import { openInNewTab } from '../../../../code/helpers';
import { XCircle } from 'lucide-react';
export const FloorplanHeatLossSettingsBlock = ({ survey, setSurvey, designTempDefault, altitudeDefaultM }) => {
    var _a, _b, _c;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex-col justify-start items-start gap-3 flex" },
            React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Settings"),
            React.createElement("div", { className: "" }, "Set the calculating conditions for the property.")),
        React.createElement("div", { className: "flex flex-col gap-5 " },
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Altitude', helperText: 'Automatically filled in based on the address' }), input: React.createElement(Input, { type: 'number', value: (_b = (_a = survey.altitude_override_m) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : altitudeDefaultM.toString(), setValue: (e) => setSurvey(prev => ({ ...prev, altitude_override_m: Number(e) })), postfix: React.createElement("div", { className: "gap-2.5 flex items-center" },
                        React.createElement("div", { className: "" }, "m"),
                        survey.altitude_override_m !== undefined && survey.altitude_override_m !== altitudeDefaultM &&
                            React.createElement(Icon, { icon: XCircle, onClick: () => setSurvey(prev => ({ ...prev, altitude_override_m: undefined })), colour: 'text-gray-400' })) }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Design outdoor temperature', helperText: 'This defaults to the 99th percentile', info: React.createElement(Info, { infoModalHeader: 'Design outdoor temperature', infoModalBody: React.createElement("div", null,
                            "The heat pump must be able to keep the home at the specified room temperatures when it's this temperature outside. This is automatically populated based on the property's location and altitude using the values in the CIBSE's Guide A - Environmental Design. More info ",
                            React.createElement(Link, { onClick: () => openInNewTab('https://spruce-energy.notion.site/Design-outdoor-temperature-247b30607c514fdbaca06bc2e92a84c5'), className: 'inline', text: 'here' }),
                            ".") }) }), input: React.createElement(Input, { type: 'number', value: (_c = survey.design_temp_override_c) !== null && _c !== void 0 ? _c : designTempDefault, setValue: (e) => setSurvey(prev => ({ ...prev, design_temp_override_c: Number(e) })), postfix: React.createElement("div", { className: "gap-2.5 flex items-center" },
                        React.createElement("div", { className: "" }, "\u00B0C"),
                        survey.design_temp_override_c !== undefined && survey.design_temp_override_c !== designTempDefault &&
                            React.createElement(Icon, { icon: XCircle, onClick: () => setSurvey(prev => ({ ...prev, design_temp_override_c: undefined })), colour: 'text-gray-400' })) }) }),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Indoor temperature based on CIBSE defaults', helperText: 'Based on room type' }), input: React.createElement(Toggle, { size: 'LG', value: survey.use_cibse_indoor_temps, setValue: () => setSurvey(prev => ({
                                ...prev, use_cibse_indoor_temps: !survey.use_cibse_indoor_temps
                            })) }) }),
                    !survey.use_cibse_indoor_temps &&
                        React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Indoor temperature default' }), input: React.createElement(Input, { type: 'number', value: survey.indoor_temp_overall_c.toString(), setValue: (e) => setSurvey(prev => ({ ...prev, indoor_temp_overall_c: Number(e) })), postfix: React.createElement("span", { className: 'text-gray-500' }, "\u00B0C") }) }))),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Air changes per hours based on CIBSE defaults', info: React.createElement(Info, { infoModalHeader: 'CIBSE defaults', infoModalBody: React.createElement("div", null,
                                    "The CIBSE default ACH values are based on the room type and age band of the property. ",
                                    React.createElement(Link, { onClick: () => openInNewTab('https://docs.openenergymonitor.org/heatpumps/air_change_rate_calculations.html'), className: 'inline', text: 'Other methods and empirical data' }),
                                    " suggest that these values are very conservative and may overestimate the actual ACH of the property.") }), helperText: 'Based on room type and age band' }), input: React.createElement(Toggle, { size: 'LG', value: survey.use_cibse_air_change_values, setValue: () => setSurvey(prev => ({
                                ...prev,
                                use_cibse_air_change_values: !survey.use_cibse_air_change_values
                            })) }) }),
                    survey.use_cibse_air_change_values &&
                        React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Property age' }), input: React.createElement(Select, { selectedKey: survey.air_change_year_uuid, options: ACH_AGES, setSelectedKey: (e) => setSurvey(prev => ({ ...prev, air_change_year_uuid: e })) }) }),
                    !survey.use_cibse_air_change_values &&
                        React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Air changes per hour default', info: React.createElement(Info, { infoModalHeader: 'Air changes input', infoModalBody: 'The air changes per hour (ACH) value is the number of times the volume of air in a room is replaced in an hour. ' +
                                        ' This should be the sum of the air changes due to mechanical ventilation and the air changes from uncontrolled infiltration. ' }) }), input: React.createElement(Input, { type: 'number', value: survey.air_change_per_hour_overall.toString(), setValue: (e) => setSurvey(prev => ({ ...prev, air_change_per_hour_overall: Number(e) })) }) }))),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Thermal bridging', helperText: 'Increase u-values to account for thermal bridging', info: React.createElement(Info, { infoModalHeader: 'Thermal bridging', infoModalBody: React.createElement("div", null, "A thermal bridge is a detail of a building's construction that allows heat to pass through significantly more easily than through the surrounding materials. This is typically where there is a break in the insulation, less insulation or the insulation is penetrated by an element with a higher thermal conductivity, such as wall ties, junctions in materials, or lintels. This can lead to a higher heat loss at this point than the calculated value. It is not accounted for in the standard u-values.") }) }), input: React.createElement(Toggle, { size: 'LG', value: survey.include_thermal_bridging, setValue: () => setSurvey(prev => ({
                            ...prev,
                            include_thermal_bridging: !survey.include_thermal_bridging
                        })) }) }),
                survey.include_thermal_bridging &&
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Increase in u-value based on', info: React.createElement(Info, { infoModalHeader: 'Increase in u-value based on thermal bridging', infoModalBody: React.createElement("div", null,
                                    "These options are based on Table 3.4 in the CIBSE domestic heating design guide:",
                                    React.createElement("ul", { className: "list-disc pl-5 mt-1 space-y-1" },
                                        React.createElement("li", null,
                                            React.createElement("strong", null, "All other buildings - 0.1 W/m\u00B2K:"),
                                            " All other buildings"),
                                        React.createElement("li", null,
                                            React.createElement("strong", null, "Insulation above current national standards - 0.02 W/m\u00B2K:"),
                                            " Buildings built with a high level of heat insulation (over and above current national standards) and which implement minimisation of thermal bridging practices that exceed generally recognised rules of practice"),
                                        React.createElement("li", null,
                                            React.createElement("strong", null, "Current national standards - 0.05 W/m\u00B2K:"),
                                            " Buildings built to current national standards and in compliance with generally recognised rules of practice regarding the minimisation of thermal bridges"),
                                        React.createElement("li", null,
                                            React.createElement("strong", null, "Exterior wall insulation broken by solid ceilings - 0.15 W/m\u00B2K:"),
                                            " Buildings with exterior wall insulation broken by solid ceilings (e.g. reinforced concrete)"))) }) }), input: React.createElement(Select, { selectedKey: survey.thermal_bridging_addition_key, options: THERMAL_BRIDGING_ADDITIONS, setSelectedKey: (e) => setSurvey(prev => ({
                                ...prev,
                                thermal_bridging_addition_key: e
                            })) }) })),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Intermittent heating required', helperText: 'Home is heated intermittently so owner wants oversize system to reduce reheat time (not advised)' }), input: React.createElement(Toggle, { size: 'LG', value: survey.intermittent_heating, setValue: () => setSurvey(prev => ({
                            ...prev,
                            intermittent_heating: !survey.intermittent_heating
                        })) }) }),
                survey.intermittent_heating &&
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Oversize factor' }), input: React.createElement(Input, { type: 'number', value: survey.intermittent_heating_oversize_factor_percentage.toString(), setValue: (e) => setSurvey(prev => ({
                                ...prev,
                                intermittent_heating_oversize_factor_percentage: Number(e)
                            })), postfix: '%' }) })),
            React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'MVHR installed', helperText: 'Mechanical ventilation with heat recovery is installed at the property', info: React.createElement(Info, { infoModalHeader: 'How we treat MVHR', infoModalBody: React.createElement("div", { className: "space-y-4" },
                            React.createElement("p", null, "Reduces the modelled ventilation heat loss by 50%."),
                            React.createElement("p", null, "This is an approximation based on the assumption that the MVHR has a sensible heat recovery efficiency of ~80% and that ~60% of the total ventilation goes through the MVHR, with the remaining ~40% being uncontrolled infiltration."),
                            React.createElement("p", null, "For this to be correct you need to ensure that the ACH value entered covers both the mechanical ventilation and uncontrolled infiltration.")) }) }), input: React.createElement(Toggle, { size: 'LG', value: survey.mvhr_installed, setValue: () => setSurvey(prev => ({
                        ...prev,
                        mvhr_installed: !survey.mvhr_installed
                    })) }) }),
            survey.mvhr_installed && React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Reduction in ventilation heat loss (%)' }), input: React.createElement(Input, { type: 'number', value: '50', postfix: '%', disabled: true, setValue: () => { } }) }),
            React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Exposed location', helperText: 'Property is exposed to adverse weather conditions (e.g. located close to the coast)' }), input: React.createElement(Toggle, { size: 'LG', value: survey.exposed_location, setValue: () => setSurvey(prev => ({
                        ...prev,
                        exposed_location: !survey.exposed_location,
                        design_temp_override_c: undefined
                    })) }) })));
};
