import React from 'react';
export const Error404Page = () => {
    return (React.createElement("div", { className: "flex flex-col justify-center items-center mt-10 gap-y-2" },
        React.createElement("div", { className: "text-9xl font-semibold text-slate-700" }, "404"),
        React.createElement("div", null, "Sorry, we couldn't find this page."),
        React.createElement("div", null, "Have you entered the correct company name?")));
};
export const Error500Page = ({ error }) => {
    var _a;
    return (React.createElement("div", { className: "flex flex-col justify-center items-center mt-10 gap-y-2" },
        React.createElement("div", { className: "text-9xl font-semibold text-slate-700" }, "500"),
        React.createElement("div", null, ((_a = error === null || error === void 0 ? void 0 : error.cause) === null || _a === void 0 ? void 0 : _a.message) || JSON.stringify(error)),
        React.createElement("div", null,
            React.createElement("span", { onClick: () => { window.location.reload(); }, className: "text-blue-500 underline cursor-pointer" }, "Click here"),
            " to reload the app.")));
};
