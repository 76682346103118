import React, { useEffect, useState } from 'react';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Button } from '../../../../components/buttons/button';
import { AttachmentCard } from '../../../../code/proposal_attachments/attachment_card';
import { Section } from '../components/section';
import { deleteProposalLink, insertProposalLink } from '../../../../code/models/proposal_link';
import { Plus } from 'lucide-react';
// Map category keys to their titles
export const proposalCategoryTitles = {
    proposal_terms_and_conditions: 'Terms & Conditions',
    proposal_heat_pump_spec: 'Heat pump data sheet',
    proposal_hot_water_cylinder_spec: 'Hot water cylinder data sheet'
};
export const ProposalFiles = ({ proposal, setProposal, companyPublicInfo }) => {
    var _a, _b, _c, _d, _e;
    // Required attachments
    const [requiredAttachments, setRequiredAttachments] = useState([
        { key: 'proposal_terms_and_conditions', attachment: (_a = proposal.links) === null || _a === void 0 ? void 0 : _a.find(link => link.category === 'proposal_terms_and_conditions') },
        { key: 'proposal_heat_pump_spec', attachment: (_b = proposal.links) === null || _b === void 0 ? void 0 : _b.find(link => link.category === 'proposal_heat_pump_spec') },
        { key: 'proposal_hot_water_cylinder_spec', attachment: (_c = proposal.links) === null || _c === void 0 ? void 0 : _c.find(link => link.category === 'proposal_hot_water_cylinder_spec') }
    ]);
    // Allow installer to upload additional documents
    const [customAttachments, setCustomAttachments] = useState((_e = (_d = proposal.links) === null || _d === void 0 ? void 0 : _d.filter(link => link.category === 'proposal_custom')) !== null && _e !== void 0 ? _e : []);
    const createAttachment = (uuid, title, url, internal, category) => {
        // We get the URL from the external link, or from the newly uploaded internal file downstream.
        return { uuid, title, url, internal, category };
    };
    // Helper function for adding / removing required attachments. Doing it inline was too messy.
    const handleRequiredAttachmentChange = (key, title, url, internal, isAdding) => {
        var _a, _b;
        // Generate 1 uuid for both state and DB updates
        const uuid = crypto.randomUUID();
        setRequiredAttachments(prev => prev.map(e => e.key === key ? { key: e.key, attachment: isAdding ? createAttachment(uuid, title, url, internal, key) : undefined } : e));
        if (isAdding) {
            insertProposalLink({
                uuid,
                title,
                url,
                internal,
                category: key
            }, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
        }
        else {
            const uuidToDelete = (_b = (_a = proposal.links) === null || _a === void 0 ? void 0 : _a.find(link => link.category === key)) === null || _b === void 0 ? void 0 : _b.uuid;
            if (uuidToDelete) {
                deleteProposalLink(uuidToDelete, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
            }
        }
    };
    const handleCustomAttachmentChange = (index, title, url, internal, isAdding) => {
        var _a;
        // Generate 1 uuid for both state and DB updates
        const uuid = crypto.randomUUID();
        setCustomAttachments(prev => prev.map((e, i) => i === index ? (isAdding ? createAttachment(uuid, title, url, internal, 'proposal_custom') : undefined) : e).filter(e => e !== undefined));
        if (isAdding) {
            insertProposalLink({
                uuid,
                title,
                url,
                internal,
                category: 'proposal_custom'
            }, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
        }
        else {
            const uuidToDelete = (_a = customAttachments[index]) === null || _a === void 0 ? void 0 : _a.uuid;
            if (uuidToDelete) {
                deleteProposalLink(uuidToDelete, proposal.uuid, proposal.lead_uuid, companyPublicInfo.uuid);
            }
        }
    };
    // Update proposal when attachments change
    useEffect(() => {
        const links = [
            ...requiredAttachments.map(e => e.attachment).filter((e) => e !== undefined),
            ...customAttachments.filter((e) => e !== undefined)
        ];
        setProposal({ ...proposal, links });
    }, [requiredAttachments, customAttachments]);
    return (React.createElement(Section, null,
        React.createElement(FormLabel, { size: 'LG', labelText: 'Documents', helperText: 'To ensure MCS compliance, we recommend you include the following documents.' }),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            requiredAttachments.map(({ key }) => {
                var _a;
                return (React.createElement(AttachmentCard, { key: key, title: proposalCategoryTitles[key], attachment: (_a = requiredAttachments.find(e => e.key === key)) === null || _a === void 0 ? void 0 : _a.attachment, forceTitle: proposalCategoryTitles[key], onAddAttachment: (title, url, internal) => handleRequiredAttachmentChange(key, proposalCategoryTitles[key], url, internal, true), onRemoveAttachment: () => handleRequiredAttachmentChange(key, '', '', false, false) }));
            }),
            React.createElement(FormLabel, { size: 'LG', labelText: 'Extra documents', helperText: 'You can also include any other supporting documentation.' }),
            customAttachments.map((customAttachment, index) => (React.createElement(AttachmentCard, { showLabel: false, key: index, attachment: customAttachment, onAddAttachment: (title, url, internal) => handleCustomAttachmentChange(index, title, url, internal, true), onRemoveAttachment: () => handleCustomAttachmentChange(index, '', '', false, false) })))),
        React.createElement(Button, { iconLeft: Plus, colour: 'LIGHT', className: 'w-fit', disabled: customAttachments.length > 0 && customAttachments.some(attachment => attachment === undefined), onClick: () => setCustomAttachments(prev => [...prev, undefined]) }, "Add document")));
};
