import React from 'react';
import { Modal } from '../../../../components/containers/modal';
import { Loader } from '../../../../components/indicators_and_messaging/loader';
import { yellowEnvelope } from '../../../../assets/images/images';
import { Input } from '../../../../components/inputs_and_selections/input';
import _, { noop } from 'lodash';
import { Icon } from '../../../../components/buttons/icon';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Tiptap } from '../../../../components/inputs_and_selections/tiptap';
import { validateEmail } from '../../../../code/validators';
import { Send } from 'lucide-react';
export const SendEmailWithTemplateModal = (props) => {
    const [sendingStatus, setSendingStatus] = React.useState('INIT');
    const [emailRecipients, setEmailRecipients] = React.useState(props.emailRecipients);
    const [emailText, setEmailText] = React.useState(props.emailText);
    const recipientsStringToArray = () => {
        return _.uniq(_.compact(props.emailRecipients.split(',').map(r => validateEmail(r.trim()).value)));
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Modal, { visible: props.visible, setVisible: props.setVisible, title: 'Send via Email', cancelDisabled: sendingStatus !== 'INIT', onConfirm: async () => {
                // dual logic for the confirm button: either send the email or close the modal
                if (sendingStatus === 'INIT') {
                    setSendingStatus('SENDING');
                    await props.handleSend(recipientsStringToArray());
                    setSendingStatus('SENT');
                }
                else {
                    props.setVisible(false);
                    setSendingStatus('INIT');
                }
            }, confirmButtonLabel: sendingStatus === 'INIT' ? 'Send' : 'Close', confirmDisabled: sendingStatus === 'SENDING', hideOnConfirm: false },
            React.createElement("div", { className: 'w-full flex flex-col gap-6' },
                sendingStatus === 'SENDING' && React.createElement(Loader, null),
                sendingStatus === 'SENT' && React.createElement("div", { className: 'px-5 py-8 bg-green-50 rounded-lg flex-col justify-center items-center gap-2 flex' },
                    React.createElement("img", { alt: 'envelope', src: yellowEnvelope, className: 'w-16 h-16' }),
                    React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Success"),
                    React.createElement("div", { className: "text-center flex flex-col gap-1" },
                        React.createElement("span", null, "Your email was successfully sent to"),
                        React.createElement("div", { className: 'flex flex-col gap-0' }, recipientsStringToArray().map((r, i) => React.createElement("span", { className: 'font-bold', key: i }, r))))),
                sendingStatus === 'INIT' && React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'flex flex-col gap-0' },
                        React.createElement("p", null, "Send an email to your recipient(s) with a link to the estimate."),
                        React.createElement("p", null, "You can edit this templated email in your Settings.")),
                    React.createElement("div", { className: 'flex flex-col gap-3 w-full' },
                        React.createElement(Input, { onEnter: noop, value: emailRecipients, setValue: (e) => {
                                setEmailRecipients(e);
                                props.setEmailRecipients(e);
                            }, type: 'text', label: 'Send to', placeholder: 'jack@example.com, robert@example.com', size: 'MD', shrink: false, validator: (e) => ({ message: '', value: e }), className: '', prefix: React.createElement(Icon, { icon: Send }) }),
                        React.createElement("div", { className: 'flex flex-wrap gap-3' }, recipientsStringToArray().map((r, i) => React.createElement("span", { key: i, className: 'rounded-lg px-2 py-1 text-sm bg-gray-200' }, r)))),
                    React.createElement("div", { className: 'flex flex-col gap-2' },
                        React.createElement(FormLabel, { labelText: 'Customer email', size: 'SM' }),
                        React.createElement("div", { className: 'flex flex-col gap-4' },
                            React.createElement("div", { className: 'space-y-2' },
                                React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                                        setEmailText(editor.getJSON());
                                        props.setEmailText(editor.getJSON());
                                    }, placeholders: props.editorPlaceholders, content: emailText }))))))));
};
