import React from 'react';
const variants = {
    ACTIVE: 'shadow bg-white text-gray-900',
    DEFAULT: 'text-gray-600 bg-gray-100'
};
export const TabGroup = ({ items }) => {
    return React.createElement("div", { className: "p-1 bg-gray-100 rounded-lg gap-1 flex overflow-x-auto w-full" }, items.map(x => React.createElement("div", { "data-cy": "tab", onClick: x.onClick, key: x.name, className: `${variants[x.variant]} p-1 rounded-md justify-center items-center flex cursor-pointer flex-1 whitespace-nowrap` },
        React.createElement("div", { className: "px-2 items-center gap-2 flex" },
            React.createElement("div", { className: "text-sm font-semibold" }, x.name),
            x.secondaryText && React.createElement("div", { className: "text-xs text-gray-500" }, x.secondaryText)))));
};
