import intersect from '@turf/intersect';
import { featureCollection, polygon } from '@turf/helpers';
import { compact } from 'lodash';
import { captureException } from '@sentry/react';
export const getIntersectingShapes = (rooms) => {
    try {
        const roomsToPolygons = rooms.map(r => ({ uuid: r.uuid, coords: r.walls.map(w => [w.x + r.x, w.y + r.y]) }));
        const shapes = roomsToPolygons.map(r => ({ ...r, coords: polygon([[...r.coords, r.coords[0]]]) })); // Turf requires a closed shape to repeat the first coord, which is different to Konva.
        const results = shapes.map(x => {
            const intersections = compact(shapes
                .filter(y => y.uuid !== x.uuid) // don't compare shape to itself
                .map(y => {
                var _a;
                const result = intersect(featureCollection([x.coords, y.coords]));
                return (_a = result === null || result === void 0 ? void 0 : result.geometry) === null || _a === void 0 ? void 0 : _a.coordinates;
            }));
            return intersections;
        }).flat().flat();
        return results;
    }
    catch (e) {
        captureException(e);
    }
    return [];
};
