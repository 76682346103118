export const getWallUValue = (wall, thermalBridgingUValueAdditionWPerM2K) => {
    var _a, _b, _c, _d, _e, _f;
    // Thermal bridging is only added to external elements
    const uValueAddition = ((_a = wall.material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'external-wall' ? thermalBridgingUValueAdditionWPerM2K : 0;
    if (['custom_layered', 'custom_simple'].includes(wall.material.type)) {
        return ((_b = wall.material) === null || _b === void 0 ? void 0 : _b.extra_data).u_value + uValueAddition;
    }
    switch ((_c = wall.material) === null || _c === void 0 ? void 0 : _c.applicable_to) {
        case 'internal-wall':
            return ((_d = wall.material) === null || _d === void 0 ? void 0 : _d.extra_data).u_value;
        case 'external-wall':
            return ((_e = wall.material) === null || _e === void 0 ? void 0 : _e.extra_data).u_value + uValueAddition;
        case 'party-wall':
            return ((_f = wall.material) === null || _f === void 0 ? void 0 : _f.extra_data).u_value;
        default:
            return 0;
    }
};
