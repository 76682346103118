import React from 'react';
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper';
import { CylinderLocationExistingPhotosBlock } from './cylinder_location/cylinder_location_photos_block';
import { CylinderLocationExistingDetailsBlock } from './cylinder_location/cylinder_location_existing_details_block';
import { CylinderLocationExistingPipesBlock } from './cylinder_location/cylinder_location_pipes_block';
import { CylinderLocationHasExistingHWCBlock } from './cylinder_location/cylinder_location_has_existing_hwc_block';
import { CylinderLocationProposedPhotosBlock } from './cylinder_location/cylinder_location_proposed_photos_block';
import { getCylinderChecks } from './validations';
import { CylinderLocationProposedLocationBlock } from './cylinder_location/cylinder_location_proposed_location_block';
import { CylinderLocationPipeRunsBlock } from './cylinder_location/cylinder_location_pipes_runs_block';
export const SurveyCylinderLocationContext = React.createContext(undefined);
export const CylinderLocation = ({ setSurveyTab, survey, files, setFiles, setSurvey, companyUUID, isOffline, allSynced }) => {
    // WARNING! Never change flagBits for the pages because they are stored in the DB.
    // instead, add new pages or replace existing with new flags
    const pages = [
        { flagBit: 0x0001, pbValue: 10, page: React.createElement(CylinderLocationHasExistingHWCBlock, null) }, // show always
        // pages if HWC exists
        { flagBit: 0x0002, pbValue: 30, page: React.createElement(CylinderLocationExistingPhotosBlock, null), hideOrSkipPage: () => !survey.has_existing_cylinder },
        { flagBit: 0x0004, pbValue: 50, page: React.createElement(CylinderLocationExistingDetailsBlock, null), hideOrSkipPage: () => !survey.has_existing_cylinder },
        { flagBit: 0x0008, pbValue: 60, page: React.createElement(CylinderLocationExistingPipesBlock, null), hideOrSkipPage: () => !survey.has_existing_cylinder },
        // pages for proposed HWC location
        { flagBit: 0x0010, pbValue: 70, page: React.createElement(CylinderLocationProposedPhotosBlock, null) },
        { flagBit: 0x0020, pbValue: 90, page: React.createElement(CylinderLocationProposedLocationBlock, null) },
        // show always
        { flagBit: 0x0040, pbValue: 100, page: React.createElement(CylinderLocationPipeRunsBlock, null) }
    ];
    return React.createElement(SurveyCylinderLocationContext.Provider, { value: { isOffline, allSynced, survey, setSurvey, files, setFiles, setSurveyTab, companyUUID } },
        React.createElement(SurveySectionFlowWrapper, { isOffline: isOffline, allSynced: allSynced, completeSectionID: 'CYLINDER', sectionUIName: 'Cylinder location', pages: pages, flags_attribute_name: 'flags_cylinder_location_pages_completed', onPageComplete: (flagBit) => {
                setSurvey({ ...survey, flags_cylinder_location_pages_completed: survey.flags_cylinder_location_pages_completed | flagBit });
            }, onFormComplete: () => window.history.back(), survey: survey, setSurvey: setSurvey, completionChecks: getCylinderChecks(survey) }));
};
