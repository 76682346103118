import React from 'react';
import { Badge } from '../indicators_and_messaging/badge';
import { Check } from 'lucide-react';
export const FormLabel = ({ labelText, helperText, required = false, complete = false, size = 'MD', info = undefined }) => {
    return (React.createElement("div", { className: 'flex-col flex' },
        React.createElement("div", { className: 'flex justify-between gap-3' },
            React.createElement("div", { className: "items-center gap-1 flex" },
                React.createElement("div", { className: `text-bold font-bold ${size === 'XL' ? 'text-xl' : size === 'LG' ? 'text-lg' : size === 'SM' ? 'text-sm' : 'text-base'}` }, labelText),
                info && info),
            required &&
                React.createElement(Badge, { color: complete ? 'GREEN' : 'YELLOW', text: 'Required', icon: complete ? Check : undefined })),
        helperText &&
            React.createElement("div", { className: `text-gray-500 text-sm ${size === 'LG' ? 'text-base' : 'text-sm'}` }, helperText)));
};
