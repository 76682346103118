import React from 'react';
import { Caption } from './caption_1';
import { Header } from './header';
export const StepHeader = ({ guideText, headerText, captionText }) => {
    return React.createElement("div", { className: "flex flex-col gap-2" },
        React.createElement("div", { className: "flex flex-col gap-2" },
            guideText && React.createElement(Caption, { content: guideText, size: "sm", uppercase: true }),
            React.createElement(Header, { content: headerText })),
        captionText && React.createElement(Caption, { content: captionText }));
};
