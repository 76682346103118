import { linearInterpolate } from './range_heat_pump';
export const getFluidProperties = (fluidType, flowTempC, deltaTFlowReturnC) => {
    const temperatureC = flowTempC - deltaTFlowReturnC / 2;
    // Later take a dict with percentage of each fluid
    if (temperatureC < 20 || temperatureC > 80) {
        throw new Error('Temperature out of range');
    }
    let fluidPropertiesLowerBound, fluidPropertiesUpperBound;
    for (const fluidProperty of FLUID_PROPERTIES) {
        if (fluidProperty.type === fluidType && fluidProperty.temperature_c <= temperatureC && (!fluidPropertiesLowerBound || fluidPropertiesLowerBound.temperature_c < fluidProperty.temperature_c)) {
            fluidPropertiesLowerBound = fluidProperty;
        }
        if (fluidProperty.type === fluidType && fluidProperty.temperature_c >= temperatureC && (!fluidPropertiesUpperBound || fluidPropertiesUpperBound.temperature_c > fluidProperty.temperature_c)) {
            fluidPropertiesUpperBound = fluidProperty;
            break;
        }
    }
    if (!fluidPropertiesLowerBound || !fluidPropertiesUpperBound)
        return undefined;
    return {
        type: fluidType,
        temperature_c: temperatureC,
        density_kg_per_m3: linearInterpolate(fluidPropertiesLowerBound.temperature_c, fluidPropertiesLowerBound.density_kg_per_m3, fluidPropertiesUpperBound.temperature_c, fluidPropertiesUpperBound.density_kg_per_m3, temperatureC),
        specific_heat_capacity_j_per_kg_k: linearInterpolate(fluidPropertiesLowerBound.temperature_c, fluidPropertiesLowerBound.specific_heat_capacity_j_per_kg_k, fluidPropertiesUpperBound.temperature_c, fluidPropertiesUpperBound.specific_heat_capacity_j_per_kg_k, temperatureC),
        kinematic_viscosity_m_per_s: linearInterpolate(fluidPropertiesLowerBound.temperature_c, fluidPropertiesLowerBound.kinematic_viscosity_m_per_s, fluidPropertiesUpperBound.temperature_c, fluidPropertiesUpperBound.kinematic_viscosity_m_per_s, temperatureC)
    };
};
// Just support water for now but add glycol later
// NOTE: you MUST have properties between 20 and 80 degrees C if you add more properties here
export const FLUID_PROPERTIES = [
    { type: 'WATER', temperature_c: 20, density_kg_per_m3: 998.21, specific_heat_capacity_j_per_kg_k: 4182, kinematic_viscosity_m_per_s: 1.004e-6 },
    { type: 'WATER', temperature_c: 25, density_kg_per_m3: 997.05, specific_heat_capacity_j_per_kg_k: 4179.8, kinematic_viscosity_m_per_s: 8.931e-6 },
    { type: 'WATER', temperature_c: 30, density_kg_per_m3: 995.65, specific_heat_capacity_j_per_kg_k: 4179, kinematic_viscosity_m_per_s: 8.01e-7 },
    { type: 'WATER', temperature_c: 35, density_kg_per_m3: 994.03, specific_heat_capacity_j_per_kg_k: 4178.8, kinematic_viscosity_m_per_s: 7.233e-7 },
    { type: 'WATER', temperature_c: 40, density_kg_per_m3: 992.22, specific_heat_capacity_j_per_kg_k: 4179, kinematic_viscosity_m_per_s: 6.58e-7 },
    { type: 'WATER', temperature_c: 45, density_kg_per_m3: 990.21, specific_heat_capacity_j_per_kg_k: 4179.7, kinematic_viscosity_m_per_s: 6.032e-7 },
    { type: 'WATER', temperature_c: 50, density_kg_per_m3: 988.04, specific_heat_capacity_j_per_kg_k: 4181, kinematic_viscosity_m_per_s: 5.56e-7 },
    { type: 'WATER', temperature_c: 55, density_kg_per_m3: 985.69, specific_heat_capacity_j_per_kg_k: 4182.8, kinematic_viscosity_m_per_s: 5.14e-7 },
    { type: 'WATER', temperature_c: 60, density_kg_per_m3: 983.20, specific_heat_capacity_j_per_kg_k: 4185, kinematic_viscosity_m_per_s: 4.77e-7 },
    { type: 'WATER', temperature_c: 65, density_kg_per_m3: 980.55, specific_heat_capacity_j_per_kg_k: 4187.3, kinematic_viscosity_m_per_s: 4.452e-7 },
    { type: 'WATER', temperature_c: 70, density_kg_per_m3: 977.76, specific_heat_capacity_j_per_kg_k: 4190, kinematic_viscosity_m_per_s: 4.17e-7 },
    { type: 'WATER', temperature_c: 75, density_kg_per_m3: 974.84, specific_heat_capacity_j_per_kg_k: 4193.3, kinematic_viscosity_m_per_s: 3.908e-7 },
    { type: 'WATER', temperature_c: 80, density_kg_per_m3: 971.79, specific_heat_capacity_j_per_kg_k: 4197, kinematic_viscosity_m_per_s: 3.67e-7 }
];
