import { axiosDeleteV2, axiosGetV2, axiosPatchV2, axiosPostV2 } from '../axios';
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../calculate_estimate';
import { calculateQuote } from '../calculate_quote';
import { getApproxNumberOfRadiatorChanges } from './estimated_rooms_and_radiators';
// TODO SPR-1232: FIX
export const estimateAndLeadToCalculatedEstimate = (estimate, lead, adminData) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const [calculatedQuote] = calculateQuote({
        company: adminData.company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, (_b = (_a = adminData.company) === null || _a === void 0 ? void 0 : _a.estimate_default_radiator_change_percentage) !== null && _b !== void 0 ? _b : DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
        parts: adminData.parts,
        labour: adminData.labour,
        packs: adminData.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    return {
        name: lead.customer.name,
        address: lead.property.address,
        postcode: lead.property.postcode,
        total_watts: estimate.totalWatts,
        range_heat_pump_uuid: (_e = (_d = (_c = estimate.heatPump) === null || _c === void 0 ? void 0 : _c.range_heat_pump) === null || _d === void 0 ? void 0 : _d.uuid) !== null && _e !== void 0 ? _e : '',
        range_heat_pump: (_f = estimate.heatPump) === null || _f === void 0 ? void 0 : _f.range_heat_pump,
        heat_pump_name: (_h = (_g = estimate.heatPump) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : '',
        range_hot_water_cylinder_uuid: (_k = (_j = estimate.hotWaterCylinder) === null || _j === void 0 ? void 0 : _j.uuid) !== null && _k !== void 0 ? _k : '',
        range_hot_water_cylinder: estimate.hotWaterCylinder,
        hot_water_cylinder_name: (_m = (_l = estimate.hotWaterCylinder) === null || _l === void 0 ? void 0 : _l.name) !== null && _m !== void 0 ? _m : '',
        co2_saved_kg: estimate.CO2SavedKg,
        annual_kwh_heat_required: estimate.annualKwhHeat,
        average_efficiency_baseline: estimate.efficiencyBaseline,
        fuel_name_baseline: estimate.fuelNameBaseline,
        tariff_p_per_kwh_baseline: estimate.tariffPPerKwhBaseline,
        annual_bill_baseline_gbp: estimate.annualBillBaselineGBP,
        average_efficiency_hp: estimate.averageEfficiencyHeatPump,
        tariff_p_per_kwh_electricity_hp_tariff: estimate.tariffPPerKwhElectricityHpTariff,
        electric_hp_tariff_based_on: estimate.electricHpTariffBasedOn,
        annual_bill_hp_tariff_gbp: estimate.annualBillHeatPumpTariffGBP,
        tariff_p_per_kwh_electricity_price_cap: estimate.tariffPPerKwhElectricityPriceCap,
        price_cap_description: estimate.priceCapDescription,
        annual_bill_hp_price_cap_gbp: estimate.annualBillHeatPumpPriceCapGBP,
        scop: estimate.sCOP,
        flow_temperature_c: estimate.flowTempC,
        lead_uuid: lead.uuid,
        commutesSaved: estimate.commutesSaved,
        flightsSaved: estimate.flightsSaved,
        lmk_key: (_p = (_o = lead.epcData) === null || _o === void 0 ? void 0 : _o.lmkKey) !== null && _p !== void 0 ? _p : '',
        status: undefined,
        calculated_estimate_quote_items: calculatedQuote,
        sender_snapshot: {
            first_name: (_q = adminData.user) === null || _q === void 0 ? void 0 : _q.first_name,
            last_name: (_r = adminData.user) === null || _r === void 0 ? void 0 : _r.last_name,
            email: (_s = adminData.user) === null || _s === void 0 ? void 0 : _s.email
        }
    };
};
export const getCalculatedEstimate = async (calculatedEstimateId, companyUUID) => await axiosGetV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`);
export const getCalculatedEstimates = async (leadUUID, companyUUID) => await axiosGetV2(`teams/${companyUUID}/leads/${leadUUID}/calculated_estimates`);
export const insertCalculatedEstimate = async (calculatedEstimate, calculatedEstimateQuoteItems, companyUUID) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates`, {
        ...calculatedEstimate,
        calculated_estimate_quote_items: calculatedEstimateQuoteItems.map(x => ({ ...x, lead_uuid: calculatedEstimate.lead_uuid }))
    });
};
export const updateCalculatedEstimate = async (calculatedEstimate, companyUUID) => {
    await axiosPatchV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}`, calculatedEstimate);
};
export const deleteCalculatedEstimate = async (calculatedEstimateId, companyUUID) => { await axiosDeleteV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}`); };
export const sendCallback = async (calculatedEstimateId, companyUUID, discussionContent, callTime) => await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimateId}/callback`, { discussion_content: discussionContent, call_time: callTime });
export const sendSurvey = async (calculatedEstimate, companyUUID, emailHTML) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/survey`, { email_html: emailHTML });
    await updateCalculatedEstimate({ ...calculatedEstimate, status: 'Accepted' }, companyUUID);
};
export const sendRejected = async (calculatedEstimate, companyUUID, reason) => {
    await axiosPostV2(`teams/${companyUUID}/calculated_estimates/${calculatedEstimate.uuid}/not_interested`, { reason });
    await updateCalculatedEstimate({ ...calculatedEstimate, status: 'Rejected' }, companyUUID);
};
