import React, { useState } from 'react';
import { Slider } from '../../../components/inputs_and_selections/slider';
export const SliderDemo = () => {
    const [value, setValue] = useState(0);
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(Slider, { value: value, min: 0, max: 100, onChange: setValue, className: '', step: 10 })));
};
const componentProps = [
    { name: 'value', description: 'The current value of the slider' },
    { name: 'min', description: 'The minimum value the slider should go to' },
    { name: 'max', description: 'The maximum value the slider should go to' },
    { name: 'onChange', description: 'Do something after the slider moves with the new value' },
    { name: 'className', description: 'Any extra styling or formatting required' },
    { name: 'step', description: 'The increment each step of the slider should take when moving' }
];
const codeBlock = `const [value, setValue] = useState(0)

<Slider
  value={value}
  min={0}
  max={100}
  onChange={setValue}
  className=''
  step={10}
/>
`;
