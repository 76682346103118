import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { updateCompanySettings } from '../../../../../code/models/company';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { SettingsImageInput } from '../../components/settings_image_input';
import { ClickableCard } from '../../../../../components/content_display/card';
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
export function EstimateAboutUsBlock({ company, setCompany }) {
    var _a, _b;
    return (React.createElement(Section, { border: true, title: 'About us' },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: '\'About us\' section', helperText: 'Display the \'About us\' section on the estimate' }), input: React.createElement(Toggle, { value: company.public_info.show_about_us_on_estimate, setValue: async () => {
                            const newCompany = {
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    show_about_us_on_estimate: !company.public_info.show_about_us_on_estimate
                                }
                            };
                            setCompany(newCompany);
                            await updateCompanySettings(newCompany);
                        } }) })),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Description' }), input: React.createElement(TextArea, { value: (_a = company.public_info.about_us) !== null && _a !== void 0 ? _a : '', setValue: aboutUsInput => setCompany({
                        ...company,
                        public_info: {
                            ...company.public_info,
                            about_us: aboutUsInput
                        }
                    }), placeholder: 'A little info about your company...', rows: 6 }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Image' }), input: React.createElement(SettingsImageInput, { buttonText: 'Upload image', placeholderText: 'No image', helperText: 'Maximum file size 5MB', b64Image: (_b = company.public_info.about_us_image) !== null && _b !== void 0 ? _b : '', altText: 'About us image', handleUpdateImage: async (imageSrc) => {
                        const newCompany = {
                            ...company,
                            public_info: {
                                ...company.public_info,
                                about_us_image: imageSrc
                            }
                        };
                        await updateCompanySettings(newCompany);
                        setCompany(newCompany);
                    }, handleRemoveImage: async () => {
                        const newCompany = {
                            ...company,
                            public_info: {
                                ...company.public_info,
                                about_us_image: ''
                            }
                        };
                        await updateCompanySettings(newCompany);
                        setCompany(newCompany);
                    } }) }))));
}
