import React from 'react';
import { SurveySiteDetailsContext } from '../site_details';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const SiteDetailsParkingBlock = () => {
    const prContext = React.useContext(SurveySiteDetailsContext);
    const { survey, setSurvey } = prContext;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Parking"),
        React.createElement("div", { className: "items-center gap-3 flex justify-between" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Parking available")),
            React.createElement(Toggle, { size: 'LG', value: survey.parking_available, setValue: () => setSurvey({ ...survey, parking_available: !survey.parking_available }) })),
        React.createElement("div", { className: "items-center gap-3 flex justify-between" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Permit required")),
            React.createElement(Toggle, { size: 'LG', value: survey.permit_required, setValue: () => setSurvey({ ...survey, permit_required: !survey.permit_required }) })),
        React.createElement("div", { className: "items-center gap-3 flex justify-between" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Space for kit to be delivered")),
            React.createElement(Toggle, { size: 'LG', value: survey.space_for_kit_to_be_delivered, setValue: () => setSurvey({
                    ...survey,
                    space_for_kit_to_be_delivered: !survey.space_for_kit_to_be_delivered
                }) })),
        React.createElement("div", { className: "gap-3 flex flex-col justify-between" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Notes")),
            React.createElement(TextArea, { size: 'LG', value: survey.notes, setValue: (e) => setSurvey({ ...survey, notes: e }) })));
};
