import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { Check } from 'lucide-react';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { validateInMm } from '../../../../code/validators';
export const CylinderLocationProposedLocationBlock = () => {
    var _a, _b;
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const { survey, setSurvey } = cylinderLocationContext;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Proposed location"),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Available space"),
                React.createElement("div", { className: "text-gray-500 text-sm" }, "Space available for the cylinder plus all the tappings.")),
            React.createElement("div", { className: "gap-4 flex" },
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-600" }, "Width"),
                    React.createElement(Input, { type: 'number', value: survey.cylinder_available_width_mm.toString(), setValue: (e) => setSurvey({ ...survey, cylinder_available_width_mm: parseFloat(e) }), validator: validateInMm, postfix: 'mm' })),
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-600" }, "Height"),
                    React.createElement(Input, { type: 'number', value: survey.cylinder_available_height_mm.toString(), setValue: (e) => setSurvey({ ...survey, cylinder_available_height_mm: parseFloat(e) }), validator: validateInMm, postfix: 'mm' })),
                React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-600" }, "Depth"),
                    React.createElement(Input, { type: 'number', value: survey.cylinder_available_depth_mm.toString(), setValue: (e) => setSurvey({ ...survey, cylinder_available_depth_mm: parseFloat(e) }), validator: validateInMm, postfix: 'mm' })))),
        React.createElement("div", { className: "flex-col gap-2 flex" },
            React.createElement("div", { className: "flex-col flex" },
                React.createElement("div", { className: 'flex justify-between' },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Location description"),
                    React.createElement(Badge, { color: ((_a = survey.cylinder_location_description) === null || _a === void 0 ? void 0 : _a.length) > 0 ? 'GREEN' : 'YELLOW', text: 'Required', icon: ((_b = survey.cylinder_location_description) === null || _b === void 0 ? void 0 : _b.length) > 0 ? Check : undefined })),
                React.createElement("div", { className: "text-gray-500 text-sm" }, "Proposed location of the cylinder. Include the loft hatch dimensions if the cylinder is going in the loft.")),
            React.createElement(TextArea, { value: survey.cylinder_location_description, setValue: (e) => setSurvey({ ...survey, cylinder_location_description: e }) })));
};
