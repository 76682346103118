import { z } from 'zod';
export const LinkSchema = z.object({
    uuid: z.string(),
    title: z.string(),
    url: z.string(),
    internal: z.boolean(),
    category: z.string(),
    created_at: z.coerce.date().optional(),
    updated_at: z.coerce.date().optional(),
    deleted_at: z.coerce.date().optional().nullable()
});
