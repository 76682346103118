import React from 'react';
import { noop } from 'lodash';
import { HorizontalFormGroup } from '../../../components/inputs_and_selections/horizontal_form_group';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
export const HorizontalFormGroupDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Hello world', helperText: 'some helper' }), input: React.createElement(Toggle, { value: true, setValue: noop }) })));
};
const componentProps = [
    { name: 'formLabel', description: 'Accepts any react component but expects a FormLabel' },
    { name: 'input', description: 'Accepts any react component but should be a Toggle, Input type element' }
];
const codeBlock = `<HorizontalFormGroup
  formLabel={<FormLabel labelText='Hello world' helperText='some helper' />}
  toggle={<Toggle value={true} setValue={noop} />}
/>`;
