import { z } from 'zod';
import { apiUrl, client } from '../axios';
import { AuthSDK } from '../utils/auth_provider';
const JobEventTypeValues = [
    'DEPRECATED_ReportGenerated',
    'DEPRECATED_ProposalGenerated',
    'DEPRECATED_QuoteGenerated',
    'StatusChanged',
    'JobCreated',
    'EstimateSent',
    'EstimateReminderSent',
    'EnquiryDeclined',
    'EstimateRejected',
    'EstimateAccepted',
    'EstimateCallbackRequested',
    'ReportSent',
    'ProposalSent',
    'QuoteSent',
    'ProposalAccepted',
    'ReportPDFGenerated',
    'ProposalPDFGenerated',
    'QuotePDFGenerated',
    'EstimateOpened',
    'ReportOpened',
    'ProposalOpened',
    'QuoteOpened'
];
export const JobEventType = z.enum(JobEventTypeValues);
export const JobEventSchema = z.object({
    uuid: z.string().uuid(),
    event_type: JobEventType,
    extra_data: z.record(z.string(), z.unknown()).optional(),
    created_at: z.coerce.date(),
    user_name: z.string().optional(),
    company_name: z.string().optional()
});
export const JobEventCreateSchema = z.object({
    event_type: JobEventType,
    company_uuid: z.string().uuid(),
    job_uuid: z.string().uuid(),
    extra_data: z.record(z.string(), z.unknown()).optional()
});
export const createJobEvent = async (event) => {
    try {
        const result = await client.post(`${apiUrl}teams/${event.company_uuid}/leads/${event.job_uuid}/events`, event, { headers: { 'x-auth-token': AuthSDK.getToken() } });
        return result.data;
    }
    catch (e) {
        console.error('Error posting user event user', e);
        return undefined;
    }
};
