import React, { useEffect, useState } from 'react';
import { insertCalculatedEstimate } from '../../../../../code/models/calculated_estimate';
import { getCompanyEstimateCoverNote, getCompanyEstimateEmailTemplate } from '../../../../../code/models/company';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCarbonSaving, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName, TTPlaceholderHeatPumpModel, TTPlaceholderViewEstimate } from '../../../../../code/tiptap_placeholders';
import { numberFormat } from '../../../../../code/number_format';
import { formatPrice } from '../../../../../code/format_price';
import { Modal } from '../../../../../components/containers/modal';
import { logEvent } from '../../../../../code/log_event';
import { LeadStatusSchema, sendEstimate } from '../../../../../code/models/lead';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { calculateQuote } from '../../../../../code/calculate_quote';
import { DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE } from '../../../../../code/calculate_estimate';
import { sum } from 'lodash';
import { Text } from '../../../../../components/content_display/text';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { ContactCardWrapper } from '../../../../estimate_page';
import { Heading } from '../../../../../components/content_display/heading';
import { getApproxNumberOfRadiatorChanges } from '../../../../../code/models/estimated_rooms_and_radiators';
import { TabGroup } from '../../../../../components/content_display/tab';
import { createJobEvent, JobEventType } from '../../../../../code/models/job_event';
export const SendEstimateModalBlock = ({ setEstimates, setIsVisible, adminContext, estimate, calculatedEstimate, lead, setLead }) => {
    var _a, _b, _c, _d;
    const [customCoverNoteJSON, setCustomCoverNoteJSON] = useState();
    const [customEmailJSON, setCustomEmailJSON] = useState();
    const [overrideCoverNoteJSON, setOverrideCoverNoteJSON] = useState(false); // Flag to mark when cover note is edited
    const [currentTab, setCurrentTab] = useState('email');
    const [calculatedQuote] = calculateQuote({
        company: adminContext.data.company,
        selectedHeatPump: estimate.heatPump,
        selectedHotWaterCylinder: estimate.hotWaterCylinder,
        defaultRadiatorChanges: getApproxNumberOfRadiatorChanges(lead, (_b = (_a = adminContext.data.company) === null || _a === void 0 ? void 0 : _a.estimate_default_radiator_change_percentage) !== null && _b !== void 0 ? _b : DEFAULT_ESTIMATE_RAD_CHANGE_PERCENTAGE),
        parts: adminContext.data.parts,
        labour: adminContext.data.labour,
        packs: adminContext.data.packs,
        isScottish: estimate.isScottish,
        override: lead.estimate_quote_items || undefined
    });
    useEffect(() => {
        var _a;
        if (!((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.company))
            return;
        // At the point of opening the modal, we regenerate the estimate email and cover note content.
        const companyEstimateEmailTemplate = getCompanyEstimateEmailTemplate(adminContext.data.company.estimate_template);
        const renderedEmailJSON = renderJSONReplacingPlaceholders(companyEstimateEmailTemplate, {
            ...defaultPlaceholderMappings(adminContext.data.company.public_info, adminContext.data.user),
            [TTPlaceholderCustomerName.id]: calculatedEstimate.name,
            [TTPlaceholderCustomerAddress.id]: `${calculatedEstimate.address}, ${calculatedEstimate.postcode}`,
            [TTPlaceholderHeatPumpModel.id]: calculatedEstimate.heat_pump_name,
            [TTPlaceholderCarbonSaving.id]: numberFormat(0).format(calculatedEstimate.co2_saved_kg)
        });
        const companyEstimateCoverNote = getCompanyEstimateCoverNote(adminContext.data.company.public_info.calculated_estimate_cover_note);
        const renderedEstimateCoverNoteJSON = renderJSONReplacingPlaceholders(companyEstimateCoverNote, {
            ...defaultPlaceholderMappings(adminContext.data.company.public_info, adminContext.data.user)
        });
        setCustomEmailJSON(renderedEmailJSON);
        setCustomCoverNoteJSON(renderedEstimateCoverNoteJSON);
    }, [adminContext.data.company]);
    if (!((_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.company))
        return;
    const companyUUID = adminContext.data.company.public_info.uuid;
    const insertEstimate = async (setEstimates, companyUUID, estimate, lead) => {
        await insertCalculatedEstimate(estimate, calculatedQuote, companyUUID);
        setEstimates(prev => [...prev, estimate]);
    };
    return React.createElement(Modal, { title: 'Send estimate', visible: true, setVisible: setIsVisible, onConfirm: async () => {
            var _a, _b, _c, _d, _e, _f;
            logEvent({
                name: 'Send Estimate Clicked',
                properties: {}
            }, (_b = (_a = adminContext.data.company.public_info) === null || _a === void 0 ? void 0 : _a.subdomain) !== null && _b !== void 0 ? _b : '');
            const estimateToSend = {
                ...calculatedEstimate,
                status: 'Sent',
                created_at: new Date().toString(),
                uuid: crypto.randomUUID(),
                cover_note: overrideCoverNoteJSON ? JSON.stringify(customCoverNoteJSON) : undefined
            };
            const emailHTML = renderHTMLReplacingPlaceholders(customEmailJSON, {
                [TTPlaceholderViewEstimate.id]: process.env.BASE_URL + ((_e = (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d.public_info) === null || _e === void 0 ? void 0 : _e.subdomain) + '/estimate/' + estimateToSend.uuid
            });
            // Save the custom cover note to the estimate and store it.
            await insertEstimate(setEstimates, companyUUID, estimateToSend, lead);
            await sendEstimate(lead.uuid, companyUUID, emailHTML);
            const oldStatus = lead.status;
            setLead({ ...lead, status: LeadStatusSchema.enum.Quoted });
            await createJobEvent({
                event_type: JobEventType.enum.EstimateSent,
                company_uuid: companyUUID,
                job_uuid: lead.uuid,
                extra_data: {
                    calculated_estimate_uuid: estimateToSend.uuid,
                    email_content: emailHTML,
                    recipients: [(_f = lead.customer) === null || _f === void 0 ? void 0 : _f.email]
                }
            });
            if (oldStatus !== LeadStatusSchema.enum.Quoted) {
                createJobEvent({
                    event_type: JobEventType.enum.StatusChanged,
                    company_uuid: companyUUID,
                    job_uuid: lead.uuid,
                    extra_data: {
                        old_status: oldStatus,
                        new_status: LeadStatusSchema.enum.Quoted
                    }
                });
            }
        }, confirmButtonLabel: "Send" },
        React.createElement("div", { className: 'flex flex-col gap-4 h-full w-full' },
            React.createElement(Text, { size: 'MD' },
                "This will send an email to ",
                React.createElement("span", { className: "font-bold" }, (_d = lead === null || lead === void 0 ? void 0 : lead.customer) === null || _d === void 0 ? void 0 : _d.email),
                " with a link to this ",
                React.createElement("span", { className: "font-bold" }, formatPrice(sum(calculatedQuote.map(x => x.selected ? x.subtotal : 0)))),
                " estimate."),
            React.createElement(TabGroup, { items: [
                    { name: 'Email', onClick: () => setCurrentTab('email'), variant: currentTab === 'email' ? 'ACTIVE' : 'DEFAULT' },
                    { name: 'Cover note', onClick: () => setCurrentTab('cover_note'), variant: currentTab === 'cover_note' ? 'ACTIVE' : 'DEFAULT' }
                ] }),
            (currentTab === 'email' && customEmailJSON) &&
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Homeowner Email', helperText: 'Customise the email sent to the homeowner.' }), input: React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                            setCustomEmailJSON(editor.getJSON());
                        }, placeholders: [
                            TTPlaceholderViewEstimate
                        ], content: customEmailJSON }) }),
            (currentTab === 'cover_note' && customCoverNoteJSON) && React.createElement(React.Fragment, null,
                React.createElement(FormLabel, { labelText: 'Estimate cover note', helperText: 'Customise the cover note content shown on this estimate.' }),
                React.createElement("div", { className: 'w-full flex flex-col gap-5' },
                    React.createElement(Heading, { size: "lg" },
                        "Hi ",
                        calculatedEstimate.name,
                        "!"),
                    React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: (editor) => {
                            setCustomCoverNoteJSON(editor.getJSON());
                            if (!overrideCoverNoteJSON) {
                                setOverrideCoverNoteJSON(true);
                            }
                        }, content: customCoverNoteJSON }),
                    React.createElement(ContactCardWrapper, { hasContent: Boolean(adminContext.data.company.public_info.estimate_contact_name || adminContext.data.company.public_info.estimate_contact_phone || adminContext.data.company.public_info.estimate_contact_role || adminContext.data.company.public_info.estimate_contact_portrait) },
                        React.createElement("div", { className: 'flex justify-start items-center pt-1 gap-4' },
                            adminContext.data.company.public_info.estimate_contact_portrait && React.createElement("img", { src: adminContext.data.company.public_info.estimate_contact_portrait, alt: 'Estimate contact headshot', className: 'rounded-full w-20 h-20 object-cover' }),
                            React.createElement("div", { className: 'flex flex-col gap-1' },
                                React.createElement("div", null,
                                    React.createElement(Text, { bold: true, size: 'MD' }, adminContext.data.company.public_info.estimate_contact_name),
                                    React.createElement(Text, { size: 'SM' }, adminContext.data.company.public_info.estimate_contact_role)),
                                React.createElement("a", { className: 'text-base text-sky-800 font-bold underline', href: `tel:${adminContext.data.company.public_info.estimate_contact_phone}` }, adminContext.data.company.public_info.estimate_contact_phone))))))));
};
