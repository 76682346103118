import React, { useContext } from 'react';
import { acceptTermsAndConditions } from '../../../code/models/terms_and_conditions';
import { Modal } from '../../../components/containers/modal';
import { Link } from '../../../components/buttons/link';
import { AdminContext } from '../admin_layout';
export const TermsAndConditionsModal = ({ visible, setVisible, termsAndConditions }) => {
    const adminContext = useContext(AdminContext);
    return (React.createElement(Modal, { title: 'Terms & Conditions', visible: visible, setVisible: setVisible, confirmButtonLabel: 'Accept', onConfirm: async () => {
            if (await acceptTermsAndConditions() && termsAndConditions) {
                adminContext.acceptTermsAndConditions(termsAndConditions.id);
                setVisible(false);
            }
        }, cancelButtonLabel: "I'll do it later" },
        React.createElement("div", { className: "flex flex-col gap-3" },
            React.createElement("div", null,
                "Hello! Before you carry on, we\u2019d be really grateful if you could please review and accept our ",
                React.createElement(Link, { text: 'Terms & Conditions', className: "inline", onClick: () => window.open(termsAndConditions === null || termsAndConditions === void 0 ? void 0 : termsAndConditions.url, '_blank') }),
                "."),
            React.createElement("div", null, "We\u2019ve recently introduced these to give both us and you requisite legal protections and to improve our GDPR compliance."),
            React.createElement("div", null, "If you have any questions about these, please contact Jo on joseph@spruce.eco or 07795533150."),
            React.createElement("div", null, "Thank you!"))));
};
