import React, { useState } from 'react';
import { Modal } from '../../../components/containers/modal';
import { noop } from 'lodash';
import { Button } from '../../../components/buttons/button';
export const ModalDemo = () => {
    const [modalVisible, setModalVisible] = useState(false);
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(Button, { onClick: () => setModalVisible(true) }, "Open modal"),
            React.createElement(Modal, { visible: modalVisible, setVisible: setModalVisible, title: 'My modal', onConfirm: noop, confirmButtonLabel: 'Custom confirm', confirmDisabled: false, thirdButton: undefined, allowOverflow: true }, "Hello world")));
};
const componentProps = [
    { name: 'visible', description: 'Is the modal currently visible' },
    { name: 'setVisible', description: 'Set whether the modal is visible or not' },
    { name: 'title', description: 'Title of modal' },
    { name: 'onConfirm', description: 'Action to take when clicking confirm button, if not present confirm button is hidden' },
    { name: 'confirmButtonLabel', description: 'Custom confirm button label' },
    { name: 'confirmDisabled', description: 'Whether the confirm button should be disabled (for any validation)' },
    { name: 'thirdButton', description: 'Even more buttons required' },
    { name: 'allowOverflow', description: 'To allow dropdowns or other content to exit the container barrier' }
];
const codeBlock = `<Modal
  visible={true}
  setVisible={noop}
  title='My modal'
  onConfirm={noop}
  confirmButtonLabel='Custom confirm'
  confirmDisabled={false}
  thirdButton={undefined}
  allowOverflow={true}
>
  Hello world
</Modal>`;
