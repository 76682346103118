import React, { useContext, useState } from 'react';
import { ClickableCard } from '../../../components/content_display/card';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
import { Button } from '../../../components/buttons/button';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { HorizontalFormGroup } from '../../../components/inputs_and_selections/horizontal_form_group';
import { createHLReportAPI, getHLReportPreviewPDFAPI, HEATLOSS_REPORT_SECTION_ALL, HEATLOSS_REPORT_SECTION_DESIGN, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY, HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE, HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT, HEATLOSS_REPORT_SECTION_SUMMARY, sendHLReportAPI } from '../../../code/models/heatloss_report';
import { getCompanyReportEmailTemplate } from '../../../code/models/company';
import { downloadBlob, isFlagSet } from '../../../code/helpers';
import { HeatLossReportPage } from './heatloss_report';
import { defaultPlaceholderMappings, renderHTMLReplacingPlaceholders, renderJSONReplacingPlaceholders, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName, TTPlaceholderViewReport } from '../../../code/tiptap_placeholders';
import { AdminContext } from '../../admin/admin_layout';
import { SendEmailWithTemplateModal } from './partials/send_email_with_template_modal';
import { FullscreenPreviewModal } from './partials/fullscreen_preview_modal';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { FileText } from 'lucide-react';
import { createJobEvent, JobEventType } from '../../../code/models/job_event';
export const HeatLossReportSettings = ({ companyPublicInfo, lead, setLead, survey, files, currentHeatPump, currentHotWaterCylinder }) => {
    var _a, _b, _c, _d;
    const adminContext = useContext(AdminContext);
    const tiptapMappings = {
        ...defaultPlaceholderMappings(adminContext.data.company.public_info, adminContext.data.user),
        [TTPlaceholderCustomerName.id]: lead.customer.name,
        [TTPlaceholderCustomerAddress.id]: lead.property.address + ', ' + lead.property.postcode
    };
    // use saved list of recipients or default to customer's email
    const [showPreviewWindow, setShowPreviewWindow] = React.useState(false);
    const [showSendingModal, setShowSendingModal] = React.useState(false);
    const [emailText, setEmailText] = React.useState(renderJSONReplacingPlaceholders(getCompanyReportEmailTemplate(adminContext.data.company.report_email_template), tiptapMappings));
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const sections = (_a = lead.report_sections) !== null && _a !== void 0 ? _a : HEATLOSS_REPORT_SECTION_ALL;
    const snapshot = {
        customer: lead.customer,
        property: lead.property,
        survey,
        files,
        currentHeatPump,
        currentHotWaterCylinder
    };
    const handleSend = async (recipients) => {
        try {
            const report = {
                lead_uuid: lead.uuid,
                company_uuid: adminContext.data.company.public_info.uuid,
                recipients,
                email_text: JSON.stringify(emailText),
                contents_bitmask: sections,
                snapshot
            };
            const reportCreateResponse = await createHLReportAPI(report);
            if (!reportCreateResponse) {
                alert('Failed to create report');
            }
            report.uuid = reportCreateResponse.uuid;
            const emailHtmlContent = renderEmailHTML(reportCreateResponse.uuid);
            await sendHLReportAPI(report, emailHtmlContent);
            // We only post the user event if the email was successfully sent, because it's connected to billing
            createJobEvent({
                event_type: JobEventType.enum.ReportSent,
                company_uuid: companyPublicInfo.uuid,
                job_uuid: lead.uuid,
                extra_data: {
                    report_uuid: reportCreateResponse.uuid,
                    email_content: emailHtmlContent,
                    recipients
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    };
    const handleDownloadReportPreviewPDF = async () => {
        try {
            setPdfGenerating(true);
            const report = {
                lead_uuid: lead.uuid,
                company_uuid: adminContext.data.company.public_info.uuid,
                contents_bitmask: sections,
                snapshot
            };
            const reportCreateResponse = await createHLReportAPI(report);
            if (!reportCreateResponse) {
                alert('Failed to create report');
            }
            const pdfData = await getHLReportPreviewPDFAPI(reportCreateResponse.uuid, companyPublicInfo.uuid);
            downloadBlob(pdfData, lead.property.address + ' — Heat Loss Report.pdf');
            // We only post the user event if the PDF was successfully generated, because it's connected to billing
            createJobEvent({
                event_type: JobEventType.enum.ReportPDFGenerated,
                company_uuid: companyPublicInfo.uuid,
                job_uuid: lead.uuid,
                extra_data: {
                    report_uuid: reportCreateResponse.uuid
                }
            });
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setPdfGenerating(false);
        }
    };
    const renderEmailHTML = (reportUUID) => {
        return renderHTMLReplacingPlaceholders(emailText, {
            ...tiptapMappings,
            [TTPlaceholderViewReport.id]: process.env.BASE_URL + adminContext.data.company.public_info.subdomain + '/report/' + reportUUID
        });
    };
    return React.createElement("div", null,
        React.createElement(SendEmailWithTemplateModal, { visible: showSendingModal, setVisible: setShowSendingModal, handleSend: handleSend, emailRecipients: !lead.report_recipients ? ((_c = (_b = lead.customer) === null || _b === void 0 ? void 0 : _b.email) !== null && _c !== void 0 ? _c : '') : lead.report_recipients, setEmailRecipients: (e) => {
                setLead({
                    ...lead,
                    report_recipients: e
                });
            }, emailText: emailText, setEmailText: setEmailText, editorPlaceholders: [
                TTPlaceholderViewReport
            ] }),
        React.createElement(FullscreenPreviewModal, { title: 'Report preview', visible: showPreviewWindow, setVisible: setShowPreviewWindow, previewContent: React.createElement(HeatLossReportPage, { sections: (_d = lead.report_sections) !== null && _d !== void 0 ? _d : HEATLOSS_REPORT_SECTION_ALL, snapshot: snapshot, company_public_info: companyPublicInfo }), sidebarTitle: 'Send & Save to PDF', sidebarContent: React.createElement(React.Fragment, null,
                React.createElement(Button, { className: 'w-full', onClick: () => setShowSendingModal(true) }, "Send via Email"),
                React.createElement("div", { className: 'flex flex-row gap-0 items-center w-full justify-between py-6' },
                    React.createElement("div", { className: 'h-[1px] border-b border-dashed border-gray-200 w-2/5' }),
                    React.createElement("div", { className: 'text-center text-gray-500 text-xs w-1/5 ' }, "or"),
                    React.createElement("div", { className: 'h-[1px] border-b border-dashed border-gray-200 w-2/5' })),
                !pdfGenerating && React.createElement(Button, { iconLeft: FileText, onClick: handleDownloadReportPreviewPDF, colour: 'LIGHT', className: 'w-full', disabled: pdfGenerating }, "Save to PDF"),
                pdfGenerating && React.createElement("div", { className: 'text-center text-gray-500 text-xs flex flex-col gap-2' },
                    React.createElement(Loader, null),
                    React.createElement("span", null, "Generating PDF"))) }),
        !showPreviewWindow && React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement("div", { className: 'flex flex-row justify-between items-center' },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Configure report"),
                React.createElement(Button, { onClick: () => setShowPreviewWindow(true) }, "Preview and Send")),
            React.createElement("div", { className: 'gap-5 flex flex-col' },
                React.createElement(ClickableCard, { variant: 'WHITE' },
                    React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Summary', helperText: 'A page providing the customer with an overview of the heat loss findings, chosen heat pump and cost savings.' }), input: React.createElement(Toggle, { value: isFlagSet(sections, HEATLOSS_REPORT_SECTION_SUMMARY), setValue: () => setLead({
                                ...lead,
                                report_sections: sections ^ HEATLOSS_REPORT_SECTION_SUMMARY
                            }) }) })),
                React.createElement(ClickableCard, { variant: 'WHITE' },
                    React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Overall heat loss', helperText: 'Includes the calculation conditions, heat loss breakdown by room and element, and heat loss by floor including the floor-plan.' }), input: React.createElement(Toggle, { value: isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY), setValue: () => {
                                let mask = sections;
                                // if it was enabled, we're about to disable it, so we need to disable all child sections
                                if (isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY)) {
                                    mask &= ~HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO;
                                    mask &= ~HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM;
                                }
                                setLead({
                                    ...lead,
                                    report_sections: mask ^ HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY
                                });
                            } }) }),
                    React.createElement(HorizontalFormGroup, { className: 'border-l-gray-300 border-l-4 pl-4', disabled: !isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY), formLabel: React.createElement(FormLabel, { labelText: 'Introduction', helperText: 'A description of what heat loss is and why it\'s important' }), input: React.createElement(Toggle, { disabled: !isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY), value: isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO), setValue: () => setLead({
                                ...lead,
                                report_sections: sections ^ HEATLOSS_REPORT_SECTION_HEATLOSS_INTRO
                            }) }) }),
                    React.createElement(HorizontalFormGroup, { className: 'border-l-gray-300 border-l-4 pl-4', disabled: !isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY), formLabel: React.createElement(FormLabel, { labelText: 'Detailed page per room', helperText: 'Include a heat loss page for each room showing full details of all the inputs used and the results' }), input: React.createElement(Toggle, { disabled: !isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_SUMMARY), value: isFlagSet(sections, HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM), setValue: () => setLead({
                                ...lead,
                                report_sections: sections ^ HEATLOSS_REPORT_SECTION_HEATLOSS_PER_ROOM
                            }) }) })),
                React.createElement(ClickableCard, { variant: 'WHITE' },
                    React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Design', helperText: 'Details of the proposed heat pump, emitter replacement, proposed cylinder and associated hot water calcs.' }), input: React.createElement(Toggle, { value: isFlagSet(sections, HEATLOSS_REPORT_SECTION_DESIGN), setValue: () => setLead({
                                ...lead,
                                report_sections: sections ^ HEATLOSS_REPORT_SECTION_DESIGN
                            }) }) })),
                React.createElement(ClickableCard, { variant: 'WHITE' },
                    React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Sound assessment', helperText: 'Results of the sound assessment along with the inputs and calculations behind the assessment.' }), input: React.createElement(Toggle, { value: isFlagSet(sections, HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT), setValue: () => setLead({
                                ...lead,
                                report_sections: sections ^ HEATLOSS_REPORT_SECTION_SOUND_ASSESSMENT
                            }) }) })),
                React.createElement(ClickableCard, { variant: 'WHITE' },
                    React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Performance estimate', helperText: 'An estimate of system performance covering energy, bills and carbon, plus the assumptions behind the numbers.' }), input: React.createElement(Toggle, { value: isFlagSet(sections, HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE), setValue: () => setLead({
                                ...lead,
                                report_sections: sections ^ HEATLOSS_REPORT_SECTION_PERFORMANCE_ESTIMATE
                            }) }) })))));
};
