import { getCenter, getDistance } from './utils';
export const onStageTouchMove = (e, dragStopped, setDragStopped, lastCenter, setLastCenter, lastDist, setLastDist, stagePosition, setStagePosition, stageScale, setStageScale) => {
    e.evt.preventDefault();
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];
    const stage = e.currentTarget.getStage();
    if (touch1 && !touch2 && !stage.isDragging() && dragStopped) {
        stage.startDrag();
        setDragStopped(false);
    }
    if (touch1 && touch2) {
        if (stage.isDragging()) {
            setDragStopped(true);
            stage.stopDrag();
        }
        const p1 = {
            x: touch1.clientX,
            y: touch1.clientY
        };
        const p2 = {
            x: touch2.clientX,
            y: touch2.clientY
        };
        if (!lastCenter) {
            setLastCenter(getCenter(p1, p2));
            return;
        }
        const newCenter = getCenter(p1, p2);
        const dist = getDistance(p1, p2);
        if (!lastDist) {
            setLastDist(dist);
            return;
        }
        const pointTo = {
            x: (newCenter.x - stagePosition.x) / stageScale,
            y: (newCenter.y - stagePosition.y) / stageScale
        };
        const scale = stageScale * (dist / lastDist);
        setStageScale(scale);
        const dx = newCenter.x - lastCenter.x;
        const dy = newCenter.y - lastCenter.y;
        const newPos = {
            x: newCenter.x - pointTo.x * scale + dx,
            y: newCenter.y - pointTo.y * scale + dy
        };
        setStagePosition(newPos);
        setLastDist(dist);
        setLastCenter(newCenter);
    }
};
