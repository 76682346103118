import React, { useContext, useEffect, useState } from 'react';
import { acceptInvitation, getInvitationByToken, invitationIsValid } from '../code/models/invitations';
import { getUser } from '../code/models/user';
import { validateNotNull, validatePassword, validatePasswordMatch } from '../code/validators';
import { UnauthorizedPage } from './admin/unauthorized_page';
import { SpruceLogoWhite } from '../assets/images/spruce_logo/spruce_logo';
import { AuthContext, AuthSDK, AuthProvider } from '../code/utils/auth_provider';
import { Button } from '../components/buttons/button';
import { Section } from '../components/containers/section';
import { Card } from '../components/content_display/card';
import { Loader } from '../components/indicators_and_messaging/loader';
import { Input } from '../components/inputs_and_selections/input';
const INVALID_ERROR_MESSAGE = 'This invitation no longer exists. Please sign in or request a new invitation.';
const SignedInForm = ({ handleAcceptInvitation, email }) => {
    const [user, setUser] = useState(undefined);
    useEffect(() => {
        const fetchUser = async () => {
            const user = await getUser();
            setUser(user);
        };
        fetchUser();
    }, []);
    if (email !== (user === null || user === void 0 ? void 0 : user.email)) {
        return React.createElement(UnauthorizedPage, null);
    }
    return (React.createElement("div", { className: 'flex flex-col items-center' },
        React.createElement("div", { className: 'pb-4 text-sm' },
            React.createElement("div", null, "You've been invited to access a survey on Spruce.")),
        React.createElement(Button, { onClick: () => handleAcceptInvitation({ email: user.email }), className: "mt-4" }, "Accept Invitation")));
};
const SignedOutForm = ({ handleAcceptInvitation, email }) => {
    const [password, setPassword] = useState('');
    const [passwordRepeated, setPasswordRepeated] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const isValidForm = [validatePassword(password), validatePasswordMatch(password, passwordRepeated), validateNotNull(firstName), validateNotNull(lastName)].every(x => x.value);
    const onSubmit = () => {
        handleAcceptInvitation({ email, password, name: `${firstName} ${lastName}` });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: 'pb-4 text-sm' },
            React.createElement("div", null,
                "You've been invited to access a survey on Spruce. Please create an account for the email address ",
                React.createElement("span", { className: 'font-bold' }, email),
                " to continue.")),
        React.createElement("div", { className: "space-y-4" },
            React.createElement("div", { className: "space-y-2" },
                React.createElement("div", { className: "font-semibold" }, "Email"),
                React.createElement(Card, { color: 'DARK' },
                    React.createElement("div", { className: "text-sm text-gray-500" }, email))),
            React.createElement("div", { className: "space-y-2" },
                React.createElement("div", { className: "font-semibold" }, "Password"),
                React.createElement(Input, { onEnter: onSubmit, type: "password", value: password, setValue: (e) => {
                        setPassword(e);
                    }, placeholder: "Enter your password", validator: validatePassword })),
            React.createElement("div", { className: "space-y-2" },
                React.createElement("div", { className: "font-semibold" }, "Repeat your password"),
                React.createElement(Input, { onEnter: onSubmit, type: "password", value: passwordRepeated, setValue: (e) => {
                        setPasswordRepeated(e);
                    }, placeholder: "Enter your password again", validator: (value) => validatePasswordMatch(value, password) })),
            React.createElement("div", { className: "flex space-x-4" },
                React.createElement("div", { className: "space-y-2 w-1/2" },
                    React.createElement("div", { className: "font-semibold" }, "First Name"),
                    React.createElement(Input, { onEnter: onSubmit, type: "text", value: firstName, setValue: (e) => {
                            setFirstName(e);
                        }, placeholder: "Enter your first name", validator: validateNotNull })),
                React.createElement("div", { className: "space-y-2 w-1/2" },
                    React.createElement("div", { className: "font-semibold" }, "Last Name"),
                    React.createElement(Input, { onEnter: onSubmit, type: "text", value: lastName, setValue: (e) => {
                            setLastName(e);
                        }, placeholder: "Enter your last name", validator: validateNotNull }))),
            React.createElement(Button, { disabled: !isValidForm, block: true, onClick: onSubmit }, "Sign Up"))));
};
const AcceptInvitationPageInner = ({ token, navigateTo }) => {
    const authContext = useContext(AuthContext);
    const [invitation, setInvitation] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const handleAcceptInvitation = async ({ email, password, name }) => {
        if (!token) {
            console.error('No token found');
            return;
        }
        const response = await acceptInvitation({ email, password, name, token });
        if (!authContext.isSignedIn()) {
            await AuthSDK.signIn(email, password);
        }
        // Redirect to the all surveys page
        navigateTo(`/${response.data.company.subdomain}/admin/enquiries/survey_booked`);
    };
    useEffect(() => {
        const fetchInvitation = async () => {
            if (!token) {
                console.error('No token found');
                return;
            }
            const invitation = await getInvitationByToken(token);
            if (!invitationIsValid(invitation)) {
                setError(INVALID_ERROR_MESSAGE);
                setLoading(false);
                return;
            }
            setInvitation(invitation);
            setLoading(false);
        };
        fetchInvitation();
    }, []);
    if (error) {
        return (React.createElement("div", { className: "flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto" },
            React.createElement(Section, { border: true, title: "Accept Invitation" },
                React.createElement("div", null, error))));
    }
    if (loading || !invitation) {
        return (React.createElement("div", { className: "flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto" },
            React.createElement(Section, { border: true, title: "Accept Invitation" },
                React.createElement(Loader, null))));
    }
    // If the invitation is pending but already has a user associated with it, redirect to the login page
    if (!authContext.isSignedIn() && invitation.recipient_uuid) {
        navigateTo('/login');
        return;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "w-full bg-gray-900 flex items-center justify-between p-4" },
            React.createElement("img", { alt: "Spruce logo", className: "w-32 h-12", src: SpruceLogoWhite })),
        React.createElement("div", { className: "flex flex-col items-center justify-center mt-10 max-w-3xl mx-auto" },
            React.createElement(Section, { border: true, title: "Accept Invitation" }, authContext.isSignedIn()
                ? React.createElement(SignedInForm, { handleAcceptInvitation: handleAcceptInvitation, email: invitation.email })
                : React.createElement(SignedOutForm, { handleAcceptInvitation: handleAcceptInvitation, email: invitation.email })))));
};
export const AcceptInvitationPage = ({ token, navigateTo }) => {
    return (React.createElement(AuthProvider, null,
        React.createElement(AcceptInvitationPageInner, { token: token, navigateTo: navigateTo })));
};
