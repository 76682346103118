import { formatMaterialUValue, getMaterialByUuid, getMaterialUValue } from '../../../code/models/material';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import React from 'react';
export const MaterialInputField = ({ selectorProps, setMsProps, setPage }) => {
    var _a;
    const uValue = getMaterialUValue(selectorProps.selectedMaterial);
    return React.createElement("div", { className: 'w-full cursor-pointer flex items-center justify-between gap-4 border px-4 py-2 rounded-lg border-gray-200', onClick: () => {
            setMsProps(selectorProps);
            setPage('MATERIALS');
        } },
        React.createElement("div", { className: 'text-gray-600 text-sm font-semibold' }, (_a = getMaterialByUuid(selectorProps.selectedMaterial.uuid, selectorProps.materials)) === null || _a === void 0 ? void 0 : _a.name),
        uValue !== undefined && React.createElement(Badge, { color: 'INDIGO', text: formatMaterialUValue(uValue) }));
};
