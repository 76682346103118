import React from 'react';
const sizeToClass = {
    XS: 'text-xs',
    SM: 'text-sm',
    MD: 'text-base',
    LG: 'text-lg'
};
export const Text = ({ bold, children, className = '', size = 'MD' }) => {
    return React.createElement("p", { className: `${sizeToClass[size]} ${bold ? 'font-bold text-bold' : 'text-default'} ${className}` }, children);
};
