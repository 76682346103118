import React from 'react';
import { CheckCircle } from 'lucide-react';
import { Icon } from '../buttons/icon';
const variants = {
    ACTIVE: 'border-gray-900',
    DEFAULT: 'border-gray-200'
};
export const RadioGroup = ({ items, isVertical }) => {
    const isVerticalCheck = isVertical || items.some(x => x.description);
    return isVerticalCheck ? React.createElement(RadioGroupVertical, { items: items }) : React.createElement(RadioGroupHorizontal, { items: items });
};
const RadioGroupHorizontal = ({ items }) => {
    return React.createElement("div", { className: "gap-2 flex overflow-x-auto" }, items.map(x => React.createElement("div", { key: x.name, onClick: x.onClick, className: `cursor-pointer px-4 py-3 bg-white rounded-xl border-2 flex justify-center items-center ${variants[x.variant]}` },
        React.createElement("div", { className: 'flex flex-col items-center justify-center gap-2' },
            React.createElement("div", { className: "text-sm whitespace-nowrap" }, x.name),
            x.icon))));
};
const RadioGroupVertical = ({ items }) => {
    return React.createElement("div", { className: "gap-2 flex flex-col overflow-x-auto" }, items.map(x => React.createElement("div", { key: x.name, className: `cursor-pointer bg-white rounded-xl border-2 flex gap-2 justify-between items-center ${variants[x.variant]}` },
        React.createElement("div", { className: 'flex flex-col grow ps-4 py-3', onClick: x.onClick },
            React.createElement("div", { className: 'items-center flex gap-0.5' },
                React.createElement("div", { className: "text-base font-semibold" }, x.name),
                React.createElement("div", { className: 'text-sm' },
                    " ",
                    x.info && x.info)),
            React.createElement("div", { className: "text-sm text-gray-500" }, x.description)),
        React.createElement("div", { className: 'flex gap-4 items-center pe-4 py-3' },
            x.rightContent,
            x.variant === 'ACTIVE'
                ? React.createElement(Icon, { colour: 'text-bold', icon: CheckCircle })
                : React.createElement("div", { onClick: x.onClick, className: 'w-5 h-5 rounded-full bg-white border border-gray-300' })))));
};
