import React from 'react';
import { Button } from '../../../components/buttons/button';
import { Check, Undo } from 'lucide-react';
export const CompleteButton = ({ page, pageChecks, survey, setSurvey, onBack, secondaryButton }) => {
    const isCompleted = survey.completed_sections.includes(page);
    const addOrRemoveSection = async () => await setSurvey(prev => ({
        ...prev,
        completed_sections: isCompleted
            ? prev.completed_sections.filter(x => x !== page)
            : [...prev.completed_sections, page]
    }));
    return React.createElement("div", { className: "p-3 bg-white flex-col gap-3 flex justify-center items-center shadow-2xl" },
        pageChecks.length > 0 && React.createElement("div", { className: "text-sm font-bold" },
            pageChecks.filter(x => !!x).length,
            "/",
            pageChecks.length,
            " required questions complete"),
        React.createElement("div", { className: 'flex flex-col w-full' },
            React.createElement("div", { className: 'flex-row flex gap-2.5 flex-grow' },
                secondaryButton && React.createElement("div", { className: 'w-1/2' }, secondaryButton),
                React.createElement("div", { className: secondaryButton ? 'w-1/2' : 'w-full' },
                    React.createElement(Button, { block: true, colour: 'DARK', onClick: async () => {
                            await addOrRemoveSection();
                            await onBack();
                        }, iconLeft: isCompleted ? Undo : Check },
                        "Mark as ",
                        isCompleted ? 'incomplete' : 'complete')))));
};
