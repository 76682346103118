import React, { useEffect, useState } from 'react';
import { getCompanyPublicInfoSilent } from './code/models/company';
import { AdminLayout } from './pages/admin/admin_layout';
import { LoginPage } from './pages/admin/login_page';
import { RemindPasswordPage } from './pages/admin/remind_password_page';
import { Components } from './pages/components/components';
import { Error404Page } from './pages/error_pages';
import { EstimatePage } from './pages/estimate_page';
import { SignUpPage } from './pages/sign_up_page';
import { SurveyPageWrapper } from './pages/survey/survey_page';
import { AcceptInvitationPage } from './pages/accept_invitation_page';
import { useNavigation } from './code/use_navigation';
import { match } from 'path-to-regexp';
import { AuthSDK } from './code/utils/auth_provider';
import { useLocalStorage } from 'usehooks-ts';
import { getUser } from './code/models/user';
import { HeatLossReportPublicPage } from './pages/heatloss_report_public_page';
import { QuotePublicPage } from './pages/quote_public_page';
import { ProposalPublicPage } from './pages/proposal_public_page';
export const Router = () => {
    const { currentPath, navigateTo } = useNavigation();
    const [subdomain, setSubdomain] = useLocalStorage('subdomain', '');
    useEffect(() => {
        const fetchUser = async () => {
            var _a;
            if (!match('')(currentPath))
                return;
            const userUUID = (_a = AuthSDK.getTokenPayload()) === null || _a === void 0 ? void 0 : _a.user_uuid;
            if (userUUID && !subdomain) {
                const fetchedUser = await getUser();
                const subdomain = fetchedUser === null || fetchedUser === void 0 ? void 0 : fetchedUser.companies[0].subdomain;
                setSubdomain(subdomain);
                navigateTo(`/${subdomain}/admin`);
                return;
            }
            if (userUUID) {
                navigateTo(`/${subdomain}/admin`);
                return;
            }
            navigateTo('/login');
        };
        fetchUser();
    }, [currentPath]);
    const ROUTES = [{
            path: '/login',
            component: () => React.createElement(LoginPage, { navigateTo: navigateTo })
        }, {
            path: '/remind-password',
            component: () => React.createElement(RemindPasswordPage, { navigateTo: navigateTo })
        }, {
            path: '/sign_up',
            component: () => React.createElement(SignUpPage, null)
        }, {
            path: '/invitation/accept/:token',
            component: ({ token }) => {
                return React.createElement(AcceptInvitationPage, { token: token, navigateTo: navigateTo });
            }
        }, {
            path: '/components{/*subPath}',
            component: () => React.createElement(Components, { currentPath: currentPath, navigateTo: navigateTo })
        }, {
            path: '/:companySubdomain{/*subPath}',
            component: ({ companySubdomain }) => {
                const baseURL = `/${companySubdomain}`;
                return React.createElement(CompanyRouter, { companySubdomain: companySubdomain, currentPath: currentPath, navigateTo: navigateTo, baseURL: baseURL });
            }
        }];
    const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath), component: x.component })).find(x => x.evaluatedPath);
    if (page === null || page === void 0 ? void 0 : page.evaluatedPath)
        return page.component(page.evaluatedPath.params);
    return React.createElement(Error404Page, null);
};
const CompanyRouter = ({ companySubdomain, baseURL, currentPath, navigateTo }) => {
    const [companyPublicInfo, setCompanyPublicInfo] = useState();
    useEffect(() => {
        const main = async () => {
            const companyPublicInfo = await getCompanyPublicInfoSilent(companySubdomain);
            if (companyPublicInfo)
                setCompanyPublicInfo(companyPublicInfo);
        };
        main();
    }, []);
    if (!companyPublicInfo)
        return;
    const ROUTES = [{
            path: '',
            component: () => React.createElement(SurveyPageWrapper, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo, showLogo: true, isAdmin: false })
        }, {
            path: '/embed',
            component: () => React.createElement(SurveyPageWrapper, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo, showLogo: false, isAdmin: false })
        }, {
            path: '/estimate/:estimateId',
            component: ({ estimateId }) => {
                return React.createElement(EstimatePage, { estimateId: estimateId, companyPublicInfo: companyPublicInfo });
            }
        }, {
            path: '/report/:reportUUID',
            component: ({ reportUUID }) => {
                return React.createElement(HeatLossReportPublicPage, { reportUUID: reportUUID, companyPublicInfo: companyPublicInfo });
            }
        }, {
            path: '/quote/:quoteUUID',
            component: ({ quoteUUID }) => {
                return React.createElement(QuotePublicPage, { quoteUUID: quoteUUID, companyPublicInfo: companyPublicInfo });
            }
        }, {
            path: '/proposal/:proposalUUID',
            component: ({ proposalUUID }) => {
                return React.createElement(ProposalPublicPage, { proposalUUID: proposalUUID, companyPublicInfo: companyPublicInfo });
            }
        }, {
            path: '/admin{/*subPath}',
            component: () => {
                const basePath = `${baseURL}/admin`;
                return React.createElement(AdminLayout, { companyPublicInfo: companyPublicInfo, basePath: basePath, currentPath: currentPath, navigateTo: navigateTo });
            }
        }];
    const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath.replace(baseURL, '')), component: x.component })).find(x => x.evaluatedPath);
    if (page === null || page === void 0 ? void 0 : page.evaluatedPath)
        return page.component(page.evaluatedPath.params);
    return React.createElement(Error404Page, null);
};
