import React, { useState } from 'react';
import { ClickableCard } from '../../../../components/content_display/card';
import { Button } from '../../../../components/buttons/button';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { Text } from '../../../../components/content_display/text';
import { formatPrice } from '../../../../code/format_price';
import { DropdownMenu } from '../../../../components/buttons/dropdown_menu';
import { Heading } from '../../../../components/content_display/heading';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Icon } from '../../../../components/buttons/icon';
import { roundTo2dp } from '../../../../code/number_format';
import { calculateQuoteSnapshot } from './proposal_configurator';
import { Copy, Percent, Plus, Trash, XCircle } from 'lucide-react';
import { Info } from '../../../../components/buttons/info';
const percentageToAmount = (percentage, total) => (percentage / 100) * total;
export const PaymentSchedule = ({ proposal, updateProposal, calculatedQuote }) => {
    var _a, _b;
    const [isEditing, setIsEditing] = useState(false);
    const handleAddRow = () => {
        const newSchedule = [
            ...(proposal.payment_schedule || []),
            {
                name: '',
                description: '',
                due_date: undefined,
                percentage: 0
            }
        ];
        updateProposal({ ...proposal, payment_schedule: newSchedule });
    };
    const calculatedQuoteSnapshot = calculateQuoteSnapshot(calculatedQuote, proposal);
    // Payment schedule total is pre-grant
    const total = calculatedQuoteSnapshot.total_pre_deductions + calculatedQuoteSnapshot.vat_on_all + calculatedQuoteSnapshot.vat_on_survey + calculatedQuoteSnapshot.discount_total;
    if (!isEditing) {
        return (React.createElement(ClickableCard, { variant: "LIGHT", border: true, title: React.createElement("div", { className: 'w-full flex flex-row gap-2 items-center justify-between' },
                React.createElement(Heading, { size: "md", className: 'flex gap-2 items-center' },
                    "Payment schedule",
                    React.createElement(Info, { infoModalHeader: 'Payment schedule', infoModalBody: 'The payment schedule amounts are calculated as a percentage of the total pre-grant cost of the quote.' }))) }, (_a = proposal.payment_schedule) === null || _a === void 0 ? void 0 :
            _a.map((row, index) => { var _a; return React.createElement(PaymentScheduleRow, { key: index, row: row, index: index, totalRows: ((_a = proposal.payment_schedule) === null || _a === void 0 ? void 0 : _a.length) || 0, total: total }); }),
            React.createElement(Button, { colour: "LIGHT", onClick: () => {
                    if (!proposal.payment_schedule || proposal.payment_schedule.length === 0) {
                        // Create a default payment schedule row
                        handleAddRow();
                    }
                    setIsEditing(true);
                } }, "Edit schedule")));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement(Heading, { size: "md", className: 'flex gap-2 items-center' },
                "Edit payment schedule",
                React.createElement(Info, { infoModalHeader: 'Payment schedule', infoModalBody: 'The payment schedule amounts are calculated as a percentage of the total pre-grant cost of the quote.' })), (_b = proposal.payment_schedule) === null || _b === void 0 ? void 0 :
            _b.map((row, index) => {
                var _a;
                return React.createElement(PaymentScheduleRowEditor, { key: index, row: row, index: index, totalRows: ((_a = proposal.payment_schedule) === null || _a === void 0 ? void 0 : _a.length) || 0, total: total, proposal: proposal, updateProposal: updateProposal });
            }),
            React.createElement("div", { className: 'flex gap-2' },
                React.createElement(Button, { colour: 'LIGHT', iconLeft: Plus, onClick: handleAddRow }, "Add stage"),
                React.createElement(Button, { colour: "DARK", onClick: () => setIsEditing(false) }, "Save changes"))));
    }
};
export const PaymentScheduleRow = ({ row, index, totalRows, total }) => {
    return (React.createElement("div", { className: "w-full flex flex-row gap-4 items-start" },
        React.createElement("div", null,
            React.createElement(Badge, { color: "LIGHT", text: `${index + 1}/${totalRows}` })),
        React.createElement("div", null,
            React.createElement(Text, { size: "SM", bold: true }, row.name),
            row.due_date && React.createElement(Text, { size: "SM", className: "text-gray-500" }, row.due_date.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })),
            row.description && React.createElement("div", { className: 'border-l-2 border-gray-300 pl-2 mt-2' },
                React.createElement(Text, { size: "XS", className: 'text-gray-600' }, row.description))),
        React.createElement("div", { className: 'text-right ml-auto' },
            React.createElement(Text, { size: "SM", bold: true }, formatPrice(percentageToAmount(row.percentage, total), 2)))));
};
export const PaymentScheduleRowEditor = ({ row, index, totalRows, total, proposal, updateProposal }) => {
    const [name, setName] = useState(row.name);
    const [description, setDescription] = useState(row.description);
    const [percentage, setPercentage] = useState(roundTo2dp(row.percentage));
    const [dueDate, setDueDate] = useState(row.due_date || undefined);
    const [amount, setAmount] = useState(roundTo2dp(percentageToAmount(row.percentage, total)));
    const handleSetField = (field, value) => {
        var _a;
        updateProposal({
            ...proposal,
            payment_schedule: (_a = proposal.payment_schedule) === null || _a === void 0 ? void 0 : _a.map((r, i) => {
                if (i === index) {
                    return {
                        ...r,
                        [field]: value
                    };
                }
                else {
                    return r;
                }
            })
        });
    };
    const handleSetName = (value) => {
        setName(value);
        handleSetField('name', value);
    };
    const handleSetDescription = (value) => {
        setDescription(value);
        handleSetField('description', value);
    };
    const handleSetDueDate = (value) => {
        // Make sure this is a real date
        if (value instanceof Date && !isNaN(value.getTime())) {
            setDueDate(value);
            handleSetField('due_date', value);
        }
        else {
            setDueDate(undefined);
            handleSetField('due_date', undefined);
        }
    };
    const handleSetPercentage = (value) => {
        setPercentage(parseFloat(value));
        setAmount(roundTo2dp(percentageToAmount(parseFloat(value), total)));
        handleSetField('percentage', parseFloat(value));
    };
    const handleSetAmount = (value) => {
        setAmount(roundTo2dp(parseFloat(value)));
        // We round the percentage when we display it...
        setPercentage(roundTo2dp((parseFloat(value) / total) * 100));
        // ...but we don't want to round the percentage when we save it
        // (otherwise we end up with a rounding error on the amount)
        handleSetField('percentage', (parseFloat(value) / total) * 100);
    };
    const handleDeleteStage = () => {
        var _a;
        updateProposal({ ...proposal, payment_schedule: (_a = proposal.payment_schedule) === null || _a === void 0 ? void 0 : _a.filter((ps, i) => i !== index) });
    };
    const handleDuplicateStage = () => {
        const newSchedule = proposal.payment_schedule || [];
        newSchedule.splice(index, 0, {
            name,
            description,
            due_date: dueDate ? new Date(dueDate) : undefined,
            percentage
        });
        updateProposal({ ...proposal, payment_schedule: newSchedule });
    };
    // TODO: This validation should happen inside the Input component
    const setDateValue = (date) => {
        try {
            if (date instanceof Date) {
                return date.toISOString().split('T')[0];
            }
            else {
                return date !== null && date !== void 0 ? date : '';
            }
        }
        catch (e) {
            return '';
        }
    };
    return (React.createElement(ClickableCard, { variant: "LIGHT", border: true },
        React.createElement("div", { className: "flex flex-row items-center justify-between" },
            React.createElement(Text, { size: "SM", className: 'flex gap-1 items-center text-gray-600 font-semibold' },
                "Payment stage ",
                React.createElement(Badge, { color: "LIGHT", text: `${index + 1}/${totalRows}` })),
            React.createElement(DropdownMenu, { items: [
                    {
                        label: 'Delete',
                        onClick: handleDeleteStage,
                        icon: Trash,
                        confirmText: 'Are you sure you want to delete this payment stage?'
                    },
                    {
                        label: 'Duplicate',
                        onClick: handleDuplicateStage,
                        icon: Copy
                    }
                ] })),
        React.createElement(Input, { placeholder: "Stage name", value: name, setValue: handleSetName }),
        React.createElement(TextArea, { placeholder: "Description", value: description || '', setValue: handleSetDescription }),
        React.createElement("div", { className: 'grid grid-cols-2 gap-2' },
            React.createElement(Input, { type: "number", value: percentage, setValue: handleSetPercentage, postfix: React.createElement(Icon, { icon: Percent }) }),
            React.createElement(Input, { type: "number", value: amount, setValue: handleSetAmount, prefix: "\u00A3" })),
        React.createElement(Input, { type: "date", value: setDateValue(dueDate), setValue: (e) => handleSetDueDate(new Date(e)), postfix: React.createElement(Icon, { icon: XCircle, onClick: () => handleSetDueDate(undefined) }) })));
};
