export const getSiteDetailsChecks = (survey) => [
    survey.property_images.length > 0
];
export const getExistingHeatingChecks = (survey) => {
    const checks = [
        !!survey.existing_system_type_uuid && survey.existing_system_type_uuid.length > 0,
        !!survey.existing_system_radiator_pipework_uuid
    ];
    if (survey.existing_system_fuel_uuid !== 'electricity') {
        checks.push(!!survey.existing_system_age_uuid);
    }
    return checks;
};
export const getHeatPumpChecks = (survey, currentHeatPump) => {
    const checks = [
        !!currentHeatPump,
        survey.location_images.length > 0,
        !!survey.location_description,
        !!survey.mounting_location,
        !!survey.condensate_drain,
        !!survey.assessment_description,
        survey.sound_distance > 0,
        !!survey.reflective_surfaces,
        !!survey.sound_barrier_uuid
    ];
    if (survey.mounting_location === 'Ground') {
        checks.push(!!survey.base_to_be_build_by);
    }
    return checks;
};
export const getCylinderChecks = (survey) => {
    var _a;
    return [
        survey.cylinder_photos.length > 0,
        ((_a = survey.cylinder_location_description) === null || _a === void 0 ? void 0 : _a.length) > 0
    ];
};
