import React from 'react';
import { Heading } from '../../../../components/content_display/heading';
import { Text } from '../../../../components/content_display/text';
export const EmptySection = ({ title, description, icon, button }) => {
    return (React.createElement("div", { className: "px-6 py-20 rounded-lg bg-gray-50 flex-col justify-center items-center gap-4 flex w-full" },
        React.createElement("div", { className: "flex-col justify-center items-center gap-1 flex" },
            React.createElement(Heading, { size: "xl" }, title),
            React.createElement(Text, { size: "SM", className: 'text-gray-500' }, description)),
        button));
};
