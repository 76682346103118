import React, { useState } from 'react';
import { logEvent } from '../../code/log_event';
import { Input } from '../../components/inputs_and_selections/input';
import { Button } from '../../components/buttons/button';
import { AuthSDK } from '../../code/utils/auth_provider';
import { SpruceLogoBlack } from '../../assets/images/spruce_logo/spruce_logo';
import { AppContext } from '../../app_context';
import { Link } from '../../components/buttons/link';
export const LoginPage = ({ navigateTo }) => {
    const appContext = React.useContext(AppContext);
    // common admin/ preloaded data
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = async (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        logEvent({ name: 'Login Clicked', properties: {} }, null);
        await AuthSDK.signIn(email, password).then(() => {
            // Send them back to the page they tried to visit when they were
            // redirected to the login page. Use { replace: true } so we don't create
            // another entry in the history stack for the login page.  This means that
            // when they get to the protected page and click the back button, they
            // won't end up back on the login page, which is also really nice for the
            // user experience.
            navigateTo('/');
        }).catch((e) => {
            console.error('Error logging in', e);
            appContext.setError(e);
        });
    };
    return React.createElement("div", { className: "flex flex-col items-center justify-center gap-16 w-2/3 max-w-80 mx-auto" },
        React.createElement("img", { alt: "logo", className: "mt-20 max-h-28 max-w-2/3", src: SpruceLogoBlack }),
        React.createElement("div", { onSubmit: handleSubmit, className: "w-full" },
            React.createElement("div", { className: "flex flex-col w-full gap-4" },
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "text-slate-600 text-lg font-bold" }, "Email"),
                    React.createElement(Input, { onEnter: async () => { await handleSubmit(null); }, setValue: setEmail, type: 'text', value: email, placeholder: 'Email' })),
                React.createElement("div", { className: "space-y-2" },
                    React.createElement("div", { className: "text-slate-600 text-lg font-bold" }, "Password"),
                    React.createElement(Input, { onEnter: async () => { await handleSubmit(null); }, setValue: setPassword, type: "password", value: password, placeholder: 'Password' })),
                React.createElement(Link, { onClick: () => navigateTo('/remind-password'), text: 'Forgot password?' }),
                React.createElement(Button, { onClick: handleSubmit }, "Login"))));
};
