import React from 'react';
import { Text } from './text';
export const Progress = ({ progress }) => {
    const clampedProgress = Math.max(0, Math.min(progress, 100));
    return React.createElement("div", { className: 'w-full flex flex-row items-center gap-2' },
        React.createElement("div", { className: "w-full h-2 bg-white border border-light rounded-full" },
            React.createElement("div", { style: { width: `${clampedProgress}%` }, className: 'h-full bg-slate-200 transition-all' })),
        React.createElement(Text, { size: 'SM', className: 'w-10 text-center' },
            Math.round(clampedProgress),
            "%"));
};
