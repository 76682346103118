import React from 'react';
import { ProgressChart } from '../../../components/indicators_and_messaging/progress_chart';
export const ProgressChartDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(ProgressChart, { total: 100, items: [
                    { name: 'Bar 1', value: 50 },
                    { name: 'Bar 2', value: 45 }
                ], colorVariant: 'DEFAULT' })));
};
const componentProps = [
    { name: 'total', description: 'The total value of the bar, used to determine how each each item bar should be' },
    { name: 'items', description: 'An array of bars which should be in the chart' },
    { name: 'name', description: 'items prop: The name of the item shown below the bar with associated colour' },
    { name: 'value', description: 'items prop: Value of the bar, determines width of bar in relation to total' },
    { name: 'colorVariant', description: 'DEFAULT | RADIATOR (default is a pre-selected colour palette, radiators is grey/red)' }
];
const codeBlock = `<ProgressChart
  total={100}
  items={[
    { name: 'Bar 1', value: 50 },
    { name: 'Bar 1', value: 45 }
  ]}
  colorVariant='DEFAULT' 
/>`;
