import React, { useState } from 'react';
import { useFiles } from '../use_files';
import _ from 'lodash';
import { Icon } from '../../components/buttons/icon';
import { Modal } from '../../components/containers/modal';
import { Card } from '../../components/content_display/card';
import { FileDropzone } from '../../components/inputs_and_selections/file_dropzone';
import { FormLabel } from '../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group';
import { validateNotNull } from '../validators';
import { Input } from '../../components/inputs_and_selections/input';
import { Progress } from '../../components/content_display/progress';
import { File, RefreshCw, X } from 'lucide-react';
export const AddInternalAttachmentModal = ({ visible, setVisible, onAddAttachment, forceTitle }) => {
    const { uploadFile, progress, success, error } = useFiles('user-uploads');
    const [fileURL, setFileURL] = useState();
    const [fileName, setFileName] = useState(forceTitle !== null && forceTitle !== void 0 ? forceTitle : '');
    const [fileToUpload, setFileToUpload] = useState(null);
    const commonDocumentFileTypesToAccept = 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
        'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
        'image/jpeg, image/png, image/webp, image/tiff';
    const handleCloseModal = (open) => {
        if (visible) {
            setFileName(forceTitle || '');
            setFileToUpload(null);
            setVisible(open);
        }
    };
    // Main file upload logic
    const handleUploadFile = async (file) => {
        setFileToUpload(file); // Set the file to display name and status
        const formData = new FormData(); // Create the form data to send to the backend
        formData.append('file', file);
        const responseData = await uploadFile(formData);
        setFileURL(responseData); // on success - set the file URL of the proposal attachment
    };
    return React.createElement(Modal, { visible: visible, setVisible: () => handleCloseModal(false), title: 'Upload file', confirmButtonLabel: 'Save', confirmDisabled: !success || fileName.length === 0, onConfirm: () => onAddAttachment(fileName, fileURL.toString()) },
        React.createElement("div", { className: 'w-full flex flex-col gap-4' },
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Document title', helperText: 'The file will be renamed to this', required: true, complete: fileName !== '' }), input: React.createElement(Input, { placeholder: 'e.g. Case Study', setValue: (fileName) => setFileName(fileName), value: fileName, disabled: Boolean(forceTitle), validator: validateNotNull }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'File', required: true, complete: fileURL !== undefined }), input: fileToUpload
                    ? React.createElement(Card, { color: error ? 'RED' : 'DARK' },
                        React.createElement("div", { className: 'flex p-2 justify-between items-center' },
                            React.createElement("div", { className: 'flex gap-4 items-center' },
                                React.createElement(Card, { color: 'WHITE' },
                                    React.createElement(Icon, { icon: File, className: 'size-6' })),
                                React.createElement(FormLabel, { labelText: _.truncate(fileToUpload.name, { length: 30 }), helperText: error || (success ? 'Uploaded' : 'Uploading') })),
                            error
                                ? React.createElement(Icon, { icon: RefreshCw, className: 'cursor-pointer p-1', onClick: () => handleUploadFile(fileToUpload) })
                                : React.createElement(Icon, { icon: X, className: 'cursor-pointer p-1', onClick: () => setFileToUpload(null) })),
                        progress !== 100 && React.createElement(Progress, { progress: progress }))
                    // Only allow one file to be uploaded.
                    : React.createElement(FileDropzone, { helperText: 'Files must be pdf, document, spreadsheet or images', maxSizeMB: 10, accept: commonDocumentFileTypesToAccept, onChange: files => files && handleUploadFile(files[0]) }) })));
};
