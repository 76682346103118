import { axiosDeleteV2, axiosPatchV2, axiosPostV2 } from '../axios';
export const updateProposalQuoteItem = async (proposalQuoteItem, proposalUUID, leadUUID, companyUUID) => {
    await axiosPatchV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items/${proposalQuoteItem.uuid}`, { ...proposalQuoteItem, proposal_uuid: proposalUUID });
};
export const insertProposalQuoteItem = async (proposalQuoteItem, proposalUUID, leadUUID, companyUUID) => {
    await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items`, { ...proposalQuoteItem, proposal_uuid: proposalUUID });
};
export const deleteProposalQuoteItem = async (proposalQuoteItemUUID, proposalUUID, leadUUID, companyUUID) => {
    await axiosDeleteV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items/${proposalQuoteItemUUID}`);
};
export const bulkInsertProposalQuoteItems = async (proposalQuoteItems, proposalUUID, leadUUID, companyUUID) => await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items/bulk`, proposalQuoteItems);
export const bulkDeleteProposalQuoteItems = async (proposalUUID, leadUUID, companyUUID) => {
    await axiosDeleteV2(`teams/${companyUUID}/leads/${leadUUID}/proposals/${proposalUUID}/quote_items/bulk`);
};
