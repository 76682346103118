import React, { useState } from 'react';
import { Toggle } from '../../../components/inputs_and_selections/toggle';
export const ToggleDemo = () => {
    const [toggle, setToggle] = useState(true);
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(Toggle, { value: toggle, setValue: () => setToggle(!toggle), size: 'MD' })));
};
const componentProps = [
    { name: 'value', description: 'Is the toggle enabled or not' },
    { name: 'setValue', description: 'Do something on toggle click' },
    { name: 'size', description: 'SM | MD | LG' }
];
const codeBlock = `const [toggle, setToggle] = useState(true)

<Toggle
  value={toggle}
  setValue={() => setToggle(!toggle)}
  size='MD'
/>
`;
