import { AdminContext } from './pages/admin/admin_layout';
import { getRoleForCompany, USER_ROLE_SUPERADMIN } from './code/models/user';
import { UnauthorizedPage } from './pages/admin/unauthorized_page';
import React, { useContext } from 'react';
import { Loader } from './components/indicators_and_messaging/loader';
export const RequireRole = ({ children, roles }) => {
    var _a, _b, _c;
    const adminContext = useContext(AdminContext);
    if (adminContext.isLoading) {
        return (React.createElement("div", { className: 'w-full flex justify-center items-center my-4' },
            React.createElement(Loader, null)));
    }
    const currentRole = getRoleForCompany((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.user, (_c = (_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.public_info.subdomain);
    if (currentRole === USER_ROLE_SUPERADMIN) {
        return children;
    }
    if (currentRole && roles.includes(currentRole)) {
        return children;
    }
    return React.createElement(UnauthorizedPage, null);
};
