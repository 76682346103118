import React, { useContext, useEffect, useState } from 'react';
import { SurveyActionType } from '../../../code/survey/survey';
import { Input } from '../../../components/inputs_and_selections/input';
import { Button } from '../../../components/buttons/button';
import { ChevronRightIcon } from 'lucide-react';
import { logEvent } from '../../../code/log_event';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext, SurveyDispatchContext } from '../survey_page';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
export const BedroomsStep = ({ companyPublicInfo }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const dispatch = useContext(SurveyDispatchContext);
    // used to represent the "6 or more bedrooms" option
    const uncertainKey = '-1';
    const [selectedOption, setSelectedOption] = useState(survey.lead.property.noBedrooms.toString());
    const [textboxValue, setTextboxValue] = useState(survey.lead.property.noBedrooms.toString());
    const noBedrooms = selectedOption === uncertainKey ? Number(textboxValue) : Number(selectedOption);
    // If the parsed noBedrooms exceeds 6 we need to show the input box immediately with that number.
    useEffect(() => {
        if (survey.lead.property.noBedrooms >= 6)
            setSelectedOption(uncertainKey);
    }, [survey]);
    const handleNavigateNextPage = () => {
        logEvent({ name: 'Count bedroom step completed', properties: {} }, companyPublicInfo.subdomain);
        dispatch({
            type: SurveyActionType.NavigateNextPage,
            payload: {
                noBedrooms
            }
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement(QuestionnaireNavigateBack, null),
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "How many bedrooms does your property have?", captionText: "Let us know how many bedrooms \u2018as sold\u2019." }),
        React.createElement(SurveyOptionGroup, { options: [
                { label: '1 bedroom', key: '1' },
                { label: '2 bedrooms', key: '2' },
                { label: '3 bedrooms', key: '3' },
                { label: '4 bedrooms', key: '4' },
                { label: '5 bedrooms', key: '5' },
                { label: '6 or more bedrooms', key: uncertainKey }
            ], onSelect: (key) => {
                // for the -1 option, we need to set the number of bedrooms to the textbox value
                if (key === uncertainKey)
                    setTextboxValue('6');
                setSelectedOption(key);
            }, selectedOptionKey: selectedOption }),
        selectedOption === uncertainKey && React.createElement(Input, { size: 'LG', setValue: setTextboxValue, type: "number", value: textboxValue, placeholder: 'Enter number of bedrooms' }),
        React.createElement(Button, { size: 'LG', disabled: !noBedrooms || noBedrooms <= 0, onClick: handleNavigateNextPage },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))));
};
