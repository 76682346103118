import { setError } from '../../components/indicators_and_messaging/toast';
import { apiUrl, axiosPatch, axiosPost, axiosPostV2, client } from '../axios';
import { EpcScotlandSchema } from './epc_scotland';
import { DEFAULT_PROPERTY, PropertySchema } from './property';
import { AuthSDK } from '../utils/auth_provider';
import { EPCRecommendationSchema, EPCSchema } from './epc';
import { QuoteItemSchema } from '../calculate_quote';
import { z } from 'zod';
import { parseWithZod } from '../zod';
import { JobEventSchema } from './job_event';
export const LeadStatusSchema = z.enum([
    'New',
    'Quoted',
    'Converted',
    'Rejected',
    'Declined',
    'SurveyBooked',
    'SurveyInProgress',
    'SurveyCompleted',
    'ProposalSent',
    'ProposalAccepted',
    'Archived'
]);
export const CustomerDetailsSchema = z.object({
    name: z.string().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    source: z.string().optional(),
    sourceCompany: z.string().optional()
});
export const EPCChangesSchema = z.object({
    insulation: z.boolean(),
    loftConversion: z.boolean(),
    extension: z.boolean(),
    replacedWindows: z.boolean(),
    other: z.boolean(),
    noChanges: z.boolean()
});
export const HomeEnergyScotlandStatusSchema = z.object({
    scottishGrantIsInTouch: z.string(), // currently stored as "Yes" or "No". TODO later: change to boolean
    scottishGrantReason: z.string()
});
export const LeadSchema = z.object({
    // common fields
    uuid: z.string().optional(),
    companySubdomain: z.string().optional(),
    createdAt: z.string().optional(),
    status: LeadStatusSchema.optional(),
    //   main field for the property
    property: PropertySchema,
    //   fields for the lead
    customer: CustomerDetailsSchema.optional(),
    installer_notes: z.string().optional(),
    heatPumpQuote: z.boolean().optional(), // variable about whether the lead is for a heat pump. Not used currently.
    heatPumpReason: z.string().optional(),
    notes: z.string().optional(),
    whenHeatPump: z.string().optional(),
    //   fields for the EPC data
    epcId: z.string().optional(),
    epc_scotland_id: z.string().optional(),
    epc_scotland: EpcScotlandSchema.optional(),
    epcData: EPCSchema.optional(),
    epc_recommendations: z.array(EPCRecommendationSchema).optional(),
    scottishGrant: HomeEnergyScotlandStatusSchema.optional(),
    scottish_rural: z.boolean().optional(),
    //   EPC changes made by the user, initially all false
    epcChanges: EPCChangesSchema.optional(),
    heat_pump_uuid: z.string().optional(),
    hot_water_cylinder_uuid: z.string().optional(),
    estimate_quote_items: z.array(QuoteItemSchema).optional(),
    flow_temperature_c: z.number().optional(),
    report_sections: z.number().optional(),
    report_recipients: z.string().optional(),
    quote_recipients: z.string().optional(),
    proposal_recipients: z.string().optional(),
    history: z.array(JobEventSchema).optional()
});
export const getAddressIncludingPostcode = (lead) => {
    return [lead.property.address, lead.property.postcode].filter(x => !!x).join(', ');
};
export const DEFAULT_LEAD = {
    property: DEFAULT_PROPERTY,
    epcId: '',
    epc_scotland_id: '',
    status: 'New',
    epcChanges: {
        insulation: false,
        loftConversion: false,
        extension: false,
        replacedWindows: false,
        other: false,
        noChanges: false
    }
};
// Mapping of override keys to their corresponding default keys
export const DEFAULT_KEYS = {
    designTempOverride: 'designTempDefault',
    internalTempOverride: 'internalTempDefault',
    airChangeOverride: 'airChangesDefault',
    externalWallUValueOverride: 'externalWallDefault',
    partyWallUValueOverride: 'partyWallDefault',
    windowsUValueOverride: 'windowDefault',
    floorUValueOverride: 'floorDefault',
    roofUValueOverride: 'roofDefault'
};
export const leadWithNewOverride = (lead, defaults, key, value) => {
    // If we're dealing with a key that has a corresponding default value, we need to handle it differently
    if (DEFAULT_KEYS[key]) {
        // If the value is undefined, we want to remove the override (it will be set to the default value by the calculateEstimate function)
        // If the value is the same as the corresponding default value, we want to remove the override (same as above)
        // Otherwise, we want to set the override to the new value.
        // All the defaults are currently numbers
        const defaultValue = defaults[DEFAULT_KEYS[key]];
        // Should be true that any values that are being compared to the defaults are numbers already, but coerce in case so the comparison is correct
        const numberValue = typeof value === 'string' ? parseFloat(value) : value;
        // Critical to check that value is not undefined here rather than just value ?
        // Using just value ? means that when value is 0 the override gets set to undefined, whereas really 0 is a valid override
        // and more importantly, when typing 0.5 you have to type 0. first and if you just test on value then this acts as
        // if the default has been cleared as you type, which is really jumpy and horrid
        const newValue = value !== undefined ? (numberValue === defaultValue) ? undefined : numberValue : undefined;
        return {
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    [key]: newValue
                }
            }
        };
    }
    else {
        // We don't need to worry about defaults here so we can just set the override to the new value.
        return {
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    [key]: value
                }
            }
        };
    }
};
export const duplicateLead = async (leadUUID, companyUUID, name) => {
    const result = await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/duplicate`, { name });
    return { ...result.data.attributes, uuid: result.data.uuid };
};
export const postLead = async (lead, companyUUID, adminEstimate) => {
    // if (lead.houseNotes.length > 0) lead.notes = lead.houseNotes + '. ' + lead.notes;
    const attributes = Object.fromEntries(Object.entries(lead).filter(e => !['version', 'id', 'extra', 'houseNotes', 'epcData'].includes(e[0])));
    const postRequest = { ...attributes };
    return await axiosPost(`teams/${companyUUID}/leads${adminEstimate ? '?admin_estimate=true' : ''}`, postRequest, 'leads');
};
export const patchLead = async (uuid, lead, companyUUID) => {
    const attributes = Object.fromEntries(Object.entries(lead).filter(e => !['epcData', 'epc_scotland', 'lead_line_items'].includes(e[0])));
    const patchRequest = { ...attributes };
    await axiosPatch(`teams/${companyUUID}/leads/${uuid}`, patchRequest, uuid.toString(), 'leads');
};
export const searchLeads = async (companyUUID, searchText) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/search?query=${searchText}`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        return result.data.data.map(x => ({ ...x.attributes, uuid: x.uuid }));
    }
    catch (e) {
        console.error('Error loading Leads', e);
        return undefined;
    }
};
export const getLeadsList = async (companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        return result.data;
    }
    catch (e) {
        console.error('Error loading Leads', e);
        return undefined;
    }
};
export const getLeads = async (companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        const parsedResult = result.data.data.map(x => parseWithZod(LeadSchema, ({ ...x.attributes, uuid: x.uuid })));
        return parsedResult;
    }
    catch (e) {
        console.error('Error loading Leads', e);
        return undefined;
    }
};
export const getLead = async (leadUUID, companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/${leadUUID}`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        const parsedResult = parseWithZod(LeadSchema, ({ ...result.data.data.attributes, uuid: result.data.data.uuid }));
        return parsedResult;
    }
    catch (e) {
        console.error('Error loading Lead', e);
        return undefined;
    }
};
export const getEmailTextForLead = async (templateName, leadUUID, companyUUID) => {
    try {
        const result = await client.get(`${apiUrl}teams/${companyUUID}/leads/${leadUUID}/get_email_text/${templateName}`, {
            headers: {
                'x-auth-token': AuthSDK.getToken()
            }
        });
        return result.data;
    }
    catch (e) {
        setError(e);
    }
};
export const sendEstimate = async (leadUUID, companyUUID, emailHTML) => {
    try {
        await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/send_estimate`, { email_html: emailHTML });
    }
    catch (e) {
        setError(e);
    }
};
export const sendNudgeEmail = async (leadUUID, estimateUUID, companyUUID, emailHTML) => {
    try {
        await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/send_nudge`, { email_html: emailHTML, quoteUUID: estimateUUID });
    }
    catch (e) {
        setError(e);
    }
};
export const sendDeclined = async (leadUUID, companyUUID, message) => {
    try {
        await axiosPostV2(`teams/${companyUUID}/leads/${leadUUID}/send_declined`, { email_html: message });
    }
    catch (e) {
        setError(e);
    }
};
