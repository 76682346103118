import React, { useCallback, useContext, useState } from 'react';
import { AdminContext } from '../admin_layout';
import { OfflinePage } from '../offline_page';
import { updateCompanySettings } from '../../../code/models/company';
import { RequireRole } from '../../../require_role';
import { updateUser, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../../code/models/user';
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout';
import { CompanyDetails } from './company_settings/company_details';
import { EmailTemplatesSendEstimate } from './email_templates_settings/email_templates_send_estimate';
import { EstimateEnquiryForm } from './estimate_settings/estimate_enquiry_form';
import { AccountIntegrations } from './company_settings/account_integrations';
import { AccountBranding } from './company_settings/account_branding';
import { EstimateResultsPage } from './estimate_settings/estimate_results_page';
import { EmailTemplatesNudgeFollowup } from './email_templates_settings/email_templates_nudge_follow-up';
import { EmailTemplatesBookSurvey } from './email_templates_settings/email_templates_book_survey';
import { EmailTemplatesDeclineEnquiry } from './email_templates_settings/email_templates_decline_enquiry';
import { useDebounceCallback } from 'usehooks-ts';
import { EstimatePreferences } from './estimate_settings/estimate_preferences';
import { AccountTools } from './company_settings/account_tools';
import { EmailTemplatesReport } from './email_templates_settings/email_templates_report';
import { EmailTemplatesNewQuote } from './email_templates_settings/email_templates_new_quote';
import { ProposalTemplates } from './proposal_settings/proposal_templates';
import { EmailTemplatesNewProposal } from './email_templates_settings/email_templates_new_proposal';
import { ProposalAttachments } from './proposal_settings/proposal_attachments';
import { PersonalAccount } from './personal_account';
export const SettingsPage = ({ tab, secondaryTab, companyPublicInfo, navigateTo }) => {
    const adminContext = useContext(AdminContext);
    const [settingsSaved, setSettingsSaved] = useState(true);
    const debouncePatchSettings = useDebounceCallback(async (company) => {
        try {
            await updateCompanySettings(company);
            setSettingsSaved(true);
        }
        catch (e) {
            console.error(e);
        }
    }, 1000);
    const debounceUpdateUser = useDebounceCallback(async (user) => {
        try {
            await updateUser(user);
            setSettingsSaved(true);
        }
        catch (e) {
            console.error(e);
        }
    }, 1000);
    const setCompany = useCallback((company) => {
        setSettingsSaved(false);
        adminContext.setCompany(company);
        debouncePatchSettings(company);
    }, []);
    const setUser = useCallback((user) => {
        setSettingsSaved(false);
        adminContext.setUser(user);
        debounceUpdateUser(user);
    }, []);
    if (!adminContext.data.company)
        return;
    if (adminContext.isOffline) {
        return React.createElement(OfflinePage, { navigateTo: navigateTo });
    }
    return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS] },
        React.createElement(TabbedDashboardLayout, { navigateTo: navigateTo, title: 'Settings', basePath: `/${companyPublicInfo.subdomain}/admin/settings`, selectedTabId: tab, selectedSecondaryTabId: secondaryTab, isOffline: adminContext.isOffline, allSynced: settingsSaved, tabs: [
                {
                    id: 'personal-account',
                    label: 'Personal account',
                    secondaryTabs: [
                        {
                            id: 'personal-details',
                            label: 'Personal details',
                            content: React.createElement(PersonalAccount, { user: adminContext.data.user, setUser: setUser })
                        },
                        {
                            id: 'developer-tools',
                            label: 'Developer tools',
                            content: React.createElement(AccountTools, null)
                        }
                    ]
                },
                {
                    id: 'company',
                    label: 'Company',
                    secondaryTabs: [
                        {
                            id: 'company-details',
                            label: 'Company details',
                            content: React.createElement(CompanyDetails, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'branding',
                            label: 'Branding',
                            content: React.createElement(AccountBranding, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'integrations',
                            label: 'Integrations',
                            content: React.createElement(AccountIntegrations, { company: adminContext.data.company, setCompany: setCompany })
                        }
                    ]
                },
                {
                    id: 'estimate',
                    label: 'Estimate',
                    secondaryTabs: [
                        {
                            id: 'enquiry-form',
                            label: 'Enquiry form',
                            content: React.createElement(EstimateEnquiryForm, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'estimate-preferences',
                            label: 'Estimate preferences',
                            content: React.createElement(EstimatePreferences, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'results-page',
                            label: 'Results page',
                            content: React.createElement(EstimateResultsPage, { company: adminContext.data.company, setCompany: setCompany })
                        }
                    ]
                },
                {
                    id: 'proposal',
                    label: 'Proposal',
                    secondaryTabs: [
                        {
                            id: 'templates',
                            label: 'Templates',
                            content: React.createElement(ProposalTemplates, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'proposal-attachments',
                            label: 'Attachments',
                            content: React.createElement(ProposalAttachments, { company: adminContext.data.company })
                        }
                    ]
                },
                {
                    id: 'email-templates',
                    label: 'Email templates',
                    secondaryTabs: [
                        // {
                        //   id: 'enquiry-received',
                        //   label: 'Enquiry received',
                        //   content: <EmailTemplatesEnquiryReceived
                        //     company={adminContext.data.company}
                        //     setCompany={setCompany}
                        //   />
                        // },
                        {
                            id: 'send-estimate',
                            label: 'Send estimate',
                            content: React.createElement(EmailTemplatesSendEstimate, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'nudge-follow-up',
                            label: 'Nudge follow-up',
                            content: React.createElement(EmailTemplatesNudgeFollowup, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'book-survey',
                            label: 'Book survey',
                            content: React.createElement(EmailTemplatesBookSurvey, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'decline-enquiry',
                            label: 'Decline enquiry',
                            content: React.createElement(EmailTemplatesDeclineEnquiry, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'report',
                            label: 'Send report',
                            content: React.createElement(EmailTemplatesReport, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'new-quote',
                            label: 'Send quote',
                            content: React.createElement(EmailTemplatesNewQuote, { company: adminContext.data.company, setCompany: setCompany })
                        },
                        {
                            id: 'new-proposal',
                            label: 'Send proposal',
                            content: React.createElement(EmailTemplatesNewProposal, { company: adminContext.data.company, setCompany: setCompany })
                        }
                    ]
                }
            ] })));
};
