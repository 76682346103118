import React from 'react';
import { EstimateCoverNoteTemplateBlock } from './blocks/estimate_cover_note_template_block';
import { SettingsLayout } from '../components/settings_layout';
import { EstimateConfigurationBlock } from './blocks/estimate_configuration_block';
import { EstimateAboutUsBlock } from './blocks/estimate_about_us_block';
import { EstimateCustomerReviewBlock } from './blocks/estimate_customer_review_block';
import { EstimateNextStepsBlock } from './blocks/estimate_next_steps_block';
export const EstimateResultsPage = ({ company, setCompany }) => {
    return React.createElement(SettingsLayout, { heading: 'Results page', description: 'Customise the content displayed on your estimates' },
        React.createElement(EstimateConfigurationBlock, { company: company, setCompany: setCompany }),
        React.createElement(EstimateCoverNoteTemplateBlock, { company: company, setCompany: setCompany }),
        React.createElement(EstimateNextStepsBlock, { company: company, setCompany: setCompany }),
        React.createElement(EstimateAboutUsBlock, { company: company, setCompany: setCompany }),
        React.createElement(EstimateCustomerReviewBlock, { company: company, setCompany: setCompany }));
};
