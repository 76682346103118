import React, { useState } from 'react';
import { Checkbox } from '../../../components/inputs_and_selections/checkbox';
export const CheckboxDemo = () => {
    const [checked, setChecked] = useState(true);
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(Checkbox, { checked: checked, setChecked: () => setChecked(!checked) })));
};
const componentProps = [
    { name: 'checked', description: 'Whether the checkbox is checked or not' },
    { name: 'setChecked', description: 'What to do onClick of checkbox' }
];
const codeBlock = `<Checkbox
  checked={checked}
  setChecked={() => setChecked(!checked)}
/>`;
