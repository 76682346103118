import React, { useState } from 'react';
import { Input } from '../../../components/inputs_and_selections/input';
import { noop } from 'lodash';
import { Icon } from '../../../components/buttons/icon';
import { Square } from 'lucide-react';
export const InputDemo = () => {
    const [inputText, setInputText] = useState('');
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", null, "Default"),
            React.createElement(Input, { onEnter: noop, value: inputText, setValue: setInputText, type: 'text', step: undefined, label: 'Label', placeholder: 'placeholder', size: 'MD', shrink: false, validator: (e) => ({ message: '', value: e }), className: '', disabled: false, postfix: React.createElement(Icon, { icon: Square }), prefix: React.createElement(Icon, { icon: Square }) }),
            React.createElement("div", null, "Invalid (focus and unfocus to test)"),
            React.createElement(Input, { value: inputText, setValue: setInputText, validator: (e) => ({ message: 'Not valid', value: undefined }) }),
            React.createElement("div", null, "Disabled"),
            React.createElement(Input, { disabled: true, value: inputText, setValue: setInputText })));
};
const componentProps = [
    { name: 'onEnter', description: 'If we hit the enter key while focussed do something, e.g. call the submit form onClick' },
    { name: 'value', description: 'Value of the input' },
    { name: 'setValue', description: 'What to do on keypress of input' },
    { name: 'debounce', description: 'How long to wait in milliseconds after the last keypress before updating the external value. 0 by default, which means no debounce.' },
    { name: 'type', description: 'number | text' },
    { name: 'step', description: 'If input is a number, what should we increment by?' },
    { name: 'label', description: 'Label text above the input' },
    { name: 'placeholder', description: 'Placeholder when no value is present' },
    { name: 'size', description: 'SM | MD | LG' },
    { name: 'shrink', description: 'Prevent the input from being full width of container' },
    { name: 'validator', description: 'Validate the input, if message is not empty show error state' },
    { name: 'className', description: 'Any extra formatting or styling required' },
    { name: 'disabled', description: 'Should the input be disabled' },
    { name: 'prefix', description: 'Should anything exist before the input? Usually an icon' },
    { name: 'postfix', description: 'Should anything exist after the input? Usually an icon' }
];
const codeBlock = `const [inputText, setInputText] = useState('')

<Input
  onEnter={noop}
  value={inputText}
  setValue={setInputText}
  debounce={500}
  type='text'
  step={undefined}
  label='Label'
  placeholder='placeholder'
  size='MD'
  shrink={false}
  validator={(e) => ({ message: '', value: e })}
  className=''
  disabled={false}
  postfix={<Icon icon={Square} />}
  prefix={<Icon icon={Square} />}
/>`;
