import React, { useState } from 'react';
import { Modal } from '../../../components/containers/modal';
import { duplicateLead } from '../../../code/models/lead';
import { Input } from '../../../components/inputs_and_selections/input';
import { validateNotNull } from '../../../code/validators';
export const DuplicateLeadModal = ({ visible, setVisible, lead, redirectToSurvey, companyPublicInfo, navigateTo }) => {
    var _a;
    const [name, setName] = useState((((_a = lead.customer) === null || _a === void 0 ? void 0 : _a.name) || '') + ' (copy)');
    const handleConfirm = async () => {
        const newLead = await duplicateLead(lead.uuid, companyPublicInfo.uuid, name);
        if (newLead) {
            const url = redirectToSurvey
                ? `/${companyPublicInfo.subdomain}/admin/surveys/${newLead.uuid}`
                : `/${companyPublicInfo.subdomain}/admin/quotes/${newLead.uuid}`;
            navigateTo(url);
        }
    };
    return React.createElement(Modal, { visible: visible, setVisible: setVisible, title: 'Duplicate job?', onConfirm: handleConfirm, confirmButtonLabel: 'Duplicate', confirmDisabled: !validateNotNull(name).value, allowOverflow: true },
        React.createElement(Input, { className: 'w-full', value: name, setValue: setName, validator: validateNotNull, label: 'Enter a name for the duplicated job', onEnter: () => {
                if (validateNotNull(name).value) {
                    handleConfirm();
                    setVisible(false);
                }
            } }));
};
