import React from 'react';
import { BottomSheetHeader } from '../../../components/containers/bottom_sheet_header';
export const BottomSheetHeaderDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(BottomSheetHeader, { title: 'Hello world' })));
};
const componentProps = [
    { name: 'title', description: 'Title of the header' },
    { name: 'setPages', description: 'Always show back button, by default pop the last page off the stack to get to the previous page' },
    { name: 'onBack', description: 'Any additional state cleanup that should happen when going back' }
];
const codeBlock = `<BottomSheetHeader
  title: 'Hello world'
  setPages: noop
  onBack: noop
/>`;
