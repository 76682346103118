import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { ClickableCard } from '../../../../../components/content_display/card';
import { HorizontalFormGroup } from '../../../../../components/inputs_and_selections/horizontal_form_group';
import { Toggle } from '../../../../../components/inputs_and_selections/toggle';
import { validateURLAndHasProtocol } from '../../../../../code/validators';
export const EstimateCustomerReviewBlock = ({ company, setCompany }) => {
    var _a, _b, _c, _d, _e;
    const handleUpdateReview = (name, value) => {
        setCompany({
            ...company,
            public_info: {
                ...company.public_info,
                [name]: value
            }
        });
    };
    return React.createElement(Section, { border: true, title: 'Customer review' },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Customer review section', helperText: 'Display the customer review section on the estimate' }), input: React.createElement(Toggle, { value: company.public_info.show_customer_review_on_estimate, setValue: async () => {
                            const newCompany = {
                                ...company,
                                public_info: {
                                    ...company.public_info,
                                    show_customer_review_on_estimate: !company.public_info.show_customer_review_on_estimate
                                }
                            };
                            setCompany(newCompany);
                        } }) })),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Name', helperText: 'Leave blank to remove name & location from review' }), input: React.createElement(Input, { placeholder: 'Customer name', value: (_a = company.public_info.customer_review_name) !== null && _a !== void 0 ? _a : '', setValue: reviewerName => handleUpdateReview('customer_review_name', reviewerName) }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Location' }), input: React.createElement(Input, { placeholder: 'Customer location', value: (_b = company.public_info.customer_review_location) !== null && _b !== void 0 ? _b : '', setValue: reviewerLocation => handleUpdateReview('customer_review_location', reviewerLocation) }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Review' }), input: React.createElement(TextArea, { placeholder: 'Customer review', value: (_c = company.public_info.customer_review) !== null && _c !== void 0 ? _c : '', setValue: review => handleUpdateReview('customer_review', review), rows: 6 }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Button label', helperText: 'Leave blank to remove link' }), input: React.createElement(Input, { placeholder: 'Link text', value: (_d = company.public_info.customer_review_link_text) !== null && _d !== void 0 ? _d : '', setValue: reviewLinkText => handleUpdateReview('customer_review_link_text', reviewLinkText) }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Button link' }), input: React.createElement(Input, { placeholder: 'URL', value: (_e = company.public_info.customer_review_link) !== null && _e !== void 0 ? _e : '', setValue: reviewLink => {
                        // If the URL is empty or valid, update the review link
                        if (reviewLink === '' || validateURLAndHasProtocol(reviewLink).value) {
                            handleUpdateReview('customer_review_link', reviewLink);
                        }
                    }, validator: validateURLAndHasProtocol, doNotValidateWhenEmpty: true }) })));
};
