// Color Utils
export const DEFAULT_BRAND_COLOUR_PRIMARY = '#111827';
export const DEFAULT_BRAND_COLOUR_SECONDARY = '#f1f5f9';
/**
 * Determines the best text shade ('LIGHT' or 'DARK') based on the background color's luminance.
 *
 * This function calculates the luminance of a given hex color and returns either 'LIGHT' or 'DARK',
 * indicating which shade would provide better contrast against the background.
 *
 * @param {string} hexColour - The hex color code of the background, formatted '#rrggbb'
 * @param {number} [thresholdOverride] - Optional. A custom luminance threshold to determine the shade switch point.
 *                                       If not provided, the default threshold of 128 is used.
 *
 * @returns {'LIGHT' | 'DARK'} The recommended text shade: 'LIGHT' for dark backgrounds, 'DARK' for light backgrounds.
 */
export const determineBestTextShade = (hexColour, thresholdOverride) => {
    const threshold = thresholdOverride !== null && thresholdOverride !== void 0 ? thresholdOverride : 128;
    // Convert hex to RGB
    const hex = hexColour.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    // Calculate luminance
    const luminance = (r * 299 + g * 587 + b * 114) / 1000;
    if (luminance > threshold) {
        return 'LIGHT';
    }
    else {
        return 'DARK';
    }
};
