import React, { createContext, useEffect, useReducer, useState, useContext } from 'react';
import { getInitialSurveyState, stepHashPrefix, SurveyActionType, SurveyPagesEnum, surveyReducer } from '../../code/survey/survey';
import { DataLoaderContext } from './components/data_loader_context';
import { LogoHeader } from './components/logo_header';
import { ProgressBar } from './components/progress_bar';
import { BathroomsStep } from './steps/bathrooms_step';
import { BedroomsStep } from './steps/bedrooms_step';
import { ChooseAddressStep } from './steps/choose_address_step';
import { ConfirmLocationStep } from './steps/confirm_location_step';
import { EPCFoundStep } from './steps/epcfound_step';
import { FloorAreaStep } from './steps/floor_area_step';
import { GetContactsStep } from './steps/get_contacts_step';
import { ManualAddressStep } from './steps/manual_address_step';
import { NoEPCFoundStep } from './steps/no_epcfound_step';
import { ThankYouStep } from './steps/thank_you_step';
import { WallTypeStep } from './steps/wall_type_step';
import { ScottishGrantStep } from './steps/scottish_grant_step';
import { AdminContext } from '../admin/admin_layout';
import { OfflinePage } from '../admin/offline_page';
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../code/models/user';
import { RequireRole } from '../../require_role';
import { Loader } from '../../components/indicators_and_messaging/loader';
import { PageHeader } from '../heat_loss/design/components/design_page_header';
import { WrappedIcon } from '../../components/buttons/wrapped_icon';
import { Menu } from 'lucide-react';
import { OptionsStep } from './steps/options_step';
import { CAVITY_WALL_INSULATION, LOFT_INSULATION, OUTDOOR_SPACE, SOLID_WALL_INSULATION, WINDOW_TYPES } from '../../code/models/u_value';
import { logEvent } from '../../code/log_event';
import { BUILT_FORM_TYPES } from '../../code/models/built_form';
import { PROPERTY_TYPES } from '../../code/models/property_type';
import { FUELS } from '../../code/models/fuel';
import { ESTIMATE_AGE_BANDS } from '../../code/models/age_bands';
// used for reducer
export const SurveyContext = createContext(getInitialSurveyState(''));
// @ts-expect-error context should not start with null
export const SurveyDispatchContext = createContext(null);
// the whole idea of the wrapper is to reload the survey page when the user navigates to the same page
// because react-router renders component only once and doesn't re-render it when the URL changes back and forth
// inside the wrapper we have a key (a special ReactJS attribute) that changes every time the user navigates to the same page
// it solves the SPR-329: https://linear.app/spruce-eco/issue/SPR-329/enquiry-duplicates-information-from-the-previous-enquiry
export const SurveyPageWrapper = ({ showLogo, isAdmin, navigateTo, companyPublicInfo }) => {
    const [reloadKey, setReloadKey] = useState('');
    const reload = () => {
        setReloadKey(crypto.randomUUID());
    };
    useEffect(() => {
        reload();
    }, []);
    if (isAdmin) {
        return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS] },
            React.createElement(PageHeader, { title: 'New enquiry', isOffline: false }),
            React.createElement(HeatLossSurveyPage, { showLogo: false, key: reloadKey, isAdmin: isAdmin, companyPublicInfo: companyPublicInfo, navigateTo: navigateTo })));
    }
    return React.createElement(HeatLossSurveyPage, { showLogo: showLogo, key: reloadKey, isAdmin: isAdmin, companyPublicInfo: companyPublicInfo, navigateTo: navigateTo });
};
export const HeatLossSurveyPage = ({ showLogo, isAdmin, companyPublicInfo, navigateTo }) => {
    // source is used to track the source of the survey
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');
    const adminContext = useContext(AdminContext);
    const [loadingData, setLoadingData] = useState(false);
    const [page, setPage] = useState(null);
    const [hasProgressBar, setHasProgressBar] = useState(true);
    const [survey, dispatch] = useReducer(surveyReducer, source, getInitialSurveyState);
    if (!companyPublicInfo)
        return;
    // hasProgressBar: we have a progress bar on the top of the survey page,
    //      and we have to show it everywhere except some pages,
    //      so, this setting is used to hide the progress bar on the address lookup pages
    //      Also, moving it to the component state leads to infinitive re-rendering of the component
    //      this is why it's managed here as the function return value
    const selectPage = () => {
        var _a, _b, _c, _d, _e, _f, _g;
        switch (survey.currentPage) {
            // Address lookup pages
            case SurveyPagesEnum.ChooseAddressStep:
                return { page: React.createElement(ChooseAddressStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            case SurveyPagesEnum.ManualAddressStep:
                return { page: React.createElement(ManualAddressStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            case SurveyPagesEnum.ConfirmLocationStep:
                return { page: React.createElement(ConfirmLocationStep, null), hasProgressBar: true };
            case SurveyPagesEnum.NoEPCFoundStep:
                return { page: React.createElement(NoEPCFoundStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            case SurveyPagesEnum.EPCFoundStep:
                return { page: React.createElement(EPCFoundStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            // Survey pages
            case SurveyPagesEnum.PropertyTypeStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.PropertyTypeStep, headerText: "What type of property do you live in?", options: PROPERTY_TYPES.map(x => ({ icon: x.icon, key: x.uuid, label: x.name })), defaultOption: survey.lead.property.propertyType, onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Property type step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    propertyType: selectedOption
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.BuiltFormStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.BuiltFormStep, headerText: "What best describes your home?", options: BUILT_FORM_TYPES.map(x => ({ icon: x.icon, key: x.uuid, label: x.name })), defaultOption: (survey.lead.property).builtForm, onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Built form step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    builtForm: selectedOption
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.OutdoorSpaceStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.OutdoorSpaceStep, headerText: "Do you have outdoor space for a heat pump?", captionText: "An air source heat pump will work best if you have a space approximately 3x2m outside.", options: OUTDOOR_SPACE, defaultOption: (survey.lead.property).outdoorSpace, onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Outdoor space step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    outdoorSpace: selectedOption
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.BedroomsStep:
                return { page: React.createElement(BedroomsStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            case SurveyPagesEnum.BathroomsStep:
                return { page: React.createElement(BathroomsStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            case SurveyPagesEnum.CurrentHeatingTypeStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.CurrentHeatingTypeStep, headerText: "What is the main source of fuel for your heating?", captionText: "This will typically be what powers your boiler.", options: FUELS.map(x => ({ key: x.name, label: x.name })), defaultOption: survey.lead.property.fuelType, onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Current heating type step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    mainFuelSource: selectedOption
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.ConstructionAgeBandStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.ConstructionAgeBandStep, headerText: "When was your property built?", options: ESTIMATE_AGE_BANDS.map(x => ({ key: x.uuid, label: x.name })), defaultOption: survey.lead.property.construction_age_band_uuid, onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Property type step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    constructionAgeBand: ESTIMATE_AGE_BANDS.find(x => x.uuid === selectedOption)
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.FloorAreaStep:
                return { page: React.createElement(FloorAreaStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            case SurveyPagesEnum.WallTypeStep:
                return { page: React.createElement(WallTypeStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            case SurveyPagesEnum.WallCavityInsulationStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.WallCavityInsulationStep, headerText: "Cavity wall insulation", captionText: "Let us know if your cavity walls are filled with insulating material.", options: CAVITY_WALL_INSULATION.map(x => ({ icon: x.icon, key: x.name, label: x.name })), defaultOption: survey.lead.property.wallType ? survey.lead.property.wallType : (_b = (_a = survey.lead.property.construction_age_band) === null || _a === void 0 ? void 0 : _a.wallType) !== null && _b !== void 0 ? _b : '', onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Cavity wall insulation step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    wallCavityInsulation: selectedOption
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.WallSolidInsulationStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.WallSolidInsulationStep, headerText: "Are your external walls insulated?", options: SOLID_WALL_INSULATION.map(x => ({ icon: x.icon, key: x.uuid, label: x.name, sublabel: x.description })), defaultOption: (_c = survey.lead.property.wallType) !== null && _c !== void 0 ? _c : 'none', onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Other wall insulation step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    wallType: selectedOption
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.GlazingStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.GlazingStep, headerText: "What are the majority of your windows?", options: WINDOW_TYPES.map(x => ({ key: x.uuid, label: x.name })), defaultOption: survey.lead.property.windowType ? survey.lead.property.windowType : (_e = (_d = survey.lead.property.construction_age_band) === null || _d === void 0 ? void 0 : _d.windowType) !== null && _e !== void 0 ? _e : '', onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Windows step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    windowsGlazing: selectedOption
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            case SurveyPagesEnum.LoftInsulationStep:
                return {
                    page: React.createElement(OptionsStep, { key: SurveyPagesEnum.LoftInsulationStep, headerText: "How much insulation does your roof or loft have?", options: LOFT_INSULATION.map(x => ({ key: x.name, label: x.name })), defaultOption: survey.lead.property.loftInsulation ? survey.lead.property.loftInsulation : (_g = (_f = survey.lead.property.construction_age_band) === null || _f === void 0 ? void 0 : _f.loftInsulation) !== null && _g !== void 0 ? _g : '', onNavigateNextPage: (selectedOption) => {
                            logEvent({ name: 'Loft insulation step completed', properties: {} }, companyPublicInfo.subdomain);
                            dispatch({
                                type: SurveyActionType.NavigateNextPage,
                                payload: {
                                    loftInsulation: selectedOption
                                }
                            });
                        } }),
                    hasProgressBar: true
                };
            // Final pages with results and contacts
            case SurveyPagesEnum.ScottishGrantStep:
                return { page: React.createElement(ScottishGrantStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            case SurveyPagesEnum.GetContactsStep:
                return { page: React.createElement(GetContactsStep, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo, adminEstimate: Boolean(adminContext.data.company && isAdmin) }), hasProgressBar: true };
            case SurveyPagesEnum.ThankYouStep:
                return { page: React.createElement(ThankYouStep, { companyPublicInfo: companyPublicInfo }), hasProgressBar: true };
            default:
                throw new Error('Unknown page');
        }
    };
    const handleHashChange = () => {
        // Handle hash changes
        const stepString = window.location.hash.substring(stepHashPrefix.length);
        // if last page in the stack is the page in the Hash, then we are going back
        if (survey.pagesStack.length !== 0 && survey.pagesStack[survey.pagesStack.length - 1].page === Number(stepString)) {
            dispatch({
                type: SurveyActionType.NavigatePreviousPage
            });
        }
        else {
            // ignore changes in the URL hash
            // this is going to happen probably if user manually changes the URL
            // FIXME: the code below is for Forward navigation, but it doesn't logically work
            // dispatch({
            //   type: SurveyActionType.NavigateToPageFromParams,
            //   payload: {
            //     page: Number(stepString) as SurveyPagesEnum
            //   }
            // })
        }
    };
    // Listen for hash changes
    React.useEffect(() => {
        // this is going to be called ONLY when user changes URL manually or by clicking on the back button
        window.addEventListener('hashchange', handleHashChange);
        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);
    // re-render only when the currentPage changes
    useEffect(() => {
        // ensure the URL hash contains the current page
        // save current page to the browser history
        window.location.hash = `${stepHashPrefix}${survey.currentPage}`;
        // select the page to render
        const { page, hasProgressBar } = selectPage();
        setPage(page);
        setHasProgressBar(hasProgressBar);
    }, [survey.currentPage]);
    if (isAdmin && adminContext.isOffline) {
        return React.createElement(OfflinePage, { navigateTo: navigateTo });
    }
    return (
    // pass the survey state and dispatch to all children
    React.createElement(SurveyContext.Provider, { value: survey },
        React.createElement(SurveyDispatchContext.Provider, { value: dispatch },
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("div", { className: !hasProgressBar ? 'border-solid border-b border-gray-300 ' : '' },
                    React.createElement("div", { className: 'flex items-center px-4' },
                        isAdmin && showLogo && React.createElement(WrappedIcon, { className: 'cursor-pointer text-gray-600 lg:hidden', icon: Menu, onClick: () => adminContext.isSidebarOpen ? adminContext.hideSidebar() : adminContext.showSidebar() }),
                        showLogo && React.createElement(LogoHeader, { companyPublicInfo: companyPublicInfo })),
                    hasProgressBar && React.createElement(ProgressBar, { progress: survey.pbValue })),
                React.createElement(DataLoaderContext.Provider, { value: { loadingData, setLoadingData } },
                    React.createElement("div", { className: "px-7 py-8 flex-col gap-8 flex max-w-xl mx-auto w-full" },
                        loadingData && React.createElement(Loader, null),
                        !loadingData && page))))));
};
