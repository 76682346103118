export const tryParseFloat = (value, defaultValue) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? defaultValue : parsedValue;
};
export const truncateString = (str, num) => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...';
};
// we need this method because window.open(url, '_blank') doesn't work on mobile
export function openInNewTab(url) {
    const newWindow = window.open(url, '_blank');
    if (newWindow === null) {
        // The popup was blocked or not allowed
        window.open(url, '_self');
    }
}
export function isLocalEnvironment() {
    return ['local'].includes(process.env.APP_ENV);
}
export const makeDictFromScottishImprovements = (text) => {
    // downcase the text
    text = text.toLowerCase();
    // split the text by ";" to get key value pairs
    const keyValuePairs = text.split(';')
        // filtering out any potentially remaining empty strings
        .filter(str => str.trim().length);
    // construct a dictionary
    const dictionary = {};
    for (const pair of keyValuePairs) {
        const [key, value] = pair.split(':').map(str => str.trim());
        dictionary[key] = value;
    }
    return dictionary;
};
export const fileToBase64 = async (file) => {
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};
export function base64ToFile(base64, filename, contentType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], filename, { type: contentType });
}
export function base64DataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
export function isFlagSet(value, flag) {
    return (value & flag) === flag;
}
export const removeUndefinedFromList = (list) => {
    return list.filter(x => !!x);
};
const excludeList = ['win'];
export const pluralise = (word, length) => {
    return length === 1 || excludeList.includes(word) ? word : `${word}s`;
};
export async function svgToPng(svgString) {
    return await new Promise((resolve, reject) => {
        // Extract width and height from SVG
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
        const svgElement = svgDoc.documentElement;
        let width = parseInt(svgElement.getAttribute('width') || '0');
        let height = parseInt(svgElement.getAttribute('height') || '0');
        // If width or height is not specified, try to get it from viewBox
        if (width === 0 || height === 0) {
            const viewBox = svgElement.getAttribute('viewBox');
            if (viewBox) {
                const [, , vbWidth, vbHeight] = viewBox.split(' ').map(Number);
                width = width || vbWidth;
                height = height || vbHeight;
            }
        }
        // If we still don't have valid dimensions, reject
        if (width === 0 || height === 0) {
            reject(new Error('Unable to determine SVG dimensions'));
            return;
        }
        // Create a Blob from the SVG string
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
        // Create a URL for the Blob
        const url = URL.createObjectURL(svgBlob);
        // Create an Image object
        const img = new Image();
        img.onload = () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            // Get the canvas context and draw the image
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                reject(new Error('Failed to get canvas context'));
                return;
            }
            ctx.drawImage(img, 0, 0, width, height);
            // Convert the canvas to a PNG as base64
            const pngBase64 = canvas.toDataURL('image/png');
            // Clean up
            URL.revokeObjectURL(url);
            // Resolve the promise with the base64 string
            resolve(pngBase64);
        };
        img.onerror = () => {
            // Clean up
            URL.revokeObjectURL(url);
            reject(new Error('Failed to load SVG'));
        };
        // Set the image source to the Blob URL
        img.src = url;
    });
}
export const downloadBlob = (blob, filename) => {
    try {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        // Append the link to the body, click it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Release the URL object
        window.URL.revokeObjectURL(url);
    }
    catch (error) {
        console.error('Error downloading:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
    }
};
