import React, { useEffect, useState } from 'react';
import { createSurveyInvitation, revokeInvitation } from '../../../code/models/invitations';
import { validateEmail } from '../../../code/validators';
import _ from 'lodash';
import { Button } from '../../../components/buttons/button';
import { Section } from '../../../components/containers/section';
import { Table } from '../../../components/content_display/table';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { Input } from '../../../components/inputs_and_selections/input';
import { Slash } from 'lucide-react';
export const Invitations = ({ title, helpText, adminContext, leadUUID, companyPublicInfo }) => {
    var _a, _b, _c;
    const [invitationEmail, setInvitationEmail] = useState('');
    const [invitations, setInvitations] = useState([]);
    const sendInvitation = async () => {
        try {
            await sendInvitationInner(invitationEmail);
            setInvitationEmail('');
        }
        catch (e) {
            console.error('Error sending invitation', e);
        }
    };
    const revokeSurveyInvitation = async (uuid) => {
        await revokeSurveyInvitationInner(uuid);
    };
    useEffect(() => {
        var _a, _b, _c, _d;
        setInvitations((_d = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.invitations) === null || _c === void 0 ? void 0 : _c.sent.filter(x => x.entity_type === 'survey' && x.entity_uuid === leadUUID)) !== null && _d !== void 0 ? _d : []);
    }, [(_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.invitations) === null || _c === void 0 ? void 0 : _c.sent]);
    const sendInvitationInner = async (email) => {
        if (!email)
            return;
        const invitation = await createSurveyInvitation({
            email,
            companyUuid: companyPublicInfo.uuid,
            surveyUuid: leadUUID
        });
        if (invitation) {
            setInvitations([...invitations, invitation]);
        }
    };
    const revokeSurveyInvitationInner = async (uuid) => {
        const success = await revokeInvitation(uuid);
        if (success) {
            setInvitations(invitations.filter(x => x.uuid !== uuid));
        }
    };
    return (React.createElement(Section, { border: true, title: title },
        React.createElement("div", { className: "flex flex-col gap-4" },
            helpText && React.createElement("p", { className: "text-sm text-gray-500" }, helpText),
            React.createElement("div", { className: 'flex flex-col sm:flex-row gap-4 sm:items-start sm:justify-between' },
                React.createElement(Input, { className: 'flex-1', value: invitationEmail, setValue: setInvitationEmail, placeholder: 'Email address', validator: validateEmail, doNotValidateWhenEmpty: true }),
                React.createElement(Button, { onClick: sendInvitation, disabled: !validateEmail(invitationEmail).value }, "Send invitation")),
            invitations.length > 0 &&
                React.createElement(Table, { columns: [
                        { name: 'Email', key: 'email' },
                        { name: 'Status', render: (row) => React.createElement(Badge, { color: row.status === 'accepted' ? 'GREEN' : 'YELLOW', text: _.capitalize(row.status) }) },
                        { name: 'Actions', render: (row) => React.createElement(Button, { size: "SM", colour: "LIGHT", onClick: async () => await revokeSurveyInvitation(row.uuid), iconLeft: Slash }, "Revoke") }
                    ], rows: invitations }))));
};
