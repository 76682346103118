import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAllCompaniesList, getCompany } from '../../code/models/company';
import { AuthContext, AuthProvider, AuthSDK } from '../../code/utils/auth_provider';
import { noop } from 'lodash';
import { getHeatPumps } from '../../code/models/heat_pump';
import { getHotWaterCylinders } from '../../code/models/hot_water_cylinder';
import { getUser, hasEnquriesAccess, hasInventoryAccess, hasSettingsAccess } from '../../code/models/user';
import { useIndexedDBFallback } from '../../code/use_indexed_db_fallback';
import { getGenericMaterials, getMaterialsGenericLayers } from '../../code/models/material';
import { TermsAndConditionsModal } from './components/terms_and_conditions_modal';
import { getTermsAndConditions, userHasAcceptedLatestTerms } from '../../code/models/terms_and_conditions';
import { Alert } from '../../components/indicators_and_messaging/alert';
import { Link } from '../../components/buttons/link';
import { appStoreBadge, googleBadge } from '../../assets/images/store_badges/store_badges';
import { LeadsListPage } from './leads_list_page';
import { Error404Page } from '../error_pages';
import { InstallerAdminPage } from './job_layout/job_layout';
import { match } from 'path-to-regexp';
import { SettingsPage } from './settings_page/settings_page';
import { NewPasswordPage } from './new_password';
import { SurveyPageWrapper } from '../survey/survey_page';
import { getPacks } from '../../code/models/packs';
import { getLabour } from '../../code/models/labour';
import { getParts } from '../../code/models/parts';
import { CostsAndInventoryPage } from './costs_and_inventory/costs_page';
import { CircleHelp, House, LogOut, PanelLeftClose, PlusCircle, ReceiptPoundSterling, Settings } from 'lucide-react';
import { Select } from '../../components/inputs_and_selections/select';
import { WrappedIcon } from '../../components/buttons/wrapped_icon';
import { MenuSection } from './components/menu_section';
import { MenuItem } from './components/menu_item';
import { openInNewTab } from '../../code/helpers';
const RequireAuth = ({ children, companySubdomain, navigateTo, basePath }) => {
    const auth = useContext(AuthContext);
    const allowList = [
        `/${companySubdomain}/admin/login`,
        `/${companySubdomain}/admin/remind-password`,
        `/${companySubdomain}/admin/new-password`
    ];
    if (auth.isSignedIn() && (location.pathname.replace(/\/$/, '') === `/${companySubdomain}/admin` || allowList.includes(location.pathname))) {
        navigateTo(`${basePath}/enquiries`);
        return;
    }
    if (!auth.isSignedIn() && !allowList.includes(location.pathname)) {
        navigateTo('/login');
        return;
    }
    return children;
};
const Wrapper = ({ companyPublicInfo, basePath, currentPath, navigateTo }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const auth = useContext(AuthContext);
    const adminContext = useContext(AdminContext);
    const [termsModalVisible, setTermsModalVisible] = useState(false);
    const navRef = useRef(null);
    document.addEventListener('mousedown', (e) => {
        if (navRef.current && !navRef.current.contains(e.target)) {
            adminContext.hideSidebar();
        }
    });
    useEffect(() => {
        // We need to do a raw regex check here, react router useMatch does not work.
        const surveyPage = /\/admin\/quotes\/[0-9a-fA-F-]{36}\/survey\/floors\/(.*)$/;
        const isSidebarVisible = !surveyPage.test(window.location.pathname);
        adminContext.setIsSidebarVisible(isSidebarVisible);
    }, [location]);
    const NEW_ENQUIRY = '/enquiries/new';
    const JOB_LIST = '/enquiries{/:filter}';
    const JOB_DETAIL = '/quotes/:leadUUID{/:tab}{/:secondaryTab}{/*any}';
    const SETTINGS_URL = '/settings{/:tab}{/:secondaryTab}';
    const COSTS_URL = '/costs{/:tab}{/:secondaryTab}';
    const ROUTES = [{
            path: NEW_ENQUIRY,
            component: () => {
                return React.createElement(SurveyPageWrapper, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo, showLogo: true, isAdmin: true });
            }
        }, {
            path: JOB_LIST,
            component: ({ filter }) => {
                return React.createElement(LeadsListPage, { filter: filter !== null && filter !== void 0 ? filter : 'All', companyPublicInfo: companyPublicInfo, navigateTo: navigateTo, basePath: basePath });
            }
        }, {
            path: JOB_DETAIL,
            component: ({ leadUUID, tab, secondaryTab }) => {
                const basePathQuote = `${basePath}/quotes/${leadUUID}`;
                return React.createElement(InstallerAdminPage, { basePath: basePathQuote, companyPublicInfo: companyPublicInfo, leadUUID: leadUUID, tab: tab, secondaryTab: secondaryTab, currentPath: currentPath, navigateTo: navigateTo });
            }
        }, {
            path: SETTINGS_URL,
            component: ({ tab, secondaryTab }) => {
                return React.createElement(SettingsPage, { navigateTo: navigateTo, tab: tab, secondaryTab: secondaryTab, companyPublicInfo: companyPublicInfo });
            }
        }, {
            path: COSTS_URL,
            component: ({ tab, secondaryTab }) => React.createElement(CostsAndInventoryPage, { companyPublicInfo: companyPublicInfo, tab: tab, secondaryTab: secondaryTab, navigateTo: navigateTo })
        }, {
            path: '/new-password',
            component: () => React.createElement(NewPasswordPage, { navigateTo: navigateTo, companyPublicInfo: companyPublicInfo })
        }];
    const page = ROUTES.map(x => ({ evaluatedPath: match(x.path)(currentPath.replace(basePath, '')), path: x.path, component: x.component })).find(x => x.evaluatedPath);
    if (!(page === null || page === void 0 ? void 0 : page.evaluatedPath))
        return React.createElement(Error404Page, null);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex h-full" },
            auth.isSignedIn() && React.createElement(React.Fragment, null,
                React.createElement("div", { ref: navRef, className: `z-10 h-full bg-zinc-100 border-r w-64 px-4 py-6 shadow-lg md:shadow-none ${adminContext.isSidebarVisible ? 'md:flex md:static' : ''} absolute
            ${adminContext.isSidebarOpen && adminContext.isSidebarVisible ? '' : 'hidden'}
          `, "data-cy": "sidebar" },
                    React.createElement("div", { className: 'flex flex-col gap-10 justify-between h-full overflow-y-auto no-scrollbar w-full' },
                        React.createElement("div", { className: 'flex flex-col gap-10' },
                            React.createElement("div", { className: 'flex justify-between items-center' },
                                React.createElement("img", { src: companyPublicInfo.logo, className: 'w-32' }),
                                React.createElement("div", { className: 'cursor-pointer md:hidden', onClick: () => adminContext.hideSidebar() },
                                    React.createElement(WrappedIcon, { icon: PanelLeftClose }))),
                            adminContext.data.allCompaniesList && ((_a = adminContext.data.allCompaniesList) === null || _a === void 0 ? void 0 : _a.length) > 1 &&
                                React.createElement(Select, { filter: true, options: adminContext.data.allCompaniesList.map(x => ({
                                        key: x.subdomain,
                                        value: x.name + ' (' + x.subdomain + ')'
                                    })), selectedKey: companyPublicInfo.subdomain, setSelectedKey: (subdomain) => {
                                        if (subdomain) {
                                            const request = indexedDB.deleteDatabase('spruce');
                                            request.onsuccess = () => {
                                                localStorage.setItem('last_synced', '0');
                                            };
                                            localStorage.removeItem('leads');
                                            window.location.href = `/${subdomain}/admin`;
                                        }
                                    } }),
                            hasEnquriesAccess((_b = adminContext.data) === null || _b === void 0 ? void 0 : _b.company, (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.user) && React.createElement(MenuSection, { name: 'Jobs' },
                                React.createElement(MenuItem, { name: 'Jobs', icon: House, isSelected: [JOB_LIST, JOB_DETAIL].includes(page.path), onClick: () => {
                                        navigateTo(`${basePath}/enquiries`);
                                        adminContext.hideSidebar();
                                    } }),
                                React.createElement(MenuItem, { name: 'New enquiry', icon: PlusCircle, isSelected: page.path === NEW_ENQUIRY, onClick: () => {
                                        navigateTo(`${basePath}/enquiries/new`);
                                        adminContext.hideSidebar();
                                    } })),
                            (hasInventoryAccess((_d = adminContext.data) === null || _d === void 0 ? void 0 : _d.company, (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.user) || hasSettingsAccess((_f = adminContext.data) === null || _f === void 0 ? void 0 : _f.company, (_g = adminContext.data) === null || _g === void 0 ? void 0 : _g.user)) && React.createElement(MenuSection, { name: 'Configuration' },
                                React.createElement(MenuItem, { name: 'Costs & inventory', icon: ReceiptPoundSterling, isSelected: page.path === COSTS_URL, onClick: () => {
                                        navigateTo(`${basePath}/costs`);
                                        adminContext.hideSidebar();
                                    } }),
                                React.createElement(MenuItem, { name: 'Settings', icon: Settings, isSelected: page.path === SETTINGS_URL, onClick: () => {
                                        navigateTo(`${basePath}/settings`);
                                        adminContext.hideSidebar();
                                    } })),
                            React.createElement(MenuSection, { name: 'User' },
                                React.createElement(MenuItem, { name: 'Visit help centre', icon: CircleHelp, isSelected: false, onClick: () => openInNewTab('https://spruce-energy.notion.site/Spruce-Guide-de6f73384ba94ffbba3fe76adb096072') }),
                                React.createElement(MenuItem, { name: 'Logout', icon: LogOut, isSelected: false, onClick: () => auth.signOut(() => navigateTo('/login')) }))),
                        React.createElement("div", { className: "flex flex-col items-center gap-4" },
                            React.createElement("a", { href: "https://apps.apple.com/gb/app/spruce-energy/id6530588740", target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("img", { alt: "Download on the App Store", className: "w-32 h-auto", src: appStoreBadge })),
                            React.createElement("a", { href: "https://play.google.com/store/apps/details?id=eco.spruce.app", target: "_blank", rel: "noopener noreferrer" },
                                React.createElement("img", { alt: "Get it on Google Play", className: "w-32 h-auto", src: googleBadge })))))),
            React.createElement("div", { className: "overflow-auto print:overflow-visible w-full h-full flex flex-col" },
                (!adminContext.isLoading && adminContext.data.termsAndConditions && !userHasAcceptedLatestTerms(adminContext.data.user, adminContext.data.termsAndConditions)) && React.createElement(Alert, { type: 'WARNING' },
                    "We\u2019ve updated our Terms & Conditions. Please could you review and accept them ",
                    React.createElement(Link, { text: 'here', className: 'inline', onClick: () => setTermsModalVisible(true) }),
                    "."),
                page.component(page.evaluatedPath.params))),
        React.createElement(TermsAndConditionsModal, { visible: termsModalVisible, setVisible: setTermsModalVisible, termsAndConditions: adminContext.data.termsAndConditions })));
};
const AdminContextDataDefaultValue = {
    company: undefined,
    allCompaniesList: [],
    heatPumps: [],
    hotWaterCylinders: [],
    labour: [],
    parts: [],
    packs: [],
    user: undefined
};
// Used to store global state for the admin UI
// also has triggers to update the high-level state for some objects like sidebar
export const AdminContext = React.createContext({
    showSidebar: noop,
    hideSidebar: noop,
    isSidebarOpen: false,
    isLoading: true,
    isOffline: false,
    data: AdminContextDataDefaultValue,
    // seters for the data
    setUser: noop,
    setCompany: noop,
    setHeatPumps: noop,
    setHotWaterCylinders: noop,
    setLabour: noop,
    setParts: noop,
    setPacks: noop,
    acceptTermsAndConditions: noop,
    setIsOffline: noop,
    isSidebarVisible: true,
    setIsSidebarVisible: noop
});
export const AdminLayout = ({ companyPublicInfo, basePath, currentPath, navigateTo }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [adminData, setAdminData] = useState(AdminContextDataDefaultValue);
    // the only reason this is here is to force reloading data when the user signs in
    const isLoggedIn = useRef(AuthSDK.isSignedIn());
    // flags to track loading data
    const [loadedDataFlags, setLoadedDataFlags] = useState(0);
    const FLAG_COMPANY_LOADED = 0x0001;
    const FLAG_HEAT_PUMPS_LOADED = 0x0002;
    const FLAG_HWCS_LOADED = 0x0004;
    const FLAG_ALL_COMPANIES_LOADED = 0x0008;
    const FLAG_USER_LOADED = 0x0010;
    const FLAG_GENERIC_MATERIALS_LOADED = 0x0020;
    const FLAG_MATERIALS_GENERIC_LAYERS_LOADED = 0x0040;
    const FLAG_LABOUR_LOADED = 0x0100;
    const FLAG_PARTS_LOADED = 0x0200;
    const FLAG_PACKS_LOADED = 0x0400;
    const FLAG_ALL_LOADED = FLAG_COMPANY_LOADED |
        FLAG_HEAT_PUMPS_LOADED |
        FLAG_HWCS_LOADED |
        FLAG_ALL_COMPANIES_LOADED |
        FLAG_USER_LOADED |
        FLAG_GENERIC_MATERIALS_LOADED |
        FLAG_MATERIALS_GENERIC_LAYERS_LOADED |
        FLAG_LABOUR_LOADED |
        FLAG_PARTS_LOADED |
        FLAG_PACKS_LOADED;
    const [isOffline, setIsOffline] = useState(false);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    window.addEventListener('offline', () => {
        setIsOffline(true);
    });
    window.addEventListener('online', () => {
        setIsOffline(false);
    });
    const [, , , handleDataCompanyReload] = useIndexedDBFallback('admin_data', 'company', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getCompany(companyPublicInfo.subdomain);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_COMPANY_LOADED);
        setAdminData(prev => ({ ...prev, company: data }));
    });
    const [, , , handleDataHeatPumpsReload] = useIndexedDBFallback('admin_data', 'heat_pumps', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getHeatPumps(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_HEAT_PUMPS_LOADED);
        setAdminData(prev => ({ ...prev, heatPumps: data }));
    });
    const [, , , handleDataHWCsReload] = useIndexedDBFallback('admin_data', 'hot_water_cylinders', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getHotWaterCylinders(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_HWCS_LOADED);
        setAdminData(prev => ({ ...prev, hotWaterCylinders: data }));
    });
    const [, , , handleDataLabourReload] = useIndexedDBFallback('admin_data', 'labour', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getLabour(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_LABOUR_LOADED);
        setAdminData(prev => ({ ...prev, labour: data }));
    });
    const [, , , handleDataPartsReload] = useIndexedDBFallback('admin_data', 'parts', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getParts(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_PARTS_LOADED);
        setAdminData(prev => ({ ...prev, parts: data }));
    });
    const [, , , handleDataPacksReload] = useIndexedDBFallback('admin_data', 'packs', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getPacks(companyPublicInfo.uuid);
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_PACKS_LOADED);
        setAdminData(prev => ({ ...prev, packs: data }));
    });
    const [, , , handleDataAllCompaniesReload] = useIndexedDBFallback('admin_data', 'all_companies', [], async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getAllCompaniesList();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_ALL_COMPANIES_LOADED);
        setAdminData(prev => ({ ...prev, allCompaniesList: data }));
    });
    const [, , , handleDataUserReload] = useIndexedDBFallback('admin_data', 'current_user', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getUser();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_USER_LOADED);
        setAdminData(prev => ({ ...prev, user: data }));
    });
    const [, , , handleDataTermsAndConditionsReload] = useIndexedDBFallback('admin_data', 'terms_and_conditions', undefined, async () => {
        return await getTermsAndConditions();
    }, (data) => {
        setAdminData(prev => ({ ...prev, termsAndConditions: data }));
    });
    const [, , , handleDataGenericMaterialsReload] = useIndexedDBFallback('admin_data', 'generic_materials', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getGenericMaterials();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_GENERIC_MATERIALS_LOADED);
        setAdminData(prev => ({ ...prev, genericMaterials: data }));
    });
    const [, , , handleDataMaterialsGenericLayersReload] = useIndexedDBFallback('admin_data', 'generic_materials_layers', undefined, async () => {
        if (!isLoggedIn.current)
            return undefined;
        return await getMaterialsGenericLayers();
    }, (data) => {
        setLoadedDataFlags(prev => prev | FLAG_MATERIALS_GENERIC_LAYERS_LOADED);
        setAdminData(prev => ({ ...prev, materialsGenericLayers: data }));
    });
    // ask user permission to persist data
    async function persistData() {
        var _a;
        if ((_a = navigator.storage) === null || _a === void 0 ? void 0 : _a.persist) {
            await navigator.storage.persist();
        }
    }
    // aks user permission to persist data, triggers only on first load
    useEffect(() => {
        const ensureDataPersisted = async () => {
            // check if the browser supports the storage persist API
            const isPersisted = await navigator.storage.persisted();
            if (!isPersisted) {
                await persistData();
            }
        };
        ensureDataPersisted();
    }, []);
    // control the loading state
    useEffect(() => {
        // check if the user is logged in
        if (!isLoggedIn.current)
            return;
        // reset the loading state only when all data is loaded
        if (loadedDataFlags === FLAG_ALL_LOADED) {
            setIsLoading(false);
        }
    }, [loadedDataFlags]);
    const toggleSidebar = (arg) => {
        setIsSidebarOpen(arg);
    };
    const handleLoginCallback = async (newLoginStatus) => {
        isLoggedIn.current = newLoginStatus;
        if (newLoginStatus) {
            setIsLoading(true);
            await handleDataCompanyReload();
            await handleDataHeatPumpsReload();
            await handleDataHWCsReload();
            await handleDataLabourReload();
            await handleDataPartsReload();
            await handleDataPacksReload();
            await handleDataAllCompaniesReload();
            await handleDataUserReload();
            await handleDataGenericMaterialsReload();
            await handleDataMaterialsGenericLayersReload();
            await handleDataTermsAndConditionsReload();
            setIsLoading(false);
        }
    };
    return (React.createElement(AuthProvider, { loginCallback: handleLoginCallback },
        React.createElement(RequireAuth, { companySubdomain: companyPublicInfo.subdomain, navigateTo: navigateTo, basePath: basePath },
            React.createElement(AdminContext.Provider, { value: {
                    showSidebar: () => {
                        toggleSidebar(true);
                    },
                    hideSidebar: () => {
                        toggleSidebar(false);
                    },
                    isSidebarOpen,
                    isLoading,
                    isOffline,
                    setIsOffline,
                    data: adminData,
                    setUser: (data) => setAdminData(prev => ({ ...prev, user: data })),
                    setCompany: (data) => setAdminData(prev => ({ ...prev, company: data })),
                    setHeatPumps: (data) => setAdminData(prev => ({ ...prev, heatPumps: data })),
                    setHotWaterCylinders: (data) => setAdminData(prev => ({ ...prev, hotWaterCylinders: data })),
                    setLabour: (data) => setAdminData(prev => ({ ...prev, labour: data })),
                    setParts: (data) => setAdminData(prev => ({ ...prev, parts: data })),
                    setPacks: (data) => setAdminData(prev => ({ ...prev, packs: data })),
                    acceptTermsAndConditions: async (id) => { setAdminData(prev => ({ ...prev, user: { ...prev.user, accepted_terms_and_conditions_id: id } })); },
                    isSidebarVisible,
                    setIsSidebarVisible
                } },
                React.createElement(Wrapper, { companyPublicInfo: companyPublicInfo, navigateTo: navigateTo, basePath: basePath, currentPath: currentPath })))));
};
