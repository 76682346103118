import React, { useContext } from 'react';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyContext } from '../survey_page';
import { StepHeader } from '../components/steps_header';
import { SubHeader } from '../components/sub_header';
import { Accordion } from '../../../components/content_display/accordion';
import { faqRows } from './get_contacts_step';
import { checkIfScottish } from '../../../code/models/address';
import { NextStepsComponent } from '../components/next_steps';
export const ThankYouStep = ({ companyPublicInfo }) => {
    var _a;
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    return React.createElement(React.Fragment, null,
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: "Enquiry Sent \uD83D\uDE4C", captionText: `Thanks ${(_a = survey.lead.customer) === null || _a === void 0 ? void 0 : _a.name}! We'll send you an email with a detailed cost estimate within the next 48 hours. If you have any queries in the meantime, drop us an email at ${companyPublicInfo.notification_email}, or give us a call on ${companyPublicInfo.phone}.` }),
        React.createElement(NextStepsComponent, { isScottish: checkIfScottish(survey.lead.property.postcode), isChecked: false }),
        React.createElement("div", { className: "w-full flex flex-col justify-start items-start gap-1" },
            React.createElement(SubHeader, { content: "Common questions" }),
            React.createElement(Accordion, { rows: faqRows() })));
};
