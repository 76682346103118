import React from 'react';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { numberFormat } from '../../../code/number_format';
import { formatPrice } from '../../../code/format_price';
import { CylinderIcon, HeatPumpIcon2, RadiatorIcon } from '../../../assets/images/survey_images/survey_images';
export const HLRSummaryPage = () => {
    var _a, _b, _c;
    const hlrContext = React.useContext(HLRContext);
    const estimateSummary = hlrContext.performanceEstimateSummary;
    const hpImage = process.env.S3_BUCKET_URL + '/hp-images/' + ((_b = (_a = hlrContext.currentHeatPump) === null || _a === void 0 ? void 0 : _a.range_heat_pump) === null || _b === void 0 ? void 0 : _b.uuid) + '.png';
    return (React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement(HLRSubheaderBlock, { title: "Summary" }),
        React.createElement("div", { className: "flex-col flex gap-4" },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Your home\u2019s heat loss"),
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 gap-3' },
                React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Floor area"),
                    React.createElement("div", { className: 'flex gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, numberFormat(0).format(hlrContext.summaryData.floorArea)),
                        React.createElement("div", { className: " text-lg" }, "m\u00B2"))),
                React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Total heat loss"),
                    React.createElement("div", { className: 'flex gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, numberFormat(2).format(hlrContext.summaryData.heatLoss / 1000)),
                        React.createElement("div", { className: " text-lg" }, "kW"))),
                React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Average heat load"),
                    React.createElement("div", { className: 'flex gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, numberFormat(0).format(hlrContext.summaryData.heatLoss / hlrContext.summaryData.floorArea)),
                        React.createElement("div", { className: " text-lg" }, "W/m\u00B2"))))),
        React.createElement("div", { className: "flex-col flex gap-4" },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Heat pump design proposal"),
            React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-3 gap-3' },
                React.createElement("div", { className: 'sm:h-44 sm:col-span-2 p-5 flex flex-row items-center bg-gray-100 rounded-md gap-8' },
                    React.createElement("img", { alt: '', className: "w-24 max-h-32", src: hpImage, onError: (e) => {
                            e.currentTarget.onerror = null;
                            e.currentTarget.src = HeatPumpIcon2;
                        } }),
                    React.createElement("div", { className: 'w-full flex flex-col gap-4' },
                        React.createElement("div", { className: "" }, "Heat pump"),
                        React.createElement("div", { className: 'flex flex-col gap-3' },
                            React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, hlrContext.currentHeatPump.name),
                            React.createElement("div", { className: 'flex flex-col gap-2' },
                                React.createElement("div", { className: 'flex flex-row gap-8 justify-between' },
                                    React.createElement("div", { className: " text-xs font-semibold" },
                                        "Capacity at ",
                                        hlrContext.hpCapacityResult.flowTempC,
                                        " \u00B0C flow temp and ",
                                        hlrContext.hpCapacityResult.outsideTempC,
                                        " \u00B0C outdoor air temperature"),
                                    React.createElement("div", { className: "text-right  text-xs font-semibold text-nowrap" },
                                        hlrContext.hpCapacityResult.capacityKw,
                                        " kW")),
                                React.createElement("div", { className: 'flex flex-row gap-8 justify-between' },
                                    React.createElement("div", { className: " text-xs font-semibold" },
                                        "SCOP at ",
                                        hlrContext.design.flow_temp,
                                        " \u00B0C"),
                                    React.createElement("div", { className: "text-right  text-xs font-semibold text-nowrap" }, hlrContext.scop)))))),
                React.createElement("div", { className: 'row-span-2 p-5 hidden sm:flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: 'w-full h-full flex flex-col justify-between' },
                        React.createElement("div", { className: "" }, "Emitter changes"),
                        React.createElement("img", { alt: '', className: "w-36 h-36", src: RadiatorIcon }),
                        React.createElement("div", { className: "flex-col gap-2 flex" },
                            React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, hlrContext.summaryData.radiatorsNew + hlrContext.summaryData.radiatorsReplaced),
                                React.createElement("div", { className: "text-lg" }, "new radiators")),
                            hlrContext.summaryData.ufhNew > 0 && React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, hlrContext.summaryData.ufhNew),
                                React.createElement("div", { className: "text-lg" }, "new ufh zones")),
                            React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, hlrContext.summaryData.emittersUnchanged),
                                React.createElement("div", { className: "text-lg" }, "retained")),
                            React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                                React.createElement("div", { className: "text-gray-900 text-4xl font-bold" }, hlrContext.summaryData.emittersRemoved + hlrContext.summaryData.radiatorsReplaced),
                                React.createElement("div", { className: "text-lg" }, "removed"))))),
                React.createElement("div", { className: 'sm:h-44 sm:col-span-2 p-5 flex flex-row items-center bg-gray-100 rounded-md gap-8' },
                    React.createElement("img", { alt: '', className: "w-24 h-24", src: CylinderIcon }),
                    React.createElement("div", { className: 'w-full flex flex-col gap-4' },
                        React.createElement("div", { className: "" }, "Cylinder"),
                        React.createElement("div", { className: 'flex flex-col gap-3' },
                            React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, ((_c = hlrContext.currentHotWaterCylinder) === null || _c === void 0 ? void 0 : _c.name) || '?'),
                            React.createElement("div", { className: 'flex flex-col gap-2' },
                                React.createElement("div", { className: 'flex flex-row gap-8 justify-between' },
                                    React.createElement("div", { className: "text-xs font-semibold" }, "Capacity"),
                                    React.createElement("div", { className: "text-right text-xs font-semibold" },
                                        Math.round(hlrContext.currentHotWaterCylinder.litres),
                                        " litres"))))))),
            React.createElement("div", { className: 'p-5 sm:hidden flex-col justify-between bg-gray-100 rounded-md' },
                React.createElement("div", { className: "" }, "Emitter changes"),
                React.createElement("div", { className: 'flex flex-row justify-between' },
                    React.createElement("img", { alt: '', className: "w-24 h-24", src: RadiatorIcon }),
                    React.createElement("div", { className: "flex-col gap-2 flex" },
                        React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                            React.createElement("div", { className: "text-gray-900 font-bold" }, hlrContext.summaryData.radiatorsNew + hlrContext.summaryData.radiatorsReplaced),
                            React.createElement("div", null, "new radiators")),
                        hlrContext.summaryData.ufhNew > 0 && React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                            React.createElement("div", { className: "text-gray-900 font-bold" }, hlrContext.summaryData.ufhNew),
                            React.createElement("div", null, "new ufh zones")),
                        React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                            React.createElement("div", { className: "text-gray-900 font-bold" }, hlrContext.summaryData.emittersUnchanged),
                            React.createElement("div", null, "retained")),
                        React.createElement("div", { className: "justify-start items-baseline gap-1 flex" },
                            React.createElement("div", { className: "text-gray-900 font-bold" }, hlrContext.summaryData.emittersRemoved + hlrContext.summaryData.radiatorsReplaced),
                            React.createElement("div", null, "removed")))))),
        (!Number.isNaN(estimateSummary.minEmissionsTonnesSavings) && !Number.isNaN(estimateSummary.minBillSavings)) && React.createElement("div", { className: "flex-col flex gap-4" },
            React.createElement("div", { className: "text-gray-900 text-lg font-bold" }, "Performance comparison"),
            React.createElement("div", { className: 'grid grid-cols-2 gap-3' },
                !Number.isNaN(estimateSummary.minEmissionsTonnesSavings) && React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Annual CO2 savings"),
                    React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold px-1 gap-1 flex" },
                            React.createElement("div", { className: `${estimateSummary.minEmissionsTonnesSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` }, numberFormat(1).format(estimateSummary.minEmissionsTonnesSavings)),
                            numberFormat(1).format(estimateSummary.minEmissionsTonnesSavings) !== numberFormat(1).format(estimateSummary.maxEmissionsTonnesSavings) && React.createElement(React.Fragment, null,
                                React.createElement("div", null, "to"),
                                React.createElement("div", { className: `${estimateSummary.maxEmissionsTonnesSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` }, numberFormat(1).format(estimateSummary.maxEmissionsTonnesSavings)))),
                        React.createElement("div", { className: " text-lg" }, "tonnes"))),
                !Number.isNaN(estimateSummary.minBillSavings) && React.createElement("div", { className: 'h-32 p-5 flex flex-col justify-between bg-gray-100 rounded-md' },
                    React.createElement("div", { className: "" }, "Annual running cost savings"),
                    React.createElement("div", { className: 'flex flex-row gap-1 items-end' },
                        React.createElement("div", { className: "text-gray-900 text-4xl font-bold px-1 gap-1 flex" },
                            React.createElement("div", { className: `${estimateSummary.minBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` }, formatPrice(estimateSummary.minBillSavings, 0)),
                            React.createElement("div", null, "to"),
                            React.createElement("div", { className: `${estimateSummary.maxBillSavings > 0 ? 'bg-green-200' : 'bg-rose-200'} px-1 rounded-md gap-2.5 flex` }, formatPrice(estimateSummary.maxBillSavings, 0))),
                        React.createElement("div", { className: "text-lg" }, "per year")))))));
};
