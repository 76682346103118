import React from 'react';
// We can't use <ul> or <ol> because they have a lot of default styling that we don't want
// e.g. offset between the bullet and the text, padding, etc.
// so we're using a flexbox container with custom styling instead
export const HLRListBlock = ({ items, isNumbered, className, gapSize = 2 }) => {
    if (items.length === 0)
        return React.createElement(React.Fragment, null);
    // gap between the bullet and the text
    const gapClass = 'gap-' + gapSize;
    // item width class for make the bullets/items column equal width
    const itemWidthClass = 'w-' + (gapSize + 1);
    return (React.createElement("div", { className: 'w-full flex flex-col' }, items.map((item, idx) => (React.createElement("div", { key: idx, className: `${gapClass} flex items-start justify-start` },
        isNumbered && React.createElement("div", { className: `inline-block ${className} ${itemWidthClass}` },
            idx + 1,
            "."),
        !isNumbered && React.createElement("div", { className: `${className} ${itemWidthClass}` }, "\u2022"),
        React.createElement("div", { className: className }, item))))));
};
