import { Button } from '../../../components/buttons/button';
import { centreAndScaleOnFloor, centreOnRoomScaleOnFloor } from './code/utils';
import React from 'react';
import { Alert } from '../../../components/indicators_and_messaging/alert';
import { UnsnappedWallAlert } from '../wall';
import { Crosshair, Redo, Undo } from 'lucide-react';
export const UndoRedoRecenterButtonsAndAlerts = ({ drawingRooms, undoIndex, undo, redo, undoEvents, currentRoom, floor, setStagePosition, setStageScale, stageSize, intersectingShapes, hasUnsnappedInternalWalls }) => {
    // Use absolute positioning for these buttons rather than a flex container so that the area between the Undo/Redo on the left and the Re-centre on the right is clickable
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "absolute top-4 left-2 flex gap-2" }, !drawingRooms && React.createElement(React.Fragment, null,
            React.createElement(Button, { size: 'SM', colour: 'LIGHT', className: 'shadow', disabled: undoIndex === 0, onClick: undo },
                React.createElement("div", { className: "flex justify-center gap-1" },
                    React.createElement(Undo, { className: "h-4 w-4" }),
                    React.createElement("div", null, "Undo"))),
            React.createElement(Button, { size: 'SM', colour: 'LIGHT', className: 'shadow', disabled: undoIndex === undoEvents.length, onClick: () => redo(undoEvents[undoIndex]) },
                React.createElement("div", { className: "flex justify-center gap-1" },
                    React.createElement(Redo, { className: "h-4 w-4" }),
                    React.createElement("div", null, "Redo"))))),
        React.createElement("div", { className: "absolute top-4 right-2" },
            React.createElement(Button, { size: 'SM', colour: 'LIGHT', onClick: () => {
                    if (currentRoom) {
                        centreOnRoomScaleOnFloor(currentRoom, floor, setStagePosition, setStageScale, stageSize.width, stageSize.height);
                        return;
                    }
                    if (floor.rooms.length > 0) {
                        centreAndScaleOnFloor(floor, setStagePosition, setStageScale, stageSize.width, stageSize.height);
                    }
                    else {
                        setStageScale(0.05);
                        setStagePosition({ x: stageSize.width / 2, y: stageSize.height / 2 });
                    }
                } },
                React.createElement("div", { className: "flex justify-center gap-1" },
                    React.createElement(Crosshair, { className: "h-4 w-4" }),
                    React.createElement("div", null, "Re-centre")))),
        React.createElement("div", { className: "absolute top-16 left-0 right-0 w-full" },
            React.createElement("div", { className: "space-y-2" },
                intersectingShapes.length > 0 && (React.createElement(Alert, { type: "DANGER" },
                    "You have ",
                    React.createElement("span", { className: "font-bold" }, intersectingShapes.length / 2),
                    " rooms overlapping, you cannot continue until this is resolved. Locate the overlapping rooms (highlighted in red) and drag them apart.")),
                hasUnsnappedInternalWalls && UnsnappedWallAlert))));
};
