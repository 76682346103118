import React, { useState } from 'react';
import { leadWithNewOverride } from '../../../../../code/models/lead';
import { Text } from '../../../../../components/content_display/text';
import { Heading } from '../../../../../components/content_display/heading';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { validateEmail, validateNotNull } from '../../../../../code/validators';
import { Select } from '../../../../../components/inputs_and_selections/select';
import { PROPERTY_TYPES } from '../../../../../code/models/property_type';
import { BUILT_FORM_TYPES } from '../../../../../code/models/built_form';
import { ESTIMATE_AGE_BANDS } from '../../../../../code/models/age_bands';
import { CAVITY_WALL, CAVITY_WALL_INSULATION, FLOOR_INSULATION, LOFT_INSULATION, OUTDOOR_SPACE, SOLID_WALL_INSULATION, TIMBER, WALL_GROUPS, WINDOW_TYPES } from '../../../../../code/models/u_value';
import { FUELS } from '../../../../../code/models/fuel';
import { Section } from '../../components/section';
import { Button } from '../../../../../components/buttons/button';
import { HeatLossElementsTable, HeatLossSummaryCards } from '../../enquiry_manager/sections/heat_loss_estimate';
import { noop } from 'lodash';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
import { parseEnglishEpcInner } from '../../../../../code/models/epc';
import { parseScottishEpcInner } from '../../../../../code/models/epc_scotland';
import { ChevronRight, ChevronLeft, RefreshCw } from 'lucide-react';
const responseRow = (question, enquiryResponse, editComponent) => {
    return {
        question: React.createElement(Text, { size: "SM", bold: true }, question),
        enquiryResponse,
        estimateValue: editComponent
    };
};
export const EnquiryData = ({ lead, setLead, estimate }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23;
    const propertyMappedFromEpc = lead.epcData ? parseEnglishEpcInner(lead.epcData, lead.property) : parseScottishEpcInner(lead.epc_scotland, lead.property);
    const contactColumns = [
        { key: 'question', name: 'Question' },
        { key: 'estimateValue', name: 'Enquiry response' }
    ];
    const propertyColumns = [
        { key: 'question', name: 'Question' },
        { key: 'enquiryResponse', name: 'Enquiry response' },
        { key: 'estimateValue', name: 'Estimate value' }
    ];
    const contactRows = [
        responseRow('Name', ((_a = lead.customer) === null || _a === void 0 ? void 0 : _a.name) || '', React.createElement(Input, { value: ((_b = lead.customer) === null || _b === void 0 ? void 0 : _b.name) || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, name: v } }), validator: validateNotNull })),
        responseRow('Email', ((_c = lead.customer) === null || _c === void 0 ? void 0 : _c.email) || '', React.createElement(Input, { value: ((_d = lead.customer) === null || _d === void 0 ? void 0 : _d.email) || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, email: v } }), validator: validateEmail })),
        responseRow('Phone', ((_e = lead.customer) === null || _e === void 0 ? void 0 : _e.phone) || '', React.createElement(Input, { value: ((_f = lead.customer) === null || _f === void 0 ? void 0 : _f.phone) || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, phone: v } }) })),
        responseRow('Where did you hear about us?', ((_g = lead.customer) === null || _g === void 0 ? void 0 : _g.source) || '', React.createElement(Input, { value: ((_h = lead.customer) === null || _h === void 0 ? void 0 : _h.source) || '', setValue: (v) => setLead({ ...lead, customer: { ...lead.customer, source: v } }) })),
        responseRow('Customer comments', '', React.createElement(TextArea, { readonly: true, value: lead.notes || '', setValue: noop, rows: 2 }))
    ];
    const setOverride = (key, value) => {
        setLead(leadWithNewOverride(lead, estimate.defaults, key, value));
    };
    const propertyRows = [
        responseRow('Property type', lead.property.propertyType || (propertyMappedFromEpc === null || propertyMappedFromEpc === void 0 ? void 0 : propertyMappedFromEpc.propertyType) || '', React.createElement(Select, { options: PROPERTY_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: (_k = (_j = lead.property.houseOverrides) === null || _j === void 0 ? void 0 : _j.propertyType) !== null && _k !== void 0 ? _k : lead.property.propertyType, setSelectedKey: (e) => setOverride('propertyType', e) })),
        responseRow('Built form', lead.property.builtForm, React.createElement(Select, { options: BUILT_FORM_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: (_m = (_l = lead.property.houseOverrides) === null || _l === void 0 ? void 0 : _l.builtForm) !== null && _m !== void 0 ? _m : lead.property.builtForm, setSelectedKey: (e) => setOverride('builtForm', e) })),
        responseRow('Built', ((_o = ESTIMATE_AGE_BANDS.find(x => x.uuid === lead.property.construction_age_band_uuid)) === null || _o === void 0 ? void 0 : _o.name) || '', React.createElement(Select, { options: ESTIMATE_AGE_BANDS.map(x => ({ key: x.uuid, value: x.name })), selectedKey: (_q = (_p = lead.property.houseOverrides) === null || _p === void 0 ? void 0 : _p.construction_age_band_uuid) !== null && _q !== void 0 ? _q : lead.property.construction_age_band_uuid, setSelectedKey: (e) => setOverride('construction_age_band_uuid', e) })),
        responseRow('Wall construction', lead.property.wallGroup || '', React.createElement(Select, { options: WALL_GROUPS.map(x => ({ key: x.name, value: x.name })), selectedKey: (_s = (_r = lead.property.houseOverrides) === null || _r === void 0 ? void 0 : _r.wallGroup) !== null && _s !== void 0 ? _s : lead.property.wallGroup, setSelectedKey: (e) => setOverride('wallGroup', e) })),
        responseRow('Wall insulation', ((_v = (((_u = (_t = lead.property.houseOverrides) === null || _t === void 0 ? void 0 : _t.wallGroup) !== null && _u !== void 0 ? _u : lead.property.wallGroup) === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION)
            .find(x => x.uuid === lead.property.wallType)) === null || _v === void 0 ? void 0 : _v.name) || '', ((_x = (_w = lead.property.houseOverrides) === null || _w === void 0 ? void 0 : _w.wallGroup) !== null && _x !== void 0 ? _x : lead.property.wallGroup) !== TIMBER
            ? React.createElement(Select, { options: (((_z = (_y = lead.property.houseOverrides) === null || _y === void 0 ? void 0 : _y.wallGroup) !== null && _z !== void 0 ? _z : lead.property.wallGroup) === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).map(x => ({
                    key: x.uuid,
                    value: x.name
                })), selectedKey: (_1 = (_0 = lead.property.houseOverrides) === null || _0 === void 0 ? void 0 : _0.wallType) !== null && _1 !== void 0 ? _1 : lead.property.wallType, setSelectedKey: (e) => setOverride('wallType', e) })
            : null),
        responseRow('Windows', lead.property.windowType, React.createElement(Select, { options: WINDOW_TYPES.map(x => ({ key: x.uuid, value: x.name })), selectedKey: (_3 = (_2 = lead.property.houseOverrides) === null || _2 === void 0 ? void 0 : _2.windowType) !== null && _3 !== void 0 ? _3 : lead.property.windowType, setSelectedKey: (e) => setOverride('windowType', e) })),
        responseRow('Floor insulation', lead.property.floorType, React.createElement(Select, { options: FLOOR_INSULATION.map(x => ({ key: x.uuid, value: x.name })), selectedKey: (_5 = (_4 = lead.property.houseOverrides) === null || _4 === void 0 ? void 0 : _4.floorType) !== null && _5 !== void 0 ? _5 : lead.property.floorType, setSelectedKey: (e) => setOverride('floorType', e) })),
        responseRow('Loft insulation', lead.property.loftInsulation || '', React.createElement(Select, { options: LOFT_INSULATION.map(x => ({ key: x.uuid, value: x.name })), selectedKey: (_7 = (_6 = lead.property.houseOverrides) === null || _6 === void 0 ? void 0 : _6.loftInsulation) !== null && _7 !== void 0 ? _7 : lead.property.loftInsulation, setSelectedKey: (e) => setOverride('loftInsulation', e) })),
        responseRow('Bedrooms', lead.property.noBedrooms.toString(), React.createElement(Input, { type: 'number', value: ((_10 = (_9 = (_8 = lead.property.houseOverrides) === null || _8 === void 0 ? void 0 : _8.noBedrooms) !== null && _9 !== void 0 ? _9 : lead.property.noBedrooms) !== null && _10 !== void 0 ? _10 : 0).toString(), setValue: (e) => setOverride('noBedrooms', parseFloat(e)) })),
        responseRow('Bathrooms', lead.property.noBathrooms.toString(), React.createElement(Input, { type: 'number', value: ((_13 = (_12 = (_11 = lead.property.houseOverrides) === null || _11 === void 0 ? void 0 : _11.noBathrooms) !== null && _12 !== void 0 ? _12 : lead.property.noBathrooms) !== null && _13 !== void 0 ? _13 : 0).toString(), setValue: (e) => setOverride('noBathrooms', parseFloat(e)) })),
        responseRow('Fuel type', lead.property.fuelType, React.createElement(Select, { options: FUELS.map(x => ({ key: x.name, value: x.name })), selectedKey: (_15 = (_14 = lead.property.houseOverrides) === null || _14 === void 0 ? void 0 : _14.fuelType) !== null && _15 !== void 0 ? _15 : lead.property.fuelType, setSelectedKey: (e) => setOverride('fuelType', e) })),
        responseRow('Floor area (m²)', ((_16 = lead.property.floorArea) === null || _16 === void 0 ? void 0 : _16.toString()) || '', React.createElement(Input, { type: 'number', value: ((_18 = (_17 = lead.property.houseOverrides) === null || _17 === void 0 ? void 0 : _17.floorArea) !== null && _18 !== void 0 ? _18 : lead.property.floorArea).toString(), setValue: (e) => setOverride('floorArea', parseFloat(e)) })),
        responseRow('Room height (m)', ((_19 = lead.property.roomHeight) === null || _19 === void 0 ? void 0 : _19.toString()) || '', React.createElement(Input, { type: 'number', value: ((_21 = (_20 = lead.property.houseOverrides) === null || _20 === void 0 ? void 0 : _20.roomHeight) !== null && _21 !== void 0 ? _21 : lead.property.roomHeight).toString(), setValue: (e) => setOverride('roomHeight', parseFloat(e)) })),
        responseRow('Outdoor space', lead.property.outdoorSpace || '', React.createElement(Select, { options: OUTDOOR_SPACE.map(x => ({ key: x.key, value: x.label })), selectedKey: (_23 = (_22 = lead.property.houseOverrides) === null || _22 === void 0 ? void 0 : _22.outdoorSpace) !== null && _23 !== void 0 ? _23 : lead.property.outdoorSpace, setSelectedKey: (e) => setOverride('outdoorSpace', e) }))
    ];
    const [heatLossSidebarVisible, setHeatLossSidebarVisible] = useState(true);
    const handleResetEditingData = () => {
        setLead({
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    designTempOverride: undefined,
                    internalTempOverride: undefined,
                    airChangeOverride: undefined,
                    externalWallUValueOverride: undefined,
                    partyWallUValueOverride: undefined,
                    windowsUValueOverride: undefined,
                    floorUValueOverride: undefined,
                    roofUValueOverride: undefined
                }
            }
        });
    };
    return (React.createElement("div", { className: 'flex flex-col lg:flex-row gap-6' },
        React.createElement("div", { className: 'flex-1' },
            React.createElement(Section, { title: "Enquiry data", controls: React.createElement(Button, { size: "SM", iconRight: heatLossSidebarVisible ? ChevronRight : ChevronLeft, onClick: () => setHeatLossSidebarVisible(!heatLossSidebarVisible), className: "hidden lg:block" },
                    heatLossSidebarVisible ? 'Hide' : 'Show',
                    " heat loss sidebar") },
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement(Heading, { size: "lg" }, "Contact details"),
                    React.createElement(TableLite, { rows: contactRows, columns: contactColumns, alignRight: false })),
                React.createElement("div", { className: 'flex flex-col gap-2', "data-cy": "property_info" },
                    React.createElement(Heading, { size: "lg" }, "Property details"),
                    React.createElement(TableLite, { rows: propertyRows, columns: propertyColumns, alignRight: false })),
                React.createElement("div", { className: 'flex flex-col gap-2' },
                    React.createElement(Heading, { size: "lg" }, "Installer notes"),
                    React.createElement(TextArea, { value: lead.installer_notes || '', setValue: (v) => setLead({ ...lead, installer_notes: v }) })))),
        React.createElement("div", { className: `flex ${heatLossSidebarVisible ? '' : 'lg:hidden'}` },
            React.createElement(Section, { className: 'lg:max-w-[500px] w-full', title: "Heat loss estimate", controls: React.createElement(Button, { colour: 'LIGHT', iconLeft: RefreshCw, onClick: handleResetEditingData, size: "SM" }, "Reset to defaults") },
                React.createElement(HeatLossElementsTable, { lead: lead, estimate: estimate, setOverride: setOverride }),
                React.createElement("hr", { className: 'my-2 border-light' }),
                React.createElement(Heading, { size: "md" }, "Estimates"),
                React.createElement(HeatLossSummaryCards, { lead: lead, estimate: estimate, columns: 1 })))));
};
