import { updateCompanySettings } from '../../../../../code/models/company';
import { Section } from '../../../../../components/containers/section';
import React from 'react';
import { SortableList } from '../../../components/sortable_list';
import { Button } from '../../../../../components/buttons/button';
import { Info } from '../../../../../components/buttons/info';
import { RefreshCw } from 'lucide-react';
const SourcesTooltip = () => {
    return React.createElement("div", { className: 'flex flex-col gap-3' },
        React.createElement("p", null, "Drag and drop using the handle on the left of each option to reorder them."),
        React.createElement("p", null, "Use the icons on the right to edit and remove options."),
        React.createElement("p", null, "Customers will also have the option to select \"Other\" and provide their own response."));
};
export const SourcesBlock = ({ company, setCompany }) => {
    const defaultOptions = ['Social media', 'Search engine', 'Word of mouth', 'Trade show'];
    const resetToDefaults = async () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                sources: defaultOptions
            }
        };
        setCompany(newCompany);
        await updateCompanySettings(newCompany);
    };
    return React.createElement(Section, { border: true, title: "Lead source options" },
        React.createElement("div", { className: 'flex flex-col gap-6' },
            React.createElement("div", { className: 'flex gap-1 items-center' },
                React.createElement("div", null, "Choose which options customers can choose from when asked \"Where did you hear about us?\""),
                React.createElement(Info, { infoModalHeader: 'Lead source configuration', infoModalBody: React.createElement(SourcesTooltip, null) })),
            React.createElement(SortableList, { items: company.public_info.sources.map((name, id) => ({ name, id })), setItems: async (items) => {
                    // If the last item is deleted, fill the list with default options
                    if (items.length === 0) {
                        resetToDefaults();
                        return;
                    }
                    const newCompany = {
                        ...company,
                        public_info: {
                            ...company.public_info,
                            sources: items.map((item) => item.name)
                        }
                    };
                    setCompany(newCompany);
                    await updateCompanySettings(newCompany);
                }, extraActionButtons: [
                    React.createElement(Button, { key: 'resetToDefault', iconLeft: RefreshCw, colour: "LIGHT", onClick: async () => await resetToDefaults() }, "Reset to defaults")
                ] })));
};
