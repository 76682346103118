import React from 'react';
import { Section } from '../../components/section';
import { InfoCard } from '../../components/info_card';
import { numberFormat } from '../../../../../code/number_format';
import { leadWithNewOverride } from '../../../../../code/models/lead';
import { Heading } from '../../../../../components/content_display/heading';
import { Text } from '../../../../../components/content_display/text';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { TableLite } from '../../../../../components/content_display/table_lite';
import { Button } from '../../../../../components/buttons/button';
import { Icon } from '../../../../../components/buttons/icon';
import { CAVITY_WALL, CAVITY_WALL_INSULATION, SOLID_WALL_INSULATION } from '../../../../../code/models/u_value';
import { BillSavingsInfo } from '../../../../estimate_page';
import { XCircle, RefreshCw } from 'lucide-react';
export const HeatLossElementsTable = ({ lead, setOverride, estimate }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const columns = [
        { key: 'element', name: 'Element' },
        { key: 'uValue', name: 'U-Value (W/m²K)' },
        { key: 'estimate', name: 'Estimate' }
    ];
    const valueIsOverridden = (key) => {
        return lead.property.houseOverrides && lead.property.houseOverrides[key] !== undefined;
    };
    // TODO: This is kind of an ugly hack - it should be somewhere more general
    // Cf.: https://github.com/SpruceEco/spruce/pull/512#issuecomment-2333580417
    const insulationLabel = (lead) => {
        var _a, _b, _c, _d;
        const wallTypeId = (_b = (_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.wallType) !== null && _b !== void 0 ? _b : lead.property.wallType;
        const wallGroup = (_d = (_c = lead.property.houseOverrides) === null || _c === void 0 ? void 0 : _c.wallGroup) !== null && _d !== void 0 ? _d : lead.property.wallGroup;
        if (!wallTypeId || !wallGroup)
            return '';
        const wallType = (wallGroup === CAVITY_WALL ? CAVITY_WALL_INSULATION : SOLID_WALL_INSULATION).find(x => x.uuid === wallTypeId);
        if (wallGroup === CAVITY_WALL) {
            return (wallType === null || wallType === void 0 ? void 0 : wallType.name) || 'Uninsulated'; // Insultated or Uninsulated
        }
        switch (wallType === null || wallType === void 0 ? void 0 : wallType.uuid) {
            case 'internal':
                return 'Internal insulation';
            case 'external':
                return 'External insulation';
            case 'idk':
                return 'Unknown insulation';
            case 'none':
            default:
                return 'No insulation';
        }
    };
    const rows = [
        {
            element: React.createElement(React.Fragment, null,
                React.createElement(Text, { bold: true, size: "SM" }, "External walls"),
                React.createElement(Text, { size: "SM", className: 'text-light' }, [
                    (_b = (_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.wallGroup) !== null && _b !== void 0 ? _b : lead.property.wallGroup,
                    insulationLabel(lead).toLowerCase()
                ].filter(Boolean).join(', '))),
            uValue: React.createElement(Input, { type: "number", step: 0.1, shrink: true, value: ((_d = (_c = lead.property.houseOverrides) === null || _c === void 0 ? void 0 : _c.externalWallUValueOverride) === null || _d === void 0 ? void 0 : _d.toString()) || estimate.externalWallUValue.toString(), setValue: (e) => setOverride('externalWallUValueOverride', parseFloat(e)), postfix: React.createElement("div", null,
                    valueIsOverridden('externalWallUValueOverride') && React.createElement(Icon, { icon: XCircle, onClick: () => setOverride('externalWallUValueOverride', undefined), colour: 'text-gray-400' }),
                    " ") }),
            estimate: `${numberFormat(0).format(estimate.externalWallWatts)} W`
        },
        {
            element: React.createElement(React.Fragment, null,
                React.createElement(Text, { bold: true, size: "SM" }, "Party walls"),
                React.createElement(Text, { size: "SM", className: 'text-light' }, [
                    (_f = (_e = lead.property.houseOverrides) === null || _e === void 0 ? void 0 : _e.wallGroup) !== null && _f !== void 0 ? _f : lead.property.wallGroup,
                    insulationLabel(lead).toLowerCase()
                ].filter(Boolean).join(', '))),
            uValue: React.createElement(Input, { type: "number", step: 0.1, shrink: true, value: ((_h = (_g = lead.property.houseOverrides) === null || _g === void 0 ? void 0 : _g.partyWallUValueOverride) === null || _h === void 0 ? void 0 : _h.toString()) || estimate.partyWallUValue.toString(), setValue: (e) => setOverride('partyWallUValueOverride', parseFloat(e)), postfix: React.createElement("div", null,
                    valueIsOverridden('partyWallUValueOverride') && React.createElement(Icon, { icon: XCircle, onClick: () => setOverride('partyWallUValueOverride', undefined), colour: 'text-gray-400' }),
                    " ") }),
            estimate: `${numberFormat(0).format(estimate.partyWallWatts)} W`
        },
        {
            element: React.createElement(React.Fragment, null,
                React.createElement(Text, { bold: true, size: "SM" }, "Windows"),
                React.createElement(Text, { size: "SM", className: 'text-light' }, (_k = (_j = lead.property.houseOverrides) === null || _j === void 0 ? void 0 : _j.windowType) !== null && _k !== void 0 ? _k : lead.property.windowType)),
            uValue: React.createElement(Input, { type: "number", step: 0.1, shrink: true, value: ((_m = (_l = lead.property.houseOverrides) === null || _l === void 0 ? void 0 : _l.windowsUValueOverride) === null || _m === void 0 ? void 0 : _m.toString()) || estimate.windowsUValue.toString(), setValue: (e) => setOverride('windowsUValueOverride', parseFloat(e)), postfix: React.createElement("div", null,
                    valueIsOverridden('windowsUValueOverride') && React.createElement(Icon, { icon: XCircle, onClick: () => setOverride('windowsUValueOverride', undefined), colour: 'text-gray-400' }),
                    " ") }),
            estimate: `${numberFormat(0).format(estimate.windowWatts)} W`
        },
        {
            element: React.createElement(React.Fragment, null,
                React.createElement(Text, { bold: true, size: "SM" }, "Floors"),
                React.createElement(Text, { size: "SM", className: 'text-light' }, (_p = (_o = lead.property.houseOverrides) === null || _o === void 0 ? void 0 : _o.floorType) !== null && _p !== void 0 ? _p : lead.property.floorType)),
            uValue: React.createElement(Input, { type: "number", step: 0.1, shrink: true, value: ((_r = (_q = lead.property.houseOverrides) === null || _q === void 0 ? void 0 : _q.floorUValueOverride) === null || _r === void 0 ? void 0 : _r.toString()) || estimate.floorUValue.toString(), setValue: (e) => setOverride('floorUValueOverride', parseFloat(e)), postfix: React.createElement("div", null,
                    valueIsOverridden('floorUValueOverride') && React.createElement(Icon, { icon: XCircle, onClick: () => setOverride('floorUValueOverride', undefined), colour: 'text-gray-400' }),
                    " ") }),
            estimate: `${numberFormat(0).format(estimate.floorWatts)} W`
        },
        {
            element: React.createElement(React.Fragment, null,
                React.createElement(Text, { bold: true, size: "SM" }, "Roof"),
                React.createElement(Text, { size: "SM", className: 'text-light' }, (_t = (_s = lead.property.houseOverrides) === null || _s === void 0 ? void 0 : _s.loftInsulation) !== null && _t !== void 0 ? _t : lead.property.loftInsulation)),
            uValue: React.createElement(Input, { type: "number", step: 0.1, shrink: true, value: ((_v = (_u = lead.property.houseOverrides) === null || _u === void 0 ? void 0 : _u.roofUValueOverride) === null || _v === void 0 ? void 0 : _v.toString()) || estimate.roofUValue.toString(), setValue: (e) => setOverride('roofUValueOverride', parseFloat(e)), postfix: React.createElement("div", null,
                    valueIsOverridden('roofUValueOverride') && React.createElement(Icon, { icon: XCircle, onClick: () => setOverride('roofUValueOverride', undefined), colour: 'text-gray-400' }),
                    " ") }),
            estimate: `${numberFormat(0).format(estimate.roofWatts)} W`
        },
        {
            element: React.createElement(Text, { bold: true, size: "SM" }, "Ventilation"),
            uValue: '',
            estimate: `${numberFormat(0).format(estimate.ventilationWatts)} W`
        },
        {
            element: React.createElement(Text, { bold: true }, "Total"),
            uValue: '',
            estimate: React.createElement(Text, { bold: true },
                (estimate.totalWatts / 1000).toFixed(2),
                " kW")
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Heading, { size: "md" }, "Elements"),
        React.createElement(TableLite, { columns: columns, rows: rows, alignRight: false })));
};
export const HeatLossSummaryCards = ({ lead, estimate, columns }) => {
    var _a, _b;
    return (React.createElement("div", { className: `grid grid-cols-1 ${columns === 4 && 'md:grid-cols-4'} gap-5` },
        React.createElement(InfoCard, { title: "Total heat loss", value: React.createElement(React.Fragment, null,
                (estimate.totalWatts / 1000).toFixed(2),
                " kW") }),
        React.createElement(InfoCard, { title: "Heat loss per m\u00B2", value: React.createElement(React.Fragment, null,
                numberFormat(0).format(estimate.totalWatts / lead.property.floorArea),
                " W/m\u00B2") }),
        React.createElement(InfoCard, { title: React.createElement(React.Fragment, null,
                "CO",
                React.createElement("sub", null, "2"),
                " savings/year"), value: React.createElement(React.Fragment, null,
                numberFormat(0).format(estimate.CO2SavedKg),
                " kg/year") }),
        React.createElement(InfoCard, { title: "Bill savings/year", value: React.createElement(React.Fragment, null,
                "\u00A3",
                numberFormat(0).format(estimate.annualBillBaselineGBP - estimate.annualBillHeatPumpTariffGBP),
                "/year"), info: React.createElement(BillSavingsInfo, { billSavingsInfoContent: {
                    annual_bill_hp_tariff_gbp: estimate.annualBillHeatPumpTariffGBP,
                    annual_bill_baseline_gbp: estimate.annualBillBaselineGBP,
                    annual_kwh_heat_required: estimate.annualKwhHeat,
                    average_efficiency_baseline: estimate.efficiencyBaseline,
                    average_efficiency_hp: estimate.averageEfficiencyHeatPump,
                    fuel_name_baseline: estimate.fuelNameBaseline,
                    tariff_p_per_kwh_baseline: estimate.tariffPPerKwhBaseline,
                    tariff_p_per_kwh_electricity_hp_tariff: estimate.tariffPPerKwhElectricityHpTariff,
                    electric_hp_tariff_based_on: estimate.electricHpTariffBasedOn,
                    annual_bill_hp_price_cap_gbp: estimate.annualBillHeatPumpPriceCapGBP,
                    tariff_p_per_kwh_electricity_price_cap: estimate.tariffPPerKwhElectricityPriceCap,
                    price_cap_description: estimate.priceCapDescription,
                    heat_pump_name: (_b = (_a = estimate.heatPump) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '',
                    flow_temperature_c: estimate.flowTempC
                }, homeownerFacing: false }) })));
};
export const HeatLossEstimate = ({ lead, setLead, estimate }) => {
    const handleResetEditingData = () => {
        setLead({
            ...lead,
            property: {
                ...lead.property,
                houseOverrides: {
                    ...lead.property.houseOverrides,
                    designTempOverride: undefined,
                    internalTempOverride: undefined,
                    airChangeOverride: undefined,
                    externalWallUValueOverride: undefined,
                    partyWallUValueOverride: undefined,
                    windowsUValueOverride: undefined,
                    floorUValueOverride: undefined,
                    roofUValueOverride: undefined
                }
            }
        });
    };
    const setOverride = (key, value) => {
        setLead(leadWithNewOverride(lead, estimate.defaults, key, value));
    };
    const valueIsOverridden = (key) => {
        return lead.property.houseOverrides && lead.property.houseOverrides[key] !== undefined;
    };
    return (React.createElement(Section, { title: "Heat loss estimate", controls: React.createElement(React.Fragment, null,
            React.createElement(Button, { colour: 'LIGHT', iconLeft: RefreshCw, onClick: handleResetEditingData, size: "SM" }, "Reset to defaults")) },
        React.createElement(HeatLossSummaryCards, { lead: lead, estimate: estimate, columns: 4 }),
        React.createElement(Heading, { size: "md" }, "Variables"),
        React.createElement("div", { className: 'grid grid-cols-1 md:grid-cols-3 gap-5' },
            React.createElement("div", null,
                React.createElement(Input, { type: "number", step: 0.1, label: 'Design temp. (\u00B0C)', value: estimate.designTempC.toString(), setValue: (e) => setOverride('designTempOverride', parseFloat(e)), postfix: React.createElement("div", null,
                        "\u00B0C ",
                        valueIsOverridden('designTempOverride') && React.createElement(Icon, { icon: XCircle, onClick: () => setOverride('designTempOverride', undefined), colour: 'text-gray-400' }),
                        " ") })),
            React.createElement("div", null,
                React.createElement(Input, { type: "number", step: 0.1, label: 'Internal temp. (\u00B0C)', value: estimate.internalTempC.toString(), setValue: (e) => setOverride('internalTempOverride', parseFloat(e)), postfix: React.createElement("div", null,
                        "\u00B0C ",
                        valueIsOverridden('internalTempOverride') && React.createElement(Icon, { icon: XCircle, onClick: () => setOverride('internalTempOverride', undefined), colour: 'text-gray-400' }),
                        " ") })),
            React.createElement("div", null,
                React.createElement(Input, { type: "number", step: 0.1, label: "ACH", value: estimate.airChanges.toString(), setValue: (e) => setOverride('airChangeOverride', parseFloat(e)), postfix: React.createElement("div", null,
                        " ",
                        valueIsOverridden('airChangeOverride') && React.createElement(Icon, { icon: XCircle, onClick: () => setOverride('airChangeOverride', undefined), colour: 'text-gray-400' }),
                        " ") }))),
        React.createElement(HeatLossElementsTable, { lead: lead, setOverride: setOverride, estimate: estimate })));
};
