import React from 'react';
import { SurveyElectricsContext } from '../electrics';
import { ClickableCard } from '../../../../components/content_display/card';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const ElectricsCableRoutingBlock = () => {
    const electricsContext = React.useContext(SurveyElectricsContext);
    const { survey, setSurvey } = electricsContext;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Cable routing"),
        React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Distance from main board to heat pump location"),
                React.createElement(Input, { type: 'number', value: survey.electrics_distance_from_board_to_heat_pump_m.toString(), setValue: (e) => setSurvey({ ...survey, electrics_distance_from_board_to_heat_pump_m: Number(e) }), postfix: 'm' })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Distance from heat pump location to cylinder"),
                React.createElement(Input, { type: 'number', value: survey.electrics_distance_from_cylinder_to_heat_pump_m.toString(), setValue: (e) => setSurvey({ ...survey, electrics_distance_from_cylinder_to_heat_pump_m: Number(e) }), postfix: 'm' })),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement("div", { className: "gap-3 flex items-center justify-between" },
                        React.createElement("div", { className: "text-gray-900 font-bold" }, "Cylinder immersion feed required"),
                        React.createElement(Toggle, { value: survey.electrics_cylinder_immersion_required, setValue: () => setSurvey({
                                ...survey,
                                electrics_cylinder_immersion_required: !survey.electrics_cylinder_immersion_required
                            }) })),
                    survey.electrics_cylinder_immersion_required && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'flex flex-col gap-2' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Distance from main board to cylinder"),
                            React.createElement(Input, { type: 'number', value: survey.electrics_distance_from_board_to_cylinder_m.toString(), setValue: (e) => setSurvey({ ...survey, electrics_distance_from_board_to_cylinder_m: Number(e) }), postfix: 'm' }))))),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Notes"),
                React.createElement(TextArea, { value: survey.electrics_cable_notes, setValue: (e) => setSurvey({ ...survey, electrics_cable_notes: e }) }))));
};
