export const formatDate = (date, onlyYear) => {
    if (!date)
        return '';
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime()))
        return '';
    // If onlyYear is true, return the year only
    if (onlyYear) {
        return parsedDate.getFullYear().toString();
    }
    return parsedDate.toLocaleDateString('en-GB');
};
// Return a date in the format "Month Year", e.g. "January 2021"
export const formatDateMonthYear = (date) => {
    if (!date)
        return '';
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime()))
        return '';
    const options = { year: 'numeric', month: 'long' };
    return parsedDate.toLocaleDateString('en-US', options);
};
// Formats a date differently depending on how long ago it was
// If the date is in the last day, returns 'Today HH:MM'
// If the date is yesterday, returns 'Yesterday HH:MM'
// If the date is in the last week, returns 'Last [day] HH:MM'
// Otherwise, returns the full date (with no time)
export const contextSensitiveFormatDate = (date) => {
    // If the input isn't a date object, try to parse it
    if (!(date instanceof Date)) {
        date = new Date(date);
        // Invalid dates are returned as 'Invalid Date' which is NaN
        if (isNaN(date.getTime())) {
            return '';
        }
    }
    const now = new Date();
    const startOfToday = new Date(now);
    startOfToday.setHours(0, 0, 0, 0);
    if (date >= startOfToday) {
        return `Today ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`;
    }
    const startOfYesterday = new Date(startOfToday);
    startOfYesterday.setDate(startOfToday.getDate() - 1);
    if (date >= startOfYesterday) {
        return `Yesterday ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`;
    }
    // 'This week' is defined as any time from Monday to today
    const startOfThisWeek = new Date(startOfToday);
    startOfThisWeek.setDate(startOfToday.getDate() - startOfToday.getDay());
    if (date >= startOfThisWeek) {
        return date.toLocaleDateString('en-GB', { weekday: 'long', hour: '2-digit', minute: '2-digit' });
    }
    // 'Last week' is defined as any time from the previous Monday to the previous Sunday, inclusive
    const startOfLastWeek = new Date(startOfToday);
    startOfLastWeek.setDate(startOfToday.getDate() - startOfToday.getDay() - 7);
    if (date >= startOfLastWeek) {
        return `Last ${date.toLocaleDateString('en-GB', { weekday: 'long', hour: '2-digit', minute: '2-digit' })}`;
    }
    return date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' });
};
