import React from 'react';
import { WrappedIcon } from '../buttons/wrapped_icon';
export const EmptyState = ({ icon, primaryText, secondaryText, primaryButton, secondaryButton, alert }) => {
    return React.createElement("div", { className: "px-6 py-20 bg-gray-50 rounded-lg border-gray-400 flex-col justify-center items-center gap-4 flex h-full" },
        React.createElement("div", { className: "flex-col justify-center items-center gap-2 flex" },
            React.createElement(WrappedIcon, { className: 'w-12 h-12', icon: icon }),
            React.createElement("div", { className: 'flex flex-col gap-1 justify-center items-center' },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, primaryText),
                React.createElement("div", { className: "self-stretch text-center text-gray-500 text-sm font-medium leading-tight" }, secondaryText))),
        React.createElement("div", { className: 'flex gap-4' },
            primaryButton,
            secondaryButton),
        React.createElement("div", { className: 'mt-10' }, alert));
};
