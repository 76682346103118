import React from 'react';
import { getTotalHeatLossWatts } from '../../../code/models/heat_loss';
import { ClickableCard } from '../../../components/content_display/card';
import { TableLite } from '../../../components/content_display/table_lite';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from '../../../code/models/range_heat_pump';
import { HLRContext } from './heatloss_report';
import { numberFormat } from '../../../code/number_format';
import { getHeatPumpCapacityRowsAndColumns, getHeatPumpScopRowsAndColumns } from '../../admin/costs_and_inventory/components/heat_pump_modal';
import { HLRListBlock } from './partials/hlr_list';
import _ from 'lodash';
import { HLRHeaderBlock } from './partials/hlr_header';
import { PrintLayoutPageBreak } from '../../components/print_layout';
export const HLRHeatPump = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const hlrContext = React.useContext(HLRContext);
    const totalHeatLossKW = getTotalHeatLossWatts(hlrContext.survey, hlrContext.designTempC, hlrContext.groundTempC) / 1000;
    const capacity = getHeatPumpCapacityAtOutsideTempAndFlowTemp(hlrContext.currentHeatPump.range_heat_pump, hlrContext.designTempC, hlrContext.design.flow_temp).capacityKw;
    const capacityPercentage = Math.round((capacity / totalHeatLossKW) * 100);
    const scop = getHeatPumpScopAtFlowTemp((_a = hlrContext.currentHeatPump) === null || _a === void 0 ? void 0 : _a.range_heat_pump, hlrContext.design.flow_temp);
    const { scopRows, scopColumns } = getHeatPumpScopRowsAndColumns((_b = hlrContext.currentHeatPump) === null || _b === void 0 ? void 0 : _b.range_heat_pump);
    const { capacityRows, capacityColumns } = getHeatPumpCapacityRowsAndColumns((_c = hlrContext.currentHeatPump) === null || _c === void 0 ? void 0 : _c.range_heat_pump);
    const locationPhotos = hlrContext.files.filter(x => hlrContext.survey.location_images.some(y => y.image_uuid === x.uuid));
    return React.createElement("div", { className: '' },
        React.createElement(HLRHeaderBlock, { title: 'System design', description: 'This section presents our proposed system design for your home. The design is based on the detailed room by room calculations in the previous section.' }),
        React.createElement("div", { className: 'flex flex-col gap-6 pb-9 pt-14' },
            React.createElement("div", { className: 'text-3xl font-bold text-gray-900' }, "Proposed heat pump"),
            React.createElement("div", null,
                "Based on your home's design heat loss of ",
                React.createElement("span", { className: 'font-bold text-gray-900' },
                    numberFormat(1).format(totalHeatLossKW),
                    " kW"),
                " and on all the attributes of your home that we recorded during our site survey, we would suggest the following heat pump for your home."),
            React.createElement(ClickableCard, { variant: 'WHITE' }, hlrContext.currentHeatPump ? React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, (_d = hlrContext.currentHeatPump) === null || _d === void 0 ? void 0 : _d.name),
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                        React.createElement("div", { className: "text-gray-600 text-xs font-semibold" },
                            "Capacity at ",
                            hlrContext.design.flow_temp,
                            " \u00B0C (",
                            hlrContext.designTempC,
                            " \u00B0C)"),
                        React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" },
                            capacity,
                            " kW")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                        React.createElement("div", { className: "text-gray-600 text-xs font-semibold" },
                            "SCOP at ",
                            hlrContext.design.flow_temp,
                            " \u00B0C"),
                        React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" }, scop)),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                        React.createElement("div", { className: "text-gray-600 text-xs font-semibold" }, "Flow temperature"),
                        React.createElement("div", { className: 'text-right text-xs font-semibold' },
                            hlrContext.design.flow_temp,
                            " \u00B0C")))) : React.createElement("div", null, "No heat pump")),
            React.createElement("div", null,
                "This heat pump covers ",
                React.createElement("span", { className: 'font-bold text-gray-900' },
                    capacityPercentage,
                    " %"),
                " of the heating requirement at and above the design temperature of ",
                React.createElement("span", { className: 'font-bold text-gray-900' },
                    hlrContext.designTempC,
                    " \u00B0C"),
                ". The heat pump will be run on weather compensation so the efficiency will be higher when the weather is milder."),
            hlrContext.currentHeatPump.range_heat_pump && React.createElement("div", { className: 'grid grid-cols-3 gap-3 text-sm' },
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "MCS certificate number"),
                    React.createElement("div", { className: 'text-gray-600' }, (_f = (_e = hlrContext.currentHeatPump.range_heat_pump) === null || _e === void 0 ? void 0 : _e.mcs_certificate_numbers) === null || _f === void 0 ? void 0 : _f.join(', '))),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Refrigerant"),
                    React.createElement("div", { className: 'text-gray-600' }, (_g = hlrContext.currentHeatPump.range_heat_pump) === null || _g === void 0 ? void 0 : _g.refrigerant)),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Serial number"),
                    React.createElement("div", { className: 'text-gray-600' }, (_h = hlrContext.currentHeatPump.range_heat_pump) === null || _h === void 0 ? void 0 : _h.model_number)),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Sound power"),
                    React.createElement("div", { className: 'text-gray-600' }, (_j = hlrContext.currentHeatPump.range_heat_pump) === null || _j === void 0 ? void 0 :
                        _j.sound_power_max_dba,
                        " dB(A)")),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Width"),
                    React.createElement("div", { className: 'text-gray-600' }, (_k = hlrContext.currentHeatPump.range_heat_pump) === null || _k === void 0 ? void 0 :
                        _k.width_mm,
                        " mm")),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Height"),
                    React.createElement("div", { className: 'text-gray-600' }, (_l = hlrContext.currentHeatPump.range_heat_pump) === null || _l === void 0 ? void 0 :
                        _l.height_mm,
                        " mm")),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Depth"),
                    React.createElement("div", { className: 'text-gray-600' }, (_m = hlrContext.currentHeatPump.range_heat_pump) === null || _m === void 0 ? void 0 :
                        _m.depth_mm,
                        " mm")),
                React.createElement("div", { className: 'flex flex-col gap-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Weight"),
                    React.createElement("div", { className: 'text-gray-600' }, (_o = hlrContext.currentHeatPump.range_heat_pump) === null || _o === void 0 ? void 0 :
                        _o.weight_kg,
                        " kg"))),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement(PrintLayoutPageBreak, null),
                React.createElement("div", { className: 'text-gray-900 text-lg font-bold' }, "Capacity (kW)"),
                React.createElement(TableLite, { rows: capacityRows, columns: capacityColumns })),
            scopRows.length > 0 && React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900 text-lg' }, "Seasonal Coefficient of Performance (SCOP)"),
                React.createElement("div", null, "SCOP is the average coefficient of performance over the heating season, accounting for the variation in outdoor temperature. Manufacturers have to test their equipment in standard conditions and report their SCOP performance data to be eligible for certification."),
                React.createElement(TableLite, { rows: scopRows, columns: scopColumns })),
            (hlrContext.survey.location_description || hlrContext.survey.mounting_location || hlrContext.survey.condensate_drain || hlrContext.survey.condensate_drain || locationPhotos.length > 0) && React.createElement("div", { className: 'text-gray-900 text-3xl font-bold' }, "Location"),
            React.createElement("div", { className: '' },
                hlrContext.survey.location_description && React.createElement("div", null, hlrContext.survey.location_description),
                (hlrContext.survey.mounting_location || hlrContext.survey.condensate_drain || hlrContext.survey.condensate_drain) && React.createElement(HLRListBlock, { items: _.compact([
                        hlrContext.survey.mounting_location && React.createElement(React.Fragment, null,
                            "Mounting location: ",
                            hlrContext.survey.mounting_location),
                        hlrContext.survey.mounting_location === 'Ground' ? React.createElement(React.Fragment, null,
                            "Base to be built by: ",
                            hlrContext.survey.condensate_drain) : undefined,
                        hlrContext.survey.condensate_drain && React.createElement(React.Fragment, null,
                            "Condensate drain: ",
                            hlrContext.survey.condensate_drain)
                    ]) })),
            locationPhotos.length > 0 && React.createElement("div", { className: 'h-44 flex flex-wrap gap-6' }, locationPhotos.map(x => React.createElement("img", { alt: '', key: x.uuid, className: 'h-44 rounded-md', src: x.file_base64 || `${process.env.S3_BUCKET_URL}/${x.file_url}` })))));
};
