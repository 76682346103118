import React from 'react';
import { getCylinderReheatCalculationRow, getDailyHotWaterVolumeL, getNumberOfOccupants } from '../../../code/models/hot_water_cylinder';
import { ClickableCard } from '../../../components/content_display/card';
import { HLRContext } from './heatloss_report';
import { numberFormat } from '../../../code/number_format';
export const HLRHotWaterDemand = () => {
    var _a;
    const hlrContext = React.useContext(HLRContext);
    const numberOfOccupants = getNumberOfOccupants(hlrContext.survey);
    const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, hlrContext.survey.volume_per_person_l);
    const hotWaterConsumptionAnnual = hlrContext.hotWaterRowNormal.electricityKWhPerYear + hlrContext.hotWaterRowLegionella.electricityKWhPerYear;
    const cylinderReheatRow = getCylinderReheatCalculationRow(hlrContext.currentHotWaterCylinder, hlrContext.design.hot_water_storage_temperature_c, (_a = hlrContext.currentHeatPump) === null || _a === void 0 ? void 0 : _a.range_heat_pump, hlrContext.designTempC);
    return React.createElement("div", { className: 'flex-col gap-9 flex' },
        React.createElement("div", { className: 'flex-col gap-6 flex' },
            React.createElement("div", { className: 'text-gray-900 font-bold' }, "Hot water demand"),
            React.createElement("div", null, "The calculation below shows how much electricity we expect your heat pump to use for providing hot water, based on our assumptions about how much hot water people typically use in a year."),
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement("div", { className: "flex justify-between flew-row" },
                    React.createElement("div", { className: "text-gray-900 font-bold" }, "Total electricity per year for hot water"),
                    React.createElement("div", { className: "text-gray-900 font-bold" },
                        numberFormat(0).format(hotWaterConsumptionAnnual),
                        " kWh")),
                React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4' },
                    React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                        React.createElement("div", { className: 'flex flex-col' },
                            React.createElement("div", { className: "py-3 justify-between text-sm font-bold text-gray-900 flex" },
                                React.createElement("div", { className: '' }, "Normal operation"),
                                React.createElement("div", null,
                                    numberFormat(0).format(hlrContext.hotWaterRowNormal.electricityKWhPerYear),
                                    " kWh")),
                            React.createElement("div", { className: "py-3 justify-between flex text-xs" },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Electricity per day"),
                                React.createElement("div", null,
                                    numberFormat(2).format(hlrContext.hotWaterRowNormal.electricityKwhPerCycle),
                                    " kWh")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex text-xs" },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Heat energy per day"),
                                React.createElement("div", null,
                                    numberFormat(2).format(hlrContext.hotWaterRowNormal.heatEnergyKwhPerCycle),
                                    " kWh")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs" },
                                React.createElement("div", null, "Volume per day"),
                                React.createElement("div", null,
                                    dailyHotWaterVolumeL,
                                    " litres/day")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs" },
                                React.createElement("div", null, "Assumed mains water temp"),
                                React.createElement("div", null,
                                    hlrContext.hotWaterRowNormal.coldWaterTempC,
                                    " \u00B0C")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs" },
                                React.createElement("div", null, "Storage temp"),
                                React.createElement("div", null,
                                    hlrContext.hotWaterRowNormal.hotWaterTempC,
                                    " \u00B0C")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs" },
                                React.createElement("div", null, "Distribution efficiency"),
                                React.createElement("div", null,
                                    hlrContext.hotWaterRowNormal.distributionEfficiency * 100,
                                    " %")),
                            React.createElement("div", { className: "py-3 justify-between flex text-xs" },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' },
                                    "Heat pump sCOP (",
                                    hlrContext.cylinderReheatRow.flowTempC,
                                    " \u00B0C flow)"),
                                React.createElement("div", null,
                                    hlrContext.hotWaterRowNormal.heatingEfficiency * 100,
                                    " %")))),
                    React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                        React.createElement("div", { className: 'flex flex-col' },
                            React.createElement("div", { className: "py-3 justify-between text-sm font-bold text-gray-900 flex" },
                                React.createElement("div", { className: '' }, "Legionella cycles"),
                                React.createElement("div", null,
                                    numberFormat(0).format(hlrContext.hotWaterRowLegionella.electricityKWhPerYear),
                                    " kWh")),
                            React.createElement("div", { className: "py-3 justify-between flex text-xs" },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Electricity per cycle"),
                                React.createElement("div", null,
                                    numberFormat(2).format(hlrContext.hotWaterRowLegionella.electricityKwhPerCycle),
                                    " kWh")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex text-xs" },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Heat energy per cycle"),
                                React.createElement("div", null,
                                    numberFormat(2).format(hlrContext.hotWaterRowLegionella.heatEnergyKwhPerCycle),
                                    " kWh")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs" },
                                React.createElement("div", null, "Volume per cycle"),
                                React.createElement("div", null,
                                    Math.round(cylinderReheatRow.cylinderVolumeL),
                                    " litres")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs" },
                                React.createElement("div", null, "Tank starting temperature"),
                                React.createElement("div", null,
                                    hlrContext.hotWaterRowLegionella.coldWaterTempC,
                                    " \u00B0C")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs" },
                                React.createElement("div", null, "Legionella set point"),
                                React.createElement("div", null,
                                    hlrContext.hotWaterRowLegionella.hotWaterTempC,
                                    " \u00B0C")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex indent-6 text-xs" },
                                React.createElement("div", null, "Distribution efficiency"),
                                React.createElement("div", null,
                                    hlrContext.hotWaterRowLegionella.distributionEfficiency * 100,
                                    " %")),
                            React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex text-xs" },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Legionella cycle efficiency"),
                                React.createElement("div", null,
                                    hlrContext.hotWaterRowLegionella.heatingEfficiency * 100,
                                    " %")),
                            React.createElement("div", { className: "py-3 justify-between flex text-xs" },
                                React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Cycles per year"),
                                React.createElement("div", null, (hlrContext.hotWaterRowLegionella.cyclesPerYear).toFixed(0)))))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: "flex justify-between flew-row" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Reheat time"),
                React.createElement("div", { className: "text-gray-900 font-bold" }, hlrContext.cylinderReheatRow.reheatTimeHoursAndMinutes)),
            React.createElement(ClickableCard, { variant: "GREY", className: 'bg-gray-100 rounded-md p-5' },
                React.createElement("div", { className: 'flex flex-col' },
                    React.createElement("div", { className: "" },
                        React.createElement("span", { className: "text-xs font-semibold" }, "Once drained, it will take your cylinder"),
                        React.createElement("span", { className: "text-gray-900 text-xs font-semibold" }, " "),
                        React.createElement("span", { className: "text-gray-900 text-xs font-bold" }, hlrContext.cylinderReheatRow.reheatTimeHoursAndMinutes),
                        React.createElement("span", { className: "text-xs font-semibold" }, " to fully reheat based on the following inputs:")),
                    React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex text-xs" },
                        React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Cylinder volume"),
                        React.createElement("div", null,
                            Math.round(hlrContext.cylinderReheatRow.cylinderVolumeL),
                            " litres")),
                    React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex text-xs" },
                        React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Mains water temp"),
                        React.createElement("div", null,
                            hlrContext.cylinderReheatRow.coldWaterTempC,
                            " \u00B0C")),
                    React.createElement("div", { className: "py-3 border-b border-gray-200 border-dashed justify-between flex text-xs" },
                        React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Hot water storage temp"),
                        React.createElement("div", null,
                            hlrContext.cylinderReheatRow.hotWaterStorageTempC,
                            " \u00B0C")),
                    React.createElement("div", { className: "py-3 justify-between flex text-xs" },
                        React.createElement("div", { className: 'text-gray-900 font-semibold' }, "Heating power"),
                        React.createElement("div", null,
                            hlrContext.cylinderReheatRow.heatingPowerKw,
                            " kW"))))));
};
