import React, { useContext, useEffect, useState } from 'react';
import { getAddressIncludingPostcode, patchLead, getLeadsList } from '../../code/models/lead';
import { chain } from 'lodash';
import { Badge, leadStatusToBadge } from '../../components/indicators_and_messaging/badge';
import { AdminContext } from './admin_layout';
import { contextSensitiveFormatDate } from '../../code/format_date';
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE, USER_ROLE_SURVEYOR } from '../../code/models/user';
import { RequireRole } from '../../require_role';
import { Input } from '../../components/inputs_and_selections/input';
import { Icon } from '../../components/buttons/icon';
import { Button } from '../../components/buttons/button';
import { DuplicateLeadModal } from './components/duplicate_lead_modal';
import TabbedDashboardLayout from '../../layouts/tabbed_dashboard_layout';
import { DropdownMenu } from '../../components/buttons/dropdown_menu';
import { ChevronLeft, ChevronRight, Copy, Search, X } from 'lucide-react';
import { ClickableCard } from '../../components/content_display/card';
const LEAD_STATUSES = [
    { display_name: 'All jobs', code: 'AllJobs', urlKey: 'all' }, // Not real status
    { display_name: 'Estimate required', code: 'New', urlKey: 'estimated_required' },
    { display_name: 'Estimate sent', code: 'Quoted', urlKey: 'estimated' },
    { display_name: 'Accepted', code: 'Converted', urlKey: 'accepted' },
    { display_name: 'Survey booked', code: 'SurveyBooked', urlKey: 'survey_booked' },
    { display_name: 'Survey in progress', code: 'SurveyInProgress', urlKey: 'survey_in_progress' },
    { display_name: 'Survey completed', code: 'SurveyCompleted', urlKey: 'survey_completed' },
    { display_name: 'Proposal sent', code: 'ProposalSent', urlKey: 'proposal_sent' },
    { display_name: 'Proposal accepted', code: 'ProposalAccepted', urlKey: 'proposal_accepted' },
    { display_name: 'Rejected', code: 'Rejected', urlKey: 'rejected' },
    { display_name: 'Enquiry declined', code: 'Declined', urlKey: 'declined' },
    { display_name: 'Archived', code: 'Archived', urlKey: 'archived' }
];
export const LeadsListPage = (props) => {
    const adminContext = useContext(AdminContext);
    const { filter, basePath, navigateTo, companyPublicInfo } = props;
    const [leads, setLeads] = useState([]);
    useEffect(() => {
        const main = async () => {
            const allLeads = await getLeadsList(companyPublicInfo.uuid);
            if (allLeads && allLeads.length > 0)
                setLeads(allLeads);
        };
        main();
    }, [companyPublicInfo]);
    return (React.createElement(TabbedDashboardLayout, { navigateTo: navigateTo, basePath: basePath + '/enquiries', title: 'Jobs', selectedTabId: filter, isOffline: adminContext.isOffline, tabs: LEAD_STATUSES.map(x => ({ id: x.urlKey, label: x.display_name, content: React.createElement(LeadsListInner, { leads: leads, setLeads: setLeads, ...props }) })) }));
};
const LeadsListInner = ({ filter, companyPublicInfo, basePath, navigateTo, leads, setLeads }) => {
    var _a, _b;
    // duplicate Lead state
    const [isDuplicateLeadModalOpen, setIsDuplicateLeadModalOpen] = useState(false);
    const [leadToDuplicate, setLeadToDuplicate] = useState(undefined);
    const toggleDuplicateLeadModalVisibility = (visible) => {
        if (!visible)
            setLeadToDuplicate(undefined);
        setIsDuplicateLeadModalOpen(visible);
    };
    const [filterString, setFilterString] = useState(undefined);
    const [paginationIndex, setPaginationIndex] = useState(1);
    const pageSize = 20;
    const filterLogic = (lead, filterString) => {
        var _a, _b, _c, _d;
        const filterStringLower = filterString.toLowerCase();
        if ((_b = (_a = lead.customer) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(filterStringLower))
            return true;
        if (getAddressIncludingPostcode(lead).toLowerCase().includes(filterStringLower))
            return true;
        if ((_d = (_c = lead.customer) === null || _c === void 0 ? void 0 : _c.email) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes(filterStringLower))
            return true;
        return false;
    };
    const filterStatus = (_b = (_a = LEAD_STATUSES.find(x => x.urlKey === filter)) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : 'AllJobs';
    // filter leads
    const filteredLeads = chain(leads)
        .filter(x => (filterStatus === 'AllJobs' ? x.status !== 'Archived' : x.status === filterStatus) &&
        (!filterString || filterLogic(x, filterString)))
        .orderBy(x => new Date(x.createdAt), 'desc')
        .value();
    const maxPageSize = Math.ceil(filteredLeads.length / pageSize);
    const paginatedLeads = chain(filteredLeads)
        .drop((paginationIndex - 1) * pageSize)
        .take(pageSize)
        .value();
    const FilterAndPagination = React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 gap-4 items-center justify-between w-full mb-6' },
        React.createElement(Input, { className: 'w-full', prefix: React.createElement(Icon, { icon: Search }), postfix: filterString && React.createElement(Icon, { icon: X, onClick: () => setFilterString(undefined) }), placeholder: `Search ${filteredLeads.length} jobs`, value: filterString !== null && filterString !== void 0 ? filterString : '', setValue: (e) => {
                setFilterString(e);
                setPaginationIndex(1);
            } }),
        maxPageSize > 1 && React.createElement("div", { className: "md:justify-end items-center gap-7 flex w-full justify-between" },
            React.createElement(Button, { onClick: () => setPaginationIndex(prev => prev - 1), disabled: paginationIndex === 1, iconLeft: ChevronLeft, colour: 'LIGHT' }),
            React.createElement("div", { className: 'text-default text-sm' },
                "Page ",
                React.createElement("span", { className: 'font-bold' }, paginationIndex),
                " of ",
                React.createElement("span", { className: 'font-bold' }, maxPageSize)),
            React.createElement(Button, { onClick: () => setPaginationIndex(prev => prev + 1), disabled: paginationIndex === maxPageSize, iconLeft: ChevronRight, colour: 'LIGHT' })));
    const setLead = async (lead) => {
        setLeads(leads.map(x => x.uuid === lead.uuid ? lead : x));
        await patchLead(lead.uuid, lead, companyPublicInfo.uuid);
    };
    return React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR] },
        React.createElement(React.Fragment, null,
            FilterAndPagination,
            React.createElement("div", { className: 'flex flex-col gap-2' }, paginatedLeads.map(x => {
                var _a, _b, _c;
                return React.createElement(ClickableCard, { key: x.uuid, variant: 'WHITE' },
                    React.createElement("div", { className: 'grid grid-cols-1 xl:grid-cols-2 py-2 gap-4' },
                        React.createElement("div", { className: 'flex flex-grow' },
                            React.createElement("div", { className: "flex-col flex-grow gap-2 xl:gap-1 flex cursor-pointer" },
                                React.createElement("div", { className: 'flex gap-2 justify-between' },
                                    React.createElement("div", { "data-cy": "job-row", className: "text-bold font-bold flex-grow", onClick: () => navigateTo(`${basePath}/quotes/${x.uuid}`) }, (_a = x.customer) === null || _a === void 0 ? void 0 : _a.name),
                                    React.createElement("div", { className: 'xl:hidden flex items-center gap-2 justify-end' },
                                        React.createElement(Badge, { ...leadStatusToBadge(x.status) }),
                                        React.createElement(DropdownMenu, { items: [...LEAD_STATUSES.filter(x => x.code !== 'AllJobs').map(y => ({
                                                    label: y.display_name, onClick: () => setLead({ ...x, status: y.code }), group: 'status_change'
                                                })), {
                                                    label: 'Duplicate',
                                                    icon: Copy,
                                                    onClick: () => {
                                                        toggleDuplicateLeadModalVisibility(true);
                                                        setLeadToDuplicate(x);
                                                    }
                                                }] }))),
                                React.createElement("div", { className: "text-light text-xs font-medium" }, getAddressIncludingPostcode(x)))),
                        React.createElement("div", { className: 'grid grid-cols-3 gap-4 xl:gap-8 items-center justify-end' },
                            React.createElement("div", { className: "flex-col flex" },
                                React.createElement("div", { className: "text-light text-xs font-medium break-words" }, (_b = x.customer) === null || _b === void 0 ? void 0 : _b.email),
                                React.createElement("div", { className: "text-light text-xs font-medium" }, (_c = x.customer) === null || _c === void 0 ? void 0 : _c.phone)),
                            React.createElement("div", { className: 'text-light text-xs' }, contextSensitiveFormatDate(new Date(x.createdAt))),
                            React.createElement("div", { className: 'hidden xl:flex items-center gap-2 justify-end' },
                                React.createElement(Badge, { ...leadStatusToBadge(x.status) }),
                                React.createElement(DropdownMenu, { items: [...LEAD_STATUSES.filter(x => x.code !== 'AllJobs').map(y => ({
                                            label: y.display_name, onClick: () => setLead({ ...x, status: y.code }), group: 'status_change'
                                        })), {
                                            label: 'Duplicate',
                                            icon: Copy,
                                            onClick: () => {
                                                toggleDuplicateLeadModalVisibility(true);
                                                setLeadToDuplicate(x);
                                            }
                                        }] })))));
            })),
            leadToDuplicate && isDuplicateLeadModalOpen && React.createElement(DuplicateLeadModal, { visible: true, setVisible: toggleDuplicateLeadModalVisibility, lead: leadToDuplicate, companyPublicInfo: companyPublicInfo, navigateTo: navigateTo })));
};
