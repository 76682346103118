import React, { useEffect, useState } from 'react';
import { Section } from '../../../../components/containers/section';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Select } from '../../../../components/inputs_and_selections/select';
import { getSimproAPICompaniesList } from '../../../../code/models/company';
import { SettingsLayout } from '../components/settings_layout';
import { testMagicplanCreds } from '../../../../code/floorplan_adapters/magicplan';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Text } from '../../../../components/content_display/text';
export const AccountIntegrations = ({ company, setCompany }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [simproCompanies, setSimproCompanies] = useState([]);
    const [testingMagicplan, setTestingMagicplan] = useState(undefined);
    const getSimproCompaniesList = async (apiKey, url) => {
        if (!apiKey || !url)
            return [];
        const list = await getSimproAPICompaniesList(company.public_info.uuid, apiKey, url);
        setSimproCompanies(list || []);
    };
    useEffect(() => {
        if (company.magicplan_api_key && company.magicplan_customer_id) {
            setTestingMagicplan('TESTING');
            setTimeout(async () => {
                if (company.magicplan_api_key && company.magicplan_customer_id) {
                    setTestingMagicplan('TESTING');
                    const projects = await testMagicplanCreds(company.public_info.uuid, company.magicplan_api_key, company.magicplan_customer_id);
                    if (!projects || projects === -1) {
                        setTestingMagicplan('UNSUCCESSFUL');
                    }
                    else {
                        setTestingMagicplan('SUCCESSFUL');
                    }
                }
                else {
                    setTestingMagicplan(undefined);
                }
            }, 1000);
        }
        return () => {
            setTestingMagicplan(undefined);
        };
    }, [company.magicplan_api_key, company.magicplan_customer_id]);
    return React.createElement(SettingsLayout, { heading: 'Integrations', description: 'Connect Spruce to your other apps' },
        React.createElement(Section, { border: true, title: 'Your integrations' },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Payaca', helperText: 'Link your Payaca account to receive new leads from Spruce.' }), input: React.createElement(Input, { placeholder: 'Customer ID', value: (_a = company.payaca_customer_id) !== null && _a !== void 0 ? _a : '', setValue: (customerId) => setCompany({ ...company, payaca_customer_id: customerId }) }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Commusoft', helperText: 'Link your Commusoft account to receive leads from Spruce.' }), input: React.createElement(React.Fragment, null,
                        React.createElement(Input, { placeholder: 'Client ID', value: (_b = company.commusoft_client_id) !== null && _b !== void 0 ? _b : '', setValue: (clientId) => setCompany({ ...company, commusoft_client_id: clientId }) }),
                        React.createElement(Input, { placeholder: 'API key', value: (_c = company.commusoft_api_key) !== null && _c !== void 0 ? _c : '', setValue: (key) => setCompany({ ...company, commusoft_api_key: key }) })) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Simpro', helperText: 'Link your Simpro account to receive leads from Spruce.' }), input: React.createElement(React.Fragment, null,
                        (company.simpro_company_id && company.simpro_company_name) && React.createElement("div", { className: 'flex flex-row' },
                            React.createElement(Text, null,
                                "Linked to ",
                                company.simpro_company_name,
                                " - ",
                                company.simpro_company_id)),
                        React.createElement(Input, { placeholder: 'API Key', value: (_d = company.simpro_api_key) !== null && _d !== void 0 ? _d : '', setValue: async (key) => {
                                var _a;
                                setCompany({
                                    ...company,
                                    simpro_api_key: key,
                                    simpro_company_id: '',
                                    simpro_company_name: ''
                                });
                                await getSimproCompaniesList(key.trim(), ((_a = company.simpro_url) !== null && _a !== void 0 ? _a : '').trim());
                            } }),
                        React.createElement(Input, { placeholder: 'URL', value: (_e = company.simpro_url) !== null && _e !== void 0 ? _e : '', setValue: async (url) => {
                                var _a;
                                setCompany({
                                    ...company,
                                    simpro_url: url,
                                    simpro_company_id: '',
                                    simpro_company_name: ''
                                });
                                await getSimproCompaniesList(((_a = company.simpro_api_key) !== null && _a !== void 0 ? _a : '').trim(), url.trim());
                            } }),
                        (Boolean(company.simpro_api_key || company.simpro_url) &&
                            (Boolean(!company.simpro_company_id && !company.simpro_company_name) && simproCompanies.length === 0)) &&
                            React.createElement(Alert, { type: 'WARNING' }, "No companies found for the API key"),
                        simproCompanies.length !== 0 && React.createElement(React.Fragment, null,
                            (!company.simpro_company_id) && React.createElement(Alert, { type: 'INFO' }, "Please select a Simpro company"),
                            React.createElement(Select, { options: company.simpro_company_id ? [{ key: company.simpro_company_id, value: company.simpro_company_name }] : simproCompanies, selectedKey: company.simpro_company_id, setSelectedKey: (selectedCompany) => {
                                    var _a, _b;
                                    setCompany({
                                        ...company,
                                        simpro_company_id: selectedCompany,
                                        simpro_company_name: (_b = (_a = simproCompanies.find(x => x.key === selectedCompany)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ''
                                    });
                                } }))) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Magicplan', helperText: 'Link your Magicplan account to import floor plans into your surveys.' }), input: React.createElement(React.Fragment, null,
                        React.createElement(Input, { placeholder: 'API Key', value: (_f = company.magicplan_api_key) !== null && _f !== void 0 ? _f : '', setValue: (key) => {
                                setCompany({ ...company, magicplan_api_key: key });
                            } }),
                        React.createElement(Input, { placeholder: 'Customer ID', value: (_g = company.magicplan_customer_id) !== null && _g !== void 0 ? _g : '', setValue: (customerId) => {
                                setCompany({ ...company, magicplan_customer_id: customerId });
                            } }),
                        testingMagicplan === 'TESTING' &&
                            React.createElement(Alert, { type: 'INFO' }, "Verifying Magicplan integration details..."),
                        testingMagicplan === 'SUCCESSFUL' &&
                            React.createElement(Alert, { type: 'SUCCESS' }, "You've successfully integrated Magicplan with Spruce!"),
                        testingMagicplan === 'UNSUCCESSFUL' &&
                            React.createElement(Alert, { type: 'WARNING' }, "Error getting Magicplan projects. Check your Magicplan API key and Customer ID.")) }))));
};
