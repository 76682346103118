import React, { useCallback, useContext } from 'react';
import TabbedDashboardLayout from '../../../layouts/tabbed_dashboard_layout';
import { AdminContext } from '../admin_layout';
import { updateCompany } from '../../../code/models/company';
import { OfflinePage } from '../offline_page';
import { UnauthorizedPage } from '../unauthorized_page';
import { Loader } from '../../../components/indicators_and_messaging/loader';
import { hasInventoryAccess } from '../../../code/models/user';
import { HeatPumpsInventory } from './components/tabs/heat_pumps_inventory';
import { deleteHeatPump, insertHeatPump, updateHeatPump } from '../../../code/models/heat_pump';
import { logEvent } from '../../../code/log_event';
import { PartsInventory } from './components/tabs/parts_inventory';
import { deletePart as deletePartApi, insertPart, updatePart } from '../../../code/models/parts';
import { PacksInventory } from './components/tabs/packs_inventory';
import { addPartToPack as addPartToPackApi, deletePack as deletePackApi, insertPack, removePartFromPack as removePartFromPackApi, updatePack as updatePackApi, updatePartQuantityInPack } from '../../../code/models/packs';
import { deleteLabour as deleteLabourApi, insertLabour, updateLabour } from '../../../code/models/labour';
import { LabourInventory } from './components/tabs/labour_inventory';
import { EmittersInventory } from './components/tabs/emitters_inventory';
import { HotWaterCylindersInventory } from './components/tabs/hot_water_cylinders_inventory';
import { deleteHotWaterCylinders, insertHotWaterCylinders, updateHotWaterCylinders } from '../../../code/models/hot_water_cylinder';
import { useDebounceCallback } from 'usehooks-ts';
export const CostsAndInventoryPage = ({ navigateTo, tab, secondaryTab, companyPublicInfo }) => {
    const adminContext = useContext(AdminContext);
    const companySubdomain = companyPublicInfo.subdomain;
    const companyUUID = companyPublicInfo.uuid;
    const basePath = `/${companyPublicInfo.subdomain}/admin/costs`;
    const debounceUpdateCompany = useCallback(useDebounceCallback(async (company) => {
        await updateCompany(company);
    }, 250), []);
    const debounceInsertHeatPump = useCallback(useDebounceCallback(async (heatPump) => {
        await insertHeatPump(heatPump, companyUUID);
    }, 250), []);
    const debounceUpdateHeatPump = useCallback(useDebounceCallback(async (heatPump) => {
        await updateHeatPump(heatPump, companyUUID);
    }, 250), []);
    const debounceInsertPart = useCallback(useDebounceCallback(async (part) => {
        await insertPart(part, companyUUID);
    }, 250), []);
    const debounceUpdatePart = useCallback(useDebounceCallback(async (part) => {
        await updatePart(part, companyUUID);
    }, 250), []);
    const debounceInsertHotWaterCylinder = useCallback(useDebounceCallback(async (hotWaterCylinder) => {
        await insertHotWaterCylinders(hotWaterCylinder, companyUUID);
    }, 250), []);
    const debounceUpdateHotWaterCylinder = useCallback(useDebounceCallback(async (hotWaterCylinder) => {
        await updateHotWaterCylinders(hotWaterCylinder, companyUUID);
    }, 250), []);
    if (adminContext.isOffline) {
        return React.createElement(OfflinePage, { navigateTo: navigateTo });
    }
    if (adminContext.isLoading || !adminContext.data.company) {
        return (React.createElement("div", { className: 'flex justify-center items-center h-full' },
            React.createElement(Loader, null)));
    }
    const setAndUpdateCompany = ({ company, debounce = false }) => {
        adminContext.setCompany(company);
        if (debounce) {
            debounceUpdateCompany(company);
        }
        else {
            updateCompany(company);
        }
    };
    const updateOrInsertHeatPump = async ({ heatPump, updateLocal = true, debounce = false }) => {
        if (adminContext.data.heatPumps.some(x => x.uuid === heatPump.uuid)) {
            logEvent({ name: 'Heat Pump Modified', properties: {} }, companySubdomain);
            if (debounce) {
                debounceUpdateHeatPump(heatPump);
            }
            else {
                await updateHeatPump(heatPump, companyUUID);
            }
            if (updateLocal)
                adminContext.setHeatPumps(adminContext.data.heatPumps.map(x => x.uuid === heatPump.uuid ? heatPump : x));
        }
        else {
            logEvent({ name: 'Heat Pump Added', properties: {} }, companySubdomain);
            if (debounce) {
                debounceInsertHeatPump(heatPump);
            }
            else {
                await insertHeatPump(heatPump, companyUUID);
            }
            if (updateLocal)
                adminContext.setHeatPumps([...adminContext.data.heatPumps, heatPump]);
        }
    };
    const addHeatPumps = async (heatPumps) => {
        await Promise.all(heatPumps.map(async (x) => await updateOrInsertHeatPump({ heatPump: x, updateLocal: false, debounce: false })));
        const newHeatPumps = heatPumps.filter(x => { var _a; return !((_a = adminContext.data.heatPumps) === null || _a === void 0 ? void 0 : _a.some(y => y.uuid === x.uuid)); });
        const updatedHeatPumps = adminContext.data.heatPumps.map(x => x);
        adminContext.setHeatPumps([...updatedHeatPumps.map(x => {
                const heatPump = heatPumps.find(y => y.uuid === x.uuid);
                return heatPump || x;
            }), ...newHeatPumps]);
    };
    const deleteHeatPumpRow = async (heatPumpUUID) => {
        logEvent({ name: 'Heat Pump Deleted', properties: {} }, companySubdomain);
        await deleteHeatPump(heatPumpUUID, companyUUID);
        adminContext.setHeatPumps(adminContext.data.heatPumps.map(x => {
            // artificially delete the HP in the state variable by setting deleted_at
            // in reality the deleted_at is set by the server, but we don't need a precise time here
            return x.uuid === heatPumpUUID ? { ...x, deleted_at: new Date() } : x;
        }));
    };
    const addOrUpdatePart = async ({ part, debounce = false }) => {
        var _a;
        if ((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.parts.some(x => x.uuid === part.uuid)) {
            adminContext.setParts(adminContext.data.parts.map(x => x.uuid === part.uuid ? part : x));
            if (debounce) {
                debounceUpdatePart(part);
            }
            else {
                await updatePart(part, companyUUID);
            }
        }
        else {
            adminContext.setParts([...adminContext.data.parts, part]);
            if (debounce) {
                debounceInsertPart(part);
            }
            else {
                await insertPart(part, companyUUID);
            }
        }
    };
    const deletePart = async (partUUID) => {
        await deletePartApi(partUUID, companyUUID);
        adminContext.setParts(adminContext.data.parts.map(x => {
            return x.uuid === partUUID ? { ...x, deleted_at: new Date() } : x;
        }));
        adminContext.setPacks(adminContext.data.packs.map(x => {
            return { ...x, parts: (x.parts || []).filter(y => y.uuid !== partUUID) };
        }));
    };
    const addPack = async (pack, primaryInventoryItemIds) => {
        await insertPack(pack, companyUUID);
        adminContext.setPacks([...adminContext.data.packs, pack]);
        const heatPumps = primaryInventoryItemIds.map(x => adminContext.data.heatPumps.find(y => y.uuid === x)).filter(x => x);
        const hotWaterCylinders = primaryInventoryItemIds.map(x => adminContext.data.hotWaterCylinders.find(y => y.uuid === x));
        // Wait until all heat pumps and cylinders are updated before updating the local state
        await Promise.all(heatPumps.map(async (heatPump) => {
            if (!heatPump)
                return;
            // We pass false here to updateLocal because we want to update the local state once at the end
            await updateOrInsertHeatPump({ heatPump: { ...heatPump, default_pack_uuid: pack.uuid }, updateLocal: false, debounce: false });
        }, hotWaterCylinders.map(async (hotWaterCylinder) => {
            if (!hotWaterCylinder)
                return;
            await updateHotWaterCylinders({ ...hotWaterCylinder, default_pack_uuid: pack.uuid }, companyUUID);
        })));
        // Update the local state
        adminContext.setHeatPumps(adminContext.data.heatPumps.map(x => {
            if (primaryInventoryItemIds.includes(x.uuid)) {
                return { ...x, default_pack_uuid: pack.uuid };
            }
            return x;
        }));
        adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders.map(x => {
            if (primaryInventoryItemIds.includes(x.uuid)) {
                return { ...x, default_pack_uuid: pack.uuid };
            }
            return x;
        }));
    };
    const updatePack = async (pack, newHeatPumpIds, newHotWaterCylinderIds) => {
        var _a, _b, _c, _d;
        const oldHeatPumpIds = (_b = (_a = adminContext.data.heatPumps) === null || _a === void 0 ? void 0 : _a.filter(x => x.default_pack_uuid === pack.uuid).map(x => x.uuid)) !== null && _b !== void 0 ? _b : [];
        const heatPumpsToAssociate = newHeatPumpIds.filter(x => !oldHeatPumpIds.includes(x));
        const heatPumpsToDisassociate = oldHeatPumpIds.filter(x => !newHeatPumpIds.includes(x));
        const oldHotWaterCylinderIds = (_d = (_c = adminContext.data.hotWaterCylinders) === null || _c === void 0 ? void 0 : _c.filter(x => x.default_pack_uuid === pack.uuid).map(x => x.uuid)) !== null && _d !== void 0 ? _d : [];
        const hotWaterCylindersToAssociate = newHotWaterCylinderIds.filter(x => !oldHotWaterCylinderIds.includes(x));
        const hotWaterCylindersToDisassociate = oldHotWaterCylinderIds.filter(x => !newHotWaterCylinderIds.includes(x));
        const updateSingleHeatPump = async (heatPumpId, defaultPackUuid) => {
            var _a;
            const heatPump = (_a = adminContext.data.heatPumps) === null || _a === void 0 ? void 0 : _a.find(x => x.uuid === heatPumpId);
            if (heatPump) {
                await updateOrInsertHeatPump({ heatPump: { ...heatPump, default_pack_uuid: defaultPackUuid }, updateLocal: false, debounce: false });
            }
        };
        const updateSingleHotWaterCylinder = async (hotWaterCylinderId, defaultPackUuid) => {
            var _a;
            const hotWaterCylinder = (_a = adminContext.data.hotWaterCylinders) === null || _a === void 0 ? void 0 : _a.find(x => x.uuid === hotWaterCylinderId);
            if (hotWaterCylinder) {
                await updateHotWaterCylinders({ ...hotWaterCylinder, default_pack_uuid: defaultPackUuid }, companyUUID);
            }
        };
        // Update all associated and disassociated heat pumps and hot water cylinders and the pack
        await Promise.all([
            ...heatPumpsToDisassociate.map((heatPumpId) => updateSingleHeatPump(heatPumpId, null)),
            ...heatPumpsToAssociate.map((heatPumpId) => updateSingleHeatPump(heatPumpId, pack.uuid)),
            ...hotWaterCylindersToDisassociate.map((hotWaterCylinderId) => updateSingleHotWaterCylinder(hotWaterCylinderId, null)),
            ...hotWaterCylindersToAssociate.map((hotWaterCylinderId) => updateSingleHotWaterCylinder(hotWaterCylinderId, pack.uuid)),
            updatePackApi(pack, companyUUID)
        ]);
        // Update the local state last
        adminContext.setPacks(adminContext.data.packs.map(x => x.uuid === pack.uuid ? pack : x));
        adminContext.setHeatPumps(adminContext.data.heatPumps.map(x => {
            if (heatPumpsToDisassociate.includes(x.uuid)) {
                return { ...x, default_pack_uuid: null };
            }
            if (heatPumpsToAssociate.includes(x.uuid)) {
                return { ...x, default_pack_uuid: pack.uuid };
            }
            return x;
        }));
        adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders.map(x => {
            if (hotWaterCylindersToDisassociate.includes(x.uuid)) {
                return { ...x, default_pack_uuid: null };
            }
            if (hotWaterCylindersToAssociate.includes(x.uuid)) {
                return { ...x, default_pack_uuid: pack.uuid };
            }
            return x;
        }));
    };
    const deletePack = async (pack) => {
        await deletePackApi(pack, companyUUID);
        adminContext.setPacks(adminContext.data.packs.map(x => {
            return x.uuid === pack.uuid ? { ...x, deleted_at: new Date() } : x;
        }));
    };
    const addPartToPack = async (pack, part) => {
        await addPartToPackApi(pack, part, companyUUID);
        adminContext.setPacks(adminContext.data.packs.map(x => {
            return x.uuid === pack.uuid ? { ...x, parts: [...x.parts || [], part] } : x;
        }));
    };
    const removePartFromPack = async (pack, part) => {
        await removePartFromPackApi(pack, part, companyUUID);
        adminContext.setPacks(adminContext.data.packs.map(x => {
            return x.uuid === pack.uuid ? { ...x, parts: (x.parts || []).filter(y => y.uuid !== part.uuid) } : x;
        }));
    };
    const updatePartInPack = async (pack, part) => {
        const updatedParts = (pack.parts || []).map(x => x.uuid === part.uuid ? part : x);
        const updatedPack = { ...pack, parts: updatedParts };
        await updatePartQuantityInPack(pack, part, companyUUID);
        adminContext.setPacks(adminContext.data.packs.map(x => x.uuid === pack.uuid ? updatedPack : x));
    };
    const addOrUpdateLabour = async (labour) => {
        var _a;
        if ((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.labour.some(x => x.uuid === labour.uuid)) {
            await updateLabour(labour, companyUUID);
            adminContext.setLabour(adminContext.data.labour.map(x => x.uuid === labour.uuid ? labour : x));
        }
        else {
            await insertLabour(labour, companyUUID);
            adminContext.setLabour([...adminContext.data.labour, { ...labour }]);
        }
    };
    const deleteLabour = async (labour) => {
        await deleteLabourApi(labour, companyUUID);
        adminContext.setLabour(adminContext.data.labour.filter(x => x.uuid !== labour.uuid));
    };
    if (!adminContext.isLoading && !hasInventoryAccess(adminContext.data.company, adminContext.data.user)) {
        return React.createElement(UnauthorizedPage, null);
    }
    const updateOrInsertHotWaterCylinderRow = async ({ hotWaterCylinder, debounce = false }) => {
        var _a;
        if ((_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.hotWaterCylinders.some(x => x.uuid === hotWaterCylinder.uuid)) {
            adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders.map(x => x.uuid === hotWaterCylinder.uuid ? hotWaterCylinder : x));
            if (debounce) {
                debounceUpdateHotWaterCylinder(hotWaterCylinder);
            }
            else {
                await updateHotWaterCylinders(hotWaterCylinder, companyUUID);
            }
        }
        else {
            adminContext.setHotWaterCylinders([...adminContext.data.hotWaterCylinders, hotWaterCylinder]);
            if (debounce) {
                debounceInsertHotWaterCylinder(hotWaterCylinder);
            }
            else {
                await insertHotWaterCylinders(hotWaterCylinder, companyUUID);
            }
        }
    };
    const deleteHotWaterCylinderRow = async (hotWaterCylinderId) => {
        await deleteHotWaterCylinders(hotWaterCylinderId, companyUUID);
        adminContext.setHotWaterCylinders(adminContext.data.hotWaterCylinders.map(x => {
            return x.uuid === hotWaterCylinderId ? { ...x, deleted_at: new Date() } : x;
        }));
    };
    const company = adminContext.data.company;
    return (React.createElement(TabbedDashboardLayout, { navigateTo: navigateTo, basePath: basePath, title: 'Costs & inventory', selectedTabId: tab, isOffline: adminContext.isOffline, tabs: [
            {
                id: 'heat-pumps',
                label: 'Heat pumps',
                content: React.createElement(HeatPumpsInventory, { heatPumps: (adminContext.data.heatPumps || []).filter(x => !x.deleted_at), addHeatPumps: addHeatPumps, deleteHeatPump: deleteHeatPumpRow, updateHeatPump: updateOrInsertHeatPump, company: company, setCompany: setAndUpdateCompany, packs: (adminContext.data.packs || []).filter(x => !x.deleted_at) })
            },
            {
                id: 'hot-water-cylinders',
                label: 'Hot water cylinders',
                content: React.createElement(HotWaterCylindersInventory, { hotWaterCylinders: (adminContext.data.hotWaterCylinders || []).filter(x => !x.deleted_at), addHotWaterCylinder: updateOrInsertHotWaterCylinderRow, deleteHotWaterCylinder: deleteHotWaterCylinderRow, updateHotWaterCylinder: updateOrInsertHotWaterCylinderRow, company: company, packs: (adminContext.data.packs || []).filter(x => !x.deleted_at) })
            },
            {
                id: 'parts',
                label: 'Parts',
                content: React.createElement(PartsInventory, { parts: (adminContext.data.parts || []).filter(x => !x.deleted_at), company: company, addOrUpdatePart: addOrUpdatePart, deletePart: deletePart })
            },
            {
                id: 'emitters',
                label: 'Emitters',
                content: React.createElement(EmittersInventory, { company: company, setCompany: setAndUpdateCompany })
            },
            {
                id: 'labour',
                label: 'Labour',
                content: React.createElement(LabourInventory, { labour: (adminContext.data.labour || []).filter(x => !x.deleted_at), addOrUpdateLabour: addOrUpdateLabour, deleteLabour: deleteLabour, company: company, setCompany: setAndUpdateCompany })
            },
            {
                id: 'packs',
                label: 'Packs',
                content: React.createElement(PacksInventory, { packs: (adminContext.data.packs || []).filter(x => !x.deleted_at), heatPumps: (adminContext.data.heatPumps || []).filter(x => !x.deleted_at), hotWaterCylinders: (adminContext.data.hotWaterCylinders || []).filter(x => !x.deleted_at), parts: (adminContext.data.parts || []).filter(x => !x.deleted_at), addPack: addPack, deletePack: deletePack, updatePack: updatePack, addPartToPack: addPartToPack, removePartFromPack: removePartFromPack, updatePartInPack: updatePartInPack })
            }
        ] }));
};
