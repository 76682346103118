import { chain } from 'lodash';
import React, { useState } from 'react';
import { Modal } from '../../../../components/containers/modal';
import { TableLite } from '../../../../components/content_display/table_lite';
import { getHeatPumpScopAtFlowTemp } from '../../../../code/models/range_heat_pump';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Select } from '../../../../components/inputs_and_selections/select';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Text } from '../../../../components/content_display/text';
export const HeatPumpModal = ({ heatPump, setVisible, updateHeatPump, packs }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { scopRows, scopColumns } = getHeatPumpScopRowsAndColumns(heatPump.range_heat_pump);
    const { capacityRows, capacityColumns } = getHeatPumpCapacityRowsAndColumns(heatPump.range_heat_pump);
    const [name, setName] = useState(heatPump.name);
    const [selectedPackId, setSelectedPackId] = useState((_a = heatPump.default_pack_uuid) !== null && _a !== void 0 ? _a : undefined);
    const packOptions = [
        { key: null, value: 'None' },
        ...packs.map(pack => ({ key: pack.uuid, value: pack.name }))
    ];
    return React.createElement(Modal, { title: heatPump.name, visible: true, setVisible: setVisible, onConfirm: async () => {
            await updateHeatPump({ heatPump: { ...heatPump, name, default_pack_uuid: selectedPackId }, debounce: false });
        }, confirmButtonLabel: 'Save' },
        React.createElement("div", { className: 'flex flex-col gap-6 w-full' },
            React.createElement(Input, { label: "Name", placeholder: "Name", value: name, setValue: setName }),
            React.createElement("div", { className: "flex flex-col gap-2" },
                React.createElement(FormLabel, { labelText: 'Associated pack' }),
                React.createElement(Select, { placeholder: "Select a pack", options: packOptions, selectedKey: selectedPackId, setSelectedKey: setSelectedPackId }),
                React.createElement(Text, { size: "XS", className: "text-gray-500" }, "This pack will be automatically included when this heat pump is added to an estimate or quote.")),
            heatPump.range_heat_pump && React.createElement("div", { className: 'grid grid-cols-3 gap-3 text-sm' },
                React.createElement("div", { className: 'space-y-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Refrigerant"),
                    React.createElement("div", { className: 'text-gray-600' }, (_b = heatPump.range_heat_pump) === null || _b === void 0 ? void 0 : _b.refrigerant)),
                React.createElement("div", { className: 'space-y-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Serial number"),
                    React.createElement("div", { className: 'text-gray-600' }, (_c = heatPump.range_heat_pump) === null || _c === void 0 ? void 0 : _c.model_number)),
                React.createElement("div", { className: 'space-y-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Sound power"),
                    React.createElement("div", { className: 'text-gray-600' }, (_d = heatPump.range_heat_pump) === null || _d === void 0 ? void 0 :
                        _d.sound_power_max_dba,
                        " dB(A)")),
                React.createElement("div", { className: 'space-y-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Width"),
                    React.createElement("div", { className: 'text-gray-600' }, (_e = heatPump.range_heat_pump) === null || _e === void 0 ? void 0 :
                        _e.width_mm,
                        " mm")),
                React.createElement("div", { className: 'space-y-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Height"),
                    React.createElement("div", { className: 'text-gray-600' }, (_f = heatPump.range_heat_pump) === null || _f === void 0 ? void 0 :
                        _f.height_mm,
                        " mm")),
                React.createElement("div", { className: 'space-y-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Depth"),
                    React.createElement("div", { className: 'text-gray-600' }, (_g = heatPump.range_heat_pump) === null || _g === void 0 ? void 0 :
                        _g.depth_mm,
                        " mm")),
                React.createElement("div", { className: 'space-y-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "Weight"),
                    React.createElement("div", { className: 'text-gray-600' }, (_h = heatPump.range_heat_pump) === null || _h === void 0 ? void 0 :
                        _h.weight_kg,
                        " kg")),
                React.createElement("div", { className: 'space-y-1' },
                    React.createElement("div", { className: 'text-gray-900 font-bold' }, "MCS cert numbers"),
                    React.createElement("div", { className: 'text-gray-600' }, (_k = (_j = heatPump.range_heat_pump) === null || _j === void 0 ? void 0 : _j.mcs_certificate_numbers) === null || _k === void 0 ? void 0 : _k.join(', ')))),
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'text-gray-900 text-lg font-bold' }, "SCOP"),
                React.createElement(TableLite, { rows: scopRows, columns: scopColumns })),
            React.createElement("div", { className: 'space-y-2' },
                React.createElement("div", { className: 'text-gray-900 text-lg font-bold' }, "Capacity (kW)"),
                React.createElement(TableLite, { rows: capacityRows, columns: capacityColumns }))));
};
export const getHeatPumpScopRowsAndColumns = (rangeHeatPump) => {
    if (!rangeHeatPump)
        return { scopRows: [], scopColumns: [] };
    // Return an array even though always length 1 as easier if undefined to return empty array
    const scopRows = [{
            name: 'SCOP',
            at35: getHeatPumpScopAtFlowTemp(rangeHeatPump, 35),
            at40: getHeatPumpScopAtFlowTemp(rangeHeatPump, 40),
            at45: getHeatPumpScopAtFlowTemp(rangeHeatPump, 45),
            at50: getHeatPumpScopAtFlowTemp(rangeHeatPump, 50),
            at55: getHeatPumpScopAtFlowTemp(rangeHeatPump, 55)
        }];
    const scopColumns = [
        { name: '', key: 'name', render: (row) => React.createElement("div", null, row === null || row === void 0 ? void 0 : row.name) },
        { name: '35°', key: 'at35', render: (row) => React.createElement("div", null, row === null || row === void 0 ? void 0 : row.at35) },
        { name: '40°', key: 'at40', render: (row) => React.createElement("div", null, row === null || row === void 0 ? void 0 : row.at40) },
        { name: '45°', key: 'at45', render: (row) => React.createElement("div", null, row === null || row === void 0 ? void 0 : row.at45) },
        { name: '50°', key: 'at50', render: (row) => React.createElement("div", null, row === null || row === void 0 ? void 0 : row.at50) },
        { name: '55°', key: 'at55', render: (row) => React.createElement("div", null, row === null || row === void 0 ? void 0 : row.at55) }
    ];
    return { scopRows, scopColumns };
};
export const getHeatPumpCapacityRowsAndColumns = (rangeHeatPump) => {
    if (!rangeHeatPump)
        return { capacityRows: [], capacityColumns: [] };
    // Not using the get capacity method as currently shows whatever outdoor temps we have hard data for
    const capacityRows = chain(rangeHeatPump.range_heat_pumps_by_flow_and_outside)
        .groupBy(x => x.outside_temperature_c)
        .map((values, key) => {
        var _a, _b, _c, _d, _e;
        return ({
            name: Number(key),
            at35: (_a = values.find(x => x.flow_temperature_c === 35)) === null || _a === void 0 ? void 0 : _a.capacity,
            at40: (_b = values.find(x => x.flow_temperature_c === 40)) === null || _b === void 0 ? void 0 : _b.capacity,
            at45: (_c = values.find(x => x.flow_temperature_c === 45)) === null || _c === void 0 ? void 0 : _c.capacity,
            at50: (_d = values.find(x => x.flow_temperature_c === 50)) === null || _d === void 0 ? void 0 : _d.capacity,
            at55: (_e = values.find(x => x.flow_temperature_c === 55)) === null || _e === void 0 ? void 0 : _e.capacity
        });
    })
        .orderBy(x => x.name)
        .value();
    const capacityColumns = [
        { name: 'Outside Temp', key: 'name', render: (row) => React.createElement("div", null,
                row.name,
                "\u00B0C") },
        { name: '35°C', key: 'at35', render: (row) => React.createElement("div", null, row.at35) },
        { name: '40°C', key: 'at40', render: (row) => React.createElement("div", null, row.at40) },
        { name: '45°C', key: 'at45', render: (row) => React.createElement("div", null, row.at45) },
        { name: '50°C', key: 'at50', render: (row) => React.createElement("div", null, row.at50) },
        { name: '55°C', key: 'at55', render: (row) => React.createElement("div", null, row.at55) }
    ];
    return { capacityRows, capacityColumns };
};
