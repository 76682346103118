import { axiosDeleteV2, axiosGetV2, axiosPostV2 } from '../axios';
import { parseWithZod } from '../zod';
import { LinkSchema } from './links';
export const getDefaultLinks = async (companyUUID) => {
    // Retrieve default links for a company
    const result = await axiosGetV2(`teams/${companyUUID}/default_links`);
    if (result && result.length === 0) {
        // No company links
        return { links: [], error: undefined };
    }
    else if (result && result.length > 0) {
        const parsedResult = result.map(x => parseWithZod(LinkSchema, x));
        return { links: parsedResult, error: undefined };
    }
    else {
        // Return error if no resutls
        return { links: undefined, error: 'Error getting default_links' };
    }
};
export const insertDefaultLink = async (link, companyUUID) => {
    // Insert a default link
    const response = await axiosPostV2(`teams/${companyUUID}/default_links`, link);
    return parseWithZod(LinkSchema, response);
};
export const deleteDefaultLink = async (linkUUID, companyUUID) => {
    // Delete a default link
    await axiosDeleteV2(`teams/${companyUUID}/default_links/${linkUUID}`);
};
