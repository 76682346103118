import { hps, ohm } from '../../../assets/images/images';
import React from 'react';
export const LogoHeader = ({ companyPublicInfo, center }) => {
    return React.createElement("div", { className: `${center ? 'm-auto text-center' : ''}` },
        React.createElement("div", { className: "h-16 px-7 py-4 bg-white flex-row justify-start items-start gap-2.5 inline-flex" },
            companyPublicInfo.subdomain === 'ohm' &&
                React.createElement(React.Fragment, null,
                    React.createElement("img", { className: "h-9", src: ohm }),
                    React.createElement("img", { className: "h-9", src: hps })),
            companyPublicInfo.subdomain !== 'ohm' && React.createElement("img", { className: "h-9", src: companyPublicInfo.logo })));
};
