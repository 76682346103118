import React from 'react';
import { Text } from '../../../components/content_display/text';
export const TextDemo = () => {
    return (React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => (React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description)))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", null, "Sizes"),
            React.createElement("div", { className: 'flex flex-col justify-start items-start gap-4' },
                React.createElement(Text, { size: "XS" }, "Extra small text"),
                React.createElement(Text, { size: "SM" }, "Small text"),
                React.createElement(Text, { size: "MD" }, "Medium text"),
                React.createElement(Text, { size: "LG" }, "Large text")),
            React.createElement("div", null, "Variants"),
            React.createElement("div", { className: 'flex flex-col justify-start items-start gap-4' },
                React.createElement(Text, null, "Default text"),
                React.createElement(Text, { bold: true }, "Bold text"),
                React.createElement(Text, { className: "bg-orange-500 rounded underline" }, "Custom class text")))));
};
const componentProps = [
    { name: 'children', description: 'React.ReactNode or string to be rendered as text content' },
    { name: 'bold', description: 'Whether the text should be bold (optional)' },
    { name: 'className', description: 'Additional CSS classes to apply (optional)' },
    { name: 'size', description: 'XS | SM | MD | LG (optional, defaults to MD)' }
];
const codeBlock = `<Text
  bold={true}
  size="LG"
>
  Hello World
</Text>`;
