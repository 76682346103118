import { roundTo2dp } from './number_format';
export const priceCalculations = {
    calculateCustomerPrice: (costPrice, markup) => {
        return roundTo2dp(costPrice * (1 + markup / 100));
    },
    calculateCostPrice: (customerPrice, markup) => {
        return roundTo2dp(customerPrice / (1 + markup / 100));
    },
    updateCostPrice: (item, newCostPrice) => {
        return { ...item, cost_price: roundTo2dp(newCostPrice) };
    },
    updateMarkup: (item, newMarkup) => {
        return { ...item, markup: roundTo2dp(newMarkup) };
    },
    updateCustomerPrice: (part, newCustomerPrice) => {
        const newCostPrice = priceCalculations.calculateCostPrice(newCustomerPrice, part.markup);
        return { ...part, cost_price: roundTo2dp(newCostPrice) };
    },
    updateCostPriceAndMarkupToMatchCustomerPrice: (part, newCustomerPrice) => {
        return { ...part, cost_price: newCustomerPrice, markup: 0 };
    }
};
