import React, { useEffect, useState } from 'react';
import { getHLReportPublicAPI, setHLOpenedStatusAPI } from '../code/models/heatloss_report';
import { HeatLossReportPage } from './heat_loss/proposal/heatloss_report';
import { Loader } from '../components/indicators_and_messaging/loader';
import { createJobEvent, JobEventType } from '../code/models/job_event';
export const HeatLossReportPublicPage = (props) => {
    const [report, setReport] = useState();
    const isInstallerClick = new URLSearchParams(window.location.search).get('installer');
    useEffect(() => {
        const asyncCall = async () => {
            const result = await getHLReportPublicAPI(props.reportUUID, props.companyPublicInfo.uuid);
            if (result) {
                setReport(result);
            }
            if (!isInstallerClick && (result === null || result === void 0 ? void 0 : result.is_opened) === false) {
                await setHLOpenedStatusAPI(props.reportUUID, props.companyPublicInfo.uuid);
                createJobEvent({
                    event_type: JobEventType.enum.ReportOpened,
                    company_uuid: props.companyPublicInfo.uuid,
                    job_uuid: result.lead_uuid,
                    extra_data: {
                        report_uuid: props.reportUUID
                    }
                });
            }
        };
        asyncCall();
    }, []);
    if (!report) {
        return (React.createElement("div", { className: "flex flex-col items-center justify-center mt-10 mx-auto" },
            React.createElement(Loader, null)));
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'print:bg-white bg-gray-100 lg:p-6 md:p-4 p-0' },
            React.createElement("div", { className: 'bg-white print:max-w-none max-w-[260mm] print:m-0 m-auto pb-6 print:rounded-none rounded' },
                React.createElement(HeatLossReportPage, { reportUUID: props.reportUUID, sections: report.contents_bitmask, snapshot: report.snapshot, company_public_info: props.companyPublicInfo }))),
        React.createElement("div", { id: 'pdf-ready' }));
};
