import React, { useContext, useState } from 'react';
import { Button } from '../../../components/buttons/button';
import { getAddressIncludingPostcode } from '../../../code/models/lead';
import { SurveyOptionGroup } from '../../../components/inputs_and_selections/survey_option_group';
import { StepHeader } from '../components/steps_header';
import { SurveyContext } from '../survey_page';
import { ChevronRightIcon } from 'lucide-react';
import { QuestionnaireNavigateBack } from '../components/navigate_back';
export const OptionsStep = ({ headerText, captionText, options, defaultOption, onNavigateNextPage }) => {
    // survey context and dispatch
    const survey = useContext(SurveyContext);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    return React.createElement(React.Fragment, null,
        React.createElement(QuestionnaireNavigateBack, null),
        React.createElement(StepHeader, { guideText: getAddressIncludingPostcode(survey.lead), headerText: headerText, captionText: captionText }),
        React.createElement(SurveyOptionGroup, { options: options, onSelect: (key) => { setSelectedOption(key); }, selectedOptionKey: selectedOption }),
        React.createElement(Button, { size: 'LG', disabled: !selectedOption, onClick: () => onNavigateNextPage(selectedOption) },
            React.createElement("div", { className: "flex items-center gap-x-2" },
                React.createElement("div", null, "Continue"),
                React.createElement(ChevronRightIcon, { className: "h-6 w-6" }))));
};
