import React from 'react';
import { SurveyCylinderLocationContext } from '../cylinder_location';
import { Select } from '../../../../components/inputs_and_selections/select';
import { getPipeModelName, PIPE_MODELS } from '../../../../code/models/pipes';
import { pipeMaterials } from '../../../../code/models/property';
import { RadioGroup } from '../../../../components/inputs_and_selections/radio';
export const CylinderLocationExistingPipesBlock = () => {
    const cylinderLocationContext = React.useContext(SurveyCylinderLocationContext);
    const { survey, setSurvey } = cylinderLocationContext;
    const material = pipeMaterials.find(x => x === survey.existing_cylinder_pipe_material);
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Pipe between boiler and cylinder"),
        React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold" }, "Pipe material"),
                React.createElement(RadioGroup, { items: pipeMaterials.map(x => ({
                        name: x,
                        onClick: () => setSurvey({ ...survey, existing_cylinder_pipe_material: x }),
                        variant: survey.existing_cylinder_pipe_material === x ? 'ACTIVE' : 'DEFAULT'
                    })) })),
            React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 font-bold " }, "Size"),
                React.createElement(Select, { options: PIPE_MODELS.filter(x => x.material === material).map(x => ({
                        key: x.uuid,
                        value: getPipeModelName(x)
                    })), selectedKey: survey.existing_cylinder_pipe_size_uuid, setSelectedKey: (e) => setSurvey({ ...survey, existing_cylinder_pipe_size_uuid: e }) }))));
};
