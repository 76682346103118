import React from 'react';
import { Icon } from '../../components/buttons/icon';
import { Card } from '../../components/content_display/card';
import { FormLabel } from '../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../components/inputs_and_selections/vertical_form_group';
import { ProposalAttachmentPlaceholder } from './proposal_attachment_placeholder';
import { openInNewTab } from '../helpers';
import { useFiles } from '../use_files';
import { FileText, X, Link as LLink } from 'lucide-react';
// An attachment card displays an attachment or placeholder. If we want to force a title (in the case of recommended attachments)
export const AttachmentCard = ({ title, attachment, onAddAttachment, onRemoveAttachment, forceTitle, showLabel = true }) => {
    const { error, downloadFile } = useFiles('user-uploads');
    const handleGetAttachment = (attachment) => {
        if (attachment.internal) {
            // Download the file
            // Get the original filename and keep the extension:
            const fileParts = attachment.url.split('.');
            const fileExtension = fileParts.length > 1 ? fileParts.pop() : '';
            downloadFile({ fileKey: attachment.url.split('user-uploads/')[1], directDownload: false, fileNameOverride: attachment.title + '.' + fileExtension });
        }
        else {
            // Redirect to the external link
            openInNewTab(attachment.url);
        }
    };
    // If no attachment - render placeholder
    if (!attachment) {
        if (forceTitle) {
            return React.createElement(VerticalFormGroup, { formLabel: showLabel ? React.createElement(FormLabel, { labelText: forceTitle }) : React.createElement(React.Fragment, null), input: React.createElement(ProposalAttachmentPlaceholder, { forceTitle: forceTitle, addAttachment: onAddAttachment }) });
        }
        else {
            return React.createElement(ProposalAttachmentPlaceholder, { forceTitle: forceTitle, addAttachment: onAddAttachment });
        }
    }
    else {
        // Otherwise show the attachment and allow us to remove it if we want to
        return React.createElement(React.Fragment, null,
            React.createElement(VerticalFormGroup, { formLabel: showLabel ? React.createElement(FormLabel, { labelText: title !== null && title !== void 0 ? title : attachment.title }) : React.createElement(React.Fragment, null), input: React.createElement(Card, { color: 'DARK' },
                    React.createElement("div", { className: 'flex p-2 justify-between items-center' },
                        React.createElement("div", { className: 'flex text-center justify-center sm:text-left flex-wrap gap-4 items-center cursor-pointer hover:underline', onClick: () => handleGetAttachment(attachment) },
                            React.createElement(Card, { color: 'WHITE' },
                                React.createElement(Icon, { icon: attachment.internal ? FileText : LLink, className: 'size-6' })),
                            React.createElement(FormLabel, { labelText: attachment.title, helperText: error })),
                        onRemoveAttachment && React.createElement(Icon, { icon: X, className: 'cursor-pointer p-1', onClick: onRemoveAttachment, confirmTextHeader: 'Remove document', confirmTextBody: 'Are you sure you want to remove this document? This action cannot be undone.' }))) }));
    }
};
