import React from 'react';
import { SurveyElectricsContext } from '../electrics';
import { ClickableCard } from '../../../../components/content_display/card';
import { Input } from '../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../components/inputs_and_selections/text_area';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
export const ElectricsDirectLoadsBlock = () => {
    var _a;
    const electricsContext = React.useContext(SurveyElectricsContext);
    const { survey, setSurvey } = electricsContext;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Direct loads"),
        React.createElement("div", { className: "flex-col gap-5 flex" },
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement("div", { className: "gap-3 flex items-center justify-between" },
                        React.createElement("div", { className: "flex-col flex" },
                            React.createElement("div", { className: "text-gray-900 font-bold" }, "Electric car charger"),
                            React.createElement("div", { className: "text-gray-500 text-sm" }, "The property has an electric car charger fitted.")),
                        React.createElement(Toggle, { value: survey.electrics_has_car_charger, setValue: () => setSurvey({
                                ...survey,
                                electrics_has_car_charger: !survey.electrics_has_car_charger
                            }) })),
                    survey.electrics_has_car_charger && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'flex flex-col gap-2' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Charger fuse size"),
                            React.createElement(Input, { type: 'number', value: (_a = survey.electrics_car_charger_fuse_size_amps.toString()) !== null && _a !== void 0 ? _a : '', setValue: (e) => setSurvey({ ...survey, electrics_car_charger_fuse_size_amps: Number(e) }), postfix: 'amps' })),
                        React.createElement("div", { className: 'flex gap-2 justify-between' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Load limiting device"),
                            React.createElement(Toggle, { value: survey.electrics_car_charger_is_load_limiting, setValue: () => setSurvey({
                                    ...survey,
                                    electrics_car_charger_is_load_limiting: !survey.electrics_car_charger_is_load_limiting
                                }) }))))),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement("div", { className: "gap-3 flex items-center justify-between" },
                        React.createElement("div", { className: "flex-col flex" },
                            React.createElement("div", { className: "text-gray-900 font-bold" }, "Battery"),
                            React.createElement("div", { className: "text-gray-500 text-sm" }, "A home battery is installed")),
                        React.createElement(Toggle, { value: survey.electrics_has_battery, setValue: () => setSurvey({ ...survey, electrics_has_battery: !survey.electrics_has_battery }) })),
                    survey.electrics_has_battery && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'flex flex-col gap-2' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Notes"),
                            React.createElement(TextArea, { value: survey.electrics_battery_notes, setValue: (e) => setSurvey({ ...survey, electrics_battery_notes: e }) }))))),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-5' },
                    React.createElement("div", { className: "gap-3 flex items-center justify-between" },
                        React.createElement("div", { className: "flex-col flex" },
                            React.createElement("div", { className: "text-gray-900 font-bold" }, "Photovoltaic cell (PV)"),
                            React.createElement("div", { className: "text-gray-500 text-sm" }, "Solar PV is installed at the property.")),
                        React.createElement(Toggle, { value: survey.electrics_has_solar, setValue: () => setSurvey({ ...survey, electrics_has_solar: !survey.electrics_has_solar }) })),
                    survey.electrics_has_solar && React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'flex flex-col gap-2' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Notes"),
                            React.createElement(TextArea, { value: survey.electrics_solar_notes, setValue: (e) => setSurvey({ ...survey, electrics_solar_notes: e }) })),
                        React.createElement("div", { className: 'flex justify-between items-center gap-2' },
                            React.createElement("div", { className: 'flex flex-col' },
                                React.createElement("div", { className: 'font-bold text-gray-900' }, "Solar diverter"),
                                React.createElement("div", { className: 'text-gray-500 text-sm' }, "The customer would like a solar diverter installed.")),
                            React.createElement(Toggle, { value: survey.electrics_solar_wants_diverter, setValue: () => setSurvey({
                                    ...survey,
                                    electrics_solar_wants_diverter: !survey.electrics_solar_wants_diverter
                                }) }))))),
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: "justify-between items-center gap-3 flex" },
                    React.createElement("div", { className: "flex-col flex" },
                        React.createElement("div", { className: "text-gray-900 font-bold" }, "Hot tub")),
                    React.createElement(Toggle, { value: survey.electrics_has_hot_tub, setValue: () => setSurvey({ ...survey, electrics_has_hot_tub: !survey.electrics_has_hot_tub }) })))));
};
