import React from 'react';
import { Icon } from '../buttons/icon';
import { ChevronLeft } from 'lucide-react';
export const BottomSheetHeader = ({ title, goBack, children, onBack }) => {
    return React.createElement("div", { className: 'flex flex-col gap-2' },
        React.createElement("div", { className: 'flex justify-between items-center' },
            React.createElement(Icon, { onClick: () => {
                    goBack && goBack();
                    onBack === null || onBack === void 0 ? void 0 : onBack();
                }, icon: ChevronLeft }),
            React.createElement("div", { className: 'font-bold text-gray-900' }, title),
            React.createElement("div", null)),
        children);
};
