import React from 'react';
import { TextArea } from '../../components/inputs_and_selections/text_area';
import { Input } from '../../components/inputs_and_selections/input';
import { tryParseFloat } from '../../code/helpers';
import { Button } from '../../components/buttons/button';
export const SecondaryHeatingPage = ({ secondaryHeating, setSecondaryHeating, onSave }) => {
    var _a;
    if (!secondaryHeating)
        return;
    return React.createElement("div", { className: "p-5 bg-white flex-col gap-5 flex" },
        React.createElement("div", { className: "flex-col gap-1 flex" },
            React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Name of heat source"),
            React.createElement(Input, { value: secondaryHeating.name, setValue: (e) => setSecondaryHeating(prev => ({ ...prev, name: e })) })),
        React.createElement("div", { className: "flex-col gap-1 flex" },
            React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Estimated % of room's heating load covered:"),
            React.createElement(Input, { postfix: '%', value: (_a = secondaryHeating.room_heating_load_covered_percentage) !== null && _a !== void 0 ? _a : '', setValue: (e) => setSecondaryHeating(prev => ({ ...prev, room_heating_load_covered_percentage: tryParseFloat(e, undefined) })) })),
        React.createElement("div", { className: "flex-col gap-1 flex" },
            React.createElement("div", { className: "text-gray-900 text-base font-bold" }, "Notes"),
            React.createElement(TextArea, { value: secondaryHeating.notes, setValue: (e) => setSecondaryHeating(prev => ({ ...prev, notes: e })) })),
        React.createElement(Button, { disabled: !secondaryHeating.name || !secondaryHeating.room_heating_load_covered_percentage, onClick: onSave }, "Apply"));
};
