import React from 'react';
import { SettingsLayout } from './components/settings_layout';
import { Section } from '../../../components/containers/section';
import { VerticalFormGroup } from '../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../components/inputs_and_selections/form_label';
import { Input } from '../../../components/inputs_and_selections/input';
import { validateEmail } from '../../../code/validators';
import { Text } from '../../../components/content_display/text';
import { Info } from '../../../components/buttons/info';
export const PersonalAccount = ({ user, setUser }) => {
    return React.createElement(SettingsLayout, { heading: 'Personal details', description: 'Manage your Spruce account' },
        React.createElement(Section, { border: true, title: "Personal details" },
            React.createElement("div", { className: 'grid grid-cols-1 gap-4' },
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'First name' }), input: React.createElement(Input, { value: user.first_name, setValue: (value) => setUser({
                            ...user,
                            first_name: value
                        }) }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Last name' }), input: React.createElement(Input, { value: user.last_name, setValue: (value) => setUser({
                            ...user,
                            last_name: value
                        }) }) }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Email address', info: React.createElement(Info, { infoModalHeader: 'Email address', infoModalBody: React.createElement(Text, { size: "MD" },
                                "Please get in touch with Spruce support at ",
                                React.createElement("a", { href: "mailto:support@spruce.eco", className: 'font-bold text-sky-700 underline' }, "support@spruce.eco"),
                                " if you need to change your login email address.") }) }), input: React.createElement(Input, { disabled: true, value: user.email, setValue: (value) => {
                            if (validateEmail(value).value) {
                                setUser({
                                    ...user,
                                    email: value
                                });
                            }
                        }, validator: validateEmail, validateImmediately: true }) }))));
};
