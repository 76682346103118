import React from 'react';
import { Accordion } from '../../../components/content_display/accordion';
export const AccordionDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement(Accordion, { rows: [
                    { title: 'Row 1', body: 'Hello world 1' },
                    { title: 'Row 2', body: 'Hello world 2' },
                    { title: 'Row 3', body: 'Hello world 3' }
                ] })));
};
const componentProps = [
    { name: 'rows', description: 'An array of items that should appear in the accordion' },
    { name: 'title', description: 'Title of specific row' },
    { name: 'body', description: 'Body of specific row, hidden if accordion item is closed' }
];
const codeBlock = `<Accordion
  rows={[
    { title: 'Row 1', body: 'Hello world 1' },
    { title: 'Row 2', body: 'Hello world 2' },
    { title: 'Row 3', body: 'Hello world 3' }
  ]}
/>`;
