import React from 'react';
import { getRoomWatts } from '../../../code/models/heat_loss';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { TableLite } from '../../../components/content_display/table_lite';
import { getEmitterSizeName, getEmitterTypeName, getFullEmittersListByStatus } from '../../../code/models/radiator';
import { HLRContext } from './heatloss_report';
import { HLRSubheaderBlock } from './partials/hlr_subheader';
import { RadiatorIcon, UFHIcon } from '../../../assets/images/survey_images/survey_images';
import { numberFormat } from '../../../code/number_format';
import { getEmitterWatts } from '../../../code/models/radiator_model';
import { chain, sum, uniq } from 'lodash';
export const HLREmittersProposed = () => {
    const hlrContext = React.useContext(HLRContext);
    const fullRadiatorsList = getFullEmittersListByStatus(hlrContext.survey, hlrContext.design);
    const emitters = hlrContext.survey.floors.flatMap(floor => {
        const roomGroups = chain(floor.rooms)
            .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
            .map((values, key) => ({ key, values }))
            .value();
        return roomGroups.flatMap(roomGroup => {
            const roomGroupRoomWatts = sum(roomGroup.values.map(r => getRoomWatts(r, floor.rooms, hlrContext.designTempC, hlrContext.groundTempC, hlrContext.survey)));
            const isMultiRoomGroup = roomGroup.values.length > 1;
            const name = isMultiRoomGroup
                ? roomGroup.values.map(r => r.name).join(' + ')
                : roomGroup.values[0].name;
            const roomGroupRows = roomGroup.values.flatMap((room, idx) => {
                const roomRads = fullRadiatorsList
                    .filter(x => x.room.uuid === room.uuid)
                    .sort((a, b) => a.status === 'REMOVED' ? 1 : b.status === 'REMOVED' ? -1 : 0);
                return roomRads.map((r, index) => {
                    var _a;
                    const realRad = (_a = r.replacedBy) !== null && _a !== void 0 ? _a : r.radiator;
                    let badge = React.createElement(Badge, { color: 'YELLOW', text: 'Keep' });
                    let image = realRad.emitter_type === 'UNDERFLOOR'
                        ? React.createElement("img", { alt: '', src: UFHIcon, className: "p-2 w-10 h-10 rounded bg-gray-100" })
                        : React.createElement("img", { alt: '', src: RadiatorIcon, className: "p-2 w-10 h-10 rounded bg-gray-100" });
                    // try to load image for surveyed radiator
                    if (realRad.survey_or_design === 'SURVEY') {
                        const images = realRad.photos.map(x => hlrContext.files.find(y => y.uuid === x.image_uuid));
                        image = images[0] ? React.createElement("img", { src: images[0].file_base64 || `${process.env.S3_BUCKET_URL}/${images[0].file_url}`, alt: '', className: 'w-10 h-10 rounded' }) : image;
                    }
                    switch (r.status) {
                        case 'REMOVED':
                            badge = React.createElement(Badge, { color: 'RED', text: 'Remove' });
                            break;
                        case 'REPLACED':
                            badge = React.createElement(Badge, { color: 'INDIGO', text: 'Replacement' });
                            // if radiators is replaced, show the replacement
                            break;
                        case 'ADDED':
                            badge = React.createElement(Badge, { color: 'BLUE', text: 'New' });
                            break;
                    }
                    return {
                        firstRadInList: index === 0,
                        firstRoomInGroupList: idx === 0,
                        roomName: name,
                        status: r.status,
                        badge,
                        image,
                        type: realRad.emitter_type === 'UNDERFLOOR' ? 'UFH' : realRad.emitter_type === 'SECONDARY' ? 'Secondary' : 'Radiator',
                        details: React.createElement("div", { className: 'flex flex-col gap-1' },
                            React.createElement("div", null, getEmitterTypeName(realRad)),
                            React.createElement("div", null, getEmitterSizeName(realRad))),
                        emitterOutput: Math.round(getEmitterWatts(realRad, room, hlrContext.design, hlrContext.survey, hlrContext.designTempC, hlrContext.groundTempC)),
                        roomDemand: Math.round(roomGroupRoomWatts),
                        roomOutput: 0, // calculated below as sum of all emitterOutput
                        percentMet: 0, // calculated below based on roomOutput and roomHeatLoss
                        isUndersized: hlrContext.design.undersized_emitter_rooms.some(x => x.room_uuid === room.uuid || x.room_uuid === room.room_group_uuid)
                    };
                });
            });
            // calc roomOutput
            const roomOutput = roomGroupRows.reduce((acc, radiator) => acc + (radiator.status !== 'REMOVED' ? radiator.emitterOutput : 0), 0);
            // return radiators with roomOutput updated
            return roomGroupRows.map(radiator => {
                return {
                    ...radiator,
                    roomOutput,
                    percentMet: Math.round((roomOutput / roomGroupRoomWatts) * 100)
                };
            });
        });
    }).flat();
    const rooms = hlrContext.survey.floors.flatMap(x => x.rooms);
    const roomGroups = uniq(rooms.map(x => x.room_group_uuid));
    const undersizedRooms = hlrContext.design.undersized_emitter_rooms.filter(x => rooms.some(y => y.uuid === x.room_uuid) || roomGroups.includes(x.room_uuid));
    const emittersTableColumns = [
        {
            name: 'room',
            key: 'roomName',
            render: (row) => {
                // render only if the room name renders first time in the list
                if (row.firstRadInList && row.firstRoomInGroupList) {
                    return React.createElement("span", { className: 'text-gray-900 text-xs' }, row.roomName);
                }
                else {
                    return React.createElement("span", null);
                }
            }
        },
        { name: 'Status', key: 'status', render: (row) => React.createElement("div", { className: 'flex justify-end' }, row.badge) },
        { name: 'Image', key: 'image', render: (row) => React.createElement("div", { className: 'flex justify-end' }, row.image) },
        { name: 'Type', key: 'type', render: (row) => React.createElement("span", { className: row.status === 'REMOVED' ? 'text-xs line-through ' : 'text-xs' }, row.type) },
        { name: 'Details', key: 'details', render: (row) => React.createElement("span", { className: row.status === 'REMOVED' ? 'text-xs line-through ' : 'text-xs' }, row.details) },
        { name: 'Output', key: 'emitterOutput', render: (row) => React.createElement("span", { className: row.status === 'REMOVED' ? 'text-xs line-through ' : 'text-xs' },
                row.emitterOutput,
                " W") },
        {
            name: 'Room demand',
            key: 'roomDemand',
            render: (row) => {
                if (row.firstRadInList && row.firstRoomInGroupList) {
                    return React.createElement("span", { className: 'text-xs' },
                        row.roomDemand,
                        " W");
                }
                else {
                    return React.createElement("span", null);
                }
            }
        },
        {
            name: '% heat demand met*',
            key: 'percentMet',
            render: (row) => {
                if (row.firstRadInList && row.firstRoomInGroupList) {
                    return React.createElement("div", { className: 'flex justify-end' },
                        React.createElement(Badge, { color: row.percentMet >= 100 ? 'GREEN' : row.isUndersized ? 'YELLOW' : 'RED', text: row.percentMet.toString() + ' %' + (row.isUndersized ? '**' : '') }));
                }
                else {
                    return React.createElement("span", null);
                }
            }
        }
    ];
    return React.createElement("div", { className: 'flex-col gap-6 flex' },
        React.createElement(HLRSubheaderBlock, { section: "System design", title: "Proposed emitter changes" }),
        React.createElement(TableLite, { size: 'SM', rows: emitters, columns: emittersTableColumns }),
        React.createElement("div", { className: "" },
            React.createElement("span", { className: "text-gray-900 text-xs font-bold" }, "*% Heat demand met: "),
            React.createElement("span", { className: "text-xs tracking-tight" },
                "This is calculated for a day when the outdoor temperature is ",
                numberFormat(1).format(hlrContext.designTempC),
                " \u00B0C and the flow temperature is ",
                numberFormat(1).format(hlrContext.design.flow_temp),
                " \u00B0C")),
        undersizedRooms.length > 0 && React.createElement("div", { className: 'flex flex-col gap-4 text-xs' },
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "** Accepting undersized emitters"),
                React.createElement("div", null, "Please note that the emitters do not meet the room heat demand in all cases. This means that when its cold outside the following rooms will not reach their set point temperature. If you choose to go ahead, you are acknowledging that you are happy with the above. If that doesn't sound right please contact us before proceeding with the install.")),
            React.createElement("div", { className: 'flex flex-col gap-1' }, undersizedRooms.map(x => {
                var _a;
                return React.createElement("div", { key: x.room_uuid, className: 'flex gap-2' },
                    React.createElement("div", { className: 'font-bold text-gray-900' }, roomGroups.includes(x.room_uuid)
                        ? rooms.filter(y => y.room_group_uuid === x.room_uuid).map(y => y.name).join(', ')
                        : (_a = rooms.find(y => y.uuid === x.room_uuid)) === null || _a === void 0 ? void 0 : _a.name),
                    React.createElement("div", null, x.notes));
            }))));
};
