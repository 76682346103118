import React from 'react';
import { Button } from '../../../../components/buttons/button';
import { Input } from '../../../../components/inputs_and_selections/input';
import { validateInMm, validateIsPositiveNumber, validateNotNull } from '../../../../code/validators';
export const AddCustomRadiatorPage = ({ tempCustomRadiatorModel, setTempCustomRadiatorModel, setSelectedCustomRadiatorModel, setCustomRadiatorModels, onBack }) => {
    return React.createElement("div", { className: "flex flex-col" },
        React.createElement("div", { className: 'flex flex-col p-5 gap-5 overflow-y-auto' },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Type"),
                React.createElement(Input, { value: tempCustomRadiatorModel.type, setValue: (e) => setTempCustomRadiatorModel(prev => ({ ...prev, type: e })), validator: validateNotNull })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Height"),
                React.createElement(Input, { type: 'number', postfix: 'mm', step: 100, value: tempCustomRadiatorModel.height_mm.toString(), setValue: (e) => setTempCustomRadiatorModel(prev => ({ ...prev, height_mm: parseFloat(e) })), validator: validateInMm })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Width"),
                React.createElement(Input, { type: 'number', postfix: 'mm', step: 100, value: tempCustomRadiatorModel.width_mm.toString(), setValue: (e) => setTempCustomRadiatorModel(prev => ({ ...prev, width_mm: parseFloat(e) })), validator: validateInMm })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Nominal output at delta T 50\u00B0C"),
                React.createElement(Input, { type: 'number', postfix: 'W', step: 1, value: tempCustomRadiatorModel.output_capacity_w_at_50_c.toString(), setValue: (e) => setTempCustomRadiatorModel(prev => ({ ...prev, output_capacity_w_at_50_c: parseFloat(e) })), validator: (e) => validateIsPositiveNumber(e) }))),
        React.createElement("div", { className: 'p-4 bg-white' },
            React.createElement(Button, { disabled: !validateNotNull(tempCustomRadiatorModel.type).value ||
                    !validateInMm(tempCustomRadiatorModel.height_mm.toString()).value ||
                    !validateInMm(tempCustomRadiatorModel.width_mm.toString()).value ||
                    !validateIsPositiveNumber(tempCustomRadiatorModel.output_capacity_w_at_50_c.toString()).value, onClick: () => {
                    var _a;
                    const selectedCustomRadiatorModel = {
                        ...tempCustomRadiatorModel,
                        uuid: (_a = tempCustomRadiatorModel.uuid) !== null && _a !== void 0 ? _a : crypto.randomUUID()
                    };
                    setSelectedCustomRadiatorModel(selectedCustomRadiatorModel);
                    setCustomRadiatorModels(prev => {
                        return tempCustomRadiatorModel.uuid // temp model only has uuid if editing
                            ? prev.map(x => x.uuid === selectedCustomRadiatorModel.uuid ? selectedCustomRadiatorModel : x) // editing
                            : [...prev, selectedCustomRadiatorModel]; // add new
                    });
                    setTempCustomRadiatorModel({ ...tempCustomRadiatorModel, uuid: undefined }); // clear uuid of temp model but keep other characteristics so user can add another similar model
                    onBack();
                } }, "Apply")));
};
