import React, { useEffect, useState } from 'react';
import { Loader } from '../components/indicators_and_messaging/loader';
import { getQuotePDFAPI, getQuotePublicAPI, setQuoteOpenedAPI } from '../code/models/proposal';
import { Button } from '../components/buttons/button';
import { Heading } from '../components/content_display/heading';
import { Text } from '../components/content_display/text';
import { CalculatedQuoteDefaultGroups, quoteItemNameWithQuantity } from '../code/calculate_quote';
import { formatPrice } from '../code/format_price';
import { groupBy } from 'lodash';
import { Badge } from '../components/indicators_and_messaging/badge';
import { CylinderIcon2, HeatPump } from '../assets/images/survey_images/survey_images';
import { PrintLayout, PrintLayoutPageBreak } from './components/print_layout';
import { downloadBlob } from '../code/helpers';
import { renderHTMLReplacingPlaceholders } from '../code/tiptap_placeholders';
import { FileText } from 'lucide-react';
import { createJobEvent, JobEventType } from '../code/models/job_event';
const PrimaryQuoteItemRow = ({ item, subtotal, showImage = false, fallbackImage }) => {
    return (React.createElement("div", { className: `grid gap-2 py-5 ${showImage ? 'grid-cols-[56px_1fr]' : 'grid-cols-1'}`, key: item.uuid },
        showImage &&
            React.createElement("img", { src: item.image_url || fallbackImage, alt: item.name, className: 'w-12 h-12 object-contain', onError: ({ currentTarget }) => {
                    currentTarget.onerror = null;
                    if (fallbackImage) {
                        currentTarget.src = fallbackImage;
                    }
                    else {
                        // Just remove the image if there's no fallback
                        currentTarget.remove();
                    }
                } }),
        React.createElement("div", { className: 'flex gap-2' },
            React.createElement("div", { className: 'flex flex-col gap-1' },
                React.createElement(Text, { size: 'SM', bold: true }, item.name),
                React.createElement(Text, { size: 'XS', className: 'text-gray-500' }, item.description)),
            typeof subtotal === 'number' ? React.createElement("div", { className: 'ml-auto text-right' },
                React.createElement(Text, { size: 'SM' }, formatPrice(subtotal, 2))) : null)));
};
const QuoteItemGroupRow = ({ groupName, items, subtotal, postfix }) => {
    return (React.createElement("div", { className: 'py-5 flex flex-col gap-3' },
        React.createElement("div", { className: 'flex flex-row items-center justify-between' },
            React.createElement(Text, { size: "SM", bold: true }, groupName),
            typeof subtotal === 'number' && React.createElement(Text, { size: "SM" }, formatPrice(subtotal, 2))),
        React.createElement("div", { className: 'flex flex-col gap-2' }, items.map(x => (React.createElement("div", { className: "ml-6", key: x.uuid },
            React.createElement(Text, { size: "XS", bold: true }, quoteItemNameWithQuantity(x, postfix)),
            x.description && React.createElement(Text, { size: "XS", className: "text-gray-500" }, x.description)))))));
};
export const QuotePublicPage = (props) => {
    // If we're passing in a live snapshot, this page is being embedded somewhere in the dashboard,
    // so we fill the rest of the snapshot data with defaults
    const [quote, setQuote] = useState(props.snapshot ? {
        snapshot: props.snapshot,
        recipients: [],
        email_text: ''
    } : undefined);
    const isInstallerClick = new URLSearchParams(window.location.search).get('installer');
    useEffect(() => {
        const asyncCall = async () => {
            if (!props.quoteUUID)
                return;
            const result = await getQuotePublicAPI(props.quoteUUID, props.companyPublicInfo.uuid);
            if (result) {
                setQuote(result);
            }
            if (!isInstallerClick && (result === null || result === void 0 ? void 0 : result.is_opened) === false) {
                await setQuoteOpenedAPI(props.quoteUUID, props.companyPublicInfo.uuid);
                createJobEvent({
                    event_type: JobEventType.enum.QuoteOpened,
                    company_uuid: props.companyPublicInfo.uuid,
                    job_uuid: result.lead_uuid,
                    extra_data: {
                        quote_uuid: props.quoteUUID
                    }
                });
            }
        };
        if (props.quoteUUID) {
            asyncCall();
        }
    }, []);
    if (!quote) {
        return (React.createElement("div", { className: "flex flex-col items-center justify-center mt-10 mx-auto" },
            React.createElement(Loader, null)));
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'hidden print:block' },
            React.createElement(QuotePublicPageInner, { quoteUUID: props.quoteUUID, companyPublicInfo: props.companyPublicInfo, snapshot: quote.snapshot })),
        React.createElement("div", { className: 'print:hidden bg-gray-100 lg:p-6 md:p-4 p-0' },
            React.createElement("div", { className: 'bg-white max-w-[260mm] m-auto pb-6 rounded' },
                React.createElement(QuotePublicPageInner, { quoteUUID: props.quoteUUID, companyPublicInfo: props.companyPublicInfo, snapshot: quote.snapshot }))),
        React.createElement("div", { id: 'pdf-ready' }));
};
export const QuotePublicPageInner = (props) => {
    const logoImageData = props.companyPublicInfo.logo;
    return React.createElement(PrintLayout, { headerHeightPx: 120, header: React.createElement(React.Fragment, null,
            React.createElement("div", { className: [
                    'w-full bg-white border-b border-b-gray-200 justify-between items-center gap-6 flex px-1 py-1',
                    'print:p-6', // print
                    'sm:px-3 sm:py-3 ', // sm
                    'md:px-6 md:py-6 ', // md
                    'lg:top-0 ' // lg
                ].join(' ') },
                React.createElement("div", { className: 'flex flex-row gap-4' }, logoImageData && React.createElement("img", { src: logoImageData, className: "max-h-10 md:max-h-12", alt: "Installer logo" })),
                React.createElement("div", { className: 'flex flex-row gap-4 print:hidden' }, props.quoteUUID && React.createElement(Button, { colour: 'LIGHT', iconLeft: FileText, onClick: async () => {
                        const pdfData = await getQuotePDFAPI(props.quoteUUID, props.companyPublicInfo.uuid);
                        downloadBlob(pdfData, 'Heat Pump Installation Quote.pdf');
                    } },
                    React.createElement("span", { className: 'hidden sm:inline' }, "Save to PDF"))))), content: React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'px-3 md:px-6 print:px-0' },
                React.createElement(PrintLayoutPageBreak, null),
                React.createElement(Heading, { size: '3xl', className: 'pb-10' }, "Your heat pump quote"),
                React.createElement(PublicQuoteBlock, { quoteSnapshot: props.snapshot }),
                React.createElement(PublicQuotePaymentScheduleBlock, { quoteSnapshot: props.snapshot }))) });
};
export const PublicQuoteBlock = ({ quoteSnapshot }) => {
    var _a, _b, _c;
    const groupedQuote = groupBy(quoteSnapshot.quote_items, 'group_name');
    const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total + quoteSnapshot.bus_grant;
    return React.createElement("div", { className: "flex flex-col divide-y divide-gray-200 border-y border-gray-200" }, (_a = groupedQuote[CalculatedQuoteDefaultGroups.HEAT_PUMPS]) === null || _a === void 0 ? void 0 :
        _a.map(x => React.createElement(PrimaryQuoteItemRow, { key: x.uuid, item: x, subtotal: x.subtotal, showImage: true, fallbackImage: HeatPump })), (_b = groupedQuote[CalculatedQuoteDefaultGroups.HOT_WATER_CYLINDERS]) === null || _b === void 0 ? void 0 :
        _b.map(x => React.createElement(PrimaryQuoteItemRow, { key: x.uuid, item: x, subtotal: x.subtotal, showImage: true, fallbackImage: CylinderIcon2 })),
        groupedQuote[CalculatedQuoteDefaultGroups.PARTS] &&
            React.createElement(QuoteItemGroupRow, { groupName: "Parts & fittings", items: groupedQuote[CalculatedQuoteDefaultGroups.PARTS], subtotal: quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.PARTS] }),
        groupedQuote[CalculatedQuoteDefaultGroups.RADIATORS] &&
            React.createElement(QuoteItemGroupRow, { groupName: "New radiators", items: groupedQuote[CalculatedQuoteDefaultGroups.RADIATORS], subtotal: quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.RADIATORS] }),
        groupedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR] &&
            React.createElement(QuoteItemGroupRow, { groupName: "Underfloor heating", items: groupedQuote[CalculatedQuoteDefaultGroups.UNDERFLOOR], subtotal: quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.UNDERFLOOR], postfix: 'm\u00B2' }),
        groupedQuote[CalculatedQuoteDefaultGroups.LABOUR] &&
            React.createElement(QuoteItemGroupRow, { groupName: "Labour", items: groupedQuote[CalculatedQuoteDefaultGroups.LABOUR], subtotal: quoteSnapshot.group_totals[CalculatedQuoteDefaultGroups.LABOUR], postfix: 'days' }), (_c = groupedQuote[CalculatedQuoteDefaultGroups.SURVEY]) === null || _c === void 0 ? void 0 :
        _c.map(x => React.createElement(PrimaryQuoteItemRow, { key: x.uuid, item: x, subtotal: x.subtotal })),
        React.createElement("div", { className: 'py-5 flex flex-row justify-end' },
            React.createElement("div", { className: 'grid grid-cols-[auto_1fr] gap-2' },
                React.createElement(Text, { size: "XS", bold: true }, quoteSnapshot.bus_grant ? 'Total before grant (excl. VAT)' : 'Total (excl. VAT)'),
                React.createElement("div", { className: "ml-2 text-right" },
                    React.createElement(Text, { size: "XS" }, formatPrice(quoteSnapshot.total_pre_deductions, 2))),
                quoteSnapshot.discount_total < 0 && React.createElement(React.Fragment, null,
                    React.createElement(Text, { size: "XS", bold: true }, "Discount"),
                    React.createElement("div", { className: "ml-2 text-right" },
                        React.createElement(Text, { size: "XS" }, formatPrice(quoteSnapshot.discount_total, 2)))),
                quoteSnapshot.vat_on_all > 0
                    ? React.createElement(React.Fragment, null,
                        React.createElement(Text, { size: "XS", bold: true }, "VAT (20%)"),
                        React.createElement("div", { className: "ml-2 text-right" },
                            React.createElement(Text, { size: "XS" }, formatPrice(quoteSnapshot.vat_on_all, 2))))
                    : quoteSnapshot.vat_on_survey > 0 && React.createElement(React.Fragment, null,
                        React.createElement(Text, { size: "XS", bold: true }, "VAT (20%) - Survey"),
                        React.createElement("div", { className: "ml-2 text-right" },
                            React.createElement(Text, { size: "XS" }, formatPrice(quoteSnapshot.vat_on_survey, 2))),
                        React.createElement(Text, { size: "XS", bold: true }, "VAT (0%) - Installation"),
                        React.createElement("div", { className: "ml-2 text-right" },
                            React.createElement(Text, { size: "XS" }, "\u00A30.00"))),
                (quoteSnapshot.vat_on_survey === 0 && quoteSnapshot.vat_on_all === 0) && React.createElement(React.Fragment, null,
                    React.createElement(Text, { size: "XS", bold: true }, "VAT (0%)"),
                    React.createElement("div", { className: "ml-2 text-right" },
                        React.createElement(Text, { size: "XS" }, "\u00A30.00"))),
                quoteSnapshot.bus_grant < 0 && React.createElement(React.Fragment, null,
                    React.createElement(Text, { size: "XS", bold: true }, "Boiler Upgrade Scheme (BUS) Grant"),
                    React.createElement("div", { className: "ml-2 text-right" },
                        React.createElement(Text, { size: "XS" }, formatPrice(quoteSnapshot.bus_grant, 2)))),
                React.createElement(Text, { size: "SM", bold: true }, "Total"),
                React.createElement("div", { className: "ml-2 text-right" },
                    React.createElement(Text, { size: "SM", bold: true }, formatPrice(total, 2))))),
        groupedQuote['Optional items'] && (React.createElement("div", { className: 'py-5 flex flex-col gap-3' },
            React.createElement(Text, { size: "SM", bold: true }, "Optional items"),
            React.createElement("div", { className: 'flex flex-col gap-2' }, groupedQuote['Optional items'].map(x => (React.createElement("div", { className: "ml-6", key: x.uuid },
                React.createElement("div", { className: 'flex flex-row items-center justify-between' },
                    React.createElement("div", { className: 'flex flex-col gap-1' },
                        React.createElement(Text, { size: "XS", bold: true }, quoteItemNameWithQuantity(x)),
                        x.description && React.createElement(Text, { size: "XS", className: "text-gray-500" }, x.description)),
                    x.subtotal && (React.createElement("div", { className: 'text-right ml-auto flex gap-2' },
                        React.createElement(Badge, { color: 'INDIGO', text: 'Optional' }),
                        React.createElement(Text, { size: "SM" }, formatPrice(x.subtotal, 2))))))))))),
        quoteSnapshot.additional_notes && (React.createElement("div", { className: 'py-5 space-y-2', dangerouslySetInnerHTML: { __html: renderHTMLReplacingPlaceholders(JSON.parse(quoteSnapshot.additional_notes), {}, 'text-xs', 'text-sm text-bold font-bold') } })));
};
export const PublicQuotePaymentScheduleBlock = ({ quoteSnapshot }) => {
    // Payment schedule total is pre-grant
    const total = quoteSnapshot.total_pre_deductions + quoteSnapshot.vat_on_all + quoteSnapshot.vat_on_survey + quoteSnapshot.discount_total;
    return React.createElement(React.Fragment, null, (quoteSnapshot.payment_schedule && quoteSnapshot.payment_schedule.length > 0) && (React.createElement(React.Fragment, null,
        React.createElement(PrintLayoutPageBreak, null),
        React.createElement(Heading, { size: '3xl', className: 'py-10' }, "Payment schedule"),
        React.createElement("div", { className: "flex flex-col divide-y divide-gray-200 border-y border-gray-200" }, quoteSnapshot.payment_schedule.map((x, i) => {
            var _a;
            return (React.createElement("div", { key: i, className: "flex flex-row items-start py-5 gap-2" },
                React.createElement("div", null,
                    React.createElement(Badge, { color: "LIGHT", text: `${i + 1}/${(_a = quoteSnapshot.payment_schedule) === null || _a === void 0 ? void 0 : _a.length}` })),
                React.createElement("div", null,
                    React.createElement(Text, { size: "SM", bold: true }, x.name),
                    x.due_date && React.createElement(Text, { size: "XS", className: "text-gray-500" }, new Date(x.due_date).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })),
                    x.description && React.createElement("div", { className: 'border-l-2 border-gray-300 pl-2 mt-2' },
                        React.createElement(Text, { size: "XS", className: 'text-gray-600' }, x.description))),
                React.createElement("div", { className: "ml-auto text-right" },
                    React.createElement(Text, { size: "SM", bold: true }, formatPrice(x.percentage / 100 * total, 2)))));
        })))));
};
