import { Badge } from '../../components/indicators_and_messaging/badge';
import { ClickableCard } from '../../components/content_display/card';
import React from 'react';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp } from '../../code/models/range_heat_pump';
import { FormLabel } from '../../components/inputs_and_selections/form_label';
export const SelectedHeatPump = ({ currentHeatPump, soundCalculation, variant, designTempC, design, scop, totalHeatLossKW, setCurrentPage }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const heatPumpCapacityResult = variant === 'DESIGN' ? getHeatPumpCapacityAtOutsideTempAndFlowTemp(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, designTempC, design.flow_temp) : undefined;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex-col gap-3 flex" },
            React.createElement(FormLabel, { labelText: 'Heat pump model', required: true, complete: currentHeatPump !== undefined, helperText: variant === 'DESIGN'
                    ? 'Update the choice of heat pump below to ensure it matches the heat loss, fits the available space and meets the sound requirements.'
                    : 'Pre-filled from the estimate to help you check there is sufficient space.' }),
            !currentHeatPump && React.createElement(ClickableCard, { onClick: () => setCurrentPage('SELECT_HEAT_PUMP'), variant: 'PLACEHOLDER' }, "Please select a heat pump"),
            currentHeatPump &&
                React.createElement(ClickableCard, { onClick: () => setCurrentPage('SELECT_HEAT_PUMP'), variant: 'WHITE', title: currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.name },
                    React.createElement("div", { className: "flex-col gap-2 flex" },
                        React.createElement("div", { className: "flex-col flex text-xs font-semibold" },
                            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                React.createElement("div", null, "Sound power level"),
                                React.createElement("div", null, (_b = (_a = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _a === void 0 ? void 0 : _a.sound_power_max_dba) !== null && _b !== void 0 ? _b : 0,
                                    " dB(A)")),
                            variant === 'DESIGN' && React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                React.createElement("div", { className: "text-gray-600 text-xs font-semibold" }, "Sound assessment result"),
                                React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" },
                                    React.createElement(Badge, { color: soundCalculation === 0 ? 'YELLOW' : soundCalculation <= 42 ? 'GREEN' : 'RED', text: `${soundCalculation} dB(A)` }))),
                            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                React.createElement("div", null, "Refrigerant"),
                                React.createElement("div", null, (_d = (_c = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _c === void 0 ? void 0 : _c.refrigerant) !== null && _d !== void 0 ? _d : 'N/A')),
                            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                React.createElement("div", null, "Volume"),
                                React.createElement("div", null,
                                    ((((_f = (_e = currentHeatPump.range_heat_pump) === null || _e === void 0 ? void 0 : _e.width_mm) !== null && _f !== void 0 ? _f : 0) * ((_h = (_g = currentHeatPump.range_heat_pump) === null || _g === void 0 ? void 0 : _g.height_mm) !== null && _h !== void 0 ? _h : 0) * ((_k = (_j = currentHeatPump.range_heat_pump) === null || _j === void 0 ? void 0 : _j.depth_mm) !== null && _k !== void 0 ? _k : 0)) / Math.pow(10, 9)).toFixed(2),
                                    " m\u00B3")),
                            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                React.createElement("div", null, "Dimensions (WxHxD)"),
                                React.createElement("div", null, (_m = (_l = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _l === void 0 ? void 0 : _l.width_mm) !== null && _m !== void 0 ? _m : 0,
                                    " x ", (_p = (_o = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _o === void 0 ? void 0 : _o.height_mm) !== null && _p !== void 0 ? _p : 0,
                                    " x ", (_r = (_q = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _q === void 0 ? void 0 : _q.depth_mm) !== null && _r !== void 0 ? _r : 0,
                                    " mm")),
                            variant === 'DESIGN' && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                    React.createElement("div", { className: "text-gray-600 text-xs font-semibold" },
                                        "SCOP at ",
                                        design.flow_temp,
                                        " \u00B0C"),
                                    React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" }, scop)),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                    React.createElement("div", { className: "text-gray-600 text-xs font-semibold" },
                                        "Capacity at ",
                                        heatPumpCapacityResult.flowTempC,
                                        " \u00B0C (",
                                        heatPumpCapacityResult.outsideTempC,
                                        " \u00B0C)"),
                                    React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" },
                                        React.createElement(Badge, { color: heatPumpCapacityResult.capacityKw === 0 || totalHeatLossKW === 0 ? 'YELLOW' : heatPumpCapacityResult.capacityKw >= totalHeatLossKW ? heatPumpCapacityResult.warning !== undefined ? 'YELLOW' : 'GREEN' : 'RED', text: `${heatPumpCapacityResult.capacityKw} kW` })))))),
                    variant === 'DESIGN' && heatPumpCapacityResult.warning &&
                        React.createElement(ClickableCard, { variant: 'WARNING', border: false, size: 'SM', className: 'text-xs px-5 py-2' },
                            React.createElement("div", { className: "flex-col gap-2" },
                                React.createElement("div", { className: "font-bold" }, " Warning"),
                                React.createElement("div", null, heatPumpCapacityResult.warning))))));
};
