import React, { useEffect, useState } from 'react';
import { SettingsLayout } from '../components/settings_layout';
import { Section } from '../../../../components/containers/section';
import { Button } from '../../../../components/buttons/button';
import { Text } from '../../../../components/content_display/text';
export const AccountTools = () => {
    const [databaseExists, setDatabaseExists] = useState(false);
    useEffect(() => {
        const main = async () => {
            const check = await checkDatabaseExists('spruce');
            setDatabaseExists(check);
        };
        main();
    }, []);
    return React.createElement(SettingsLayout, { heading: "Developer tools", description: "" },
        React.createElement(Section, { border: true, title: 'Reset local database' },
            React.createElement("div", { className: "flex flex-col gap-6" },
                React.createElement(Text, { size: 'SM' }, "Warning, this will delete any local data on your device that has not synced with the server. Use this only if your device is failing to sync with the server and you are happy with any local data loss."),
                React.createElement("div", { className: "flex" },
                    React.createElement(Button, { confirmText: "Reset local database", disabled: !databaseExists, onClick: () => {
                            const request = indexedDB.deleteDatabase('spruce');
                            request.onsuccess = () => {
                                setDatabaseExists(false);
                                localStorage.setItem('last_synced', '0');
                            };
                        } }, "Reset local database")))));
};
const checkDatabaseExists = (dbName) => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName);
        request.onsuccess = function (event) {
            event.target.result.close();
            resolve(true);
        };
        request.onupgradeneeded = function (event) {
            event.target.transaction.abort();
            resolve(false);
        };
        request.onerror = function () {
            reject(request.error);
        };
    });
};
