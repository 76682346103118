import React from 'react';
import { Heading } from '../../../../components/content_display/heading';
import { Icon } from '../../../../components/buttons/icon';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { ChevronLeft, X } from 'lucide-react';
import { WrappedIcon } from '../../../../components/buttons/wrapped_icon';
export const FullscreenPreviewModal = (props) => {
    if (!props.visible)
        return null;
    return React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                paddingTop: 'env(safe-area-inset-top)',
                paddingRight: 'env(safe-area-inset-right)',
                paddingBottom: 'env(safe-area-inset-bottom)',
                paddingLeft: 'env(safe-area-inset-left)'
            }, className: 'fixed top-0 left-0 w-full h-full bg-white z-10' },
            React.createElement("div", { className: 'bg-white rounded-md flex flex-col w-full h-full' },
                React.createElement("div", { className: 'p-5 flex justify-between items-center border-b border-gray-200' },
                    React.createElement("div", { className: 'flex flex-row gap-4 items-center' },
                        React.createElement(Icon, { icon: ChevronLeft, onClick: () => props.setVisible(false) }),
                        React.createElement(Heading, { size: "xl" }, props.title)),
                    React.createElement(WrappedIcon, { icon: X, onClick: () => props.setVisible(false), className: 'cursor-pointer' })),
                React.createElement("div", { className: 'flex flex-row justify-between items-center h-[calc(100vh-74px)]' },
                    React.createElement("div", { className: 'hidden lg:block bg-gray-100 h-[calc(100vh-74px)] flex-grow overflow-x-scroll p-6' },
                        React.createElement("div", { className: 'bg-white max-w-[260mm] m-auto pb-6 rounded' }, props.previewContent)),
                    React.createElement("div", { className: 'w-full lg:max-w-96 lg:min-w-96 h-full flax flex-col gap-0' },
                        React.createElement("div", { className: "text-center text-gray-900 font-bold p-4 border-b border-gray-200" }, props.sidebarTitle),
                        React.createElement("div", { className: 'h-[calc(100vh-130px)] overflow-scroll w-full flex flex-col gap-0 p-5 items-center' },
                            React.createElement(Alert, { className: 'block lg:hidden w-full mb-4', type: "INFO" }, "To view the preview, please open Spruce on a tablet or desktop device."),
                            props.sidebarContent))))));
};
