import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Button } from '../../../../../components/buttons/button';
import { Heading } from '../../../../../components/content_display/heading';
import { Text } from '../../../../../components/content_display/text';
import { Link } from '../../../../../components/buttons/link';
import { noop } from 'lodash';
import { DEFAULT_BRAND_COLOUR_PRIMARY, DEFAULT_BRAND_COLOUR_SECONDARY, determineBestTextShade } from '../../../../../code/utils/colour_utils';
import { validateHexColourCode } from '../../../../../code/validators';
import { RefreshCw } from 'lucide-react';
export const BrandColoursBlock = ({ company, setCompany }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const handleResetColours = async () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                colour_primary: DEFAULT_BRAND_COLOUR_PRIMARY,
                colour_secondary: DEFAULT_BRAND_COLOUR_SECONDARY
            }
        };
        setCompany(newCompany);
    };
    return React.createElement(Section, { border: true, title: "Company colours" },
        React.createElement("div", { className: 'grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-2' },
            React.createElement("div", { className: 'flex flex-col justify-between gap-4 row-start-2 md:row-start-1' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement(FormLabel, { labelText: 'Primary', helperText: 'Used for main buttons.' }),
                    React.createElement(ColorInput, { value: (_a = company.public_info.colour_primary) !== null && _a !== void 0 ? _a : DEFAULT_BRAND_COLOUR_PRIMARY.toUpperCase(), setValue: colour => {
                            if (!colour)
                                return;
                            if (validateHexColourCode(colour !== null && colour !== void 0 ? colour : DEFAULT_BRAND_COLOUR_PRIMARY).value) {
                                setCompany({ ...company, public_info: { ...company.public_info, colour_primary: colour } });
                            }
                        } }),
                    React.createElement(FormLabel, { labelText: 'Secondary', helperText: 'Used for background accents.' }),
                    React.createElement(ColorInput, { value: (_b = company.public_info.colour_secondary) !== null && _b !== void 0 ? _b : DEFAULT_BRAND_COLOUR_SECONDARY.toUpperCase(), setValue: colour => {
                            if (!colour)
                                return;
                            if (validateHexColourCode(colour !== null && colour !== void 0 ? colour : DEFAULT_BRAND_COLOUR_SECONDARY).value) {
                                setCompany({ ...company, public_info: { ...company.public_info, colour_secondary: colour } });
                            }
                        } })),
                React.createElement(Button, { colour: 'LIGHT', iconLeft: RefreshCw, disabled: (!company.public_info.colour_primary || company.public_info.colour_primary === DEFAULT_BRAND_COLOUR_PRIMARY) && (!company.public_info.colour_secondary || company.public_info.colour_secondary === DEFAULT_BRAND_COLOUR_SECONDARY), onClick: handleResetColours, confirmText: 'Are you sure?' }, "Reset to defaults")),
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement(FormLabel, { labelText: 'Preview', helperText: 'This is what your estimates will look like' }),
                React.createElement("div", { style: { backgroundColor: (_c = company.public_info.colour_secondary) !== null && _c !== void 0 ? _c : DEFAULT_BRAND_COLOUR_SECONDARY }, className: 'w-full h-full border rounded-lg' },
                    React.createElement("div", { className: "px-6 py-10 flex flex-col items-center w-full gap-5 rounded-lg" },
                        React.createElement("div", { className: "flex flex-col gap-3 text-center" },
                            React.createElement(Heading, { size: "2xl", className: determineBestTextShade((_d = company.public_info.colour_secondary) !== null && _d !== void 0 ? _d : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Book a full heat loss survey today"),
                            React.createElement(Text, { className: determineBestTextShade((_e = company.public_info.colour_secondary) !== null && _e !== void 0 ? _e : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Or request a callback to discuss your needs in more detail.")),
                        React.createElement("div", { className: "w-full max-w-80 flex flex-col gap-3 items-center" },
                            React.createElement(Button, { style: {
                                    backgroundColor: company.public_info.colour_primary,
                                    borderColor: company.public_info.colour_primary,
                                    color: determineBestTextShade((_f = company.public_info.colour_primary) !== null && _f !== void 0 ? _f : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                                }, className: 'w-full', onClick: noop }, "View cost estimate"),
                            React.createElement(Button, { colour: 'LIGHT', className: 'w-full' }, "Request a callback"),
                            React.createElement(Link, { className: determineBestTextShade((_g = company.public_info.colour_secondary) !== null && _g !== void 0 ? _g : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? '' : 'text-white', text: 'I\u2019m not interested right now', onClick: noop })))))));
};
const ColorInput = ({ value, setValue, disabled }) => {
    const handleColourChange = (e) => {
        setValue(e.target.value);
    };
    return React.createElement("div", { className: 'flex gap-2' },
        React.createElement(Input, { value: value.toUpperCase(), setValue: e => setValue(e), validator: validateHexColourCode, validateImmediately: true }),
        React.createElement("div", { style: { backgroundColor: value }, className: `rounded-lg border max-h-9 border-gray-300 ${disabled === true ? 'opacity-70' : 'opacity-100'}` },
            React.createElement("input", { type: 'color', className: 'opacity-0 h-full disabled:cursor-auto cursor-pointer', onChange: handleColourChange, value: value })));
};
