import { getCompanyDeclinedEmailTemplate } from '../../../../../code/models/company';
import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { Tiptap } from '../../../../../components/inputs_and_selections/tiptap';
import { defaultPlaceholders, TTPlaceholderCustomerAddress, TTPlaceholderCustomerName } from '../../../../../code/tiptap_placeholders';
export const DeclineEnquiryEmailTemplateBlock = ({ company, setCompany }) => {
    var _a;
    return React.createElement(Section, { border: true, title: "Decline enquiry email template" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement(Tiptap, { editable: true, className: 'w-full rounded border border-gray-300 p-2 focus:outline-none', onUpdateCallback: async (editor) => {
                    setCompany({
                        ...company,
                        decline_template: JSON.stringify(editor.getJSON())
                    });
                }, placeholders: [
                    TTPlaceholderCustomerName,
                    TTPlaceholderCustomerAddress,
                    ...defaultPlaceholders
                ], content: getCompanyDeclinedEmailTemplate((_a = company.decline_template) !== null && _a !== void 0 ? _a : '') })));
};
