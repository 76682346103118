import React from 'react';
import { ElectricsIncomingMainAndFusePhotosBlock } from './electrics/electrics_incoming_main_and_fuse_photos_block';
import { ElectricsIncomingMainAndFuseDetailsBlock } from './electrics/electrics_incoming_main_and_fuse_details_block';
import { ElectricsEarthingArrangementPhotosBlock } from './electrics/electrics_earthing_arrangement_photos_block';
import { ElectricsEarthingArrangementDetailsBlock } from './electrics/electrics_earthing_arrangement_details_block';
import { ElectricsConsumerUnitPhotosBlock } from './electrics/electrics_consumer_unit_photos_block';
import { ElectricsConsumerUnitDetailsBlock } from './electrics/electrics_consumer_unit_details_block';
import { ElectricsDirectLoadsBlock } from './electrics/electrics_direct_loads_block';
import { ElectricsCableRoutingBlock } from './electrics/electrics_cable_routing_block';
import { SurveySectionFlowWrapper } from './components/survey_section_flow_wrapper';
export const SurveyElectricsContext = React.createContext(undefined);
export const Electrics = ({ survey, files, setFiles, setSurvey, companyUUID, isOffline, allSynced }) => {
    // WARNING! Never change flagBits for the pages because they are stored in the DB.
    // instead, add new pages or replace existing with new flags
    const pages = [
        { flagBit: 0x001, pbValue: 10, page: React.createElement(ElectricsIncomingMainAndFusePhotosBlock, null) },
        { flagBit: 0x002, pbValue: 30, page: React.createElement(ElectricsIncomingMainAndFuseDetailsBlock, null) },
        { flagBit: 0x004, pbValue: 40, page: React.createElement(ElectricsEarthingArrangementPhotosBlock, null) },
        { flagBit: 0x008, pbValue: 60, page: React.createElement(ElectricsEarthingArrangementDetailsBlock, null) },
        { flagBit: 0x010, pbValue: 70, page: React.createElement(ElectricsConsumerUnitPhotosBlock, null) },
        { flagBit: 0x020, pbValue: 80, page: React.createElement(ElectricsConsumerUnitDetailsBlock, null) },
        { flagBit: 0x040, pbValue: 90, page: React.createElement(ElectricsDirectLoadsBlock, null) },
        { flagBit: 0x080, pbValue: 100, page: React.createElement(ElectricsCableRoutingBlock, null) }
    ];
    return React.createElement(SurveyElectricsContext.Provider, { value: { isOffline, allSynced, survey, setSurvey, files, setFiles, companyUUID } },
        React.createElement(SurveySectionFlowWrapper, { isOffline: isOffline, allSynced: allSynced, completeSectionID: 'ELECTRICS', sectionUIName: 'Electrics', pages: pages, flags_attribute_name: 'flags_electrics_pages_completed', onPageComplete: (flagBit) => {
                setSurvey({ ...survey, flags_electrics_pages_completed: survey.flags_electrics_pages_completed | flagBit });
            }, onFormComplete: () => window.history.back(), survey: survey, setSurvey: setSurvey, completionChecks: [] }));
};
