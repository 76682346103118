import React from 'react';
import { Section } from '../../../../../components/containers/section';
import { TabGroup } from '../../../../../components/content_display/tab';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Info } from '../../../../../components/buttons/info';
import { VerticalFormGroup } from '../../../../../components/inputs_and_selections/vertical_form_group';
import { Heading } from '../../../../../components/content_display/heading';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { TextArea } from '../../../../../components/inputs_and_selections/text_area';
// Spruce default next steps
export const NEXT_STEPS_ENGLAND_WALES = [
    {
        title: 'View your cost estimates',
        description: "Don't worry about making a decision now, at this point we want to understand your needs and preferences so we can work together to find the right option for you."
    },
    {
        title: 'Book a full heat loss survey',
        description: "If you'd like to proceed, then the next step is to conduct an on-site survey of your home and begin creating a quote using the data we gather."
    },
    {
        title: 'Arrange installation',
        description: 'Once the survey has been completed and we have formalised a quote, the final step is to arrange installation.'
    }
];
export const NEXT_STEPS_SCOTLAND = [
    {
        title: 'Book a full heat loss survey',
        description: 'The next step is to conduct an on-site survey of your home and create a detailed proposal.'
    },
    {
        title: 'Apply for HES funding',
        description: 'Submit your application for funding through Home Energy Scotland.'
    },
    {
        title: 'Schedule your installation',
        description: 'Once the funding is secured, the final step is to arrange the installation of your heat pump.'
    }
];
const NextStepsConfiguration = ({ region, company, setCompany }) => {
    const defaultNextSteps = region === 'England/Wales' ? NEXT_STEPS_ENGLAND_WALES : NEXT_STEPS_SCOTLAND;
    const next_steps_key = region === 'England/Wales' ? 'next_steps' : 'next_steps_scottish';
    const handleUpdateSteps = (stepIndex, value) => {
        // Parse company steps to Step[]
        // Update the step at stepIndex
        // Set company steps to newSteps (after converting it back into JSON)
        const newSteps = (company.public_info[next_steps_key] ? JSON.parse(company.public_info[next_steps_key]) : defaultNextSteps).map((step, index) => index === stepIndex ? value : step);
        setCompany({ ...company, public_info: { ...company.public_info, [next_steps_key]: JSON.stringify(newSteps) } });
    };
    const parsedCompanyNextSteps = company.public_info[next_steps_key] ? JSON.parse(company.public_info[next_steps_key]) : undefined;
    return React.createElement("div", { className: 'flex flex-col gap-8' }, [...Array(3)].map((e, index) => {
        var _a;
        return React.createElement("div", { className: 'flex flex-col gap-4', key: `next_steps_${index}` },
            React.createElement(Heading, { size: 'lg' },
                "Step ",
                index + 1),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Title' }), input: React.createElement(Input, { value: (parsedCompanyNextSteps !== null && parsedCompanyNextSteps !== void 0 ? parsedCompanyNextSteps : defaultNextSteps)[index].title, setValue: val => handleUpdateSteps(index, { ...(parsedCompanyNextSteps !== null && parsedCompanyNextSteps !== void 0 ? parsedCompanyNextSteps : defaultNextSteps)[index], title: val }) }) }),
            React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Description' }), input: React.createElement(TextArea, { value: ((_a = parsedCompanyNextSteps !== null && parsedCompanyNextSteps !== void 0 ? parsedCompanyNextSteps : parsedCompanyNextSteps) !== null && _a !== void 0 ? _a : defaultNextSteps)[index].description, setValue: val => handleUpdateSteps(index, { ...(parsedCompanyNextSteps !== null && parsedCompanyNextSteps !== void 0 ? parsedCompanyNextSteps : defaultNextSteps)[index], description: val }) }) }));
    }));
};
export const EstimateNextStepsBlock = ({ company, setCompany }) => {
    const [currentTab, setCurrentTab] = React.useState('England/Wales');
    return React.createElement(Section, { border: true, title: React.createElement("div", { className: 'flex items-center gap-1' },
            "Next steps ",
            React.createElement(Info, { infoModalHeader: "Property area", infoModalBody: "We allow you to customise the 'next steps' depending on property location as we find this process usually varies.\n    We show the homeowner the correct information depending on their location." })) },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement(TabGroup, { items: [
                    { name: 'England/Wales', onClick: () => setCurrentTab('England/Wales'), variant: currentTab === 'England/Wales' ? 'ACTIVE' : 'DEFAULT' },
                    { name: 'Scotland', onClick: () => setCurrentTab('Scotland'), variant: currentTab === 'Scotland' ? 'ACTIVE' : 'DEFAULT' }
                ] }),
            React.createElement(NextStepsConfiguration, { region: currentTab, company: company, setCompany: setCompany })));
};
