import React from 'react';
import { Badge } from '../../../components/indicators_and_messaging/badge';
import { Check } from 'lucide-react';
export const BadgeDemo = () => {
    return React.createElement("div", { className: "flex flex-col gap-8" },
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Definition"),
            React.createElement("pre", { className: 'bg-gray-100 rounded-lg p-4 overflow-x-auto' },
                React.createElement("code", null, codeBlock)),
            React.createElement("div", { className: 'flex flex-col divide-y divide-dashed divide-gray-200 text-sm' }, componentProps.map(x => React.createElement("div", { key: x.name, className: 'grid grid-cols-2 py-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, x.name),
                React.createElement("div", null, x.description))))),
        React.createElement("div", { className: 'flex flex-col gap-4' },
            React.createElement("div", { className: 'text-2xl font-bold text-gray-900' }, "Component"),
            React.createElement("div", { className: 'flex gap-2' },
                React.createElement(Badge, { text: 'My badge', color: 'BLUE', icon: Check }),
                React.createElement(Badge, { text: 'My badge', color: 'DARK' }),
                React.createElement(Badge, { text: 'My badge', color: 'GREEN' }),
                React.createElement(Badge, { text: 'My badge', color: 'INDIGO' }),
                React.createElement(Badge, { text: 'My badge', color: 'LIGHT' }),
                React.createElement(Badge, { text: 'My badge', color: 'RED' }),
                React.createElement(Badge, { text: 'My badge', color: 'YELLOW' }))));
};
const componentProps = [
    { name: 'text', description: 'Primary badge text' },
    { name: 'color', description: 'Primary badge colour' },
    { name: 'icon', description: 'Font awesome icon, if present shows on the left' },
    { name: 'button', description: 'Button to show on the right' }
];
const codeBlock = `<Badge
  text='My badge'
  color='BLUE'
  icon={Check}
/>`;
